import dayjs from 'dayjs';

export const formatDate_YYYY_MM_DD = (date) => {
  return date ? dayjs(date).format('YYYY-MM-DD') : null;
};

export const formatDate_DD_MMM_YYYY = (date) => {
  return date ? dayjs(date).format('DD-MMM-YYYY') : null;
};

export const formatDate2_DD_MMM_YYYY = (
  date,
  options = { withDate: false, format: 'DD MMM, YYYY' }
) => {
  const format = options.withDate ? 'hh:mmA DD, MMM, YYYY' : options.format;
  return date ? dayjs(date).format(format ?? 'DD MMM, YYYY') : null;
};
export const getHour = (date) => {
  return date ? dayjs(date).format('H') : null;
};
export const getDuration = (startDate, endDate) => {
  const yearDiff = dayjs(endDate).diff(dayjs(startDate), 'year');
  if (yearDiff > 1) {
    return yearDiff + ' years';
  }
  const monthDiff = dayjs(endDate).diff(dayjs(startDate), 'month');
  if (monthDiff > 1) {
    return monthDiff + ' months';
  }
  const dayDiff = dayjs(endDate).diff(dayjs(startDate), 'day');

  return dayDiff + ' days';
};
