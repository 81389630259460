import { useEffect } from 'react';
import { Box } from '@material-ui/core';
import lottie from 'lottie-web';
import reactLogo from './loader-lottie.json';

const Loader = () => {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#react-logo'),
      animationData: reactLogo,
      renderer: 'svg', // "canvas", "html"
      loop: true, // boolean
      autoplay: true // boolean
    });
  }, []);
  return (
    <Box
      style={{
        width: '60%',
        margin: '5em auto',
        textAlign: 'center'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <div
          id='react-logo'
          style={{
            width: 300,
            height: 300
          }}
        />
      </div>
    </Box>
  );
};

export default Loader;
