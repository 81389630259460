export const convertToSmallLetter = (data) => {
  const newValue = data.toLowerCase();
  return newValue;
};

export const capitalize = (text, all = false) =>
  all
    ? text
        .split(' ')
        .map((text) => text.charAt(0).toUpperCase() + text.substring(1))
        .join(' ')
    : text.charAt(0).toUpperCase() + text.substring(1);

export const capitalizeAll = (texts) =>
  texts
    .split(' ')
    .map((text) => text.charAt(0).toUpperCase() + text.substring(1))
    .join(' ');
