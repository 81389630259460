import { Box } from '@material-ui/core';
import { format } from 'date-fns';
import styled from 'styled-components';

const JobDetail = ({ formik, data }) => {
  return (
    <JobDetail.Wrapper>
      <div className='rounded p-3'>
        {data?.overview && (
          <>
            <h4 className='body_header'>About Company</h4>
            <p className='body_content mb-4'>{data.overview}</p>
          </>
        )}
        {(formik?.values?.description || formik?.description) && (
          <>
            <h4 className='body_header'>Job Description</h4>
            <p
              className='body_content'
              dangerouslySetInnerHTML={{
                __html: formik?.values?.description || formik?.description
              }}
            ></p>
          </>
        )}
        {(formik?.values?.responsibilities || formik?.responsibilities) && (
          <>
            <h4 className='body_header mt-4'>Job Responsibilities</h4>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  formik?.values?.responsibilities || formik?.responsibilities
              }}
            />
          </>
        )}
        <>
          <h4 className='body_header mt-4'>
            Requirements, Skills & Qualifications
          </h4>
          {/* {formik?.values?.degree_qualification && (
            <>
              <li className='mb-3'>
                  Must have a minimum of {formik?.values?.degree_qualification}
                </li>
            </>
          )} */}
          {(formik?.values?.additional_requirements ||
            formik?.additional_requirements) && (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  formik?.values?.additional_requirements ||
                  formik?.additional_requirements
              }}
            />
          )}
          <ul>
            {(formik?.values?.years_of_experience ||
              formik?.years_of_experience) && (
              <li className='mb-3'>
                You have at least{' '}
                {formik?.values?.years_of_experience?.replaceAll('_', ' ') ||
                  formik?.years_of_experience?.replaceAll('_', ' ')}{' '}
                years of work experience
              </li>
            )}
            {formik?.degree_qualification?.title && (
              <li className='mb-3'>
                Must have a minimum of {formik?.degree_qualification?.title}
              </li>
            )}
          </ul>
          {(formik?.values?.benefits || formik?.benefits) && (
            <>
              <h4 className='body_header mt-4'>Benefits</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: formik?.values?.benefits || formik?.benefits
                }}
              />
            </>
          )}
          {(formik?.values?.fix_salary || formik?.fix_salary) === true && (
            <div className='mt-4 salary_text'>
              <p>
                Estimated salary: ₦{' '}
                {`${formik?.values?.salary || formik?.salary} ${
                  formik?.rate
                    ? formik?.rate
                    : '' || formik?.values?.rate
                    ? formik?.values?.rate
                    : ''
                }`}
              </p>
            </div>
          )}
          {(formik?.values?.fix_salary || formik?.fix_salary) === false && (
            <div className='mt-4 salary_text'>
              <p>
                Salary Range: ₦
                {`${formik?.values?.min_salary || formik?.min_salary}`} - ₦
                {`${formik?.values?.max_salary || formik?.max_salary} ${
                  formik?.rate
                    ? formik?.rate
                    : '' || formik?.values?.rate
                    ? formik?.values?.rate
                    : ''
                }`}
              </p>
            </div>
          )}
          {(formik?.values?.deadline || formik?.deadline) && (
            <div className='mt-4 application_deadline'>
              <p>
                Application deadline:{' '}
                {format(
                  new Date(formik?.values?.deadline || formik?.deadline),
                  'MMM dd yyyy'
                )}
              </p>
            </div>
          )}

          {(formik?.instruction || formik?.values?.instruction) && (
            <div>
              <h4 className='body_header mt-4'>Application Instructions</h4>

              <p
                dangerouslySetInnerHTML={{
                  __html: formik?.instruction || formik?.values?.instruction
                }}
              ></p>
            </div>
          )}
        </>
      </div>
    </JobDetail.Wrapper>
  );
};

export default JobDetail;

JobDetail.Wrapper = styled(Box)``;
