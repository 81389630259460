export const baseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://coolowo.com'
    : 'https://coolowo.com';

export const personalInformationFormDataKey = 'personal-information-form-data';
export const jobInformationFormDataKey = 'job-information-form-data';
export const compensationInformationFormDataKkey =
  'compensation-information-form-data';

export const NOT_AVAILABLE = 'N/A';
export const NOT_SPECIFIED = 'N/S';
export const BLANK = '__ __';

export const SNACK_VARIANT = {
  ERR: 'error',
  SUCC: 'success'
};

export const STEPS = {
  one: 0,
  two: 1,
  three: 2,
  four: 3,
  five: 4,
  six: 5,
  seven: 6,
  eight: 7
};

export const TABS = {
  TAB1: 0,
  TAB2: 1,
  TAB3: 2,
  TAB4: 3,
  TAB5: 4,
  TAB6: 5,
  TAB7: 6
};

export const ANTD_PLACEMENT = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
  TOPLEFT: 'topLeft',
  TOPRIGHT: 'topRight',
  BOTTOMLEFT: 'bottomLeft',
  BOTTOMRIGHT: 'bottomRight',
  LEFTTOP: 'leftTop',
  LEFTBOTTOM: 'leftBottom',
  RIGHTTOP: 'rightTop',
  RIGHTBOTTOM: 'rightBottom'
};
