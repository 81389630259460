import { useState, useEffect } from 'react';
import { getBusinessTypes } from '../../../api/common';
import { FormControl, MenuItem } from './styled.select-fields';
import { InputLabel, Select, CircularProgress } from '@material-ui/core';

function BusinessTypes({ value, onChange, OnBlur, ...props }) {
  const [isFetching, setIsFetching] = useState(false);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    async function getData() {
      try {
        setIsFetching(true);
        const data = await getBusinessTypes();
        setTypes(data);
      } finally {
        setIsFetching(false);
      }
    }

    getData();
  }, []);

  return (
    <FormControl variant='outlined' size='small'>
      <InputLabel id='business-type' color='secondary'>
        Business type
      </InputLabel>
      <Select
        labelId='business-type-label'
        id='business-type'
        name='type'
        value={value}
        color='secondary'
        onChange={onChange}
        onBlur={OnBlur}
        {...props}
        label='Business type'
      >
        {!isFetching ? (
          types.map((type) => (
            <MenuItem key={type[0]} value={type[0]}>
              {type[1]}
            </MenuItem>
          ))
        ) : (
          <CircularProgress color='secondary' />
        )}
      </Select>
    </FormControl>
  );
}

//TODO: use error boundary

export default BusinessTypes;
