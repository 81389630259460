import { Box, Grid, MenuItem, TextField } from '@material-ui/core';
import { CustomText } from 'common/Reusables';

import React from 'react';

const ReferenceForm = ({ title }) => {
  if (title.includes('Personal')) {
    return (
      <Box>
        <CustomText fontWeight={600} fontFamily='Black Sans' fontSize='14px'>
          {title}
        </CustomText>
        <Grid
          container
          spacing={1}
          className='mb-3'
          style={{ padding: '10px' }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              width: '100%'
            }}
          >
            <TextField
              placeholder='Title*'
              label='Title*'
              select
              style={{
                width: '20%'
              }}
              disabled
              variant='outlined'
              fullWidth
              size='small'
              color='secondary'
            >
              <MenuItem selected>Mr</MenuItem>
              <MenuItem selected>Mrs</MenuItem>
              <MenuItem selected>Dr</MenuItem>
              <MenuItem selected>Prof</MenuItem>
              <MenuItem selected>Miss</MenuItem>
              <MenuItem selected>Ms</MenuItem>
            </TextField>
            <TextField
              placeholder='First name*'
              disabled
              style={{
                width: '50%'
              }}
              variant='outlined'
              fullWidth
              size='small'
              color='secondary'
            />
            <TextField
              placeholder='Last name*'
              disabled
              style={{
                width: '50%'
              }}
              variant='outlined'
              fullWidth
              size='small'
              color='secondary'
            />
          </Box>
          <TextField
            disabled
            placeholder='Relationship'
            label='Relationship'
            select
            variant='outlined'
            fullWidth
            size='small'
            color='secondary'
          >
            <MenuItem selected>Parent</MenuItem>
            <MenuItem selected>Uncle</MenuItem>
            <MenuItem selected>Aunt</MenuItem>
            <MenuItem selected>Sibling</MenuItem>
          </TextField>

          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              width: '100%'
            }}
          >
            <TextField
              style={{
                width: '60%'
              }}
              disabled
              placeholder='Email Address*'
              variant='outlined'
              fullWidth
              size='small'
              color='secondary'
            />
            <TextField
              style={{
                width: '40%'
              }}
              placeholder='Phone Number*'
              disabled
              variant='outlined'
              fullWidth
              size='small'
              color='secondary'
            />
          </Box>
          <TextField
            disabled
            placeholder='Residential Address*'
            variant='outlined'
            fullWidth
            size='small'
            color='secondary'
          />
        </Grid>
      </Box>
    );
  }
  return (
    <Box>
      <CustomText fontWeight={600} fontFamily='Black Sans' fontSize='14px'>
        {title}
      </CustomText>
      <Grid container spacing={1} className='mb-3' style={{ padding: '10px' }}>
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            width: '100%'
          }}
        >
          <TextField
            placeholder='Title*'
            label='Title*'
            select
            style={{
              width: '20%'
            }}
            disabled
            variant='outlined'
            fullWidth
            size='small'
            color='secondary'
          >
            <MenuItem selected>Mr</MenuItem>
            <MenuItem selected>Mrs</MenuItem>
            <MenuItem selected>Dr</MenuItem>
            <MenuItem selected>Prof</MenuItem>
            <MenuItem selected>Miss</MenuItem>
            <MenuItem selected>Ms</MenuItem>
          </TextField>
          <TextField
            placeholder='First name*'
            disabled
            style={{
              width: '50%'
            }}
            variant='outlined'
            fullWidth
            size='small'
            color='secondary'
          />
          <TextField
            placeholder='Last name*'
            disabled
            style={{
              width: '50%'
            }}
            variant='outlined'
            fullWidth
            size='small'
            color='secondary'
          />
        </Box>

        <TextField
          disabled
          placeholder='Job Title*'
          variant='outlined'
          fullWidth
          size='small'
          color='secondary'
        />

        <TextField
          placeholder='Company Name*'
          disabled
          variant='outlined'
          fullWidth
          size='small'
          color='secondary'
        />

        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            width: '100%'
          }}
        >
          <TextField
            style={{
              width: '60%'
            }}
            disabled
            placeholder='Work Email Address*'
            variant='outlined'
            fullWidth
            size='small'
            color='secondary'
          />
          <TextField
            style={{
              width: '40%'
            }}
            placeholder='Phone Number*'
            disabled
            variant='outlined'
            fullWidth
            size='small'
            color='secondary'
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default ReferenceForm;
