import ReportModal from 'Assets/Maintenance/ReportIssue/modals/ReportModal';
import MarkModal from 'Assets/Modals/MarkModal';
import { useFetchReportedIssues } from 'Assets/queries';
import CustomMenu from 'components/CustomMenu/CustomeMenu';

import Table, { TableEmpty } from 'components/CustomTable/Table';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import { BLANK } from 'constant';
import useDisclosure from 'hooks/useDisclosure';
import { useState } from 'react';
const columns = [
  'Issue',
  'Description',
  'Reported By',
  'Status',
  'Reported Date',
  'Proprity',
  ''
];
const ReportedIssues = ({ assetId, toggleMaintenance }) => {
  const [toView, setToView] = useState(null);

  const { data, isLoading, isError } = useFetchReportedIssues({
    asset_pk: assetId
  });
  const [open, { toggle }] = useDisclosure();

  const [mark, setMark] = useState(null);

  const markAs = ({ mark, id }) => {
    toggle();
    setMark({ mark, id });
  };

  const rowData = data?.results ?? [];

  const viewModalOpen = !!toView;
  const close = () => {
    setToView(null);
  };

  return (
    <div>
      <Table>
        {!isLoading && rowData?.length < 1 ? (
          <TableEmpty className='asset_report_empty'>
            <Title textAlign='center'>You have no reported issues</Title>
          </TableEmpty>
        ) : (
          <>
            <Table.Head column={columns} />
            <Table.Body
              isLoading={isLoading}
              isError={isError}
              data={rowData}
              renderRow={(row) => {
                return (
                  <>
                    <Text>{row?.issue_type?.name ?? BLANK}</Text>
                    <Text>{row?.message ?? BLANK}</Text>
                    <Text>{row?.employee?.first_name ?? BLANK}</Text>
                    <Text>{row?.status ?? BLANK}</Text>
                    <Text>{row?.date ?? BLANK}</Text>
                    <Text>{row?.priority_level ?? BLANK}</Text>
                    <div style={{ textAlign: 'right' }}>
                      <CustomMenu
                        size={'xs'}
                        width={350}
                        divideAll
                        options={[
                          {
                            label: 'View Report',
                            onClick: () => {
                              setToView(row);
                            }
                          },
                          {
                            label: 'Edit Report',
                            onClick(_, handleClose) {
                              setToView(row);
                              handleClose();
                            }
                          },

                          {
                            label: 'Mark as sent for Inspection',
                            onClick(_, handleClose) {
                              markAs({ id: row?.id, mark: 'inspection' });
                              handleClose();
                            }
                          },

                          {
                            label: 'Delete Report',
                            style: { color: 'red' }
                          }
                        ]}
                      />
                    </div>
                  </>
                );
              }}
            />
          </>
        )}
      </Table>
      {open && (
        <MarkModal
          addAction={toggleMaintenance}
          open={open}
          toggle={toggle}
          mark={mark}
        />
      )}
      {!!toView && (
        <ReportModal open={viewModalOpen} toggle={close} data={toView} />
      )}
    </div>
  );
};

export default ReportedIssues;
