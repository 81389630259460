import _ from 'validator';

const validator = (values) => {
  const errors = {};

  if (!_.isEmail(values.email)) {
    errors.email = 'Enter a valid email address';
  }

  if (!values.password) {
    errors.password = 'Enter a password';
  }
  return errors;
};

export const validateSignup = (values) => {
  const errors = {};

  if (!_.isEmail(values.email)) {
    errors.email = 'Enter a valid email address';
  }

  if (!values.password) {
    errors.password = 'Enter a password';
  }
  if (values.password.length < 8) {
    errors.password = 'Password is too short';
  }
  if (_.isNumeric(values.password)) {
    errors.password = 'Password cannot contain only numbers.';
  }
  return errors;
};

export const validateBusinessCreation = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Enter a valid name';
  }

  if (!values.first_name) {
    errors.first_name = 'Enter a valid first name';
  }

  if (!values.last_name) {
    errors.last_name = 'Enter a valid last name';
  }

  // if (
  //   !values.phone_number ||
  //   values.phone_number.length < 11 ||
  //   values.phone_number.length > 12
  // ) {
  //   errors.phone_number = 'Enter a valid phone number';
  // }

  // if (!values.type) {
  //   errors.type = 'Select a business type';
  // }

  if (!values.address1) {
    errors.address1 = 'Enter a valid address';
  }

  if (!values.country) {
    errors.country = 'Select a country';
  }

  if (!values.state) {
    errors.state = 'Select a state';
  }

  if (!values.city) {
    errors.city = 'Enter a city';
  }
  return errors;
};

export const NewAppraisal = (values) => {};

export default validator;
