import { CustomButton as Button } from 'components/Button';
import CircularLoader from 'components/CircularLoader';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from 'components/Dialog';
import Group from 'components/StyledComponents/ButtonGroup';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import { runIfFn } from 'utilities/utils';
import { useRemoveAssets } from '../queries';

const DeleteModal = ({ open, toggle, onDeleteComplete, data }) => {
  const mutation = useRemoveAssets({
    onSuccess() {
      runIfFn(onDeleteComplete, toggle);
    }
  });
  const handleDelete = () => {
    mutation.mutate({ ids: data });
  };
  return (
    <Dialog
      rounded='15px'
      style={{ borderRadius: '15px' }}
      open={open}
      toggle={toggle}
      fullWidth
      maxWidth='xs'
    >
      <DialogTitle onClose={toggle} divider>
        <Title>Permanently Delete Asset</Title>
      </DialogTitle>
      <DialogContent>
        <Text size='xs' my='.4rem'>
          Are you sure you want to permanently delete this asset and all items
          associated with it ?
        </Text>
      </DialogContent>
      <DialogActions>
        <Group fullWidth>
          <Button
            onClick={handleDelete}
            size='xs'
            disabled={mutation.isLoading}
            fullWidth
            variant='contained_warning'
          >
            <CircularLoader show={mutation.isLoading} /> Yes Delete
          </Button>
          <Button onClick={toggle} size='xs' fullWidth variant='outlined'>
            No, don't delete
          </Button>
        </Group>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
