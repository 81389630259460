/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { disableSubmit, FormikDocumentCreation } from './utils';
import { useMutation, useQueryClient } from 'react-query';
import DescriptionIcon from '@material-ui/icons/Description';
import {
  createStatutoryDocument,
  uploadVehicleFile,
  editVehicleFile,
  deleteOneUploadedAttachment
} from '../../api/fleet';
import moment from 'moment';
import useFleetControl from '../../hooks/useFleetControl';
import useVehicleDocumentsControl from '../../hooks/useVehicleDocumentsControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams } from 'react-router';

// ====== Main imports
import DocumentTypes from './SelectFields/DocumentTypes';
import SetReminder from './SelectFields/SetReminder';
import ProgressBar from '../../HR/Home/ProgressBar';
import styled from 'styled-components';

const useStyles = makeStyles((theme) => ({
  Heading2: {
    ...theme.mixins.makeResponsive({ theme, fontSize: 18, object: true }),
    fontWeight: 700,
    marginBottom: '3%'
  },
  Heading6: {
    ...theme.mixins.makeResponsive({ theme, fontSize: 10, object: true }),
    fontWeight: 400,
    marginBottom: '1%'
  },
  Spacing: {
    margin: '10px 0'
  },
  AttachmentButton: {
    background: '#F5E9E9',
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    height: '50px',
    fontWeight: 700
  },
  FileName: {
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 6px rgba(116, 116, 116, 0.04)',
    borderRadius: '5px',
    padding: '10px 15px',
    color: '#2898A4',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem 0',
    backgroundColor: '#FAFAFA'
  },
  Link: {
    // textDecoration : "none",
    color: '#2898A4'
  },
  DeleteIcon: {
    cursor: 'pointer'
  },
  FileUploadDiv: {
    border: `1px dashed ${theme.palette.secondary.main}`,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '4% 3%',
    marginBotton: '100px',
    borderRadius: '5px',

    '& > * ': {
      display: 'block',
      margin: 'auto 0'
    },
    '& .MuiTypography-body1': {
      ...theme.mixins.makeResponsive({ theme, fontSize: 14, object: true }),
      fontWeight: 700,
      color: '#0B0B0B'
    },
    '& .MuiTypography-body2': {
      ...theme.mixins.makeResponsive({ theme, fontSize: 15, object: true }),
      fontWeight: 600,
      texAlign: 'right'
    },
    '& > .MuiBox-root': {
      background: '#E3FAFC',
      borderRadius: '4px',
      textTansform: 'capitalize',
      padding: '2% auto'
    }
  },
  UploadButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '.5rem',
    cursor: 'pointer'
  }
}));

function RenewStatutoryDocumentForm({ width, id, editMode = false, close }) {
  const classes = useStyles();
  const [documentsState, setDocumentsState] = useVehicleDocumentsControl();

  const { id: vehicleId } = useParams();

  const [showDelete, setShowDelete] = useState('');
  const [file, setFile] = useState('');
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState();
  const [fileId, setFileId] = useState('');
  const [disableSaveButton, setDisableSaveButton] = React.useState(false);

  const apiMethod = editMode
    ? createStatutoryDocument
    : createStatutoryDocument;
  const fileApiMethod = editMode ? editVehicleFile : uploadVehicleFile;

  const mutation = useMutation(apiMethod);
  const fileMutation = useMutation(fileApiMethod, {
    onSuccess: async () => {
      setDisableSaveButton(false);
    }
  });

  const fileDelete = useMutation(deleteOneUploadedAttachment, {
    onSuccess: async (id) => {
      if (!editMode) {
        setFiles((prev) => prev.filter((item) => item.id !== id));
        setFile((prev) => prev.filter((item) => item.id !== id));
        setFileId((prev) => prev.filter((item) => item !== id));
      }
    },
    onSettled: (data, error, editedValue) => {
      if (files && fileId) {
        setFiles((prev) => prev.filter((item) => item.id !== editedValue));
        setFileId((prev) => prev.filter((item) => item !== editedValue));
      }
      if (formik.values.attachments.length > 0) {
        const filtered = formik.values.attachments.filter(
          (item) => item.id !== editedValue
        );
        formik.values.attachments = filtered;
      }
    }
  });
  const queryClient = useQueryClient();
  const [fleetState, setFleetState] = useFleetControl();
  const formik = useFormik({
    initialValues: FormikDocumentCreation.initialValues(
      editMode ? documentsState.editDocumentDetails : {}
    ),
    validate: FormikDocumentCreation.validate,
    onSubmit: (values) => {
      const payload = {
        ...values,
        id: documentsState?.editDocumentDetails?.vehicle?.id,
        due_date: moment(formik.values.due_date).format('YYYY-MM-DD'),
        service_date: moment(formik.values.service_date).format('YYYY-MM-DD'),
        attachments: editMode ? formik.values.attachments || [] : fileId || [],
        // The next two properties are needed for updates
        vehicleId,
        documentId: documentsState?.editDocumentDetails?.id
      };
      mutation.mutateAsync(payload).then(() => {
        queryClient.refetchQueries(['vehicle-documents']);
        queryClient.refetchQueries(['vehicle', { id }]);
        queryClient.refetchQueries(['get-statutory-reminder']);
        queryClient.refetchQueries(['get-single-statutory-reminder']);
        setFleetState({
          ...fleetState,
          modal: '',
          toast: true,
          toastMessage: 'Statutory document has been renewed sucessfully',
          severity: 'success'
        });
        editMode &&
          setDocumentsState({
            ...documentsState,
            editDocumentDetails: {},
            modal: '',
            selectedDocuments: []
          });
      });
    }
  });

  React.useEffect(() => {
    const formUploadData = new FormData();
    if (editMode) {
      formUploadData.append('file', file[file.length - 1]);
      formUploadData.append('content_type', 'vehiclestatutory');
      formUploadData.append('object_id', documentsState.editDocumentDetails.id);
    } else {
      formUploadData.append('file', file[file.length - 1]);
    }
    const fileData = formUploadData;
    if (disableSaveButton) {
      fileMutation
        .mutateAsync({ fileData, progress: setProgress })
        .then((res) => {
          formik.values.file = res;
          setFiles((prev) => [...prev, res]);
          // setFile(prev => prev.name === res.name ? [...prev, res] : [...prev])
          setFile((prev) =>
            prev.map((item) => (item.name === res.name ? res : item))
          );
          setFileId((prev) => [...prev, res.id]);
          setDisableSaveButton(false);
        });
    }
  }, [disableSaveButton, files]);

  const hasErrors = (key) => {
    return formik.touched[key] && !!formik.errors[key];
  };

  const handleFile = (event) => {
    if (event.target.files.length) {
      // setFile(event.target.files[0]);
      setFile((prev) => [...prev, event.target.files[0]]);
      // event.target.files = {}
    }
    setDisableSaveButton(true);
  };

  const handleDelete = (id) => {
    fileDelete.mutateAsync(id).then((res) => {});
  };

  return (
    <RenewStatutoryDocumentForm.Wrapper>
      <Grid container>
        <Grid item sm={12}>
          <DocumentTypes
            name='type'
            label='Select Type'
            error={hasErrors('type')}
            value={formik.values.type}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {hasErrors('description') ? (
            <Typography variant='caption' color='primary'>
              {formik.errors.description}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12}>
          <TextField
            color='secondary'
            size={isWidthDown('lg', width) ? 'small' : 'medium'}
            fullWidth
            name='title'
            variant='outlined'
            label='Title'
            type='text'
            error={hasErrors('title')}
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {hasErrors('title') ? (
            <Typography variant='caption' color='primary'>
              {formik.errors.title}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      <Grid
        container
        // spacing={1}
        alignItems='flex-start'
        style={{ margin: '1.5% 0px 15.5px' }}
      >
        <Grid item sm={6} style={{ paddingRight: '10px' }}>
          <TextField
            color='secondary'
            size={isWidthDown('lg', width) ? 'small' : 'medium'}
            fullWidth
            name='issue_date'
            variant='outlined'
            label='Issue Date'
            type='date'
            error={hasErrors('issue_date')}
            value={formik.values.issue_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputLabelProps={{
              shrink: true
            }}
          />
          {hasErrors('issue_date') ? (
            <Typography variant='caption' color='primary'>
              {formik.errors.issue_date}
            </Typography>
          ) : null}
        </Grid>
        <Grid item sm={6} style={{ paddingRight: '0', paddingLeft: '10px' }}>
          <TextField
            color='secondary'
            size={isWidthDown('lg', width) ? 'small' : 'medium'}
            fullWidth
            name='due_date'
            variant='outlined'
            label='Expiry Date'
            type='date'
            error={hasErrors('due_date')}
            value={formik.values.due_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputLabelProps={{
              shrink: true
            }}
          />
          {hasErrors('due_date') ? (
            <Typography variant='caption' color='primary'>
              {formik.errors.due_date}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      <Grid
        container
        style={{ margin: '.5% 0px 15.5px' }}
        // style={{ margin: '2.5% 0px' }}
      >
        <Grid item sm={12}>
          <TextField
            color='secondary'
            size={isWidthDown('lg', width) ? 'small' : 'medium'}
            fullWidth
            variant='outlined'
            label='Mileage (Optional)'
            name='mileage'
            error={hasErrors('mileage')}
            value={formik.values.mileage}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {hasErrors('mileage') ? (
            <Typography variant='caption' color='primary'>
              {formik.errors.mileage}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      <Grid
        container
        style={{ margin: '1.5% 0px 15.5px' }}
        // style={{ margin: '2.5% 0px' }}
      >
        <Grid item sm={12}>
          <SetReminder
            color='secondary'
            size={isWidthDown('lg', width) ? 'small' : 'medium'}
            fullWidth
            variant='outlined'
            label='Set Reminder (Optional)'
            error={hasErrors('reminder_days')}
            name='reminder_days'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.reminder_days}
          />
          {hasErrors('reminder_days') ? (
            <Typography variant='caption' color='primary'>
              {formik.errors.reminder_days}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      <Grid container style={{ margin: '1.5% 0px 2%' }}>
        <Grid item sm={12}>
          <Typography variant='subtitle2' color=''>
            <strong>Upload Documents(Optional)</strong>
          </Typography>
          <Divider />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item={true} xs={12} direction='column' alignItems='center'>
          {editMode &&
            formik.values.attachments.map((file) => (
              <Box
                color='secondary'
                className={classes.FileName}
                onMouseEnter={() => setShowDelete(file.name)}
                onMouseLeave={() => setShowDelete('')}
              >
                <Box
                  to={{ pathname: file.file }}
                  target='_blank'
                  className={classes.Link}
                  style={{ display: 'flex' }}
                >
                  <DescriptionIcon
                    htmlColor='#545454'
                    style={{ verticalAlign: 'middle', marginRight: '.5rem' }}
                  />
                  <Typography>{file.name}</Typography>
                </Box>

                {showDelete === file.name && (
                  <img
                    src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638788581/Bizedge_Frontend_Assets/Vector_1_hzkq4t.png'
                    alt='delete'
                    className={classes.DeleteIcon}
                    onClick={() => handleDelete(file.id)}
                  />
                )}
              </Box>
            ))}
          <h2>{editMode && 'Added files'}</h2>
          {file &&
            file.map((file) => (
              <Box
                color='secondary'
                className={classes.FileName}
                onMouseEnter={() => setShowDelete(file.name)}
                onMouseLeave={() => setShowDelete('')}
              >
                <Box
                  // to={{ pathname: file.file }}
                  // target='_blank'
                  className={classes.Link}
                >
                  <Typography>
                    {' '}
                    <DescriptionIcon
                      htmlColor='#545454'
                      style={{ verticalAlign: 'middle' }}
                    />{' '}
                    {file.name}
                  </Typography>
                </Box>

                {showDelete === file.name && (
                  <img
                    src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638788581/Bizedge_Frontend_Assets/Vector_1_hzkq4t.png'
                    alt='delete'
                    className={classes.DeleteIcon}
                    onClick={() => handleDelete(file.id)}
                  />
                )}
              </Box>
            ))}

          {progress && progress !== 100 && (
            // <Progress strokeWidth='6' percentage={progress} />
            <ProgressBar bgcolor='#2898A4' height='2px' completed={progress} />
          )}
        </Grid>

        {/* ============ DOCUMENTS SECTION ============= */}
        <Grid
          item={true}
          xs={12}
          direction='column'
          alignItems='left'
          className={classes.DocumentSection}
          style={{ marginTop: '1rem' }}
        >
          <Typography variant='h6' align='left' className={classes.Heading6}>
            Uploaded File
          </Typography>

          <input
            type='file'
            id='docs-upload'
            accept='.pdf, .png, .jpeg, .tiff'
            style={{ display: 'none' }}
            onChange={handleFile}
            onBlur={formik.handleBlur}
            name='file'
            error={hasErrors('file')}
          />

          <Box className={classes.FileUploadDiv} justifyContent='center'>
            <label htmlFor='docs-upload'>
              <Box className={classes.UploadButton}>
                <BackupOutlinedIcon
                  style={{ margin: 'auto 10px' }}
                  color='secondary'
                />
                <Typography variant='body2' color='secondary'>
                  Upload a file
                </Typography>
              </Box>
            </label>
          </Box>
        </Grid>
      </Grid>
      {/* ============== SUBMIT =============== */}

      <Box
        textAlign='end'
        display='flex'
        alignItems='center'
        justifyContent='flex-end'
        mr='.7rem'
      >
        <Button
          variant='contained'
          color='secondary'
          type='button'
          onClick={close}
          style={{
            width: '30%',
            border: '1px solid #2898A4',
            background: 'white',
            color: '#545454',
            marginTop: '5%',
            height: '40px',
            boxShadow: 'none',
            marginRight: '10px',
            fontWeight: 'bold'
          }}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='secondary'
          type='submit'
          disbled={disableSubmit(formik.errors)}
          onClick={formik.handleSubmit}
          style={{
            width: '30%',
            fontWeight: 'bold',
            height: '40px'
          }}
          startIcon={
            mutation.isLoading ? (
              <CircularProgress style={{ color: 'white' }} size={20} />
            ) : null
          }
        >
          {mutation.isLoading ? null : 'Renew'}
        </Button>
      </Box>
    </RenewStatutoryDocumentForm.Wrapper>
  );
}

RenewStatutoryDocumentForm.Wrapper = styled(FormGroup)`
  .MuiTextField-root {
    margin-bottom: 0 !important;
  }
  & > * {
    margin-bottom: 15.5px;
  }
  .MuiGrid-spacing-xs-1 > .MuiGrid-item {
    padding-top: 0;
    padding-bottom: 0;
  }
`;
export default withWidth()(RenewStatutoryDocumentForm);
