import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Wrapper } from './styled.modals';
import useVehicleDocumentsControl from '../../hooks/useVehicleDocumentsControl';
import RenewStatutoryDocumentForm from '../Forms/RenewStatutoryDocumentForm';

function RenewStatutoryDocumentModal() {
  const [state, setState] = useVehicleDocumentsControl();

  const handleBackdropClick = () => setState({ ...state, modal: '' });

  const handleModalClose = () => {
    setState({ ...state, modal: '' });
  };

  return (
    <Modal
      open={state.modal === 'RenewDocumentModal'}
      onBackdropClick={handleBackdropClick}
    >
      <Wrapper width='556px' widthPercent='70%'>
        <Grid
          container
          alignItems='center'
          justify='space-between'
          style={{ marginBottom: '1em' }}
        >
          <Grid item>
            <Typography variant='h2' style={{ color: '#545454' }}>
              Renew statutory document
            </Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label='Go back' onClick={handleModalClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <RenewStatutoryDocumentForm editMode={true} close={handleModalClose} />
      </Wrapper>
    </Modal>
  );
}

export default RenewStatutoryDocumentModal;
