import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { Wrapper } from './styled.modals';
import CreateVehicleForm from '../Forms/CreateVehicleForm';
import useFleetControl from '../../hooks/useFleetControl';

function CreateVehicleModal() {
  const [state, setState] = useFleetControl();

  const handleClick = () => setState({ ...state, modal: '' });
  return (
    <Modal
      open={state.modal === 'CreateVehicleModal'}
      onBackdropClick={handleClick}
    >
      <Wrapper width='556px' widthPercent='70%'>
        <Typography variant='h2' style={{ color: '#545454' }}>
          Add a Vehicle
        </Typography>
        <Typography
          variant='subtitle1'
          style={{
            fontWeight: 'normal',
            fontSize: '14.22px',
            lineheight: '15px',
            color: '#878787'
          }}
        >
          Add a vehicle you want to manage
        </Typography>
        <CreateVehicleForm />
      </Wrapper>
    </Modal>
  );
}

export default CreateVehicleModal;
