import {
  useCreateDocumentType,
  useCreateReminder,
  useCreateStatutoryDocument,
  useGetDocumnentType
} from 'Assets/queries';
import { useFormik } from 'formik';
import { defaultTime } from 'Assets/Shared/ReminderForm';
import { useEffect, useState } from 'react';
import {
  formatDate,
  formatDateTime,
  inputPropsSetterConfig
} from 'utilities/formHelpers';
import { runIfFn } from 'utilities/utils';
import useDisclosure from 'hooks/useDisclosure';

const useAssetDocState = ({ data, assetId, toggle, onSuccess }) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const { data: documentType, refetch } = useGetDocumnentType();
  const [openedAttachmentAlert, { toggle: toggleAttachmentAlert }] =
    useDisclosure(false, { closeTimeout: 5000 });
  const formik = useFormik({
    initialValues: {
      file: null,
      date_of_issue: null,
      expiry_date: null,
      // attachment_type: '',
      content_type: 'asset',
      document_type: '',
      object_id: data?.id ?? assetId,
      reminder: {
        asset: data?.id ?? assetId,
        type: '',
        date: defaultTime(),
        set_reminder: ''
      }
    }
  });

  const { setFieldValue, values, touched, setFieldTouched, validateForm } =
    formik;

  useEffect(() => {
    if (!values.file && touched.file) {
      validateForm();
      return;
    }
  }, [values.file, validateForm, touched.file]);

  const setInputProps = inputPropsSetterConfig({ ...formik });

  const mutation = useCreateStatutoryDocument({
    onSuccess(data) {
      runIfFn(refetch, toggle, onSuccess);
    }
  });

  const handleSubmit = (reminderId) => {
    const payload = structuredClone(values);
    if (!payload.file) {
      delete payload.file;
    }
    payload.reminder = reminderId;
    payload.date_of_issue = formatDate(values.date_of_issue);
    payload.expiry_date = formatDate(values.expiry_date);
    const formData = new FormData();
    for (let [key, value] of Object.entries(payload)) {
      formData.append(key, value);
    }
    mutation.mutate(formData);
  };

  const reminderMutation = useCreateReminder({
    onSuccess(res) {
      const reminderId = res?.id;
      handleSubmit(reminderId);
    }
  });

  const handleFileUpload = async (files, progressSetter) => {
    setFieldValue('file', files);
    setFieldTouched('file');
  };

  const handleAddDoc = async () => {
    // if (!values.file) {
    //  return toggleAttachmentAlert();
    // }
    if (!values.reminder) {
      handleSubmit(null);
    } else {
      const reminderPayload = structuredClone(values.reminder);
      reminderPayload.type = values.document_type;
      reminderPayload.date = formatDateTime(
        values.reminder.date,
        values.expiry_date
      );
      reminderMutation.mutate(reminderPayload);
    }
  };

  const createDocumentType = useCreateDocumentType({
    onSuccess() {
      refetch();
    }
  });

  const handleCustomClick = () => {
    if (inputValue === 'Add New') {
      return;
    }
    createDocumentType.mutate({ name: inputValue });
  };

  return {
    handleCustomClick,
    handleAddDoc,
    handleFileUpload,
    reminderMutation,
    mutation,
    formik,
    setInputProps,
    setInputValue,
    inputValue,
    createDocumentType,
    documentType,
    value,
    setValue,
    openedAttachmentAlert,
    toggleAttachmentAlert
  };
};

export default useAssetDocState;
