import axios from './axios.config';
import getBaseDomainURL from './base-domain-url';

// Onboarding
export function getAppOnboardingInfo({ queryKey }) {
  const { type, business_id } = queryKey[1];
  let param = {
    type,
    business_id
  };
  return axios
    .get(`${getBaseDomainURL()}/app_onboarding/`, {
      params: param
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function createOnboardingFlow(payload) {
  return axios
    .post(`${getBaseDomainURL()}/app_onboarding/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function updateUserOnboardingFlow(payload) {
  const { id } = payload;

  return axios
    .put(`${getBaseDomainURL()}/app_onboarding/${id}/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function fiscalYear(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/fiscal_year/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

// Product & Services
export function addProduct(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/product/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getProduct() {
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/product/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getProductAll() {
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/product/all`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getSingleProduct({ queryKey }) {
  const id = queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/product/${id}/inventory_data`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function editSingleProduct(payload) {
  const { id, data } = payload;

  return axios
    .put(`${getBaseDomainURL()}/accounting_v2/product/${id}/`, data)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function addPriceList(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/product/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getPriceList({ queryKey }) {
  const queryKeys = queryKey[1];
  let param = {
    page: queryKeys?.page + 1,
    search: queryKeys?.search,
    page_size: queryKeys?.page_size
  };
  let param1 = {
    search: queryKeys?.search
  };
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/product/`, {
      params: queryKeys?.page >= 0 ? param : param1
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getAllPriceList() {
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/product/all/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getFiscalYear() {
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/fiscal_year/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function deleteProduct(payload) {
  return axios
    .patch(`${getBaseDomainURL()}/accounting_v2/product/bulk_delete/`, {
      ids: payload
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getServiceList({ queryKey }) {
  const search = queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/accounting/services/`, {
      params: search
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function addInventoryList(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting/inventory/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function addSalesCategory(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting/salescategory/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getSalesCategory() {
  return axios
    .get(`${getBaseDomainURL()}/accounting/salescategory/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getInventory() {
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/inventory/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function deleteInventory(id) {
  return axios
    .delete(`${getBaseDomainURL()}/accounting/inventory/${id}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

// Dashboard
export function getDashboardInfo({ queryKey }) {
  const { timeline } = queryKey[1];
  let param = {
    timeline
  };
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/dashboard/get_dashboard_data/`, {
      params: param
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getDashboardPieChartData({ queryKey }) {
  const { timeline } = queryKey[1];
  let param = {
    timeline
  };
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/dashboard/get_piechart_data/`, {
      params: param
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getDashboardFlow({ queryKey }) {
  const { timeline } = queryKey[1];
  let param = {
    timeline
  };
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/dashboard/get_cashflow_data/`, {
      params: param
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

// Contact
export function addContact(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/contacts/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getContacts({ queryKey }) {
  const { page, search } = queryKey[1];
  let param = {
    page: page + 1,
    search
  };
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/contacts/`, {
      params: param
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getAllContacts({ queryKey }) {
  const { search } = queryKey[1];
  let param = {
    search
  };
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/contacts/all/`, {
      params: param
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function editContact(payload) {
  const { id } = payload;
  return axios
    .put(`${getBaseDomainURL()}/accounting_v2/contacts/${id}/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getSingleContact({ queryKey }) {
  const { id } = queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/contacts/${id}/`)
    .then()
    .catch((error) => {
      throw error;
    });
}

export function deleteContact(payload) {
  return axios
    .patch(`${getBaseDomainURL()}/accounting_v2/contacts/bulk_delete/`, {
      ids: payload
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function addSingleContactNote(data) {
  const { contact } = data;
  return axios
    .post(
      `${getBaseDomainURL()}/accounting_v2/contacts/${contact}/contact-note/`,
      data
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getSingleContactNote({ queryKey }) {
  const { id } = queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/contacts/${id}/contact-note/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function deleteSingleContactNote(data) {
  const { id, noteId } = data;
  return axios
    .delete(
      `${getBaseDomainURL()}/accounting_v2/contacts/${id}/contact-note/${noteId}`
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

// Tax
export function getTax() {
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/tax/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function addTax(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/tax/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

// Discount
export function getDiscount() {
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/discount/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function addDiscount(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/discount/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

// Invoice
export function getInvoice({ queryKey }) {
  const { page, search, limit, status } = queryKey[1];
  let param = {
    page: page + 1,
    search,
    limit,
    status
  };
  let param1 = {
    search,
    limit,
    status
  };
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/invoice/`, {
      params: page >= 0 ? param : param1
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function addInvoiceLine(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/invoice_line/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function createInvoice(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/invoice/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function markInvoiceAsSent(payload) {
  const { id } = payload;
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/invoice/${id}/mark_as_sent/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function recordInvoice(payload) {
  const { id } = payload;
  return axios
    .post(
      `${getBaseDomainURL()}/accounting_v2/invoice/${id}/mark_as_paid/`,
      payload
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function markInvoiceAsDraft(payload) {
  const { id } = payload;
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/invoice/${id}/mark_as_draft/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function deleteInvoiceLine(id) {
  return axios
    .delete(`${getBaseDomainURL()}/accounting/invoice_line/${id}/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function checkInvoiceNumber(invoiceNumber) {
  if (!invoiceNumber) return;
  return axios
    .get(
      `${getBaseDomainURL()}/accounting_v2/invoice/check_invoice_number/?invoice_number=${invoiceNumber}`
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function checkBillNumber(billNumber) {
  if (!billNumber) return;
  return axios
    .get(
      `${getBaseDomainURL()}/accounting_v2/bill/check_bill_number/?bill_number=${billNumber}`
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getSingleInvoice({ queryKey }) {
  const { id } = queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/invoice/${id}/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function suggestInvoiceNumber() {
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/invoice/generate_invoice_number/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function suggestBillNumber() {
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/bill/suggest_bill_number/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function editSingleInvoice(payload) {
  const { id, ...data } = payload;
  return axios
    .put(`${getBaseDomainURL()}/accounting_v2/invoice/${id}/`, data)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function deleteInvoice(payload) {
  return axios
    .patch(`${getBaseDomainURL()}/accounting_v2/invoice/bulk_delete/`, {
      ids: payload
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function sendMail(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/mail/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

// Transactions
export function addTransaction(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/transactions/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getTransactionBalances() {
  return axios
    .get(
      `${getBaseDomainURL()}/accounting_v2/transaction_list/current_assets_breakdown/`
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function createJournal(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/journal/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function editJournal(payload) {
  const { id, ...data } = payload;
  return axios
    .put(`${getBaseDomainURL()}/accounting_v2/journal/${id}/`, data)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getSingleJournal({ queryKey }) {
  const { id } = queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/journal/${id}/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getTransactions({ queryKey }) {
  const { page, limit, search, type, current_asset } = queryKey[1];
  let param = {
    page: page + 1,
    limit,
    search,
    current_asset,
    type
  };
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/transaction_list/`, {
      params: param
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function deleteTransaction(payload) {
  return axios
    .patch(
      `${getBaseDomainURL()}/accounting_v2/transaction_list/bulk_delete/`,
      {
        ids: payload
      }
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getSingleTransaction({ queryKey }) {
  const { id } = queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/transactions/${id}/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function updateTransaction(payload) {
  const { id, ...data } = payload;
  return axios
    .patch(`${getBaseDomainURL()}/accounting_v2/transactions/${id}/`, data)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function editSingleTransaction(payload) {
  const { id, ...data } = payload;
  return axios
    .put(`${getBaseDomainURL()}/accounting_v2/payments/${id}/`, data)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function addJournal(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting/journal/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

// Chart of Accounts
export function addChartOfAccount(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/chart_of_account/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getChartOfAccount({ queryKey }) {
  const { search } = queryKey[1];

  let params = {
    search
  };
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/chart_of_account/`, {
      params
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getAllChartOfAccount({ queryKey }) {
  const { search } = queryKey[1];

  let params = {
    search
  };
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/chart_of_account/all/`, {
      params
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function deleteChartOfAccount(payload) {
  return axios
    .patch(
      `${getBaseDomainURL()}/accounting_v2/chart_of_account/bulk_delete/`,
      {
        ids: payload
      }
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getOneChartOfAccount({ queryKey }) {
  const { id } = queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/chart_of_account/${id}/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function editChartOfAccount(payload) {
  const { id } = payload;
  return axios
    .put(`${getBaseDomainURL()}/accounting_v2/chart_of_account/${id}/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

// Estimate
export function getEstimate({ queryKey }) {
  const { page, search, limit, status } = queryKey[1];
  let param = {
    page: page + 1,
    search,
    limit,
    status
  };
  let param1 = {
    search,
    limit,
    status
  };
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/estimate/`, {
      params: page >= 0 ? param : param1
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function createEstimate(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/estimate/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function suggestEstimateNumber() {
  return axios
    .get(
      `${getBaseDomainURL()}/accounting_v2/estimate/generate_estimate_number/`
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getSingleEstimate({ queryKey }) {
  const { id } = queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/estimate/${id}/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function editSingleEstimate(payload) {
  const { id, ...data } = payload;
  return axios
    .put(`${getBaseDomainURL()}/accounting_v2/estimate/${id}/`, data)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function addEstimateLine(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/estimate_line/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function deleteEstimate(payload) {
  return axios
    .patch(`${getBaseDomainURL()}/accounting_v2/estimate/bulk_delete/`, {
      ids: payload
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function checkEstimateNumber(estimateNumber) {
  if (!estimateNumber) return;
  return axios
    .get(
      `${getBaseDomainURL()}/accounting_v2/estimate/check_estimate_number/?invoice_number=${estimateNumber}`
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function deleteEstimateLine(payload) {
  return axios
    .patch(`${getBaseDomainURL()}/accounting_v2/estimate_line/bulk_delete/`, {
      ids: [payload]
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function createBill(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting/bill/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function createBill2(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/bill/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getBill() {
  return axios
    .get(`${getBaseDomainURL()}/accounting/bill/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export const getSingleBill = ({ queryKey }) => {
  const { id } = queryKey[1];

  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/bill/${id}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export function deleteBill(id) {
  return axios
    .delete(`${getBaseDomainURL()}/accounting_v2/bill/${id}/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function deleteBillBulk(payload) {
  let idsToDelete = [];
  idsToDelete.push(payload);

  return axios
    .patch(`${getBaseDomainURL()}/accounting_v2/bill/bulk_delete/`, {
      ids: idsToDelete
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function editSingleBill(payload) {
  const { id, ...data } = payload;
  return axios
    .put(`${getBaseDomainURL()}/accounting_v2/bill/${+id}/`, data)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getSingleInventory({ queryKey }) {
  const { id } = queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/accounting/inventory/${id}/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function editInventory(payload) {
  const { id, ...data } = payload;
  return axios
    .put(`${getBaseDomainURL()}/accounting/inventory/${id}/`, data)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

// Expenses
export function getExpenses({ queryKey }) {
  const { page, search } = queryKey[1];
  let param = {
    page: page + 1,
    search
  };
  let param1 = {
    search
  };
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/expense/`, {
      params: page >= 0 ? param : param1
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getSingleExpense({ queryKey }) {
  const { id } = queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/expense/${id}/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function deleteExpense(payload) {
  return axios
    .patch(`${getBaseDomainURL()}/accounting_v2/expense/bulk_delete/`, {
      ids: payload
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function addExpense(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/expense/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function updateExpense(payload) {
  const { id, ...data } = payload;
  return axios
    .put(`${getBaseDomainURL()}/accounting_v2/expense/${id}/`, data)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

// Services
export function addService(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting/services/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getSingleService({ queryKey }) {
  const { id } = queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/accounting/services/${id}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function deleteService(payload) {
  return axios
    .patch(`${getBaseDomainURL()}/accounting/services/bulk_delete/`, {
      ids: payload
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function editService(payload) {
  const { id, ...data } = payload;
  return axios
    .put(`${getBaseDomainURL()}/accounting/services/${id}/`, data)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

// Payment
export function deletePayment(id) {
  return axios
    .delete(`${getBaseDomainURL()}/accounting_v2/payments/${id}/delete/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

// Income
export function addIncome(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/income/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

// Report
export function getReport({ queryKey }) {
  const { start_date, end_date } = queryKey[1];
  let param = {
    start_date,
    end_date
  };
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/reports_template/`, {
      params: param
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

// Regions

export function getRegions() {
  return axios.get(`${getBaseDomainURL()}/regions/`).then((data) => data);
}

export function getBillLists({ queryKey }) {
  const { page, search, status } = queryKey[1];
  let param = {
    page: page + 1,
    search,
    status
  };
  let param1 = {
    search
  };

  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/bill/`, {
      params: page >= 0 ? param : param1
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getBillSingle({ queryKey }) {
  const { id } = queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/bill/${id}/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

/// transfarInventory
export function transfarInventory(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/inventory_transfer/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
// inventoryAdjustment
export function inventoryAdjustment(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/inventory_adjustment/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function getInventoryAdjustment(params) {
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/inventory_adjustment/`, {
      params
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function getInventoryTransfer(params) {
  return axios
    .get(`${getBaseDomainURL()}/accounting_v2/inventory_transfer/`, {
      params
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function addInventoryListV2(payload) {
  return axios
    .post(`${getBaseDomainURL()}/accounting_v2/inventory/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
/// delete inventory v2

export function deleteInventoryV2(payload) {
  return axios
    .patch(`${getBaseDomainURL()}/accounting_v2/inventory/bulk_delete/`, {
      ids: payload
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
// import contacts
export function importContacts(payload) {
  return axios
    .post(
      `${getBaseDomainURL()}/accounting_v2/contacts/contact_import/`,
      payload
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
