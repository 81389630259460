import Text from '../Typography/Text';

// fontSize: '12px',
const StatusChip = ({
  label,
  color,
  bgColor,
  children,
  p,
  size = 'xs',
  style = {}
}) => {
  return (
    <Text
      textColor={color}
      size={size}
      sx={{
        backgroundColor: bgColor,
        fontWeight: 'bold',
        fontFamily: 'Black Sans',
        borderRadius: '36px',
        padding: p ?? '.2rem .5rem',
        textAlign: 'center',
        display: 'inline-block',
        ...style
      }}
    >
      {label ?? children}
    </Text>
  );
};

export default StatusChip;
