import { Checkbox } from '@mui/material';
import { reminderSet } from 'Assets/Modals/ReminderModal';
import { CustomSelect, CustomTextField } from 'components/Inputs';
import Group from 'components/StyledComponents/ButtonGroup';
import Text from 'components/Typography/Text';
import { useEffect, useState } from 'react';
import { isFunc } from 'utilities/utils';

export const defaultTime = () =>
  new Date().toLocaleTimeString('en-US', {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric'
  });

const ReminderForm = ({
  setReminderInput,
  setTimeInput,
  clearInputs,
  defaultEnabled = true
}) => {
  const [enableReminder, setEnableReminder] = useState(() => defaultEnabled);
  useEffect(() => {
    if (!enableReminder && isFunc(clearInputs)) {
      clearInputs();
    }
  }, [enableReminder, clearInputs]);

  const currentTime = new Date().toLocaleTimeString('en-US', {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric'
  });

  return (
    <div>
      <Group items='center' jc='flex-start'>
        <Checkbox
          checked={enableReminder}
          onChange={(event) => {
            setEnableReminder(event.target.checked);
          }}
        />
        <Text>Set Renewal Reminder</Text>
      </Group>
      {enableReminder && (
        <Group>
          <CustomSelect
            size='small'
            options={reminderSet}
            label='Set reminder'
            {...(setReminderInput ? setReminderInput : {})}
          />
          <CustomTextField
            inputProps={{ fullWidth: true, size: 'small' }}
            size='small'
            fullWidth
            label='Service Time'
            type='time'
            defaultValue={currentTime}
            {...(setTimeInput ? setTimeInput : {})}
          />
        </Group>
      )}
    </div>
  );
};

export default ReminderForm;
