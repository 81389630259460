import { CustomTextField } from 'components/Inputs';
import Group from 'components/StyledComponents/ButtonGroup';
import { useFormikContext } from 'formik';
import FormSectionTitle from './FormSectionTitle';
const AdditionalInformation = () => {
  const {
    handleChange,
    values: { brand, model, color, serial_no, notes }
  } = useFormikContext();
  return (
    <>
      <FormSectionTitle>Additional Information</FormSectionTitle>

      <Group>
        <CustomTextField
          val
          size='small'
          name='brand'
          value={brand}
          onChange={handleChange}
          label='Brand'
        />
        <CustomTextField
          size='small'
          name='model'
          value={model}
          onChange={handleChange}
          label='Model'
        />
      </Group>
      <Group>
        <CustomTextField
          name='serial_no'
          value={serial_no}
          onChange={handleChange}
          size='small'
          label='Serial Number'
        />

        <CustomTextField
          name='color'
          value={color}
          onChange={handleChange}
          size='small'
          label='Color'
        />
      </Group>

      <CustomTextField
        name='notes'
        value={notes}
        onChange={handleChange}
        size='small'
        label='Description'
        multiline
        rows={4}
      />
    </>
  );
};

export default AdditionalInformation;
