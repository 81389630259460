import { createContext, useContext, useState } from 'react';

export const VehicleDocumentsContext = createContext({});

export function VehicleDocumentsProvider({ children }) {
  const [state, setState] = useState({
    modal: '',
    selectedDocuments: [],
    editDocumentsDetails: {},
    search: ''
  });

  return (
    <VehicleDocumentsContext.Provider value={[state, setState]}>
      {children}
    </VehicleDocumentsContext.Provider>
  );
}

function useVehicleDocumentsControl() {
  return useContext(VehicleDocumentsContext);
}

export default useVehicleDocumentsControl;
