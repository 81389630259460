import DeleteModalGeneric from 'common/GenericModals/DeleteModal';

const DeleteModal = ({
  open,
  toggle,
  onDeleteComplete,
  data,
  title = '',
  desc = '',
  handleDelete,
  isLoading,
  ...rest
}) => {
  const props = {
    open,
    toggle,
    onDeleteComplete,
    data,
    title,
    desc,
    handleDelete,
    isLoading,
    ...rest
  };
  return <DeleteModalGeneric {...props} />;
};

export default DeleteModal;
