import Avatar from 'common/Avatar/Avatar';
import { CustomButton as Button } from 'components/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from 'components/Dialog';
import Group from 'components/StyledComponents/ButtonGroup';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import { BLANK, NOT_AVAILABLE } from 'constant';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { capitalize } from 'utilities/Capitalized';

const ReportModal = ({ data, open, toggle }) => {
  return (
    <Dialog open={open} onClose={toggle} fullWidth maxWidth='xs' cp='2rem'>
      <DialogTitle as='div' px={4} onClose={toggle}>
        <Title>Asset Issue Report</Title>
        <Text size='xs'>
          You can schedule a repair or maintenance for your asset
        </Text>
      </DialogTitle>
      <DialogContent>
        <Group orientation='col' fullWidth gap='1.5rem'>
          <Group fullWidth>
            <Event label='Issue Type' value={data?.issue_type?.name ?? BLANK} />
            <Event
              label='Issue Date'
              value={dayjs(data?.date).format('Do MMM, YYYY')}
            />
          </Group>
          <Event label='Description' value={data?.message ?? BLANK} />
          <Group fullWidth>
            <Event
              label='Priority Level'
              value={capitalize(data?.priority_level)}
            />
            <Event label='Report Date' value={data?.issueDate} />
          </Group>
          <Event
            label='Assigned To'
            value={
              data?.employee ? <Avatar {...data?.employee} /> : NOT_AVAILABLE
            }
          />

          <Event
            label='Images'
            value={
              <Group>
                {data?.attachments?.map((attachment, index) => {
                  return (
                    <img
                      style={{ width: '170px', height: '120px' }}
                      src={attachment.file}
                      alt={`report attachment ${index + 1}`}
                    />
                  );
                })}
              </Group>
            }
          />
        </Group>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' fullWidth>
          Add Maintenance
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportModal;

const Event = ({ label, value }) => {
  return (
    <EventWrapper>
      <Text bold size='sm' className='label'>
        {label}
      </Text>
      <Text size='xs'>{value}</Text>
    </EventWrapper>
  );
};

const EventWrapper = styled.div`
  background: #f2f2f2;
  padding: 0.4rem 0.7rem;
  padding-bottom: 2rem;
  border-radius: 0.3rem;

  width: 100%;
  .label {
    text-transform: capitalize;
    margin-bottom: 0.2rem;
  }
`;
