import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

function VehicleTypes({ width, name, formik, error }) {
  const types = ['bike', 'car', 'bus', 'truck'];
  return (
    <FormControl
      variant='outlined'
      size={isWidthDown('lg', width) ? 'small' : 'medium'}
      color='secondary'
      fullWidth
      error={error}
    >
      <InputLabel id='vehicle-type'>Select Vehicle Type</InputLabel>
      <Select
        labelId='vehicle-type'
        label='Vehicle Type'
        size='small'
        name={name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      >
        {types.map((type) => (
          <MenuItem value={type} style={{ textTansform: 'capitalize' }}>
            {type}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default withWidth()(VehicleTypes);
