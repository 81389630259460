import React from 'react';
import ReminderDocView from './ReminderDocView';

const ReminderList = ({ reminders, overdue = false, handleClick }) => {
  return reminders.map((reminder, index) => {
    return (
      <ReminderDocView
        overdue={overdue}
        onClick={handleClick(reminder)}
        key={index}
        {...reminder}
      />
    );
  });
};

export default ReminderList;
