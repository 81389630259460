import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';

// const states = [
//   'Abia',
//   'Adamawa',
//   'Akwa Ibom',
//   'Anambra',
//   'Bauchi',
//   'Bayelsa',
//   'Benue',
//   'Borno',
//   'Cross River',
//   'Delta',
//   'Ebonyi',
//   'Edo',
//   'Ekiti',
//   'Enugu',
//   'FCT - Abuja',
//   'Gombe',
//   'Imo',
//   'Jigawa',
//   'Kaduna',
//   'Kano',
//   'Katsina',
//   'Kebbi',
//   'Kogi',
//   'Kwara',
//   'Lagos',
//   'Nasarawa',
//   'Niger',
//   'Ogun',
//   'Ondo',
//   'Osun',
//   'Oyo',
//   'Plateau',
//   'Rivers',
//   'Sokoto',
//   'Taraba',
//   'Yobe',
//   'Zamfara'
// ];

function States({ value, onChange, onBlur, ...props }) {
  return (
    // <FormControl
    //   variant='outlined'
    //   // className={classes.formControl}
    //   size='small'
    // >
    //   <InputLabel id='demo-simple-select-outlined-label' color='secondary'>
    //     State
    //   </InputLabel>
    //   <Select
    //     {...props}
    //     name='state'
    //     value={value}
    //     color='secondary'
    //     onChange={onChange}
    //     onBlur={onBlur}
    //     labelId='demo-simple-select-outlined-label'
    //     id='demo-simple-select-outlined'
    //     label='State'
    //   >
    //     <MenuItem></MenuItem>
    //     {states.map((state) => (
    //       <MenuItem key={state} value={state}>
    //         {state}
    //       </MenuItem>
    //     ))}
    //   </Select>
    // </FormControl>
    <TextField
      select
      size={props.size ?? 'small'}
      label='State'
      onBlur={onBlur}
      onChange={onChange}
      name='state'
      color='secondary'
      value={value}
      id='demo-simple-select-outlined'
      variant='outlined'
      fullWidth
    >
      {props.states?.map((state) => (
        <MenuItem key={state} value={state?.name}>
          {state?.name}
        </MenuItem>
      ))}
      <MenuItem value='Other'>Other</MenuItem>
    </TextField>
  );
}

//TODO: use error boundaries to prevent crash

export default States;
