import GoBack from 'components/GoBack';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import useCheckEdit from 'hooks/useCheckEdit';

const Header = ({ assetName = 'asset Name' }) => {
  const isEditView = useCheckEdit('view');
  return (
    <section>
      <div className='header_block'>
        <div className='typos'>
          <Title order={4}>{isEditView ? 'Edit' : 'Create'} Asset</Title>
          {isEditView ? (
            <Text size='sm'>Editing {assetName} </Text>
          ) : (
            <Text size='sm'>Add a new asset to your asset list</Text>
          )}
        </div>
        <GoBack color='#2898A4' title='Back to Dashboard' />
      </div>
      <hr />
    </section>
  );
};

export default Header;
