import axios from './axios.config';
import getBaseDomainURL from './base-domain-url';

export function getBusinessOwner() {
  return axios
    .get(`${getBaseDomainURL()}/business_users/me/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function permissionList() {
  return axios
    .get(`${getBaseDomainURL()}/business_permissions/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function sendInvite(payload) {
  return axios
    .post(`${getBaseDomainURL()}/business_user_invitations/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function sendInviteEmployee(payload) {
  return axios
    .post(
      `${getBaseDomainURL()}/business_user_invitations/invite_employee/`,
      payload
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getPendingInvites() {
  return axios
    .get(`${getBaseDomainURL()}/business_user_invitations/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getActiveUsers() {
  return axios
    .get(`${getBaseDomainURL()}/business_users/`, {
      params: {
        page_size: 100
      }
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function getAllUsers() {
  return axios
    .get(`${getBaseDomainURL()}/business_users/business_users_new/`, {
      params: {
        page_size: 100
      }
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function deleteInvite(payload) {
  return axios
    .delete(`${getBaseDomainURL()}/business_user_invitations/${payload}/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function resendInvite(id) {
  return axios
    .post(`${getBaseDomainURL()}/business_user_invitations/${id}/resend/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function disableUser(id) {
  return axios
    .post(`${getBaseDomainURL()}/business_users/${id}/disable/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getDisabledUsers() {
  return axios
    .get(`${getBaseDomainURL()}/business_users/?disabled=true`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function enableUser(id) {
  return axios
    .post(`${getBaseDomainURL()}/business_users/${id}/enable/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function verifyInvite(payload) {
  return axios
    .post(`accounts/auth/business_users/invitations/check/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function acceptInvite(payload) {
  return axios
    .post(`accounts/auth/business_users/invitations/accept/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function signupInvite(payload) {
  return axios
    .post(`accounts/auth/business_users/signup/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function businessUser() {
  return axios
    .get(`${getBaseDomainURL()}/business_users/me/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function editPermission(param) {
  const { id, payload } = param;
  return axios
    .put(`${getBaseDomainURL()}/business_users/${id}/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getBusinessDetail() {
  return axios
    .get(`${getBaseDomainURL()}/businesses/details/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function updateBusinessDetail(payload) {
  return axios
    .post(`${getBaseDomainURL()}/businesses/update_details/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function uploadLogo(payload) {
  return axios
    .post(
      `${getBaseDomainURL()}/businesses/upload_logo/`,
      payload.formDataUpload
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function removeLogo() {
  return axios
    .delete(`${getBaseDomainURL()}/businesses/remove_logo/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getBusinessAddress() {
  return axios
    .get(`${getBaseDomainURL()}/business_address/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function editBusinessAddress(payload) {
  return axios
    .post(`${getBaseDomainURL()}/business_address/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function editBusiness(payload) {
  return axios
    .put(`/businesses/update_business_details/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export const getCompanyEmployees = (params) => {
  return axios
    .get(`${getBaseDomainURL()}/business_users/`, { params })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
};

export function getBusinessSupport() {
  return axios
    .get(`${getBaseDomainURL()}/business_users/get_support/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function grantAccessRequest(id) {
  return axios
    .post(
      `${getBaseDomainURL()}/dev-admin-P8pKk03rt-4579aEr/business/${id}/grant_access/`
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
