import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircle';
import { makeStyles } from '@material-ui/core/styles';
import CreateVehicleModal from '../Modals/CreateVehicleModal';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    // height: '70%',
    // margin: '50% auto'
    width: '50%',
    margin: '10% auto',
    textAlign: 'center',
    '& .MuiTypography-subtitle2': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#444444'
    }
  },
  img: {
    display: 'block',
    margin: 'auto',
    position: 'relative',
    marginBottom: '20px',
    width: '250px'
  },
  emptyStateTop: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '25.63px',
    lineHeight: '29px',
    color: '#747474',
    padding: '5px 0px 10px'
  },
  button: {
    textTransform: 'capitalize',
    // position: 'relative',
    // left: '50%',
    // transform: 'translate(-50%, 0)',
    marginTop: '10px',
    boxShadow: 'none'
  },
  h4: {
    ...theme.mixins.makeResponsive({
      theme,
      fontSize: 35,
      object: true
    }),
    fontWeight: 700,
    color: '#747474'
  },
  bodyText: {
    ...theme.mixins.makeResponsive({
      theme,
      fontSize: 16,
      lineHeight: 18.7,
      object: true
    }),
    color: '#444444',
    marginTop: '10px'
  }
}));

function EmptyState() {
  const history = useHistory();
  const handleClick = () => {
    history.push('?tab=add-vehicle');
    //  setFleetState({ ...fleetState, modal: 'CreateVehicleModal' });
  };

  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <img
        src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638789945/Bizedge_Frontend_Assets/emptyVehicle_vnmwmx.svg'
        alt='An illustration car'
      />
      <Typography variant='h4' className={classes.emptyStateTop}>
        No engines revving here yet
      </Typography>
      {/* <Typography variant="subtitle2">Add a vehicle to continue</Typography>  */}
      <Button
        className={classes.button}
        variant='contained'
        color='secondary'
        startIcon={<AddCircleOutlineIcon />}
        onClick={handleClick}
      >
        Add vehicle
      </Button>
      <CreateVehicleModal />
    </Box>
  );
}

export default EmptyState;
