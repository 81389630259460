import { Text } from 'components/Typography';
import React from 'react';
import { capitalize } from 'utilities/Capitalized';
import { truncate } from 'utilities/truncate';
import { CustomButton } from 'components/Button';
import { BLANK } from 'constant';
import { reminderType } from 'Assets/Modals/ReminderModal';
import { formateReminderDate } from 'Assets/data';

const documentRenewal = reminderType[0].value;

const ReminderDocView = ({ overdue, type, onClick, asset_name, date }) => {
  const dateFormat = formateReminderDate(date, false);

  return (
    <div
      style={{
        backgroundColor: '#FAFAFA',
        border: '1px solid #E1E1E1',
        padding: '.3rem .4rem',
        paddingLeft: '.7rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '5px'
      }}
    >
      <div className='d-flex d-flex-col d-gap-xxs '>
        <div className='d-flex align-items-center d-gap-xs'>
          <Text bold size='xs'>
            {capitalize(
              truncate(asset_name ?? type?.split('_')?.join(' ') ?? BLANK, 25)
            )}{' '}
          </Text>
          <Text size='xs' light>
            licence
          </Text>
        </div>
        <Text size='xxs'>
          {capitalize(
            truncate(asset_name ?? type?.split('_')?.join(' ') ?? BLANK, 30)
          )}{' '}
          due {dateFormat}
        </Text>
      </div>

      <CustomButton onClick={onClick} color='#4069D0' size='xxs'>
        {type === documentRenewal ? 'Renew Document' : 'view Asset'}
      </CustomButton>
    </div>
  );
};

export default ReminderDocView;
