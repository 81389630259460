import AddIssueModal from 'Assets/Modals/IssueModal';
import MarkModal from 'Assets/Modals/MarkModal';
import { useFetchAllReportedIssues } from 'Assets/queries';
import { CustomButton as Button } from 'components/Button';
import ButtonTabOffWhite from 'components/ButtonTab';
import CustomMenu from 'components/CustomMenu/CustomeMenu';
import Table from 'components/CustomTable/Table';
import { SearchFieldAntd } from 'components/FIlters/SearchField';
import { AntSelect } from 'components/FIlters/SelectField';
import StatusChip from 'components/StatusChip';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import { BLANK, NOT_AVAILABLE } from 'constant';
import useDisclosure from 'hooks/useDisclosure';
import { useState } from 'react';
import styled from 'styled-components';
import { capitalize } from 'utilities/Capitalized';
import AddMaintenanceModal from '../../Modals/AddMaintenanceModal';
import ReportModal from './modals/ReportModal';
import {
  columns,
  dataSource,
  maintenance,
  statusColor,
  tabs
} from './tableConfig';

import { useHistory } from 'react-router-dom';

const priorityColor = {
  medium: '#F8B636',
  low: '#2898A4',
  high: '#E70000'
};

const ReportedIssue = () => {
  const [toView, setToView] = useState(null);
  const [tab, setTab] = useState(0);
  const [mark, setMark] = useState(null);
  // const [selected, setSelected] = useState([]);
  const [open, { toggle }] = useDisclosure();
  const [openMaintenance, { toggle: toggleMaintenance }] = useDisclosure();
  const [openIssueModal, { toggle: toggleIssueModal }] = useDisclosure();

  const markAs = ({ mark, id }) => {
    setMark({ mark, id });
    toggle();
  };

  const { data, isLoading, isError } = useFetchAllReportedIssues();
  const tableColumn = columns({ viewAssetAction: setToView });
  const viewModalOpen = !!toView;
  const close = () => {
    setToView(null);
  };
  const history = useHistory();

  const handleAddReport = () => {
    history.push('/assets?tab=maintenance&view=create-report');
  };

  return (
    <ServiceAndRepairWrapper>
      <div className='header_block'>
        <Title order={3}>Reported Issue</Title>
        <Button
          size='sm'
          mx='.7rem'
          variant='contained'
          onClick={handleAddReport}
        >
          Add new issue
        </Button>
      </div>
      <div className='tab_block'>
        <ButtonTabOffWhite
          tabHeight={'35px'}
          value={tab}
          tabs={tabs}
          onChange={(e, value) => {
            setTab(value);
          }}
        />
      </div>
      <div className='search_block'>
        <SearchFieldAntd placeholder='Search by name or estimate no' />
        <AntSelect placeholder='Asset Type' />
        <AntSelect placeholder='Issue Type' options={maintenance} />
      </div>
      <div className='table_block'>
        <Table
          column={tableColumn}
          spacing={[2, 1.5, 1.5, 1.5, 1.5, 1.5, 1.5, 1]}
          minWidth='1000px'
        >
          <Table.Head column={tableColumn} />
          <Table.Body
            isLoading={isLoading}
            isError={isError}
            data={data?.results ?? dataSource ?? []}
            renderRow={(row) => {
              const status = row?.status ?? 'pending';
              return (
                <>
                  <Text size='sm'>{row?.asset_name ?? BLANK}</Text>
                  <Text size='sm'>{row?.asset_type ?? BLANK}</Text>
                  <Text size='sm'>{row?.issue_type?.name ?? BLANK}</Text>
                  <Text size='sm'>{row?.reportedBy ?? BLANK}</Text>
                  <StatusChip
                    label={capitalize(status?.split('_').join(' '))}
                    color={statusColor[status?.split('_')?.join(' ')]?.text}
                    bgColor={statusColor[status?.split('_')?.join(' ')]?.bg}
                  />
                  <Text size='sm'>{row?.reported_date ?? BLANK}</Text>
                  <Text
                    size='xs'
                    color={
                      priorityColor[row?.priority_level] ?? 'var(--text-color)'
                    }
                  >
                    {capitalize(row?.priority_level ?? '') ?? NOT_AVAILABLE}
                  </Text>
                  <div style={{ textAlign: 'right' }}>
                    <CustomMenu
                      size={'xs'}
                      width={350}
                      divideAll
                      options={[
                        {
                          label: 'View Report',
                          onClick: () => {
                            setToView(row);
                          }
                        },
                        {
                          label: 'Mark as sent for Repair',
                          onClick() {
                            markAs({ id: row?.id, mark: 'repair' });
                          }
                        },
                        // {
                        //   label: 'Mark as Resolved',
                        //   onClick() {
                        //     markAs({ id: row?.id, mark: 'resolved' });
                        //   }
                        // },
                        {
                          label: 'Mark as sent for Inspection',
                          onClick() {
                            markAs({ id: row?.id, mark: 'inspection' });
                          }
                        },

                        {
                          label: 'Delete Report',
                          style: { color: 'red' }
                        }
                      ]}
                    />
                  </div>
                </>
              );
            }}
          />
        </Table>
      </div>
      {openMaintenance && (
        <AddMaintenanceModal
          open={openMaintenance}
          toggle={toggleMaintenance}
          data={{ id: mark?.id }}
        />
      )}
      {openIssueModal && (
        <AddIssueModal open={openIssueModal} toggle={toggleIssueModal} isNew />
      )}

      {open && (
        <MarkModal
          addAction={toggleMaintenance}
          open={open}
          toggle={toggle}
          mark={mark}
        />
      )}
      {!!toView && (
        <ReportModal open={viewModalOpen} toggle={close} data={toView} />
      )}
    </ServiceAndRepairWrapper>
  );
};

export default ReportedIssue;

const ServiceAndRepairWrapper = styled.div`
  .header_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-block: 2rem;
    gap: 0.5rem;
  }
  .search_block {
    margin-top: 2rem;
    display: flex;
    flex: 1;
    gap: 0.5rem;
    width: min(100%, 800px);
  }
  .tab_block {
    width: min(100%, 600px);
  }

  .table_block {
    margin-top: 2rem;
  }
`;
