import { useFetchAllAssets } from 'Assets/queries';
import { CustomButton } from 'components/Button';
import { useHistory } from 'react-router-dom';
import { SearchResultWrapper } from './Styled.Wrapper';
import SearchField from 'components/FIlters/SearchField';
import Text from 'components/Typography/Text';
import useDebouceSearch from 'hooks/useDebouceSearch';

const SearchResults = () => {
  const [search, handleSearch, inputValue] = useDebouceSearch();
  const { data, isLoading } = useFetchAllAssets({
    search,
    assetSearch: 'asset_search'
  });

  return (
    <SearchResultWrapper>
      <SearchField
        placeholder='Search Assets'
        className='search-input'
        size='small'
        value={inputValue}
        onChange={(event) => {
          handleSearch(event.target.value);
        }}
        clearable
      />

      {inputValue ? (
        <Results
          results={data?.results ?? []}
          isLoading={isLoading}
          inputValue={search}
        />
      ) : null}
    </SearchResultWrapper>
  );
};

export default SearchResults;

const Results = ({ results, inputValue, isLoading }) => {
  const history = useHistory();
  const emptyResults = results.length < 1;
  const handleViewAll = () => {
    history.push(`/assets?tab=asset-list&view=list`, {
      search: inputValue
    });
  };

  const handleViewIndividual = (id) => {
    history.push(`/assets?tab=asset-list&view=asset&id=${id}`, {
      search: inputValue
    });
  };
  return (
    <div className='asset_dashboard_search_result'>
      {isLoading ? (
        'loading ..'
      ) : emptyResults ? (
        <Text color='#2898A4'>No results matching “{inputValue}”</Text>
      ) : (
        <ul>
          {results?.map((resultItem, index) => (
            <li
              key={resultItem.name + index}
              onClick={() => {
                handleViewIndividual(resultItem?.id);
              }}
            >
              <Text size='sm'>{resultItem?.name}</Text>
              <Text light size='xs'>
                {resultItem?.serial_no ?? resultItem?.plate_no}
              </Text>
            </li>
          ))}
          <li>
            <CustomButton onClick={handleViewAll} size='sm'>
              View all results
            </CustomButton>
          </li>
        </ul>
      )}
    </div>
  );
};
