import UnAssignModal from 'Assets/Modals/UnassignModal';
import Avatar from 'common/Avatar/Avatar';
import { CustomButton } from 'components/Button';
import Text from 'components/Typography/Text';
import useDisclosure from 'hooks/useDisclosure';
const AssigneeDetails = ({
  assetId,
  first_name,
  last_name,
  employee_id,
  id,
  ...props
}) => {
  const [open, { toggle }] = useDisclosure();

  return (
    <div className='assign_details'>
      <div className='assign_details_action'>
        <Text>Assign To</Text>
        <CustomButton
          onClick={toggle}
          rounded='5px'
          size='sm'
          py='.3rem'
          px='1.5rem'
          variant={'contained'}
          bg={'#CCF3FF'}
          color='#2898A4'
        >
          usassign
        </CustomButton>
      </div>
      <Avatar
        width={45}
        height={45}
        first_name={first_name}
        last_name={last_name}
        {...props}
      />
      {open && (
        <UnAssignModal
          open={open}
          data={{ employee_id, id: assetId }}
          toggle={toggle}
          refetch={props.refetch}
        />
      )}
    </div>
  );
};

export default AssigneeDetails;
