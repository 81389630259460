import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { getVehicleStatusSummary } from '../../../api/fleet';
import { useQuery } from 'react-query';

const useStyles = makeStyles((theme) => ({
  Wrapper: {
    background: 'white',
    // borderRadius: '11px',
    // boxShadow: '0px 4px 10px rgba(214, 214, 214, 0.3)',
    // padding: '30px 0',
    padding: '20px 30px 20px 30px',
    height: '285px',
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 30%)',
    borderRadius: '5px',
    // width: '328px',
    // border: '2px solid red',

    '& .MuiTypography-h4': {
      // ...theme.mixins.makeResponsive({ theme, fontSize: 20, object: true }),
      // fontWeight: 700,
      // color: '#7F7F7F',
      padding: '0 0px 20px 0px',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20.25px',
      lineHeight: '23px',
      color: '#545454'
    }
  },
  Span: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14.22px',
    lineHeight: '16px',
    color: '#545454'
  },
  Status: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #F0F0F0',
    padding: '14px 0px',
    textTransform: 'capitalize',

    '& span': {
      width: '31px',
      textAlign: 'center',
      borderRadius: '88px'
    }
  }
}));

const Status = ({ status }) => {
  const name = status?.name?.replace('_', '-');
  const classes = useStyles();
  const styles = {
    active: {
      background: '#017AFE',
      color: '#2898A4'
    },
    inactive: {
      background: '#F8B636',
      color: '#7C7C7C'
    },
    in_shop: {
      background: '#2898A4',
      color: '#FF7372'
    },
    sold: {
      background: '#FF7372',
      color: 'white'
    }
  };
  return (
    <Box className={classes.Status}>
      <Typography variant='subtitle2'>{name}</Typography>
      <Box width='15%'>
        <Box
          component='span'
          display='flex'
          style={{
            display: 'inline-block',
            backgroundColor: '#FF7372',
            width: '10.69px',
            height: '11.51px',
            borderRadius: '1.64486px',
            marginRight: '.5rem',
            ...styles[status.name]
          }}
        ></Box>
        <Typography component='span' className={classes.Span}>
          {status.value}
        </Typography>
      </Box>
    </Box>
  );
};

function VehicleStatusSummary() {
  const { data = {}, isFetching } = useQuery(
    'analytics:vehicle-status',
    getVehicleStatusSummary
  );

  const classes = useStyles();

  if (isFetching) {
    return (
      <Box style={{ height: '100%' }}>
        <Skeleton
          variant='rect'
          height={285}
          style={{ borderRadius: '10px' }}
        />
      </Box>
    );
  }

  return (
    <Box className={classes.Wrapper}>
      <Typography variant='h4'>Status</Typography>

      {Object.keys(data).map((key) => (
        <Status key={key} status={{ name: key, value: data[key] }} />
      ))}
    </Box>
  );
}

export default VehicleStatusSummary;
