import React, { useCallback } from 'react';
import WhiteWrapper from 'Performance/components/WhiteWrapper';
import { CustomButton } from 'components/Button/';
import { Box, Grid, Stack } from '@mui/material';
import styled from 'styled-components';
import { Text, Title } from 'components/Typography';
import AssetDetails from './assets/Asset.svg';
import { useHistory } from 'react-router-dom';
import { CustomText } from 'common/Reusables';
import Asset1 from './assets/Asset1.svg';
import Asset2 from './assets/Asset2.svg';
import Asset3 from './assets/Asset3.svg';

const AssetEmpty = () => {
  const history = useHistory();
  const handleCreateAssetClick = useCallback(() => {
    const path = '?tab=home&view=create';
    history.push(path);
  }, [history]);
  return (
    <AssetEmpty.Wrapper>
      <WhiteWrapper>
        <div>
          <Grid container spacing={3} alignItems={'center'}>
            <Grid item md={6}>
              <Stack spacing={3}>
                <Title order={5} color='#171717'>
                  Welcome to Assets{' '}
                </Title>
                <Text size={'sm'} light>
                  Bring simplicity to your assets management and asset tracking.
                  With Assets, you can track assets growth and manage all your
                  hardware, software, and fixed assets in a centralized and
                  automated way
                </Text>
                <div>
                  <CustomButton
                    variant='contained'
                    p='10px 30px'
                    rounded='md'
                    onClick={handleCreateAssetClick}
                  >
                    Create New Asset
                  </CustomButton>
                </div>
              </Stack>
            </Grid>
            <Grid item md={6}>
              <div className='container'>
                <img src={AssetDetails} alt='cycle details' />
              </div>
            </Grid>
          </Grid>
        </div>
      </WhiteWrapper>
      {/* <WhiteWrapper> */}
      <Box
        p='2rem'
        borderRadius='20px'
        bgcolor='#FAFAFA'
        border='1px solid #E1E1E1'
        mt='1.5rem'
      >
        <CustomText
          fontSize='24px'
          lineHeight='28px'
          fontWeight='600'
          margin='0 0 3rem'
          textAlign='center'
          color='#171717'
        >
          How to Use Assets?
        </CustomText>
        {/* <CustomText
          fontSize='16px'
          textAlign='center'
          fontWeight='400'
          margin='0 0 3rem'
          lineHeight='25px'
          color='#545454'
        >
          Performance review made easy, effective and engaging for all.
        </CustomText> */}

        <Grid
          container
          spacing={1}
          alignItems='center'
          justifyContent={'space-between'}
          style={{ marginTop: '2rem' }}
        >
          <Grid
            item
            md={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}
          >
            <img src={Asset1} alt='' style={{ marginLeft: '-20px' }} />
          </Grid>
          <Grid item md={6}>
            <Grid container justifyContent='space-between'>
              <div>
                <CustomText
                  fontSize='24px'
                  lineHeight='28px'
                  fontWeight='600'
                  margin='0 0 1rem'
                >
                  Create Asset & Asset Types
                </CustomText>
                <CustomText
                  fontSize='16px'
                  fontWeight='400'
                  margin='0 0 1rem'
                  lineHeight='151.36%'
                >
                  Asides from our default asset types, you can create your own
                  unique asset type for easier tracking of your assets.
                </CustomText>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          alignItems='center'
          justifyContent={'space-between'}
          style={{ marginTop: '1rem' }}
        >
          <Grid item md={6}>
            <Grid container justifyContent='center'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  maxWidth: '400px'
                }}
              >
                <div>
                  <CustomText
                    fontSize='24px'
                    lineHeight='28px'
                    fontWeight='600'
                    margin='0 0 1rem'
                  >
                    Assign Assets{' '}
                  </CustomText>
                  <div style={{}}>
                    <CustomText
                      fontSize='16px'
                      fontWeight='400'
                      margin='0 0 1rem'
                      lineHeight='151.36%'
                    >
                      Assign assets to departments, employees or to a location.
                    </CustomText>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            md={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img src={Asset2} alt='' />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          alignItems='center'
          justifyContent={'space-between'}
          style={{ marginTop: '2rem' }}
        >
          <Grid
            item
            md={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img src={Asset3} alt='' style={{ marginLeft: '-50px' }} />
          </Grid>
          <Grid item md={6}>
            <Grid container justifyContent='center'>
              <div>
                <CustomText
                  fontSize='24px'
                  lineHeight='28px'
                  fontWeight='600'
                  margin='0 0 1rem'
                >
                  Maintain Your Assets
                </CustomText>
                <CustomText
                  fontSize='16px'
                  fontWeight='400'
                  margin='0 0 1rem'
                  lineHeight='151.36%'
                >
                  Keep track of your assets maintenance and get up to date
                  reminders on upcoming or overdue maintenance schedules.
                </CustomText>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* </WhiteWrapper> */}
    </AssetEmpty.Wrapper>
  );
};

export default AssetEmpty;

AssetEmpty.Wrapper = styled(Box)`
  display: grid;
  place-content: center;
  align-items: center;
  justify-content: center;
  /* border: 2px solid red; */
  max-width: 750px;
  margin: auto;
  .container {
    display: grid;
    place-content: center;
    margin-bottom: 18px;
  }
`;
