import { useInfiniteQuery, useQuery } from 'react-query';
import { getAllEmployeeWithFilter, getEmployees } from '../api/hr';

const useAllEmployees = (
  query = { department: '', active: '', status: '', search: '' },
  options = {}
) => {
  return useQuery(
    ['get-all-employees', query],
    () =>
      getAllEmployeeWithFilter({
        search: query?.search ?? '',
        department_string: query.department,
        status: query.status ?? null
      }),
    options
  );
};

export default useAllEmployees;

export const useEmployees = (params) => {
  return useQuery(['get-employees', params], () => getEmployees(params));
};

useEmployees.key = 'get-employees';

export const useEmployeesInfinite = (params) => {
  return useInfiniteQuery(
    ['projects', params],
    ({ pageParam = 1 }) =>
      getEmployees({ page: pageParam, page_size: 20, ...params }),
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.next ? pages.length + 1 : null
    }
  );
};
