import React from 'react';
import { usePermission } from '../Auth/permission-context';
import { Snackbar } from '@mui/material';

const Index = ({ children, path }) => {
  const { data } = usePermission();

  const accessLevels = data?.permissions.map((level) => level.codename);

  // if (!data?.is_owner && !accessLevels?.includes(path)) {
  //   return <Redirect to='/404' />;
  // } else if (
  //   !data?.business?.billing.is_premium &&
  //   !data?.business?.billing.is_trial &&
  //   path === 'access_hr'
  // ) {
  //   return <Redirect to='/billing?tab=upgrade-plan' />;
  // } else if (
  //   !data?.business?.billing.is_premium &&
  //   !data?.business?.billing.is_trial &&
  //   path === 'access_payroll'
  // ) {
  //   return <Redirect to='/billing?tab=upgrade-plan' />;
  // }

  if (!data?.is_owner && !accessLevels?.includes(path)) {
    <Snackbar>You're not authorized</Snackbar>;
    // return <Redirect to='/dashboard' />;
  }
  return <div>{children}</div>;
};

export default Index;
