import { Avatar, Checkbox } from '@mui/material';

import styled from 'styled-components';

import Text from 'components/Typography/Text';
import { capitalize } from '../../utilities/Capitalized';

const EmployeeCard = ({ CardComponent = AssignCard, ...props }) => {
  const { isSelected, handleSelect, noChecks, details } = props;

  const components = {
    checkbox: noChecks ? null : (
      <Checkbox
        disableTouchRipple
        disableFocusRipple
        disableRipple
        className='check'
        checked={isSelected}
        onChange={handleSelect}
        size='small'
        style={{ color: '#2898A4', paddingLeft: 0 }}
      />
    ),
    avatar: details?.photo ? (
      <Avatar
        sx={{ width: 36, height: 36 }}
        src={details?.photo}
        alt={details?.first_name || details?.last_name}
      />
    ) : (
      <Avatar
        alt={details?.first_name || details?.last_name}
        sx={{ width: 36, height: 36 }}
      >
        {details?.first_name ? details?.first_name[0] : ''}
        {details?.last_name ? details?.last_name[0] : ''}
      </Avatar>
    )
  };
  return <CardComponent {...props} components={components} />;
};

export default EmployeeCard;

const EmployeeSC = styled.div`
  display: grid;
  grid-template-columns:  ${(props) =>
    props.noChecks ? '1fr 4fr 2fr' : '1fr 1fr 4fr 2fr'}} ;
  padding-block: 10px;
  border-bottom: 1px solid #e1e1e1;
  align-items: center;
  font-family: var(--black-family);

  ${(props) =>
    props.noChecks
      ? `
  padding-left: .7rem;
  cursor : pointer;

  `
      : ''}

  ${(props) =>
    props.noChecks && props.isSelected
      ? `
  background-color : #E0EEF0 ;
  border-radius : 5px;

  `
      : ''}

  .about {
    margin-right: 2rem;
  }
`;

export const AssignCard = ({
  isSelected,
  handleSelect,
  noChecks,
  details,
  style,
  components
}) => {
  const { checkbox, avatar } = components;
  return (
    <EmployeeSC
      noChecks={noChecks}
      isSelected={isSelected}
      style={style}
      onClick={noChecks ? handleSelect : () => {}}
    >
      {checkbox}
      {avatar}
      <div className='about'>
        <Text
          sx={{
            mb: 0.7
          }}
          fw='700'
          size='sm'
        >
          {capitalize(details?.first_name || '') +
            ' ' +
            capitalize(details?.last_name || '')}
        </Text>
        <Text
          size='xs'
          variant='subtitle2'
          textColor='#878787'
          textTransform={'capitalize'}
        >
          {details?.job_title}
        </Text>
      </div>
      <Text
        variant='caption'
        textTransform={'capitalize'}
        className='job'
        size='xs'
      >
        {details?.department_name}
      </Text>
    </EmployeeSC>
  );
};
