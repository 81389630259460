import { Box, Tabs as MuiTabs, Tab, Typography } from '@mui/material';
import { sizes } from 'components/Typography/Text';
import styled from 'styled-components';

// const tabHeight = '46px';
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const Tabs = ({
  value = 0,
  onChange: handleChange,
  tabs,
  tabWidth,
  tabHeight = 46,
  styles,
  counts = [],
  className = '',
  indicatorColor,
  colors = {},
  size,
  activeColor,
  whiteSpacing = '.2rem',
  rightIcons = [],
  ...props
}) => {
  const colorStyle = {
    others: '#878787',
    active: activeColor || '#2898A4',
    indicatorColor: indicatorColor || '#D6F2F5',
    bg: '#F2F2F2',
    ...colors
  };
  const wrapperProps = {
    tabHeight,
    tabWidth,
    colors,
    whiteSpacing,
    size: size || 'sm',
    ...colorStyle,
    ...props
  };

  return (
    <TabsWrapper
      style={
        styles?.root ||
        (colors.background && { background: colors?.background })
      }
      {...wrapperProps}
    >
      <TabsRoot
        style={styles?.tab || {}}
        variant='fullWidth'
        value={value || 0}
        onChange={handleChange}
        height={tabHeight}
        colors={colorStyle}
      >
        {tabs?.map((tab, index) => (
          <TabRoot
            disableRipple
            key={index}
            height={tabHeight}
            colors={colorStyle}
            style={{
              minWidth: tabWidth || `${100 / tabs?.length}%`,
              color: colorStyle.others,
              fontWeight: 600,
              fontSize: sizes[size],
              fontFamily: 'Black Sans',
              ...(styles?.tab || {})
            }}
            label={
              <TextBadge
                text={tab}
                count={counts[index]}
                rightIcon={rightIcons[index]}
              />
            }
            {...a11yProps(index)}
          />
        ))}
      </TabsRoot>
    </TabsWrapper>
  );
};

export default Tabs;

const TabsWrapper = styled('div')(
  ({ indicatorColor, whiteSpacing, active, bg }) => {
    return {
      padding: whiteSpacing,
      background: bg,
      border: '1px solid #E1E1E1',
      boxShadow: 'inset -4px 5px 8px rgba(237, 237, 237, 0.37)',
      borderRadius: '32.6585px',
      display: 'flex',
      justifyContent: 'center',
      '& .MuiTab-textColorInherit.Mui-selected': {
        borderRadius: '50px',
        minHeight: '20px',
        color: active,
        fontSize: '14.512px',
        fontWeight: 600
      },
      '& .MuiTabs-indicator': {
        background: indicatorColor,
        borderRadius: '41px',
        height: '100%',
        zIndex: 1
      },
      '& .MuiTab-wrapper': {
        textTransform: 'capitalize'
      },
      '& .MuiBox-root': {
        padding: '0px'
      }
    };
  }
);

const TabsRoot = styled(MuiTabs)(({ height, colors, tabHeight }) => ({
  minHeight: (height ? height : tabHeight) + '!important',
  height: (height ? height : tabHeight) + '!important',
  flex: 1,
  fontSize: '14px',
  color: colors?.active ?? '#171717',
  fontWeight: 600,

  '& .Mui-selected': {
    borderRadius: '50px',
    color: `${colors?.active} !important`
  }
}));

const TabRoot = styled(Tab)(({ height, colors, tabHeight }) => ({
  minHeight: (height ? height : tabHeight) + '!important',
  height: (height ? height : tabHeight) + '!important',
  minWidth: '120px',
  border: '2px solid red',
  borderRadius: '50px',
  fontSize: '14px',
  color: colors?.others,
  fontWeight: 600,

  '&.Mui-selected': {
    zIndex: 2,
    color: colors?.active
  }
}));

const TextBadge = ({ text, count, rightIcon }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px'
      }}
    >
      {text}

      {rightIcon ? rightIcon : null}
      {count === 0 || count ? (
        <Typography
          sx={{
            bgcolor: '#FF6666',
            color: '#fff',
            fontWeight: 'bold',
            fontFamily: 'Black Sans',
            width: '33px',
            borderRadius: '36px',
            height: '17px',
            fontSize: '12px'
          }}
        >
          {count}
        </Typography>
      ) : null}
    </Box>
  );
};
