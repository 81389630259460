import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { CustomButton as Button } from 'components/Button';
import { DragAndDrop, FileView } from 'components/Inputs';
import Text from 'components/Typography/Text';
import { useFormikContext } from 'formik';
import { useUploadTemporaryDocument } from 'hooks/queries';
import { useState } from 'react';
import FormSectionTitle from './FormSectionTitle';
import { Stack } from '@mui/material';
import { useEffect } from 'react';

const AddImage = ({ editMode, data }) => {
  const { setFieldValue } = useFormikContext();
  const [attachments, setAttachments] = useState(null);

  useEffect(() => {
    if (editMode && data) {
      setAttachments(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mutation = useUploadTemporaryDocument({
    onSuccess(data) {
      setFieldValue('image', data.id);
      setAttachments(data.fileInfo);
    }
  });
  const handleFileUpload = async (file, progressSetter) => {
    mutation.mutate({ file, progressSetter });
  };

  return (
    <>
      <FormSectionTitle>Add Image (Optional)</FormSectionTitle>
      {attachments ? (
        <FileView
          fileIcon={({ color }) => <InsertPhotoIcon htmlColor={color} />}
          handleRemove={() => {
            setAttachments(null);
            setFieldValue('image', null);
          }}
          name={attachments?.name}
        />
      ) : (
        <DragAndDrop
          onUpload={handleFileUpload}
          component={(onClick) => <DndLabel onClick={onClick} />}
        />
      )}
    </>
  );
};

export default AddImage;

const DndLabel = ({ onClick }) => {
  return (
    <Stack paddingY={'1rem'}>
      <Text size='sm'>Drag and Drop your asset image</Text>
      <Text size='xs'>Maximum file size and type: 5MB (Jpegs, Pdf)</Text>
      <Button size='sm' onClick={onClick}>
        Choose a file
      </Button>
    </Stack>
  );
};
