import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { CustomButton as Button } from 'components/Button';
import StatCard from 'components/StatCard';
import Title from 'components/Typography/Title';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { capitalize } from 'utilities/Capitalized';
import { recentlyAddedAssetType } from '../../api/asset';

function RecentAsset() {
  const { data, isFetching } = useQuery(
    ['recently-added'],
    recentlyAddedAssetType
  );

  const history = useHistory();
  const handleViewAll = () => {
    history.push('/assets?tab=asset-list&view=type');
  };

  if (data && data?.data?.length === 0) {
    return (
      <Box>
        <Title>Recently Added</Title>
        <Box>
          <Typography variant='subtitle2'>You have no recent asset</Typography>
        </Box>
      </Box>
    );
  }

  if (isFetching) {
    return (
      <Box>
        <Skeleton
          variant='rect'
          height={150}
          style={{ borderRadius: '10px' }}
        />
      </Box>
    );
  }

  return (
    <RecentWrapper>
      <div className='title_block'>
        <Title order={4}>Recently Added</Title>
        <Button size='search' onClick={handleViewAll}>
          See all asset type
        </Button>
      </div>
      <div className='stat_block'>
        {data?.data?.slice(0, 4).map((asset) => (
          <StatCard
            style={{ cursor: 'pointer' }}
            key={asset?.id}
            bgColor='offBlue'
            height='93px'
            title={capitalize(asset?.name)}
            titleProps={{ style: { fontWeight: 510, color: '#545454' } }}
            valueProps={{
              style: {
                fontSize: '22px',
                fontWeight: 600,
                color: '#545454'
              }
            }}
            onClick={() => {
              const props = {
                search: asset.title,
                id: asset.id,
                isVehicle: asset?.vehicle_type
              };
              history.push('/assets?tab=asset-list&view=list', props);
            }}
            value={asset?.cost ?? asset?.asset_type ?? asset?.asset_count ?? 0}
          />
        ))}
      </div>
    </RecentWrapper>
  );
}

export default RecentAsset;

const RecentWrapper = styled.section`
  .title_block {
    display: flex;
    justify-content: space-between;
  }

  .stat_block {
    margin-top: 1.6rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    flex-wrap: wrap;
    gap: 0.5rem;
  }
`;
