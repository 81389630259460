import React from 'react';

const PayrollIcon = () => {
  return (
    <svg
      width='36'
      height='37'
      viewBox='0 0 36 37'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_b_1527_1592)'>
        <path
          d='M30.0835 0.5H5.91654C2.64893 0.5 0 3.14893 0 6.41654V30.5835C0 33.8511 2.64893 36.5 5.91654 36.5H30.0835C33.3511 36.5 36 33.8511 36 30.5835V6.41654C36 3.14893 33.3511 0.5 30.0835 0.5Z'
          fill='#FFE5E5'
        />
        <path
          d='M5.91654 0.75H30.0835C33.213 0.75 35.75 3.287 35.75 6.41654V30.5835C35.75 33.713 33.213 36.25 30.0835 36.25H5.91654C2.787 36.25 0.25 33.713 0.25 30.5835V6.41654C0.25 3.287 2.787 0.75 5.91654 0.75Z'
          stroke='white'
          stroke-width='0.5'
        />
      </g>
      <g filter='url(#filter1_di_1527_1592)'>
        <path
          d='M28.8076 7.70004C28.3261 7.77409 27.8748 7.98075 27.5042 8.29685C27.1336 8.61296 26.8584 9.02602 26.7093 9.48975C26.6231 9.73763 26.5813 9.99878 26.5859 10.2612V26.0754C26.5859 26.928 26.2483 27.7458 25.6469 28.3501C25.0455 28.9544 24.2293 29.2959 23.3767 29.3H23.1684H23.0759C22.9862 29.2825 22.8984 29.2567 22.8136 29.2229C22.7418 29.1915 22.6723 29.1554 22.6053 29.1149C22.4874 29.0404 22.3787 28.9524 22.2813 28.8526C21.9955 28.5335 21.7753 28.1613 21.6333 27.7572C21.5312 27.5082 21.4537 27.2498 21.4019 26.9857C21.3647 26.7717 21.3467 26.5549 21.3479 26.3377C21.3479 26.2529 21.3479 26.168 21.3479 26.0909V24.1546H12.5073L12.4688 10.4618C12.4688 10.3615 12.4688 10.2612 12.4688 10.1532C12.5326 9.55979 12.7808 9.00132 13.1785 8.55633C13.3763 8.33585 13.6081 8.14835 13.865 8.0009C14.0339 7.8981 14.2192 7.82504 14.4127 7.7849H14.729H28.8076V7.70004Z'
          fill='#FF7272'
        />
        <path
          d='M7.19189 24.1315H21.3476V26.3147C21.3461 26.8956 21.4725 27.4698 21.7179 27.9964C21.832 28.3591 22.0543 28.6783 22.3548 28.9113C22.6553 29.1443 23.0198 29.2799 23.3996 29.3001H9.5756C9.00864 29.2981 8.4612 29.0928 8.03275 28.7215C7.75509 28.4751 7.54078 28.1656 7.40789 27.8189C7.27804 27.4926 7.21255 27.1442 7.21504 26.7929L7.19189 24.1315Z'
          fill='#FFCCCC'
        />
      </g>
      <defs>
        <filter
          id='filter0_b_1527_1592'
          x='-17'
          y='-16.5'
          width='70'
          height='70'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='8.5' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_1527_1592'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_1527_1592'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_di_1527_1592'
          x='4.19189'
          y='5.70004'
          width='31.6157'
          height='28.6'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='1.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0.49 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_1527_1592'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_1527_1592'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='7' dy='-2' />
          <feGaussianBlur stdDeviation='3.5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.895833 0 0 0 0 0.884635 0 0 0 0 0.884635 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_1527_1592'
          />
        </filter>
      </defs>
    </svg>
  );
};

export default PayrollIcon;
