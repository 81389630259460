import { CustomButton as Button } from 'components/Button';
import CircularLoader from 'components/CircularLoader';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from 'components/Dialog';
import { CustomTextField } from 'components/Inputs';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import { useFormik } from 'formik';
import { runIfFn } from 'utilities/utils';
import { useCreateIssueType } from '../queries';

const AddIssueTypeModal = ({ open, toggle, refetch, editData = null }) => {
  const isEdit = Boolean(editData?.id);
  const { handleChange, values } = useFormik({
    initialValues: { name: isEdit ? editData.name : '', is_default: false }
  });

  const { name } = values;
  const mutation = useCreateIssueType({
    editId: isEdit ? editData?.id : null,
    onSuccess() {
      runIfFn(toggle, refetch);
    }
  });

  const handleSubmit = () => {
    mutation.mutate(values);
  };

  return (
    <Dialog open={open} fullWidth maxWidth='xs' cp={'35px'} onClose={toggle}>
      <DialogTitle px={4} onClose={toggle}>
        <span>
          <Title>{isEdit ? 'Edit' : 'Add New'} Issue Type</Title>
          <Text size='xs'>
            Provide asset name to complete creating a category of asset{' '}
          </Text>
        </span>
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <div>
            <CustomTextField
              size='small'
              label='Enter Issue Name'
              name='name'
              value={name}
              onChange={handleChange}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          bold
          size='sm'
          fullWidth
          variant='contained'
          disabled={!name || mutation.isLoading}
          onClick={handleSubmit}
        >
          <CircularLoader size={15} show={mutation.isLoading} />
          {isEdit ? 'Save Edit' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddIssueTypeModal;
