import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, IconButton, Stack } from '@mui/material';
import Text from 'components/Typography/Text';
import { useEffect } from 'react';

let cachedCount = 0;

const TablePagination = ({
  sx,
  count,
  page,
  rowsPerPage,
  onChange: onPageChange,
  isFetching,
  wrap,
  pageCacheKey,
  disbaleOnFetch = true
}) => {
  const calCount = isFetching ? cachedCount : count;
  // cachedCount = count ? count : cachedCount;
  cachedCount = calCount;
  rowsPerPage = cachedCount <= rowsPerPage ? cachedCount : rowsPerPage;

  const currentStart = page * rowsPerPage + 1;
  const currentEnd = page * rowsPerPage + rowsPerPage;
  useEffect(() => {
    if (pageCacheKey) {
      const cache = sessionStorage.getItem(pageCacheKey);
      if (cache) {
        const { page, count } = JSON.parse(cache);
        onPageChange(null, page);
        cachedCount = count;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pageCacheKey) {
      sessionStorage.setItem(
        pageCacheKey,
        JSON.stringify({ page, count: cachedCount })
      );
    }
  }, [page, pageCacheKey]);

  const prevIcon = (
    <IconButton
      size='small'
      aria-label='previous'
      disabled={page === 0 || (disbaleOnFetch && isFetching)}
      onClick={(event) => onPageChange(event, (prev) => prev - 1)}
    >
      {' '}
      <KeyboardArrowLeftIcon />
    </IconButton>
  );

  return (
    <Stack
      sx={{
        alignItems: 'center',
        gap: wrap ? 0.2 : 1,
        py: 1,
        ...sx
      }}
      direction='row'
    >
      {wrap && prevIcon}
      <Text>
        {currentStart} -{' '}
        {' ' +
          (!cachedCount
            ? '0'
            : currentEnd < cachedCount
            ? currentEnd
            : cachedCount)}{' '}
        of {' ' + cachedCount ? cachedCount : '0'}
      </Text>

      <Box>
        {!wrap && prevIcon}
        <IconButton
          size='small'
          aria-label='next'
          disabled={
            page * rowsPerPage + rowsPerPage >= cachedCount ||
            (disbaleOnFetch && isFetching)
          }
          onClick={(event) => onPageChange(event, (prev) => prev + 1)}
        >
          {' '}
          <ChevronRightIcon />{' '}
        </IconButton>
      </Box>
    </Stack>
  );
};

export default TablePagination;
