import { Box, Button } from '@material-ui/core';
import styled from 'styled-components';

export const TabWrapper = styled(Box)`
  ${({ theme }) => `
        border-bottom: 1px solid #E1E1E1;
        width: 100%;
        overflow-x: auto;

        background: #fff;
        padding: 0rem 2rem;

    `}
`;

export const TabButtonWrapper = styled(Button)`
  ${({ theme, isactive, menuTab, mr }) => `
    text-transform: none;
    padding-bottom: 1%;
    color: ${isactive === true ? theme.palette.secondary.main : '#878787'};
    span{
      font-style: normal;
      font-size: ${menuTab === true ? '16px' : '16px'};
      font-weight: ${
        isactive === true ? 'bold' : menuTab && isactive ? 'normal' : 'normal'
      };
    }
    margin-right: ${mr ? mr : '3%'}; ;
    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 5px;
        background: ${
          isactive === true ? theme.palette.secondary.main : 'transparent'
        };
        border-radius: 5px 5px 0 0;
    }
  `}
`;
