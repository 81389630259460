import { CustomButton as Button } from 'components/Button';
import CircularLoader from 'components/CircularLoader';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from 'components/Dialog';
import Group from 'components/StyledComponents/ButtonGroup';
import Typography from 'components/Typography';
import Text from 'components/Typography/Text';
const defaultCtaTexts = ['Yes, Delete', "No, don't delete"];

const DeleteModal = ({
  open,
  toggle,
  onDeleteComplete,
  data,
  title = '',
  desc = '',
  handleDelete,
  isLoading,
  size = 'xs',
  ctas = [],
  ctaText = defaultCtaTexts,
  descNode = null,
  ...rest
}) => {
  return (
    <Dialog
      rounded='15px'
      style={{ borderRadius: '15px' }}
      open={open}
      toggle={toggle}
      onClose={toggle}
      fullWidth
      maxWidth={size}
      ap='1rem'
      {...rest}
    >
      {title ? (
        <DialogTitle onClose={toggle} divider>
          <Typography bold size='sm'>
            {title}
          </Typography>
        </DialogTitle>
      ) : null}
      <DialogContent>
        {descNode ? (
          descNode
        ) : (
          <Text size='xs' my='.4rem'>
            {desc}
          </Text>
        )}
      </DialogContent>
      <DialogActions>
        <Group fullWidth>
          <Button
            onClick={handleDelete}
            size='xs'
            disabled={isLoading}
            fullWidth
            variant='contained_warning'
          >
            <CircularLoader show={isLoading} />
            {ctaText?.[0] ?? defaultCtaTexts[0]}
          </Button>
          <Button onClick={toggle} size='xs' fullWidth variant='outlined'>
            {ctaText?.[1] ?? defaultCtaTexts[1]}
          </Button>
        </Group>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
