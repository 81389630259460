import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { Country } from 'country-state-city';

function Currencies({ value, onChange, onBlur, ...props }) {
  return (
    <TextField
      select
      size='small'
      label='Currency'
      onBlur={onBlur}
      onChange={onChange}
      name='currency'
      color='secondary'
      value={value}
      id='demo-simple-select-outlined'
      variant='outlined'
      fullWidth
    >
      {Object.values(Country.getAllCountries()).map((currency) => (
        <MenuItem key={currency.name} value={currency.currency}>
          {`${currency.currency} (${currency.name})`}
        </MenuItem>
      ))}
    </TextField>
  );
}

//TODO: use error boundaries to prevent crash

export default Currencies;
