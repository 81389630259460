import moment from 'moment';
import _ from 'validator';

export const FormikVehicleCreation = {
  getInitialValues: (initValues = {}) => {
    const make = !initValues.model ? '' : initValues.model.make.id;
    const model = !initValues.model ? '' : initValues.model.id;
    const start_mileage =
      initValues.start_mileage === 0
        ? 0
        : !initValues.start_mileage
        ? null
        : initValues.start_mileage;
    const date_purchased = !initValues.date_purchased
      ? ''
      : moment(initValues.date_purchased).format('YYYY-MM-DD');
    const date_assigned = !initValues.date_assigned
      ? null
      : moment(initValues.date_assigned).format('YYYY-MM-DD');
    const {
      year = '',
      plate_no = '',
      purchase_price = null,
      color = '',
      status = '',
      type = '',
      assignee = {}
    } = initValues;

    return {
      make,
      model,
      year,
      plate_no,
      start_mileage,
      purchase_price,
      color,
      status,
      type,
      date_purchased,
      assignee: assignee?.id,
      date_assigned,
    };
  },

  // Validation
  validate: (values) => {
    const errors = {};

    if (!values.make) errors.make = 'Select a make.';
    if (!values.model) errors.mod = 'Select a model.';
    if (!values.year) errors.year = 'Enter a Year';
    if (!values.type) errors.type = 'Select a type';
    if (!values.plate_no) errors.plate_no = 'Enter a plate number.';
    if (values.plate_no.length > 50) errors.plate_no = 'Too long.';
    if (!values.model) errors.model = 'Enter a valid model.';
    if (!values.status) errors.status = 'Select a status.';
    if (!values.color) errors.color = 'Enter a color.';
    if (!values.date_purchased)
      errors.date_purchased = 'Select a purchase date.';
    // if (values.start_mileage !== 0 && !values.start_mileage)
    //   errors.start_mileage = "Enter vehicle's current mileage.";
    if (!values.purchase_price) errors.purchase_price = 'Enter price.';

    return errors;
  }
};

export const disableSubmit = (errors) => {
  return !!Object.entries(errors).filter((error) => !!error[1]).length;
};

export const FormikServiceCreation = {
  initialValues: (service = {}) => ({
    service_date: service.service_date || '',
    due_date: service.due_date || '',
    description: service.description || '',
    mileage: service.mileage?.toString() || null,
    file: service.file || null,
    reminder: service.reminder_days || '',
    cost: service.cost || null,
    attachments: service.attachments || []
  }),

  validate: (values) => {
    const errors = {};
    if (!values.service_date) errors.service_date = 'Select a service date.';
    if (!values.due_date) errors.due_date = 'Select a due date.';
    if (!values.description) errors.description = 'Enter description.';
    // if (!values.mileage) errors.mileage = 'Enter a mileage';
    // if (!values.file) errors.file = 'Please upload a document';
    if (values.mileage && !_.isNumeric(values.mileage))
      errors.mileage = 'Invalid mileage';
    return errors;
  }
};

export const FormikRepairCreation = {
  initialValues: (repair = {}) => ({
    description: repair.description,
    repair_date: repair.repair_date,
    cost: repair.cost,
    mileage: repair.mileage?.toString(),
    attachments: repair.attachments || []
  }),

  validate: (values) => {
    const errors = {};
    if (!values.description) errors.description = 'Enter description.';
    if (!values.repair_date) errors.repair_date = 'Enter a repair date.';
    // if (!values.mileage) errors.mileage = 'Enter a mileage';
    // if (values.mileage && !_.isNumeric(values.mileage.toString()))
    // errors.mileage = 'Invalid mileage';
    // if (!values.cost) errors.cost = 'Enter a cost';
    if (values.cost && !_.isNumeric(values.cost.toString()))
      errors.cost = 'Invalid cost';
    return errors;
  }
};

export const FormikDocumentCreation = {
  initialValues: (document = {}) => ({
    type: document.type,
    title: document.title,
    mileage: document.mileage,
    issue_date: document.issue_date,
    due_date: document.due_date,
    reminder_days: document.reminder_days,
    attachments: document.attachments || []
  }),
  validate: (values) => {
    const errors = {};
    if (!values.type) errors.type = 'Select a document type.';
    if (!values.title) errors.title = 'Enter a title.';
    if (!values.issue_date) errors.issue_date = 'Select issue date.';
    if (!values.due_date) errors.due_date = 'Select due date';
    // if (!values.reminder_days) errors.reminder_days = 'Select reminder days';
    return errors;
  }
};
