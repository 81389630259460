import { CustomButton as Button, CustomButton } from 'components/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from 'components/Dialog';
import { DatePicker, DragAndDrop, FileView } from 'components/Inputs';
import Group from 'components/StyledComponents/ButtonGroup';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import ReminderForm from 'Assets/Shared/ReminderForm';
import CircularLoader from 'components/CircularLoader';
import { AutoCompleteSelect } from 'components/Inputs';
import {
  filterOptions,
  getOptionLabel,
  renderOption
} from 'components/Inputs/AutoCompelete';

// import { statutoryDocType } from './helpers';
import { capitalize } from 'utilities/Capitalized';
import useAssetDocState from './useDocument';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import { Alert } from '@mui/material';
// import Typography from 'components/Typography';

const AssetDocumentModal = ({
  open,
  toggle,
  onSuccess,
  assetId = null,
  allowOthersDoc,
  data
}) => {
  const history = useHistory();

  const {
    handleCustomClick,
    handleAddDoc,
    handleFileUpload,
    reminderMutation,
    mutation,
    formik,
    setInputProps,
    documentType,
    inputValue,
    setInputValue,
    createDocumentType,
    value,
    setValue
    // openedAttachmentAlert
  } = useAssetDocState({ data, assetId, toggle, onSuccess });

  const { setFieldValue, values, touched } = formik;

  const allDocType = documentType
    ? documentType?.map((doc) => ({
        ...doc,
        label: capitalize(doc.name),
        value: doc.id
      }))
    : [];

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='xs'
      cp={20}
      onClose={toggle}
      rounded='15px'
    >
      <DialogTitle iconFlex onClose={toggle} px={3} divider>
        <span>
          <Title>Add Statutory Information</Title>
          <Text size='xs' light='true'>
            Statutory documents are documents that may need renewal upon expiry
          </Text>
        </span>
      </DialogTitle>
      <DialogContent>
        {/* {openedAttachmentAlert ? ( */}
        {/* <Alert variant='filled' severity='error'>
          <Typography size='sm' textColor='white'>
            File attachment is required
          </Typography>
        </Alert> */}
        {/* ) : null} */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
          <>
            <AutoCompleteSelect
              size='small'
              label='Information Name e.g Insurance'
              name='document_type'
              value={value}
              options={allDocType}
              id='statutory-informatiom'
              onChange={(event, newValue) => {
                setFieldValue('document_type', newValue.value);
                setValue(newValue);
              }}
              inputProps={{
                isLoading: createDocumentType?.isLoading
              }}
              // {...setInputProps('document_type')}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              filterOptions={filterOptions}
              selectOnFocus
              getOptionLabel={getOptionLabel}
              renderOption={renderOption(handleCustomClick)}
              addMessage={(isAvailable, inputValue) => {
                return !isAvailable
                  ? 'Add New'
                  : `Add "${inputValue}" as new statutory information`;
              }}
            />
            <div>
              <DatePicker
                inputProps={{ fullWidth: true, size: 'small' }}
                size='small'
                fullWidth
                label='Date Issued'
                {...setInputProps('date_of_issue', true)}
              />
            </div>
            <DatePicker
              inputProps={{ fullWidth: true, size: 'small' }}
              size='small'
              fullWidth
              label='Expiry Date'
              {...setInputProps('expiry_date', true)}
            />

            <ReminderForm
              setReminderInput={setInputProps('reminder.set_reminder')}
              setTimeInput={setInputProps('reminder.date')}
              clearInputs={() => {
                setFieldValue('reminder', null);
              }}
            />
          </>
          {values.file ? (
            <FileView
              // fileIcon={({ color }) => <InsertPhotoIcon htmlColor={color} />}
              handleRemove={() => {
                setFieldValue('file', null);
              }}
              name={values.file?.name}
            />
          ) : (
            <DragAndDrop
              onUpload={handleFileUpload}
              component={(onClick) => <DndLabel onClick={onClick} />}
            />
          )}
          <hr />
          {formik.errors.file && touched.file ? (
            <Text size='xs' color='red'>
              Field is Required
            </Text>
          ) : (
            <Text size='xs'>Make sure the file size is below 3MB</Text>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Group fullWidth>
          <CustomButton
            onClick={() => history.push('/assets?tab=asset-list&view=list')}
            size='sm'
            fullWidth
            variant='offwhite'
          >
            Cancel
          </CustomButton>
          <CustomButton
            size='sm'
            fullWidth
            variant='contained'
            onClick={handleAddDoc}
            disabled={reminderMutation.isLoading || mutation.isLoading}
          >
            <CircularLoader
              show={reminderMutation.isLoading || mutation.isLoading}
            />
            Add Document
          </CustomButton>
        </Group>
      </DialogActions>
    </Dialog>
  );
};

export default AssetDocumentModal;

const DndLabel = ({ onClick }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        padding: '1.4rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '.5rem',
        alignItems: 'center'
      }}
    >
      <Text size='sm'>Drag and Drop a file</Text>
      <Button size='xs' onClick={onClick} variant='white'>
        Click here to upload document
      </Button>
    </div>
  );
};
