import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { OutlinedInput } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import styled from 'styled-components';
import { isFunc } from 'utilities/utils';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      minWidth: 250,
      maxWidth: 260,
      left: 365
    }
  }
};

export default function MultipleSelectCheckmarks({
  options,
  value,
  label,
  onChange,
  ...props
}) {
  const handleChange = (event) => {
    const {
      target: { value }
    } = event;

    isFunc(onChange) &&
      onChange(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <MultiSelectWrapper fullWidth size={props?.size}>
      <InputLabel id={label}>{label}</InputLabel>
      <StyledSelect
        labelId={label}
        id={label}
        multiple
        value={value}
        onChange={handleChange}
        input={<OutlinedInput size={props?.size} label={label} />}
        MenuProps={MenuProps}
        IconComponent={KeyboardArrowDownIcon}
        {...props}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.label}>
            <ListItemText primary={option.label} />
            <Checkbox checked={value.indexOf(option.label) > -1} />
          </MenuItem>
        ))}
      </StyledSelect>
    </MultiSelectWrapper>
  );
}

const MultiSelectWrapper = styled(FormControl)`
  & label.Mui-focused {
    color: #a8a8a8 !important;
  }
`;

const StyledSelect = styled(Select)`
  //   &[class$='-MuiInputBase-root-MuiOutlinedInput-root']
  &.MuiInputBase-root,
  &.MuiOutlinedInput-root {
    border-radius: 10px !important;
    border-color: #a8a8a8;
    &:hover,
    &:focus {
      border-color: #a8a8a8 !important;
    }

    &.Mui-focused {
      border-color: #a8a8a8 !important;
    }

    &.Mui-focused fieldset {
      border-color: white;
      border-color: #a8a8a8 !important;
    }
  }
`;
