import dayjs from 'dayjs';

export const formateReminderDate = (date, due) => {
  const currentDate = dayjs();
  const reminderDate = dayjs(date);

  const firstDate = due ? currentDate : reminderDate;
  const secondDate = due ? reminderDate : currentDate;
  const dateDiff = firstDate.diff(secondDate, 'day');
  const monthDiff = firstDate.diff(secondDate, 'month');
  const yearDiff = firstDate.diff(secondDate, 'year');

  let dateFormat = 'today';
  if (dateDiff > 0) {
    dateFormat = `${dateDiff} days`;
  }

  if (monthDiff > 0) {
    dateFormat = `${monthDiff} months`;
  }
  if (yearDiff > 0) {
    dateFormat = `${yearDiff} years`;
  }

  return dateFormat === 'today'
    ? dateFormat
    : due
    ? `${dateFormat} ago`
    : `in ${dateFormat}`;
};
