export function getIconColor(word) {
  if (!word)
    return {
      letter: '',
      color: '#D8D8D8'
    };
  const _alphabets = 'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z';
  const alphabets = _alphabets.toUpperCase().split(',');
  const availableColors = ['#4069D0', '#F8B636', '#FF7372'];
  const letter = word.trim()[0].toUpperCase();
  // using % 3 because there are three available colors to choose from
  const index = alphabets.indexOf(letter) % 3;
  return {
    letter,
    color: availableColors[index]
  };
}

export function getTimeOfDay() {
  const hour = new Date().getHours();

  const isMorning = hour >= 0 && hour < 12 ? 'morning' : false;
  const isAfternoon = hour >= 12 && hour < 17 ? 'afternoon' : false;
  const isEvening = hour >= 17 && hour < 24 ? 'evening' : false;

  return isMorning || isAfternoon || isEvening || '';
}

export const isFunc = (callback) => {
  return !!callback && typeof callback === 'function';
};

export function runIfFn(...arg) {
  const isAllFn = arg.map((fn) => isFunc(fn));
  if (isAllFn.includes(false)) {
    // const indexOfNotAFn = isAllFn.indexOf(false);
    return;
  }
  try {
    arg.forEach((fn, index) => {
      fn(this && this.length > 0 ? this[index] : null);
    });
  } catch (error) {
    throw error;
  }
}

export const progressEventHandler = (progressHandler) => (progressEvent) => {
  const progress = Math.round(
    (progressEvent.loaded * 100) / progressEvent.total
  );
  runIfFn.bind([progress])(progressHandler);
};

export const numberInputOnWheelPreventChange = (e) => {
  e.target.blur();
  e.stopPropagation();
  // setTimeout(() => {
  //   e.target.focus();
  // }, 0);
};
