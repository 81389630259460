import { CustomButton as Button, CustomButton } from 'components/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from 'components/Dialog';
import {
  CustomTextField,
  DatePicker,
  DragAndDrop,
  FileView
} from 'components/Inputs';
import Group from 'components/StyledComponents/ButtonGroup';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import { useHistory } from 'react-router-dom';
import ReminderForm from 'Assets/Shared/ReminderForm';
import CircularLoader from 'components/CircularLoader';

import useAddNormalDoc from './useAddNormalDoc';

const AddNormalDoc = ({
  open,
  toggle,
  assetId = null,
  allowOthersDoc,
  data
}) => {
  const history = useHistory();
  const {
    formik,
    reminderMutation,
    mutation,
    setInputProps,
    handleFileUpload,
    handleAddDoc
  } = useAddNormalDoc({ data, assetId, toggle });

  const { setFieldValue, values, touched } = formik;

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='xs'
      cp={20}
      onClose={toggle}
      rounded='15px'
    >
      <DialogTitle iconFlex onClose={toggle} px={3} divider>
        <span>
          <Title>Add document</Title>
        </span>
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
          <CustomTextField
            label='Document Name'
            size='small'
            {...setInputProps('name')}
          />
          <>
            <div>
              <DatePicker
                inputProps={{ fullWidth: true, size: 'small' }}
                size='small'
                fullWidth
                label='Date Issued'
                {...setInputProps('date_of_issue', true)}
              />
            </div>
            <DatePicker
              inputProps={{ fullWidth: true, size: 'small' }}
              size='small'
              fullWidth
              label='Expiry Date'
              {...setInputProps('expiry_date', true)}
            />

            <ReminderForm
              setReminderInput={setInputProps('reminder.set_reminder')}
              setTimeInput={setInputProps('reminder.date')}
              clearInputs={() => {
                setFieldValue('reminder', null);
              }}
            />
          </>
          {values.file ? (
            <FileView
              handleRemove={() => {
                setFieldValue('file', null);
              }}
              name={values.file?.name}
            />
          ) : (
            <DragAndDrop
              onUpload={handleFileUpload}
              component={(onClick) => <DndLabel onClick={onClick} />}
            />
          )}
          <hr />
          {formik.errors.file && touched.file ? (
            <Text size='xs' color='red'>
              Field is Required
            </Text>
          ) : (
            <Text size='xs'>form Make sure the file size is below 3MB</Text>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Group fullWidth>
          <CustomButton
            onClick={() => history.push('/assets?tab=asset-list&view=list')}
            size='sm'
            fullWidth
            variant='offwhite'
          >
            Cancel
          </CustomButton>
          <CustomButton
            size='sm'
            fullWidth
            variant='contained'
            onClick={handleAddDoc}
            disabled={reminderMutation.isLoading || mutation.isLoading}
          >
            <CircularLoader
              show={reminderMutation.isLoading || mutation.isLoading}
            />
            Add Document
          </CustomButton>
        </Group>
      </DialogActions>
    </Dialog>
  );
};

export default AddNormalDoc;

const DndLabel = ({ onClick }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        padding: '1.4rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '.5rem',
        alignItems: 'center'
      }}
    >
      <Text size='sm'>Drag and Drop a file</Text>
      <Button size='xs' onClick={onClick} variant='white'>
        Click here to upload document
      </Button>
    </div>
  );
};
