/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useQuery } from 'react-query';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router-dom';
import { getVehicles } from '../../api/fleet';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import ConfirmDelete from '../Modals/ConfirmDelete';
import EditVehicleModal from '../Modals/EditVehicleModal';
import useFleetControl from '../../hooks/useFleetControl';
import ConfirmMultipleVehiclesDelete from '../Modals/ConfirmMultipleVehiclesDelete';
import { NameSection } from './styled.vehicle-list';
import { makeStyles, withStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import { FleetNameIcon } from '../../common/Reusables';

const StyledMenu = withStyles({
  paper: {
    // border: '1px solid #d3d4d5',
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 30%)',
    borderRadius: '5px',
    padding: '5px 15px',
    width: '150px',
    '& .edit': {
      borderTop: '1px solid #FAFAFA',
      paddingTop: '10px',
      fontSize: '12.64px',
      lineHeight: '14px',
      color: '#737373',
      cursor: 'pointer'
    },
    '& .delete': {
      color: '#DC0000',
      paddingTop: '10px',
      fontSize: '12.64px',
      lineHeight: '14px',
      cursor: 'pointer'
    }
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  actionEdit: {
    color: '#A8A8A8',
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  actionDelete: {
    color: '#A8A8A8',
    '&:hover': {
      color: '#FF3132'
    }
  },
  tableContainer: {
    marginTop: '2%'
  },
  dataHead: {
    '& .MuiTableCell-head': {
      padding: '16px 16px',
      color: '#333333',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14.22px',
      lineHeight: '16px'
    }
  },
  dataRow: {
    '& .MuiTableCell-body': {
      padding: '8px 16px'
    }
  },
  tableHead: {
    color: '#333333',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14.22px',
    lineHeight: '16px'
  }
}));

function Head({ vehiclesIds }) {
  const classes = useStyles();

  return (
    <>
      <TableCell className={classes.tableHead}>Vehicle</TableCell>
      {['Plate No.', 'Type', 'Status', 'Colour', 'Assignee', ''].map(
        (column) => (
          <TableCell key={uuid()} className={classes.tableHead}>
            {column}
          </TableCell>
        )
      )}
    </>
  );
}

function DataRow({ vehicle, setPaginateResult, setLoading }) {
  const [fleetState, setFleetState] = useFleetControl();
  const history = useHistory();
  let { model, plate_no, type_display, status_display, color, assignee, type } =
    vehicle;
  const [showPrompt, setShowPrompt] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  let assigneeName = assignee === null ? 'No assignee' : assignee?.first_name;

  const isSelectedRow = fleetState.selectedVehicles.includes(vehicle.id);

  const handlePopeverOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePopeverClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleVehicleClick = (event) => {
    event.stopPropagation();
    // setSelectedAssetId(id)
  };

  const handleVehicleDelete = (e) => {
    e.stopPropagation();
    setShowPrompt(true);
  };

  return (
    <>
      <TableRow
        selected={isSelectedRow}
        onClick={() => history.push(`/fleet/home/${vehicle.id}?tab=overview`)}
        style={{ cursor: 'pointer' }}
      >
        <TableCell>
          <NameSection>
            <FleetNameIcon
              first={model.make.name}
              second={model.name}
              type={type}
              scale={0.8}
            />
            <Typography variant='body1'>
              {model.make.name} {model.name}
            </Typography>
          </NameSection>
        </TableCell>
        <TableCell>{plate_no}</TableCell>
        <TableCell>{type_display}</TableCell>
        <TableCell>{status_display}</TableCell>
        <TableCell style={{ textTransform: 'capitalize' }}>{color}</TableCell>
        <TableCell style={{ textTransform: 'capitalize' }}>
          {assigneeName}
        </TableCell>
        <TableCell style={{ textAlign: 'center' }} size='small'>
          <IconButton
            aria-label='more'
            aria-controls='long-menu'
            aria-haspopup='true'
            onClick={(event) => handleVehicleClick(event)}
          >
            <MoreVertIcon
              fontSize='small'
              style={{ cursor: 'pointer' }}
              aria-controls='customized-menu'
              aria-haspopup='true'
              variant='contained'
              onClick={(event) => handlePopeverOpen(event)}
            />
            <StyledMenu
              id='customized-menu'
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={(event) => handlePopeverClose(event)}
            >
              <ListItemText
                primary='Edit'
                className='edit'
                onClick={(e) => {
                  e.stopPropagation();
                  setFleetState({
                    ...fleetState,
                    editVehicleDetails: vehicle
                  });
                  history.push(
                    `/fleet?tab=edit-vehicle&vehicleId=${vehicle.id}`
                  );
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              />
              <ListItemText
                primary='Delete'
                className='unassign'
                onClick={handleVehicleDelete}
                style={{ cursor: 'pointer', color: '#FF3132' }}
              />
            </StyledMenu>
          </IconButton>
        </TableCell>
      </TableRow>

      <ConfirmDelete
        open={showPrompt}
        toggle={() => setShowPrompt(!showPrompt)}
        id={vehicle.id}
        setPaginateResult={setPaginateResult}
        setLoading={setLoading}
      />

      <ConfirmMultipleVehiclesDelete />
    </>
  );
}

function VehiclesTable({ paginateResult = [], setPaginateResult, setLoading }) {
  const classes = useStyles();
  const [fleetState, setFleetState] = useFleetControl();
  const { search = '' } = fleetState;
  const { data = {}, isFetching } = useQuery(
    ['vehicles', { search }],
    getVehicles
  );

  if (isFetching) return <CircularProgress color='secondary' />;

  const { results: vehicles = [] } = data;

  // if (!vehicles.length && !fleetState.search) return <VehicleListEmptyState />;
  return (
    <>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table>
          <TableHead className={classes.dataHead}>
            <TableRow>
              <Head vehiclesIds={vehicles.map((v) => v.id)} />
            </TableRow>
          </TableHead>
          <TableBody className={classes.dataRow}>
            {paginateResult &&
              paginateResult?.results?.map((vehicle) => (
                <DataRow
                  vehicle={vehicle}
                  id={vehicle.id}
                  setPaginateResult={setPaginateResult}
                  setLoading={setLoading}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EditVehicleModal vehicle={''} />
    </>
  );
}

export default VehiclesTable;
