import styled from 'styled-components';

export const AssetTypeSC = styled.div`
  .filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-block: 2rem;
    gap: 0.5rem;
    &_type {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    .asset_type_filter_button {
      display: flex;
      width: 105px;
      padding: 5px 40px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 54px;
      border: 1px solid #a8a8a8;
      cursor: pointer;

      &.type_active {
        background-color: #2898a4;
        color: white;
      }
    }
    .search {
      display: flex;
      flex: 1;
      gap: 0.5rem;
      max-width: 650px;
    }
  }
  .stats_block {
    height: 90vh;
    padding-right: 5px;
    overflow-y: auto;
  }
`;
