import ReminderModal from 'Assets/Modals/ReminderModal';
import UnAssignModal from 'Assets/Modals/UnassignModal';
import AddAssetBtn from 'Assets/Shared/AddAssetBtn';
import // useFetchAllAssets,
// useFetchAssetTypes,
// useVehicleTypeList
'Assets/queries';
import { SearchFieldAntd } from 'components/FIlters/SearchField';
import { AntSelect } from 'components/FIlters/SelectField';
// import Table from 'components/Table';
import DeleteModal from 'Assets/Modals/DeleteAssetModal';
import CustomMenu from 'components/CustomMenu/CustomeMenu';
import Table from 'components/CustomTable/Table';
import TablePagination from 'components/CustomTable/TablePagination';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import { BLANK } from 'constant';
import dayjs from 'dayjs';
import useDisclosure from 'hooks/useDisclosure';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { capitalizeAll } from 'utilities/Capitalized';
import { assetStatus } from '../AddAsset/data';
import AddMaintenanceModal from '../Modals/AddMaintenanceModal';
import { column } from './tableConfig';
import useFilter from './useFilter';
import {
  asset_major_types,
  statusColors,
  spacing,
  formatAssetList
} from './data';
// import useGetAssetType from './useGetAssetType';
import { useQuery } from 'react-query';
import { getMethod } from 'hooks/queries/getHook';
import { useEffect } from 'react';

const AssetList = () => {
  const [currSelected, setCurrentSelected] = useState(null);
  // const { data: regions } = useFetchRegions();
  const [open, { toggle }] = useDisclosure();
  const [unassignOpen, { toggle: toggleUnassign }] = useDisclosure();
  const [maintenanceOpen, { toggle: toggleMaintenance }] = useDisclosure();
  const [deleteOpen, { toggle: toggleDelete }] = useDisclosure();
  const {
    search,
    handleSearch,
    location,
    setLocation,
    asset_type,
    setAssetType,
    status,
    setStatus,
    inputValue,
    page,
    setPage,
    rowPerPage,
    asset_vehicle_type,
    setAssetVehicleType
  } = useFilter();
  // const { data, isLoading, isError, refetch, isFetching } = useFetchAllAssets({
  //   asset_type: asset_type,
  //   search,
  //   location,
  //   status,
  //   page: page + 1,
  //   page_size: rowPerPage
  // });
  const route =
    asset_vehicle_type === true || typeof asset_type === 'string'
      ? `/asset-management/asset_types/vehicle_type_details/`
      : `/asset-management/assets/`;

  const typeParams =
    typeof asset_type === 'string' ? 'vehicle_type' : 'asset_type';

  const { data, isLoading, isError, refetch, isFetching } = useQuery(
    [
      `assets-vehicle-type-${asset_vehicle_type}`,
      {
        route,

        params: {
          [typeParams]: asset_type,
          search,
          location,
          status,
          page: page + 1,
          page_size: rowPerPage,
          route
        }
      }
    ],
    getMethod
  );

  const { data: types } = useQuery(
    [
      'assets-type',
      {
        route: `/asset-management/asset_types/all_asset_types/`,
        params: {
          search,
          vehicle_type_param:
            asset_vehicle_type === 'all' ? null : asset_vehicle_type
        }
      }
    ],
    getMethod
  );

  const { data: regions } = useQuery(
    ['get-branch', { route: '/regions/all/', params: {} }],
    getMethod
  );

  useEffect(() => {
    if (typeof asset_type === 'string') {
      setAssetVehicleType(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset_type]);
  const assetTypes =
    asset_vehicle_type === true
      ? types?.map((item) => {
          return {
            label: item.name,
            value: item.name
          };
        })
      : types?.map((item) => {
          return {
            label: item.name,
            value: item.id ? item.id : item.name
          };
        });
  // ---- handler trans
  const closeModal = (toggle) => () => {
    setCurrentSelected(null);
    toggle();
  };
  const { push } = useHistory();

  const gotoView = (id) => {
    push(`/assets?tab=asset-list&view=asset&id=${id}`);
  };

  const handleMenuClick = (action, asset) => {
    setCurrentSelected(asset);

    if (action === 'view') {
      gotoView(asset.id);
    }

    if (action === 'edit') {
      push(`/assets?tab=home&view=edit&id=${asset.id}`);
    }

    if (action === 'unassign') {
      toggleUnassign();
    }
    if (action === 'reminder') {
      toggle();
    }

    if (action === 'maintenance') {
      toggleMaintenance();
    }
    if (action === 'delete') {
      toggleDelete();
    }
  };

  // ----- data trans
  const tableColumn = column({ handleMenuClick });

  const dataSource = formatAssetList(
    (!!asset_vehicle_type
      ? data?.results
      : data?.results?.filter((el) => el.asset_type?.name !== 'Vehicle')) || []
  );

  return (
    <AssetListWrapper>
      <div className='asset_list_header d-flex align-items-center justify-content-between'>
        <Title>Asset List</Title>
        <AddAssetBtn />
      </div>
      <div className='filter_block'>
        <div className='search'>
          <SearchFieldAntd
            onChange={handleSearch}
            value={inputValue}
            placeholder='Search by asset name, tag, status, employee and category '
          />
          <AntSelect
            placeholder='Asset Type'
            value={asset_vehicle_type}
            onChange={setAssetVehicleType}
            options={asset_major_types}
          />
          <AntSelect
            placeholder='Asset Type'
            value={asset_type}
            onChange={setAssetType}
            options={assetTypes}
          />
          <AntSelect
            placeholder='Location'
            value={location}
            onChange={setLocation}
            options={
              regions?.map((type) => ({
                label: type.name,
                value: type.id,
                ...type
              })) || []
            }
          />
          <AntSelect
            placeholder='Status'
            value={status}
            options={assetStatus}
            onChange={setStatus}
          />
        </div>

        <TablePagination
          count={data?.count ?? 0}
          page={page}
          isFetching={isFetching}
          rowsPerPage={rowPerPage}
          onChange={(e, setter) => setPage(setter)}
          pageCacheKey={'asset-list-paginate'}
          disbaleOnFetch={false}
        />
      </div>

      <div className='table_wrapper'>
        <Table column={tableColumn} spacing={spacing} minWidth={1250}>
          <Table.Head column={tableColumn} />
          <Table.Body
            seperate
            rowClick={(event, row) => {
              gotoView(row.id);
            }}
            isLoading={isLoading}
            isError={isError}
            data={dataSource}
            renderRow={(row) => {
              const assignee = row?.assignee;

              const status =
                row.status === 'active' && !row.assignee?.employee_id
                  ? 'unassigned'
                  : row.status;
              return (
                <>
                  <Text size='sm'>{capitalizeAll(row.name || '')}</Text>
                  <Text size='sm'>{row.id}</Text>
                  <Text size='sm'>{row?.asset_type?.name}</Text>
                  <Text size='sm'>
                    {row.created_at
                      ? dayjs(row.created_at).format('DD MMM, YYYY')
                      : BLANK}
                  </Text>
                  <div>
                    <Text
                      style={{
                        borderRadius: '20px',
                        width: '120px',
                        paddingBlock: '.3rem',
                        textAlign: 'center',
                        background: statusColors[status]?.bg
                      }}
                      textColor={statusColors[status]?.c}
                      size='xs'
                    >
                      {status === 'active'
                        ? 'Assigned'
                        : capitalizeAll(status.split('_').join(' '))}
                    </Text>
                  </div>
                  <Text size='sm'>
                    {row.assignee?.department?.name || BLANK}
                  </Text>
                  <Text size='sm'>
                    {(assignee?.first_name || BLANK) +
                      ' ' +
                      (assignee?.last_name
                        ? assignee?.last_name[0] + '.'
                        : BLANK)}
                  </Text>
                  <CustomMenu
                    size={'sm'}
                    innerWidth={180}
                    stopPropagation
                    divideAll
                    options={[
                      {
                        label: 'View Asset',
                        onClick() {
                          handleMenuClick('view', row);
                        }
                      },
                      assignee && assignee.employee_id
                        ? {
                            label: 'Unassign Asset',
                            onClick: (_, handleClose) => {
                              handleMenuClick('unassign', row);
                              handleClose();
                            }
                          }
                        : {},
                      {
                        label: 'Edit Asset',
                        onClick() {
                          handleMenuClick('edit', row);
                        }
                      },
                      {
                        label: 'Add Maintenance',
                        onClick: (_, handleClose) => {
                          handleMenuClick('maintenance', row);
                          handleClose();
                        }
                      },
                      // {
                      //   label: 'Set Reminder',
                      //   onClick: (_, handleClose) => {
                      //     handleMenuClick('reminder', row);
                      //     handleClose();
                      //   }
                      // },
                      {
                        label: 'Delete',
                        onClick(_, handleClose) {
                          handleMenuClick('delete', row);
                          handleClose();
                        },
                        style: { color: 'red' }
                      }
                    ]}
                  />
                </>
              );
            }}
          />
        </Table>
      </div>
      {open && currSelected ? (
        <ReminderModal
          open={open}
          toggle={closeModal(toggle)}
          data={currSelected}
        />
      ) : null}
      {unassignOpen && currSelected ? (
        <UnAssignModal
          refetch={refetch}
          open={unassignOpen}
          toggle={closeModal(toggleUnassign)}
          data={currSelected}
          onUnAssignComplete={refetch}
        />
      ) : null}
      {maintenanceOpen && currSelected ? (
        <AddMaintenanceModal
          isEdit={true}
          open={maintenanceOpen}
          toggle={closeModal(toggleMaintenance)}
          data={currSelected}
        />
      ) : null}
      {deleteOpen && currSelected ? (
        <DeleteModal
          open={deleteOpen}
          toggle={toggleDelete}
          data={[currSelected?.id]}
          onDeleteComplete={refetch}
        />
      ) : null}
    </AssetListWrapper>
  );
};

export default AssetList;

const AssetListWrapper = styled.div`
  .filter_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-block: 2rem;
    gap: 0.5rem;
    .search {
      display: flex;
      flex: 1;
      gap: 0.5rem;
      max-width: 900px;
      align-items: center;
    }
  }
  .stats_block {
    height: min(80vh, 700px);
    padding-right: 5px;
    overflow-y: auto;
  }
  .table_wrapper {
    padding-block: 1rem;
  }
`;
