import { Box } from '@mui/material';
import React from 'react';

const BizLoader = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '60vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <img
        src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638526892/Bizedge_Frontend_Assets/BizLoader_dzmb4i.gif'
        width={250}
        height={250}
        alt='loading spinner'
      />
    </Box>
  );
};

export default BizLoader;
