import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment } from '@mui/material';
import { Input } from 'antd';
import CustomeInputBox from 'components/Inputs/CustomeInputBox';
import { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { isFunc } from 'utilities/utils';

const SearchField = (
  {
    clearable = false,
    clear = () => {},
    value,
    onChange,
    size,
    InputProps,
    syncValue = false,
    ...props
  },
  ref
) => {
  const [actualValue, setValue] = useState(() => value);

  useEffect(() => {
    if (syncValue) {
      setValue(value);
    }
  }, [value, syncValue]);

  const handleChange = (event) => {
    setValue(event?.target.value);
    if (isFunc(onChange)) {
      onChange(event);
    }
  };

  const actualProps = {
    value: actualValue,
    onChange: handleChange,
    size,
    ...props
  };
  return (
    <StyledTextField>
      <CustomeInputBox
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
          ...InputProps
        }}
        {...actualProps}
        inputRef={ref}
      />
      {actualValue && clearable && (
        <IconButton
          className='clear-icon'
          disableRipple
          size='small'
          aria-label='clear'
          onClick={() => handleChange({ target: { value: '' } })}
        >
          <ClearIcon />
        </IconButton>
      )}
    </StyledTextField>
  );
};

export default forwardRef(SearchField);

const StyledTextField = styled.div`
  position: relative;
  & .clear-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    padding: 0.1rem !important;

    background: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    svg {
      width: 13px;
      height: 13px;
      margin-left: 1px;
      path {
        fill: white;
        stroke: var(--text-button);
        fill: var(--text-button);
      }
    }
  }
`;

export const SearchFieldAntd = (props) => {
  return (
    <StyledAntInput
      size='large'
      allowClear
      prefix={<SearchIcon sx={{ color: '#878787' }} fontSize='small' />}
      {...props}
    />
  );
};

const StyledAntInput = styled(Input)`
  && {
    &:hover,
    &:focus {
      border-color: var(--text-color);
      outline: var(--text-color);
    }

    border-radius: 4px;
    font-family: var(--black-family) !important;
    .ant-input::placeholder {
      font-family: var(--black-family) !important;
      font-weight: 500;
    }
    & .ant-select-selector {
      border-radius: 4px;
      &:hover {
        border-color: var(--text-color);
        outline: var(--text-color);
      }
    }
  }
`;
