import styled from 'styled-components';
import Box from '@material-ui/core/Box';

export const Wrapper = styled(Box)`
  ${({ theme }) => `
    background: white;
    width: 656px;
    min-height: 300px;
    max-height: 95vh;
    max-width: 40%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    outline: none;
    padding: 2% 3% 4%;
    // overflow: scroll;

    h2 {
      ${theme.mixins.makeResponsive({ theme, fontSize: 24 })};
      font-weight: 700;
      & + * {
        color: #7C7C7C;
        ${theme.mixins.makeResponsive({ theme, fontSize: 14 })};
        margin-top: 1%;
      }
    }
 `}
`;
