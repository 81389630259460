import { useGetAssetAttachments } from 'Assets/queries';
import DocumentIconType from 'common/HR/Document/DocumentIconType';
import { CustomButton } from 'components/Button';
import Table, { TableEmpty } from 'components/CustomTable/Table';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import { BLANK } from 'constant';
import { capitalize } from 'utilities/Capitalized';
import AssigneeHistory from './AssigneeTable';
import MaintenanceTable from './MaintenanceTable';
import ReportedIssues from './ReportTable';
import { formatDate2_DD_MMM_YYYY } from 'utilities/dateUtils';
import CustomMenu from 'components/CustomMenu/CustomeMenu';
import fileSaver from 'file-saver';
import { useSnackbar } from 'notistack';
const Tables = ({ tab, asset, toggleStatuory, toggleMaintenance }) => {
  const assetId = asset?.id;
  const attachments = asset?.attachments;
  const tabsTab = [
    { comp: MaintenanceTable },
    { comp: AssigneeHistory },
    { comp: ReportedIssues, props: { toggleMaintenance } },
    { comp: StatutoryDocumentTable, props: { attachments, toggleStatuory } },
    { comp: DocumentTable, props: { attachments } }
  ];

  const selected = tabsTab[tab];
  const Component = selected.comp;

  return (
    <div className='tables_tab'>
      <Component {...selected?.props} assetId={assetId} />
    </div>
  );
};

export default Tables;

const StatutoryDocumentTable = ({ attachments, toggleStatuory, assetId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const columns = [
    'Name',
    'Issue Date',
    'Expiry Date',
    'Date Added',
    'Document',
    ''
  ];

  const attachmentsQuery = useGetAssetAttachments({
    asset_pk: assetId
  });

  const statutoryDocs =
    attachmentsQuery.data?.attachments?.filter(
      (doc) => doc.document_type_name
    ) ?? [];
  // const handleChange = (e) => {};
  return (
    <div>
      {statutoryDocs?.length < 1 ? (
        <TableEmpty className='asset_report_empty'>
          <Title textAlign='center'>
            You have not added any statutory information
          </Title>
          <Text size='xs' light textAlign='center'>
            Statutory information refers to asset information that may be due
            for renewal upon its expiry. Examples of statutory information may
            include but not limited to insurance, registration, vehicle
            inspection etc
          </Text>
          <CustomButton onClick={toggleStatuory}>
            Add Statutory Information
          </CustomButton>
        </TableEmpty>
      ) : (
        <>
          <div style={{ maxWidth: '300px', marginBottom: '2rem' }}>
            {/* <SearchFieldAntd onChange={handleChange} /> */}
          </div>
          <Table spacing={[3.5, 2, 2, 2, 2, 0.5]}>
            <Table.Head column={columns} />
            <Table.Body
              data={statutoryDocs}
              renderRow={(row) => {
                return (
                  <>
                    <Text size='sm'>
                      {capitalize(row?.document_type_name ?? BLANK)}
                    </Text>
                    <Text size='sm'>
                      {formatDate2_DD_MMM_YYYY(row?.date_of_issue) ?? BLANK}
                    </Text>
                    <Text size='sm'>
                      {formatDate2_DD_MMM_YYYY(row?.expiry_date) ?? BLANK}
                    </Text>
                    <Text size='sm'>
                      {formatDate2_DD_MMM_YYYY(row.created_at) ?? BLANK}
                    </Text>
                    <Text size='sm'>
                      <DocumentIconType type={''} />
                    </Text>
                    <div>
                      <CustomMenu
                        options={[
                          {
                            label: 'Download file',

                            async onClick() {
                              fetch(row.file)
                                .then((r) => r.blob())
                                .then((blob) => {
                                  fileSaver.saveAs(blob, row.name);
                                })
                                .catch((err) => {
                                  enqueueSnackbar('File not found', {
                                    variant: 'error'
                                  });
                                });
                            }
                          }
                        ]}
                      />
                    </div>
                  </>
                );
              }}
            />
          </Table>
        </>
      )}
    </div>
  );
};

const DocumentTable = ({ attachments, assetId }) => {
  const columns = ['Document Name', 'Date Added', 'File Type', ''];
  const { enqueueSnackbar } = useSnackbar();

  const attachmentsQuery = useGetAssetAttachments({
    asset_pk: assetId
  });

  const docs =
    attachmentsQuery.data?.attachments?.filter(
      (doc) => !doc.document_type_name
    ) ?? [];

  return (
    <div>
      <Table spacing={[3.5, 2, 2, 2, 2, 0.5]}>
        {!attachmentsQuery.isLoading && docs?.length < 1 ? (
          <TableEmpty className='asset_report_empty'>
            <Title textAlign='center'>You have no documents</Title>
          </TableEmpty>
        ) : (
          <>
            <Table.Head column={columns} />
            <Table.Body
              data={docs}
              isLoading={attachmentsQuery.isLoading}
              renderRow={(row) => {
                const strippedName = row?.name?.slice(0, row?.name?.length - 4);
                const name = strippedName;
                return (
                  <>
                    <Text size='sm'>{capitalize(name ?? BLANK)}</Text>
                    <Text size='sm'>
                      {formatDate2_DD_MMM_YYYY(row?.date_of_issue) ?? BLANK}
                    </Text>
                    <Text size='sm'>
                      {formatDate2_DD_MMM_YYYY(row?.expiry_date) ?? BLANK}
                    </Text>
                    <Text size='sm'>
                      {formatDate2_DD_MMM_YYYY(row.created_at) ?? BLANK}
                    </Text>
                    <Text size='sm'>
                      <DocumentIconType type={''} />
                    </Text>
                    <div>
                      <CustomMenu
                        options={[
                          {
                            label: 'Download file',
                            async onClick() {
                              fetch(row?.file)
                                .then((r) => r.blob())
                                .then((blob) => {
                                  fileSaver.saveAs(blob, row.name);
                                })
                                .catch((err) => {
                                  enqueueSnackbar('File not found', {
                                    variant: 'error'
                                  });
                                });
                            }
                          }
                        ]}
                      />
                    </div>
                  </>
                );
              }}
            />
          </>
        )}
      </Table>
    </div>
  );
};
