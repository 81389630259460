import AddCircleOutlineIcon from '@material-ui/icons/AddCircle';
import { CustomButton } from 'components/Button';
import { useHistory } from 'react-router-dom';
const AddAssetBtn = ({ title, className, variant }) => {
  const history = useHistory();
  const changeRoute = () => {
    const path = '?tab=home&view=create';
    history.push(path);
  };
  return (
    <CustomButton
      onClick={changeRoute}
      variant={variant || className || 'contained'}
      size='sm'
    >
      <AddCircleOutlineIcon fontSize='small' />
      {title || 'Add Assets'}
    </CustomButton>
  );
};

export default AddAssetBtn;
