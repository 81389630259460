import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import { useFetchAsset } from 'Assets/queries';
import Group from 'components/StyledComponents/ButtonGroup';
import qs from 'query-string';
import Display from './Display';
import Maintenance from './Maintenance';
import { AssetWrapper } from './StyleWrapper';
function AssetView() {
  const search = qs.parse(window.location.search);
  const { id: assetId } = search;
  const { data, isLoading, refetch } = useFetchAsset({ assetId });

  if (isLoading) {
    // if (true) {
    return (
      <Box>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid as='div' item spacing='10px' sx={{ border: '2px solid red' }}>
            <Skeleton
              height={180}
              width={600}
              sx={{ border: '2px solid red' }}
            />
            <Skeleton height={150} width={600} />
            <Group spacing='1rem' jc='flex-start'>
              <Skeleton height={80} width={200} />
              <Skeleton height={80} width={200} />
            </Group>
          </Grid>
          <Grid items='start'>
            <Skeleton width={400} height={600} />
          </Grid>
        </Grid>
        <Box style={{ width: '100%', margin: '20px auto' }}>
          <Skeleton variant='rect' width={'100%'} height={500} />
        </Box>
      </Box>
    );
  }

  return (
    <AssetWrapper>
      <Display asset={data} refetch={refetch} />
      <Maintenance asset={data} refetch={refetch} />
    </AssetWrapper>
  );
}

export default AssetView;
