import { createContext } from 'react';
import styled from 'styled-components';
import { TableBodyV2 } from './TableBody';
import TableHead from './TableHead';
export const TableContext = createContext(null);

const Table = ({ bordered = false, spacing, column, minWidth, ...props }) => {
  return (
    <div
      style={{
        width: '100%',
        overflowX: 'auto'
      }}
    >
      <div
        style={{
          minWidth: minWidth ?? 1000,
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          ...props?.style
        }}
      >
        <TableContext.Provider
          value={{ bordered, spacing, column, minWidth, ...props }}
        >
          {props.children}
        </TableContext.Provider>
      </div>
    </div>
  );
};

export default Table;

Table.Head = TableHead;
Table.Body = TableBodyV2;

export const TableEmpty = ({ children, ...props }) => {
  return <TableEmptyStyle {...props}>{children}</TableEmptyStyle>;
};

const TableEmptyStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.7rem;
  margin-inline: auto;
  min-height: ${(props) => props.height ?? '100px'};
  max-width: 530px;
`;
