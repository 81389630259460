import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import { useQuery } from 'react-query';
import { getServiceReminders, getStatutoryReminders } from '../../api/fleet';
import useFleetControl from '../../hooks/useFleetControl';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
// import RenewDocumentModal from '../Modals/RenewDo';
import RenewServiceModal from '../Modals/RenewServiceModal';
// import EditDocumentModal from '../Modals/EditDocumentModal';
import RenewStatutoryDocumentModal from '../Modals/RenewStatutoryDocument';
import moment from 'moment';
import { FleetNameIcon } from '../../common/Reusables';
import useVehicleDocumentsControl from '../../hooks/useVehicleDocumentsControl';

export const IconBox = styled(Avatar)`
  ${({ theme, color }) => `
    background: ${color}
  `}
`;

const useStyles = makeStyles((theme) => ({
  Header: {
    ...theme.mixins.makeResponsive({ theme, fontSize: 40, object: true }),
    fontWeight: 700
  },
  Caption: {
    ...theme.mixins.makeResponsive({ theme, fontSize: 22, object: true }),
    fontWeight: 700,
    color: '#737373'
  },
  Wrapper: {
    marginTop: '3%',
    // width: '95%',
    '& .MuiTypography-h4': {
      ...theme.mixins.makeResponsive({ theme, fontSize: 20, object: true }),
      fontWeight: 700,
      color: '#7F7F7F'
    }
  },
  emptyStateWrapper: {
    width: '50%',
    margin: '10% auto',
    textAlign: 'center'
  },
  emptyStateTop: {
    color: '#E1E1E1',
    fontWeight: 700,
    fontStyle: 'normal'
  },
  cardWrapper1: {
    cursor: 'pointer',
    '&:hover': {
      '& .MuiButton-outlined': {
        color: '#FF3132',
        border: '1px solid #FF3132'
      }
    }
  },
  cardWrapper2: {
    cursor: 'pointer',
    '&:hover': {
      '& .MuiButton-outlined': {
        color: '#FF3132',
        border: '1px solid #FF3132'
      }
    }
  },
  reminderWrapper: {
    marginBottom: '20px',
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: 'white',
    borderBottom: '1px solid #F2F2F2',
    '& .MuiButton-outlined': {
      color: '#545454',
      textTransform: 'capitalize'
    }
  }
}));

function ReminderList() {
  const classes = useStyles();
  const [fleetState, setFleetState] = useFleetControl();
  const [documentsState, setDocumentsState] = useVehicleDocumentsControl();

  const { data, isSuccess } = useQuery(
    ['get-service-reminder'],
    getServiceReminders
  );

  const { data: statutoryReminders, isSuccess: isSuccessStatutoryReminders } =
    useQuery(['get-statutory-reminder', { id: null }], getStatutoryReminders);

  // const { data: statutoryReminder, isFetching: fetchingStatutory, isSuccess: statutorySuccess } = useQuery(
  //   ['get-statutory-reminder'],
  //   getStatutoryReminders
  // );

  if (isSuccess && data.count === 0) {
    return (
      <Box className={classes.emptyStateWrapper}>
        <Typography variant='h2' className={classes.emptyStateTop}>
          Oops!
        </Typography>
        <Typography variant='subtitle2' style={{ color: '#878787' }}>
          You have no active reminder
        </Typography>
      </Box>
    );
  }

  //  if (fetchingService && fetchingStatutory){
  //    return (
  //      <>
  //       <Typography variant='h2' color='secondary' className={classes.Header}>
  //         Reminders
  //       </Typography>
  //       <Typography style={{marginTop: '1%'}}>
  //         Your vehicle reminders
  //       </Typography>
  //       <Box style={{marginTop: '2%'}}>
  //         <CircularProgress color='secondary' />
  //       </Box>
  //       </>
  //    )
  //  }

  const getDate = (start) => {
    let refined = moment(start).format('YYYY/MM/DD');
    const splittedStartDate = refined?.split('/');
    let startDate = moment(start, 'YYYY-MM-DD');
    let startMonthName = startDate.format('MMM');
    return `${startMonthName} ${splittedStartDate[2]} ${splittedStartDate[0]}`;
  };

  const handleRenew = (res) => {
    setFleetState({
      ...fleetState,
      modal: 'RenewServiceModal',
      editServiceDetails: res
    });
  };

  const handleRenewDocument = (res) => {
    setDocumentsState({
      ...documentsState,
      modal: 'RenewDocumentModal',
      // selectedDocuments: [document.id],
      editDocumentDetails: res
    });
  };

  return (
    <>
      <Grid
        container
        justify='space-between'
        alignItems='center'
        style={{ marginTop: '2%' }}
      >
        {/* <Grid item sm={8}>
          <Grid container spacing={1} alignItems='center'>
            <Grid item sm={7}>
              <TextField
                variant='outlined'
                color='secondary'
                fullWidth
                label='Search'
                placeholder='search by asset name, tag, status, employee and category'
                size='small'
              />
            </Grid>
            <Grid item sm={3}>
              <FormControl
                variant='outlined'
                size='small'
                color='secondary'
                fullWidth
              >
                <InputLabel id='document-type'>All</InputLabel>
                <Select labelId='document-type' label='Make' size='small'>
                  <MenuItem value='default'>Gadgets </MenuItem>
                  <MenuItem value='autoreg'>Status </MenuItem>
                  <MenuItem value='roadworthiness'>Clear all filters</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
      <Grid container style={{ margin: '2% 0px' }}>
        <Grid item>
          <Typography variant='h2' className={classes.Caption}>
            Maintenance Reminders
          </Typography>
        </Grid>
      </Grid>
      <Box
        style={{
          background: 'white',
          boxShadow: '0 1px 3px 0 rgb(0 0 0 / 30%)',
          borderRadius: '5px'
        }}
        className={classes.Wrapper}
      >
        {isSuccess &&
          data.count > 0 &&
          data.results.map((item) => (
            <>
              {/* <Grid container justify="space-between" alignItems="center" style={{marginTop: '2%'}}>
        <Grid item sm={8}>
          <Grid container spacing={1} alignItems="center">
            <Grid item sm={7}>
              <TextField variant="outlined" color="secondary" fullWidth label="Search" placeholder="search by asset name, tag, status, employee and category" size="small" />
            </Grid>
            <Grid item sm={3} >
            <FormControl
              variant='outlined'
              size='small'
              color='secondary'
              fullWidth
            >
              <InputLabel id='document-type'>All</InputLabel>
              <Select labelId='document-type' label='Make' size='small' >
                <MenuItem value='default'>Gadgets </MenuItem>
                <MenuItem value='autoreg'>Status </MenuItem>
                <MenuItem value='roadworthiness'>Clear all filters</MenuItem>
              </Select>
            </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
              {/* <Grid container style={{margin: '2% 0px'}}>
        <Grid item>
          <Typography variant="h2" className={classes.Caption}>Service Reminders</Typography>
        </Grid>
      </Grid> */}
              <Box container className={classes.reminderWrapper}>
                <Grid container justify='space-between' alignItems='center'>
                  <Grid item>
                    <Typography variant='subtitle1'>
                      <strong>{item?.description}</strong>
                    </Typography>
                    <Typography
                      variant='subtitle2'
                      style={{ color: '#FF3132' }}
                    >
                      {item?.due_in}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <FleetNameIcon
                          scale={0.8}
                          first={item.vehicle?.model?.make?.name}
                          second={item.vehicle?.model?.name}
                          type={item.type}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant='subtitle1'>
                          <strong
                            style={{
                              color: '#737373'
                            }}
                          >
                            {item.vehicle?.model?.make?.name}{' '}
                            {item.vehicle?.model?.name}
                          </strong>
                        </Typography>
                        <Typography
                          variant='subtitle2'
                          style={{
                            color: '#A8A8A8'
                          }}
                        >
                          {item?.vehicle?.plate_no}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant='subtitle1'
                      style={{
                        color: '#878787'
                      }}
                    >
                      Registered
                    </Typography>
                    <Typography
                      variant='subtitle2'
                      style={{
                        color: '#878787'
                      }}
                    >
                      <strong> {getDate(item?.service_date)}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={'2'}>
                    <Button
                      variant='outlined'
                      color='secondary'
                      style={{
                        width: '100%'
                      }}
                      onClick={() => handleRenew(item)}
                    >
                      Renew
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          ))}
      </Box>

      <Grid container style={{ margin: '2% 0px' }}>
        <Grid item>
          <Typography variant='h2' className={classes.Caption}>
            Statutory Reminders
          </Typography>
        </Grid>
      </Grid>

      <Box
        style={{
          background: 'white',
          boxShadow: '0 1px 3px 0 rgb(0 0 0 / 30%)',
          borderRadius: '5px'
        }}
        className={classes.Wrapper}
      >
        {isSuccessStatutoryReminders &&
          statutoryReminders.count > 0 &&
          statutoryReminders.results.map((item) => (
            <>
              {/* <Grid container justify="space-between" alignItems="center" style={{marginTop: '2%'}}>
        <Grid item sm={8}>
          <Grid container spacing={1} alignItems="center">
            <Grid item sm={7}>
              <TextField variant="outlined" color="secondary" fullWidth label="Search" placeholder="search by asset name, tag, status, employee and category" size="small" />
            </Grid>
            <Grid item sm={3} >
            <FormControl
              variant='outlined'
              size='small'
              color='secondary'
              fullWidth
            >
              <InputLabel id='document-type'>All</InputLabel>
              <Select labelId='document-type' label='Make' size='small' >
                <MenuItem value='default'>Gadgets </MenuItem>
                <MenuItem value='autoreg'>Status </MenuItem>
                <MenuItem value='roadworthiness'>Clear all filters</MenuItem>
              </Select>
            </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
              {/* <Grid container style={{margin: '2% 0px'}}>
        <Grid item>
          <Typography variant="h2" className={classes.Caption}>Service Reminders</Typography>
        </Grid>
      </Grid> */}
              <Box container className={classes.reminderWrapper}>
                <Grid container justify='space-between' alignItems='center'>
                  <Grid item>
                    <Typography variant='subtitle1'>
                      <strong>{item?.title}</strong>
                    </Typography>
                    <Typography
                      variant='subtitle2'
                      style={{ textTransform: 'capitalize' }}
                    >
                      {item?.type}
                    </Typography>
                    <Typography
                      variant='subtitle2'
                      style={{ color: '#FF3132' }}
                    >
                      {item?.due_in}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <FleetNameIcon
                          scale={0.8}
                          first={item.vehicle?.model?.make?.name}
                          second={item.vehicle?.model?.name}
                          type={item.type}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant='subtitle1'>
                          <strong
                            style={{
                              color: '#737373'
                            }}
                          >
                            {item.vehicle?.model?.make?.name}{' '}
                            {item.vehicle?.model?.name}
                          </strong>
                        </Typography>
                        <Typography
                          variant='subtitle2'
                          style={{
                            color: '#A8A8A8'
                          }}
                        >
                          {item?.vehicle?.plate_no}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant='subtitle1'
                      style={{
                        color: '#878787'
                      }}
                    >
                      Issued Date
                    </Typography>
                    <Typography
                      variant='subtitle2'
                      style={{
                        color: '#878787'
                      }}
                    >
                      <strong> {getDate(item?.issue_date)}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={'2'}>
                    <Button
                      variant='outlined'
                      color='secondary'
                      style={{
                        width: '100%'
                      }}
                      onClick={() => handleRenewDocument(item)}
                    >
                      Renew
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          ))}
      </Box>
      {/* <Grid container justify="space-between" alignItems="center" style={{marginTop: '2%'}}>
        <Grid item sm={8}>
          <Grid container spacing={1} alignItems="center">
            <Grid item sm={7}>
              <TextField variant="outlined" color="secondary" fullWidth label="Search" placeholder="search by asset name, tag, status, employee and category" size="small" />
            </Grid>
            <Grid item sm={3} >
            <FormControl
              variant='outlined'
              size='small'
              color='secondary'
              fullWidth
            >
              <InputLabel id='document-type'>All</InputLabel>
              <Select labelId='document-type' label='Make' size='small' >
                <MenuItem value='default'>Gadgets </MenuItem>
                <MenuItem value='autoreg'>Status </MenuItem>
                <MenuItem value='roadworthiness'>Clear all filters</MenuItem>
              </Select>
            </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{margin: '2% 0px'}}>
        <Grid item>
          <Typography variant="h2" className={classes.Caption}>Service Reminders</Typography>
        </Grid>
      </Grid>
      <Box container className={classes.reminderWrapper} style={{backgroundColor: 'white'}}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="subtitle1"><strong>Auto Registration</strong></Typography>
            <Typography variant="subtitle2" style={{color: '#FF3132'}}>Due Today</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <NameIcon name="test" />
              </Grid>
              <Grid item>
                <Typography variant="subtitle1"><strong>Toyota Maxi</strong></Typography>
                <Typography variant="subtitle2">TO2345KJA</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">Registered</Typography>
            <Typography variant="subtitle2"><strong>08/03/2021</strong></Typography>
          </Grid>
          <Grid item>
            <Button variant='outlined' color="secondary" onClick={() => setFleetState({ ...fleetState, modal: 'RenewDocumentModal', })}>
              Renew
            </Button>
          </Grid>
        </Grid>
      </Box> */}

      {/* <Typography variant='h2' color='secondary' className={classes.Header}>
        Reminders
      </Typography>
      <Typography style={{marginTop: '1%'}}>
        Your vehicle reminders
      </Typography>
      
      <Box className={classes.Wrapper}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Typography variant='h4'>Statutory reminders</Typography>
        </Grid>
      </Grid>
      {statutorySuccess && statutoryReminder.count >=1 ? (
        statutoryReminder.results.map((res) => (
          <>
          <Card elevation={0} className={classes.cardWrapper1} style={{marginTop: '10px'}}>
            <CardContent>
              <Container maxWidth='xl'>
                <Grid container justify='space-between' alignItems='center'>
                  <Grid item>
                    <Typography color="secondary" style={{textTransform: 'capitalize'}}><strong>{res.title}</strong></Typography>
                    <Typography variant="subtitle2" color="primary">{res.due_in}</Typography>
                  </Grid>
                  <Grid item style={{display: 'flex'}}>
                    <Grid container sm={4}>
                      <Avatar className={classes.small}>{res.vehicle.model.make.name.charAt(0)}</Avatar>
                    </Grid>
                    <Grid container sm={8}>
                    <Typography variant="subtitle2">{res.vehicle.model.make.name}</Typography>
                    <Typography variant="subtitle2">{res.vehicle.plate_no}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">Registered</Typography>
                    <Typography variant="subtitle2">{res.issue_date}</Typography>
                  </Grid>
                  <Grid item>
                  <Button variant='outlined' 
                    onClick={() => setFleetState({ ...fleetState, modal: 'RenewDocumentModal', editDocumentsDetails: res })}
                    >
                      Renew
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </CardContent>
          </Card>
          <Container>
            <Divider />
          </Container>

      <Box className={classes.Wrapper}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Typography variant='h4'>Service reminders</Typography>
        </Grid>
      </Grid>
      {serviceSuccess && serviceReminder.count >=1 ? (
        serviceReminder.results.map((res) => (
          <>
          <Card elevation={0} className={classes.cardWrapper2} style={{marginTop: '10px'}}>
            <CardContent>
              <Container maxWidth='xl'>
                <Grid container justify='space-between' alignItems='center'>
                  <Grid item>
                    <Typography color="secondary" style={{textTransform: 'capitalize'}}><strong>{res.description}</strong></Typography>
                    <Typography variant="subtitle2" color="primary">{res.due_in}</Typography>
                  </Grid>
                  <Grid item style={{display: 'flex'}}>
                    <Grid container sm={4}>
                      <Avatar className={classes.small}>{res.vehicle.model.make.name.charAt(0)}</Avatar>
                    </Grid>
                    <Grid container sm={8}>
                    <Typography variant="subtitle2">{res.vehicle.model.make.name}</Typography>
                    <Typography variant="subtitle2">{res.vehicle.plate_no}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">Registered</Typography>
                    <Typography variant="subtitle2">{res.service_date}</Typography>
                  </Grid>
                  <Grid item>
                    <Button variant='outlined' 
                    onClick={() => setFleetState({ ...fleetState, modal: 'RenewServiceModal', editServiceDetails: res })}
                    >
                      Renew
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </CardContent>
          </Card>
          <Container>
            <Divider />
          </Container>
          </>
        ))
      ) : (
        <Typography>No Service Reminder yet</Typography>
      )}
      </Box>

      
          </>
        ))
      ) : (
        <Typography>No statutory document reminder</Typography>
      )}
      </Box>
      
      <RenewServiceModal vehicle={serviceReminder} refetchKey={['get-vehicle-service-reminder']} /> */}
      <RenewServiceModal
        // vehicle={data}
        vehicle={data}
        refetchKey={['get-vehicle-service-reminder']}
      />
      {/* <EditDocumentModal /> */}
      <RenewStatutoryDocumentModal />
    </>
  );
}

export default ReminderList;
