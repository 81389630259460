import { useQuery, useQueryClient } from 'react-query';

const queryHOC = (key, fn) => {
  if (!key) throw new Error('key is required');
  return (query = {}, options = {}) => {
    return useQuery(
      [key, query],
      () => fn({ queryKey: [key, query] }),
      options
    );
  };
};

export default queryHOC;

export const queryHOCV2 =
  (key, fn) =>
  (query = {}, options = {}) => {
    return useQuery(
      [key, query],
      () => fn({ queryKey: [key, query] }),
      options
    );
  };

export const useQuerieInvalidator = () => {
  const queryClient = useQueryClient();
  return (key, ...res) => queryClient.invalidateQueries([key, ...res]);
};
