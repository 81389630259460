import CustomMenu from 'components/CustomMenu/CustomeMenu';

export const column = ({ handleMenuClick }) => [
  {
    label: 'Asset Name',
    title: 'Asset Name',
    dataIndex: 'name'
  },
  {
    title: 'Asset ID',
    label: 'Asset ID',
    dataIndex: 'id'
  },
  {
    title: 'Asset Type',
    label: 'Asset Type',
    dataIndex: 'asset_type',
    render(type) {
      return type.name;
    }
  },

  {
    title: 'Date Created',
    label: 'Date Created',
    dataIndex: 'dateCreated'
  },
  {
    title: 'Status',
    label: 'Status',
    dataIndex: 'status'
  },
  {
    title: 'Department',
    label: 'Department',
    dataIndex: 'assignee',
    render(assignee) {
      return assignee?.department?.name;
    }
  },
  {
    title: 'Assigned to',
    label: 'Assigned to',
    dataIndex: 'assigne_to',
    render(assignee) {
      return (
        assignee?.first_name +
        ' ' +
        (assignee?.last_name ? assignee?.last_name[0] : '')
      );
    }
  },
  {
    title: '',
    label: '',
    dataIndex: 'action',
    render: (asset) => {
      return (
        <CustomMenu
          size={'sm'}
          innerWidth={180}
          divideAll
          options={[
            {
              label: 'Unassign Asset',
              onClick() {
                handleMenuClick('unassign', asset);
              }
            },
            {
              label: 'Edit Asset',
              onClick() {
                handleMenuClick('edit', asset);
              }
            },
            {
              label: 'Add Maintenance',
              onClick() {
                handleMenuClick('maintenance', asset);
              }
            },
            {
              label: 'Set Reminder',
              onClick: () => {
                handleMenuClick('reminder', asset);
              }
            },
            {
              label: 'Delete',
              style: { color: 'red' }
            }
          ]}
        />
      );
    }
  }
];
