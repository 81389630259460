import { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import { FleetProvider } from '../hooks/useFleetControl';
import { VehicleServiceProvider } from '../hooks/useVehicleServiceControl';
import { VehicleRepairRecordsProvider } from '../hooks/useRepairRecrordsControl';
import { VehicleDocumentsProvider } from '../hooks/useVehicleDocumentsControl';
import Dashboard from './Dashboard';

const OneVehicle = lazy(() => import('./OneVehicle'));
const Reminders = lazy(() => import('./Reminders'));

function FleetRoutes({ base }) {
  const getRoute = (route) => `${base}/${route}`;

  return (
    <FleetProvider>
      <VehicleServiceProvider>
        <VehicleRepairRecordsProvider>
          <VehicleDocumentsProvider>
            <Dashboard />
            <Suspense fallback={<></>}>
              {/* <Route exact path={getRoute('home')} component={VehicleList} /> */}
              <Route exact path={getRoute('home/:id')} component={OneVehicle} />
              <Route exact path={getRoute('reminders')} component={Reminders} />
            </Suspense>
          </VehicleDocumentsProvider>
        </VehicleRepairRecordsProvider>
      </VehicleServiceProvider>
    </FleetProvider>
  );
}

export default FleetRoutes;
