import React from 'react';

const AssetsIcon = () => {
  return (
    <svg
      width='36'
      height='37'
      viewBox='0 0 36 37'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_b_1527_1682)'>
        <path
          d='M30.0835 0.5H5.91654C2.64893 0.5 0 3.14893 0 6.41654V30.5835C0 33.8511 2.64893 36.5 5.91654 36.5H30.0835C33.3511 36.5 36 33.8511 36 30.5835V6.41654C36 3.14893 33.3511 0.5 30.0835 0.5Z'
          fill='#E9FFCD'
        />
      </g>
      <g filter='url(#filter1_di_1527_1682)'>
        <path
          d='M29.0672 9.90341C29.2444 9.9956 29.3892 10.1396 29.4825 10.3162C29.5757 10.4928 29.6129 10.6936 29.5891 10.8919C29.5891 15.3494 29.5891 19.8069 29.5891 24.2644C29.5891 24.9581 29.2944 25.259 28.6006 25.259H7.3631C6.66317 25.259 6.3746 24.9643 6.3746 24.2582C6.3746 19.8069 6.3746 15.3494 6.3746 10.8919C6.35337 10.6939 6.3917 10.4941 6.48467 10.318C6.57763 10.1419 6.72102 9.99756 6.89648 9.90341H29.0672ZM18.0156 24.0249H27.9621C28.3489 24.0249 28.355 24.0249 28.355 23.6504V11.512C28.355 11.1314 28.355 11.1314 27.9682 11.1314H8.07532C7.67009 11.1314 7.67009 11.1314 7.67009 11.5489V23.589C7.67009 24.0065 7.67009 24.0065 8.09987 24.0065L18.0156 24.0249Z'
          fill='#6FAA22'
        />
        <path
          d='M3.79004 25.6705H4.12159H14.9276C15.1548 25.6705 15.3635 25.6705 15.3451 25.9775C15.3451 26.1372 15.4802 26.1249 15.5907 26.1249H20.3552C20.5639 26.1249 20.7051 26.1249 20.6806 25.8425C20.6806 25.7135 20.7481 25.6644 20.8709 25.6644H31.7936C32.2049 25.6644 32.2541 25.7258 32.1804 26.1372C32.1307 26.3973 31.9944 26.6329 31.7937 26.8057C31.5931 26.9785 31.3398 27.0783 31.0752 27.0888H30.7989C22.2524 27.0888 13.7058 27.0888 5.15921 27.0888C4.86827 27.1213 4.57489 27.0547 4.32645 26.8998C4.07802 26.745 3.88904 26.5109 3.79004 26.2354V25.6705Z'
          fill='#6FAA22'
        />
      </g>
      <defs>
        <filter
          id='filter0_b_1527_1682'
          x='-17.7267'
          y='-17.2267'
          width='71.4533'
          height='71.4533'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='8.86334' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_1527_1682'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_1527_1682'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_di_1527_1682'
          x='0.661803'
          y='7.81792'
          width='38.8464'
          height='24.4927'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2.08549' />
          <feGaussianBlur stdDeviation='1.56412' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0.49 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_1527_1682'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_1527_1682'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='7.29922' dy='-2.08549' />
          <feGaussianBlur stdDeviation='3.64961' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.895833 0 0 0 0 0.884635 0 0 0 0 0.884635 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_1527_1682'
          />
        </filter>
      </defs>
    </svg>
  );
};

export default AssetsIcon;
