import { Box } from '@material-ui/core';
import styled from 'styled-components';
export const Heading = styled(Box)`
  font-style: normal;
  font-weight: 700;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '18px')};
  line-height: 20px;
  color: ${(props) => (props.color ? props.color : '#545454')};
  margin: ${(props) => props.margin};
`;
export const SubHeading = styled(Box)`
  font-style: normal;
  font-weight: 700;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12.46px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '14px')};
  color: ${(props) => (props.color ? props.color : '#545454')};
  margin: ${(props) => props.margin};
`;

export const Paragraph = styled(Box)`
  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12.64px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '114.36%')};
  color: ${(props) => (props.color ? props.color : '#545454')};
  margin: ${(props) => props.margin};
`;

export const FlexBox = styled(Box)`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'row')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  justify-content: ${(props) => (props.jc ? props.jc : 'space-between')};
`;
