import { useCreateAssetMaintenance } from 'Assets/queries';
import { CustomButton as Button } from 'components/Button';
import CircularLoader from 'components/CircularLoader';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from 'components/Dialog';
import { CustomSelect, CustomTextField, DatePicker } from 'components/Inputs';
import Group from 'components/StyledComponents/ButtonGroup';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { capitalize } from 'utilities/Capitalized';
import {
  formatDate,
  formatDateTime,
  inputPropsSetterConfig
} from 'utilities/formHelpers';
import { runIfFn } from 'utilities/utils';
import * as yup from 'yup';
// import AssetType from '../AddAsset/AssetType';
import ReminderForm, { defaultTime } from 'Assets/Shared/ReminderForm';
const requiredMsg = 'Feild is required';
const validationSchema = yup.object().shape({
  // name: yup.string().required(requiredMsg),
  type: yup.string().required(requiredMsg),
  cost: yup.string().required(requiredMsg),
  notes: yup.string().required(requiredMsg),
  asset: yup.string().required(requiredMsg)
});

export const maintenanceType = [
  { label: 'Service', value: 'service' },
  { label: 'Repair', value: 'repair' },
  { label: 'Inspection', value: 'inspection' }
];
const initialValues = (data, mark) => ({
  asset: data?.id || '',
  issue: '',
  type: mark ?? 'service',
  date: null,
  due_date: null,
  cost: '',
  mileage: '',
  notes: '',
  reminder: {
    asset: data?.id || '',
    type: '',
    set_reminder: '',
    date: defaultTime()
  }
});

const AssetMaintenance = ({ open, toggle, data = {}, mark }) => {
  const formik = useFormik({
    validationSchema,
    initialValues: initialValues(data, mark?.mark),
    onSubmit(values) {
      if (!isValid) {
        return validateForm();
      }
      const mDate = values.date ? formatDate(values.date) : null;
      const dDate = values?.due_date ? formatDate(values?.due_date) : null;

      if (!values.issue) {
        delete values.issue;
      }

      if (!values.reminder.set_reminder) {
        delete values.reminder;
      }

      const payload = {
        ...values,
        date: mDate
      };

      if (values.type === 'service') {
        const rDate = formatDateTime(values?.reminder?.date, values.due_date);
        payload.due_date = dDate;
        payload.reminder = {
          ...values.reminder,
          type: values.type,
          date: rDate
        };
      }
      mutation.mutate(payload);
    }
  });

  const {
    setFieldValue,
    values,
    isValid,
    setFieldTouched,
    validateForm,
    submitForm
  } = formik;

  const mutation = useCreateAssetMaintenance({
    onSuccess(data) {
      runIfFn(toggle);
    }
  });

  const handleSubmit = () => {
    submitForm();
  };

  const setInputProps = inputPropsSetterConfig(formik);

  useEffect(() => {
    if (data) {
      setFieldTouched('asset', true);
      setFieldValue('asset', data?.id);
    }
  }, [data, setFieldTouched, setFieldValue]);

  return (
    <Dialog open={open} fullWidth maxWidth='xs' onClose={toggle}>
      <DialogTitle px={4} iconFlex onClose={toggle}>
        <span>
          <Title order={5}>Add Maintenance</Title>
          <Text size='xs' light>
            You can schedule or record a repair or maintenance for your asset
          </Text>
        </span>
      </DialogTitle>
      <DialogContent>
        <form
          style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}
        >
          <CustomSelect
            options={maintenanceType}
            size='small'
            label={capitalize(values.type)}
            defaultValue='service'
            {...setInputProps('type')}
          />

          <div>
            <DatePicker
              inputProps={{ fullWidth: true, size: 'small' }}
              size='small'
              fullWidth
              name='date'
              label={`${capitalize(values.type)} Date`}
              value={values.date}
              onChange={(newValue) => setFieldValue('date', newValue)}
            />
          </div>
          {values.type === 'service' ? (
            <div>
              <DatePicker
                inputProps={{ fullWidth: true, size: 'small' }}
                size='small'
                fullWidth
                name='due_date'
                label={`Due Date (Optional)`}
                value={values.due_date}
                onChange={(newValue) => setFieldValue('due_date', newValue)}
              />
            </div>
          ) : null}

          <CustomTextField
            label='Mileage (Optional)'
            size='small'
            {...setInputProps('mileage')}
          />
          <CustomTextField
            label='Cost'
            size='small'
            type={'number'}
            {...setInputProps('cost')}
          />

          <CustomTextField
            label='Note'
            size='small'
            rows={3}
            multiline
            {...setInputProps('notes')}
          />
          {values.type === 'service' ? (
            <ReminderForm
              setReminderInput={setInputProps('reminder.set_reminder')}
              setTimeInput={setInputProps('reminder.date')}
              clearInputs={() => {
                setFieldValue('reminder', null);
              }}
            />
          ) : null}
        </form>
      </DialogContent>
      <DialogActions>
        <Group fullWidth>
          <Button onClick={toggle} size='sm' fullWidth variant='offwhite'>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            size='sm'
            disabled={mutation.isLoading}
            fullWidth
            variant='contained'
          >
            <CircularLoader size={15} show={mutation.isLoading} />
            Add Maintenance
          </Button>
        </Group>
      </DialogActions>
    </Dialog>
  );
};

export default AssetMaintenance;
