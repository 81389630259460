// import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-query';
import axios from 'api/axios.config';
import React from 'react';

const AuthContext = React.createContext();
AuthContext.displayName = 'AuthContext';

const useStyles = makeStyles((theme) => ({
  loader: {
    width: '20%',
    margin: '10em auto',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '2em auto'
    }
  }
}));

export default function AuthProvider(props) {
  const classes = useStyles();
  const result = useQuery(
    'user',
    async () => {
      const res = await axios.get('/accounts/auth/user/', null, {
        withCredentials: false
      });
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false
    }
  );

  if (result.isLoading) {
    return (
      <div className={classes.loader}>
        <img
          src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638526892/Bizedge_Frontend_Assets/BizLoader_dzmb4i.gif'
          width={250}
          height={250}
          alt='loading spinner'
        />
      </div>
    );
  }
  return <AuthContext.Provider value={result} {...props} />;
}

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within an AuthProvider`);
  }
  return context;
}
