import { CustomButton as Button } from 'components/Button';
import CircularLoader from 'components/CircularLoader';
import {
  CustomSelect,
  CustomTextField,
  DatePicker,
  DragAndDrop,
  FileView
} from 'components/Inputs';
import Text from 'components/Typography/Text';
import { useFormik } from 'formik';
import { useUploadTemporaryDocument } from 'hooks/queries';
import { useQueryClient } from 'react-query';
import { formatDate, inputPropsSetterConfig } from 'utilities/formHelpers';
import { runIfFn } from 'utilities/utils';
import * as yup from 'yup';
import { assetStatus } from '../../AddAsset/data';
import {
  keys,
  useCreateIssueReport,
  useFetchAllAssets,
  useFetchIssueType
} from '../../queries';
import { transformIssueType } from '../../Modals/helpers';
import Group from 'components/StyledComponents/ButtonGroup';
import { AddFormStyled } from '../Maintenance';
import Header from './Header';
import { useHistory } from 'react-router-dom';

const VALIDATION_SCHEMA = yup.object().shape({
  attachments: yup
    .object()
    .shape({
      name: yup.string().required('Attachment is required'),
      uid: yup.string().required('Attachment is required')
    })
    .nullable(),
  date: yup.string('Fied is Required').nullable().required('Date is required'),
  issue_type: yup.string().required('Issue type is required'),
  priority_level: yup.string().required('Priority level is required'),
  message: yup.string().required('Message is required'),
  asset: yup.number().required('Asset is required'),
  asset_pk: yup.number().required('Asset is required')
});

const AddIssue = ({ asset }) => {
  const queryClient = useQueryClient();
  const formik = useFormik({
    initialValues: {
      asset_pk: asset?.id,
      asset: asset?.id,
      message: '',
      date: '',
      issue_type: '',
      priority_level: '',
      attachments: {}
    },
    onSubmit(values) {
      const { attachments } = values;
      if (attachments?.name) {
        mutateTemporal.mutate({ file: attachments });
      } else {
        handleReportIssue(null);
      }
    },
    validationSchema: VALIDATION_SCHEMA
  });
  const { setFieldValue, values, handleChange } = formik;

  const { data } = useFetchIssueType();

  const setInputProps = inputPropsSetterConfig(formik);

  const mutation = useCreateIssueReport({
    onSuccess(data) {
      runIfFn(() => queryClient.refetchQueries([keys.REPORTED_ISSUES]));
    }
  });

  const handleFileUpload = async (files, progressSetter) => {
    setFieldValue('attachments', files);
  };

  const mutateTemporal = useUploadTemporaryDocument({
    noMsg: true,
    onSuccess(res) {
      const attachmentId = res.id;
      handleReportIssue([attachmentId]);
    }
  });
  const handleReportIssue = async (attachmentId) => {
    const payload = {
      ...values,
      date: formatDate(values.date),
      attachments: attachmentId
    };

    mutation.mutate(payload);
  };
  const { data: assets, isLoading } = useFetchAllAssets();
  const assetsOptions =
    assets?.results?.map((asset) => ({
      label: asset.name,
      value: asset.id
    })) ?? [];

  const history = useHistory();
  const handleSubmit = () => {
    formik.submitForm();
  };

  return (
    <AddFormStyled>
      <Header />
      <form
        className='form_block'
        style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}
      >
        <CustomSelect
          options={assetsOptions}
          size='small'
          isLoading={isLoading}
          label='Select Asset'
          onSelect={(value) => {
            setFieldValue('asset_pk', value);
          }}
          {...setInputProps('asset')}
        />
        <CustomSelect
          options={transformIssueType(data) || []}
          size='small'
          label='Issue Type'
          handleChange={handleChange}
          {...setInputProps('issue_type')}
        />
        <CustomSelect
          options={assetStatus || []}
          size='small'
          label='Asset Status'
          {...setInputProps('status')}
        />

        <div>
          <DatePicker
            inputProps={{ fullWidth: true, size: 'small' }}
            size='small'
            fullWidth
            label={'issue Date'}
            {...setInputProps('date', true)}
          />
        </div>

        <CustomTextField
          label='Note'
          size='small'
          multiline
          rows={4}
          {...setInputProps('message')}
        />
        <CustomSelect
          options={[
            { label: 'High', value: 'high' },
            { label: 'Medium', value: 'medium' },
            { label: 'Low', value: 'low' }
          ]}
          size='small'
          label='Priority level'
          {...setInputProps('priority_level')}
        />

        <DatePicker
          inputProps={{ fullWidth: true, size: 'small' }}
          size='small'
          fullWidth
          label='Report Date'
          {...setInputProps('report_date', true)}
        />
        {values?.attachments?.name ? (
          <FileView
            // fileIcon={({ color }) => <InsertPhotoIcon htmlColor={color} />}
            handleRemove={() => {
              setFieldValue('attachments', null);
            }}
            name={values.attachments?.name}
          />
        ) : (
          <DragAndDrop
            onUpload={handleFileUpload}
            component={(onClick) => <DndLabel onClick={onClick} />}
          />
        )}
        <hr />
        {formik.errors?.attachments ? (
          <>
            <Text color='red' size='xxs'>
              Attactment is required
            </Text>
          </>
        ) : null}
        <Text size='xs'>Make sure the file size is below 3MB</Text>
        <Group fullWidth className='action_block'>
          <Button
            onClick={history.goBack}
            size='sm'
            fullWidth
            variant='offwhite'
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            size='sm'
            disabled={mutation.isLoading}
            fullWidth
            variant='contained'
          >
            <CircularLoader size={15} show={mutation.isLoading} />
            Reported Issues
          </Button>
        </Group>
      </form>
    </AddFormStyled>
  );
};

export default AddIssue;

const DndLabel = ({ onClick, error }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        padding: '1.4rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '.5rem',
        alignItems: 'center'
      }}
    >
      <Text size='sm'>Drag and Drop a file</Text>
      <Button size='xs' onClick={onClick} className='contained white'>
        Click here to upload document
      </Button>
    </div>
  );
};
