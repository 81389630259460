import {
  FileImageFilled,
  FilePdfFilled,
  FileTextFilled,
  FileWordFilled
} from '@ant-design/icons';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
const iconStyle = {
  fontSize: '28px'
};

export const fileIconType = {
  image: <FileImageFilled style={iconStyle} />,
  pdf: <FilePdfFilled style={iconStyle} />,
  txt: <FileTextFilled style={iconStyle} />,
  doc: <FileWordFilled style={iconStyle} />,
  docx: <FileWordFilled style={iconStyle} />
};

const DocumentIconType = ({ type }) => {
  return (
    <>
      {Object.keys(fileIconType).includes(type) ? (
        fileIconType[type]
      ) : (
        <InsertDriveFileIcon fontSize='large' htmlColor='#e1e1e1' />
      )}
    </>
  );
};

export default DocumentIconType;
