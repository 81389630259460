import React from 'react';

const CheckIcon = (props) => {
  return (
    <svg
      {...props}
      width='54'
      height='55'
      viewBox='0 0 54 55'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26.875 0.94043C12.1589 0.94043 0.166992 12.9323 0.166992 27.6484C0.166992 42.3645 12.1589 54.3564 26.875 54.3564C41.5911 54.3564 53.583 42.3645 53.583 27.6484C53.583 12.9323 41.5911 0.94043 26.875 0.94043ZM39.6414 21.5056L24.498 36.649C24.1224 37.0242 23.6133 37.2348 23.0825 37.2348C22.5516 37.2348 22.0425 37.0242 21.6669 36.649L14.1086 29.0907C13.736 28.7137 13.5271 28.2051 13.5271 27.6751C13.5271 27.1452 13.736 26.6366 14.1086 26.2596C14.8831 25.4851 16.1651 25.4851 16.9396 26.2596L23.0825 32.4025L36.8104 18.6745C37.5849 17.9 38.8669 17.9 39.6414 18.6745C40.416 19.4491 40.416 20.7044 39.6414 21.5056Z'
        fill='#2898A4'
      />
    </svg>
  );
};

export default CheckIcon;
