import { createFilterOptions } from '@mui/material/Autocomplete';
import * as yup from 'yup';
const filter = createFilterOptions();

export const renderOption = (props, option) => {
  return option?.isAdd ? (
    <li
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingInline: 0,
        ...props.style
      }}
      {...props}
    >
      {props.nonExisting ? (
        <div style={{ paddingBlock: '.5rem' }}>No results found</div>
      ) : null}
      <div
        style={{
          backgroundColor: '#EAF8FA',
          color: '#2898A4',
          width: '100%',
          flex: 1,
          height: props.nonExisting ? 'auto' : '100%',
          padding: '.5rem'
        }}
      >
        {option?.label}
      </div>
    </li>
  ) : (
    <li
      style={{ borderBottom: '1px solid #F2F2F2', ...props?.style }}
      {...props}
    >
      {option?.label}
    </li>
  );
};

export const getOptionLabel = (option) => {
  // Value selected with enter, right from the input
  if (typeof option === 'string') {
    return option;
  }
  // Add "xxx" option created dynamically
  if (option.inputValue) {
    return option.inputValue;
  }
  // Regular option
  return option.label;
};

export const filterOptions = (options, params) => {
  const filtered = filter(options, params);

  const { inputValue } = params;
  const isExisting = options.some((option) =>
    option.label.includes(inputValue)
  );

  //   if (inputValue !== '' && !isExisting) {
  filtered.push({
    inputValue,
    nonExisting: inputValue !== '' && !isExisting,
    isAdd: true,
    label: `Add Item`
  });
  //   }

  return filtered;
};

export const getCreateAssetInitialValues = (type) => {
  return type === 'Assets';
};

export const assetStatus = [
  {
    label: 'Assigned',
    value: 'active'
  },
  {
    label: 'Unassigned',
    value: 'inactive'
  },
  {
    label: 'In repair',
    value: 'out_for_repair'
  },
  {
    label: 'Damaged',
    value: 'condemned'
  },
  {
    label: 'Discarded',
    value: 'discarded'
  },
  {
    label: 'Missing',
    value: 'missing'
  },
  {
    label: 'Sold',
    value: 'sold'
  }
];

export const conditions = [
  {
    label: 'New',
    value: 'new'
  },
  { label: 'Used', value: 'used' }
];
const requiredMsg = 'field is required';

export const assetInitial = (assetType = '', data = {}, isEdit) => ({
  assignee: data?.assignee?.id || null,
  assetType: assetType,
  asset_type: data?.asset_type?.id || '',
  name: data?.name || '',
  location: data?.location?.id || '',
  status: data?.status || '',
  asset_condition: data?.asset_condition || '',
  brand: data?.brand || '',
  model: data?.model || '',
  color: data?.color || '',
  serial_no: data?.serial_no || '',
  notes: data?.notes || '',
  ...(isEdit
    ? {}
    : {
        attachments: [],
        image: ''
      }),
  date_assigned: data?.date_assigned || null,
  purchase_date: data?.purchase_date || null,
  cost: data?.cost || '',
  contact: data?.contact || ''
});

export const assetSchema = yup.object().shape({
  assetType: yup.string().required(requiredMsg),
  asset_type: yup.string().required(requiredMsg),
  name: yup.string().required(requiredMsg),
  location: yup.string().required(requiredMsg),
  asset_condition: yup.string().required(requiredMsg),
  status: yup.string().required(requiredMsg),
  date_assigned: yup.string().required('date assigned is required')
});

export const vechicleInitial = (assetType = '', data, isEdit) => {
  return {
    assetType: assetType,
    asset_type: data?.asset_type?.id || '',
    vehicle_type: data?.vehicle_type || '',
    vehicle_make: data?.vehicle_make?.id ?? data?.vehicle_model?.make?.id ?? '',
    vehicle_model: data?.vehicle_model?.id || '',
    year: data?.year ? new Date(data?.year, 0, 1) : null,
    color: data?.color || '',
    plate_no: data?.plate_no || '',
    mileage: data?.millage || '',
    status: data?.status || '',
    asset_condition: data?.asset_condition || '',
    location: data?.location?.id || '',
    contact: data?.contact || '',
    ...(isEdit
      ? {}
      : {
          image: '',
          attachments: []
        }),
    date_assigned: data?.date_assigned || null,
    purchase_date: data?.purchase_date || null,
    cost: data?.cost ?? '',
    assignee: data?.assignee?.id || null
  };
};
export const vehicleSchema = yup.object().shape({
  vehicle_type: yup.string().required(requiredMsg),
  vehicle_make: yup.string().required(requiredMsg),
  vehicle_model: yup.string().re,
  status: yup.string().required(requiredMsg),
  contact: yup.string().required(requiredMsg)
});

export const vehicleType = [
  { label: 'Cars', value: 'car' },
  { label: 'Trucks', value: 'truck' },
  { label: 'Buses', value: 'bus' },
  { label: 'Bikes', value: 'bike' },
  { label: 'Forklifts', value: 'forklift' }
];

export const assetType = [
  { label: 'Assets', value: 'asset' },
  { label: 'Vehicle', value: 'vehicle' }
];

export const provideErrorInfo = (error, touched) => {
  return {
    error: touched && Boolean(error),
    info: touched && error
  };
};
