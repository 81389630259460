import { createContext, useContext, useState } from 'react';

export const VehicleRepairRecordsContext = createContext({});

export function VehicleRepairRecordsProvider({ children }) {
  const [state, setState] = useState({
    modal: '',
    selectedRepairs: [],
    editRepairDetails: {}
  });

  return (
    <VehicleRepairRecordsContext.Provider value={[state, setState]}>
      {children}
    </VehicleRepairRecordsContext.Provider>
  );
}

function useVehicleRepairRecordsControl() {
  return useContext(VehicleRepairRecordsContext);
}

export default useVehicleRepairRecordsControl;
