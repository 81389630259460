/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { TabWrapper, TabButtonWrapper } from './styled.tab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  tab: {
    pointerEvents: (props) => (props.disabled ? 'none' : 'auto')
  }
});

function TabButton({ tab, active, menuTab, isPayroll, disabled }) {
  const classes = useStyles();
  const [isActive, setIsActive] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const parsedHref = window.location.href.split(/[/?]/);

    const parsedTabView = tab.view.slice(1, tab.view.length);

    const _isActive = parsedHref.includes(parsedTabView);
    setIsActive(_isActive);
  }, [window.location.href]);

  const path = isPayroll ? tab.view : window.location.pathname + tab.view;

  return (
    <TabButtonWrapper
      className={classes.buttonLabel}
      isactive={isActive}
      onClick={() => history.push(path)}
      menuTab={menuTab}
      style={{ pointerEvents: disabled ? 'none' : 'auto' }}
    >
      {tab.name}
    </TabButtonWrapper>
  );
}

function TabNavigation({ tabs = [], menuTab, isPayroll, disabled }) {
  return (
    <TabWrapper>
      {tabs.map((tab) => (
        <TabButton
          key={tab.view}
          tab={tab}
          menuTab={menuTab}
          isPayroll={isPayroll}
          disabled={disabled}
        />
      ))}
    </TabWrapper>
  );
}

export default TabNavigation;
