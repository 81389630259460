import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Text from 'components/Typography/Text';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const settings = [
  {
    label: 'Asset Type & ID',
    value:
      'Create and manage work settings such as work days, work hours, lateness etc.',
    onClick: (push) => push('/assets?tab=settings&view=asset-type')
  },
  {
    label: 'Issue Types',
    value: 'Create issue types and manage how your issues are reported',
    onClick: (push) => push('/assets?tab=settings&view=issued-type')
  },
  {
    label: 'Statutory Information',
    value: 'Create and manage Statutory information types',
    onClick: (push) => push('/assets?tab=settings&view=statutory-type')
  }
];
const Settings = () => {
  const { push } = useHistory();
  return (
    <div>
      {settings.map((setting) => (
        <Setting {...setting} onClick={() => setting.onClick(push)} />
      ))}
    </div>
  );
};

export default Settings;

const Setting = ({ label, value, onClick }) => {
  return (
    <SettingWrapper onClick={onClick}>
      <div className='top'>
        <Text size='md' bold>
          {label}
        </Text>
        <NavigateNextIcon />
      </div>
      <div className='desc'>
        <Text size='sm'>{value}</Text>
      </div>
    </SettingWrapper>
  );
};

const SettingWrapper = styled.div`
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  margin-bottom: 2rem;
  padding: 0.8rem 1.5rem;
  border-radius: 0.6rem;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.4rem;
  }
  .desc {
    opacity: 0.8;
  }
`;
