import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

function DocumentType({ width, name, value, onChange, OnBlur, error, label }) {
  const types = [
    {
      title: 'Auto Reg',
      value: 'autoreg'
    },
    {
      title: 'Road Wothiness',
      value: 'roadworthiness'
    },
    {
      title: 'Insurance',
      value: 'insurance'
    }
  ];
  return (
    <FormControl
      variant='outlined'
      size={isWidthDown('lg', width) ? 'small' : 'medium'}
      color='secondary'
      fullWidth
      error={error}
    >
      <InputLabel id='document-types'>{label}</InputLabel>
      <Select
        labelId='document-types'
        size='small'
        name={name}
        value={value}
        onChange={onChange}
        onBlur={OnBlur}
      >
        {types.map((type) => (
          <MenuItem value={type.value}>{type.title}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default withWidth()(DocumentType);
