/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import qs from 'query-string';
import { useFormik } from 'formik';
import { usePaystackPayment } from 'react-paystack';
import {
  Label,
  SubmitButton,
  PolicyDiv,
  SignUpPrompt,
  PasswordTooTip,
  ShowPassword,
  ServerError,
  AuthFormContainer,
  DividerHide,
  SignUpContainer
} from './styled.forms';
import {
  CircularProgress,
  TextField,
  Typography,
  Divider,
  Box,
  FormGroup,
  Button,
  Snackbar
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Switch from '@material-ui/core/Switch';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { validateSignup } from './validator';
import { Link, useHistory, Redirect, useLocation } from 'react-router-dom';
import { createBusiness, signup } from '../../api/common';
import { useAuth } from '../../Auth/auth-context';
import BizedgeImage from '../../common/images/bizedgeRound.svg';
import BusinessRegistration from './BusinessRegistration';
import {
  getBilling,
  billingUpdateSetup,
  verifyPayment,
  verifyCouponCode
} from '../../api/billing';
import { useQuery, useQueryClient } from 'react-query';
import { checkCompanyAddStatus } from '../../api/hr';
import ProgressBar from '../../HR/Home/ProgressBar';
import * as yup from 'yup';
import { Alert } from '@material-ui/lab';
import { useMediaQuery } from 'react-responsive';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const tabHeight = '32px';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 30,
    height: 16,
    padding: 0,
    marginRight: '.3em'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  checked: {}
}))(Switch);

const useStyles = makeStyles({
  root: {
    '& .MuiSvgIcon-root': {
      // fontSize: '13px'
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      background: '#E0EEF0',
      borderRadius: '50px',
      minHeight: '20px',
      color: '#2898A4',
      fontWeight: 600,
      fontSize: '14px',
      fontStyle: 'normal',
      overflow: 'hidden'
    },
    '& .MuiTabs-indicator': {
      background: 'transparent !important'
    },
    '& .MuiTab-wrapper': {
      textTransform: 'capitalize'
    },
    '& .MuiTab-root': {
      // border: '1px solid purple',
      // width: '240px',
      overflow: 'hidden'
    },
    '& .MuiOutlinedInput-input': {
      background: '#FAFAFA'
    },
    '& .MuiTypography-h5': {
      fontWeight: 700,
      color: '#545454',
      fontSize: '18px',
      fontStyle: 'normal',
      paddingBottom: '.5em'
    },
    '& .MuiTypography-h6': {
      fontWeight: 800,
      color: '#545454',
      fontSize: '30px',
      fontStyle: 'normal',
      paddingBottom: '.5em'
    },
    // '& .MuiTypography-subtitle2': {
    //   paddingBottom: '.7em'
    // },
    '& .MuiTab-textColorInherit.Mui-selected': {
      background: '#E0EEF0',
      borderRadius: '50px',
      minHeight: '20px',
      color: '#2898A4',
      fontWeight: 600,
      fontSize: '14px',
      fontStyle: 'normal'
    },
    '& .MuiTabs-indicator': {
      background: 'transparent !important'
    },
    '& .MuiTab-wrapper': {
      textTransform: 'capitalize'
    },
    '& .MuiTypography-body2': {
      padding: '0px 0px .5em',
      color: '#545454',
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: '20px'
    },
    '& .test': {
      border: '1px solid #E1E1E1',
      borderRadius: '21px',
      padding: '2em',
      '& .MuiTypography-h6': {
        color: '#545454',
        fontWeight: 700,
        fontSize: '18px',
        marginBottom: '.5em'
      },
      '& .summaryWrapper': {
        padding: '1em',
        borderRadius: '11px',
        border: '1px solid #2898A4',
        background: '#E0EEF0',
        boxShadow: '0px 5px 18px rgba(40, 41, 61, 0.07)'
      },
      '& .employeeNumber': {
        margin: '.6em 0px',
        '& .MuiTypography-subtitle2': {
          color: '#545454',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          paddingBottom: '0px'
        }
      },
      '& .tier': {
        '& .MuiTypography-subtitle1': {
          color: '#545454',
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      '& .MuiTab-textColorInherit.Mui-selected': {
        background: '#E0EEF0',
        borderRadius: '50px',
        minHeight: '20px',
        color: '#2898A4',
        fontWeight: 600,
        fontSize: '14px',
        fontStyle: 'normal'
      },
      '& .MuiTabs-indicator': {
        background: 'transparent !important'
      },
      '& .MuiTab-wrapper': {
        textTransform: 'capitalize'
      },
      '& .trial': {
        fontSize: '14px',
        textAlign: 'center',
        padding: '1em',
        fontWeight: 600,
        color: '#545454',
        textDecoration: 'underline'
      }
    }
  },
  inputWrapper: {
    marginBottom: '1em'
  },
  divider: {
    margin: '2em 0'
  },
  plan: {
    border: '1.02058px solid #E1E1E1',
    borderRadius: '50px',
    width: '100%',
    margin: '0px auto',
    overflow: 'hidden',
    background: '#FAFAFA',
    '& .MuiTabs-flexContainer': {
      display: 'block'
    }
  },
  tabsRoot: {
    minHeight: tabHeight,
    height: tabHeight
  },
  tabRoot: {
    minHeight: tabHeight,
    height: tabHeight
  },
  user: {
    '& .MuiOutlinedInput-input': {
      textAlign: 'center'
    }
  },
  summary: {
    border: '1px solid #E1E1E1',
    background: '#FAFAFA',
    padding: '10.5px 10px 10.5px 20px',
    borderRadius: '5px',
    margin: '2em auto',
    width: '100%',

    '& .MuiTypography-subtitle2': {
      color: '#878787',
      fontWeight: 400,
      fontSize: '14px'
    },
    '& .MuiTypography-subtitle1': {
      color: '#545454',
      fontWeight: 400,
      fontSize: '17px'
    }
  },
  recurring: {
    '& .MuiTypography-subtitle2': {
      color: '#878787',
      fontWeight: 400,
      fontSize: '14px'
    }
  },
  loaderWrapper: {
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0) 56.25%, #D6F2F5 100%)',
    height: '100vh',
    width: '100%',
    '& .MuiTypography-subtitle1': {
      color: '#333333',
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: '28px',
      lineHeight: '138.55%'
    },
    '& .MuiTypography-subtitle2': {
      color: '#878787',
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '18px',
      lineHeight: '175.55%',
      textAlign: 'center',
      maxWidth: '80%',
      margin: 'auto'
    }
  }
});

function useQueryPlan() {
  const { search } = useLocation();
  const { plan } = qs.parse(search);
  const { from_app } = qs.parse(search);
  return { plan, from_app };
}

function PasswordHint({ email, password }) {
  const classes = useStyles();

  return (
    <ul className={classes.root}>
      <li>
        {password.length > 8 ? (
          <CheckCircleIcon fontSize='small' />
        ) : (
          <RadioButtonUncheckedIcon fontSize='small' />
        )}{' '}
        Your password must contain at least 8 characters
      </li>
      <li>
        {!email.includes(password) && password.length !== 0 ? (
          <CheckCircleIcon fontSize='small' />
        ) : (
          <RadioButtonUncheckedIcon fontSize='small' />
        )}{' '}
        Your password can't be too similar to your other personal information
      </li>
      {/* <li>Your password can't be a commonly used password</li> */}
      <li>
        {isNaN(password) ? (
          <CheckCircleIcon fontSize='small' />
        ) : (
          <RadioButtonUncheckedIcon fontSize='small' />
        )}{' '}
        Your password can't be entirely numeric
      </li>
    </ul>
  );
}

const initialValues = {
  email: '',
  password: ''
};

function Signup({ width }) {
  const result = useAuth();
  const classes = useStyles();
  const queryClient = useQueryClient();
  const queryPlan = useQueryPlan();
  const [showPassword, setShowpassword] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [value, setValue] = useState(0);
  const [counter, setCounter] = useState();
  const [isQuarterly, setIsQuarterly] = useState([7500]);
  const [isMonthly, setIsMonthly] = useState([5000]);
  const history = useHistory();
  const [serverErrors, setServerErrors] = useState({
    password: ''
  });
  const [isComplete, setIsComplete] = useState(false);
  const [buyNowStep, setBuyNowStep] = useState(1);
  const [isValidCoupon, setIsValidCoupon] = useState(false);
  const [discount, setDiscount] = useState();
  const [isEmpty, setIsEmpty] = useState(false);
  const [isCheckingCoupon, setCheckingCoupon] = useState(false);
  const [numOfEmp, setNumOfEmp] = useState();
  const [employeeCount, setEmployeeCount] = useState();
  const { data: billingData, isSuccess: successBilling } = useQuery(
    ['current-billing'],
    getBilling
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [businessName, setBusinessName] = useState('');
  const [businessId, setBusinessId] = useState('');
  const [isBusinessCreated, setIsBusinessCreated] = useState(false);
  const [isRecurring, setIsRecurring] = useState(false);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState({ severity: '', message: '' });
  const [completed, setCompleted] = useState(5);

  const isMobile = useMediaQuery({
    query: '(max-width: 640px)'
  });
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (isLoading) {
      setInterval(() => {
        setCompleted((prev) => (prev >= 95 ? prev : prev + 5));
      }, 5000);
    }
  }, [isLoading]);

  useEffect(() => {
    const newData = () => {
      if (successBilling && billingData) {
        setCounter(billingData?.num_employees);
        setEmployeeCount(billingData?.num_employees);
        setIsRecurring(billingData && billingData.recurring);
        setValue(billingData?.frequency === 'monthly' ? 0 : 1);
        setNumOfEmp(billingData?.num_employees);
      }
    };
    newData();
  }, [successBilling, billingData]);

  useEffect(() => {
    if (value === 0) {
      setIsMonthly(employeeCount * 500);
    } else if (value === 1) {
      // let amount = billingData?.is_first_subcription
      //   ? employeeCount * 250 * 3
      //   : employeeCount * 500 * 3;
      let amount = employeeCount * 500 * 3;
      if (discount) {
        let discountAmount = (discount / 100) * amount;
        setIsQuarterly(amount - discountAmount);
      } else {
        setIsQuarterly(amount);
      }
    }
  }, [billingData, employeeCount, value, discount]);
  const [products, setProducts] = useState([]);
  const handleSubmitBusiness = async (payload) => {
    const formData = new FormData();

    formData.append('name', payload?.name);
    payload?.logo && formData.append('logo', payload?.logo);
    payload?.avatar && formData.append('avatar', payload?.avatar);
    formData.append('type', payload?.type);
    formData.append('state', payload?.state);
    formData.append('phone_number', payload?.phone_number);
    formData.append('last_name', payload?.last_name);
    formData.append('first_name', payload?.first_name);
    formData.append('description', payload?.description);
    formData.append('country', payload?.country);
    formData.append('city', payload?.city);
    formData.append('address2', payload?.address2);
    formData.append('address1', payload?.address1);
    formData.append('size', payload?.size);
    formData.append('point_of_contact', payload?.point_of_contact);
    if (payload?.rating) {
      formData.append('rating', payload?.rating);
    }
    if (products.length > 0) {
      formData.append('interested_in', products.toString());
    }

    setIsLoading(true);
    try {
      const response = await createBusiness(formData);
      if (response) {
        let formData = new FormData();
        formData.append('task_id', response?.task_id);
        const BusinessCreate = setInterval(async () => {
          const newBusiness = await checkCompanyAddStatus(formData);
          if (newBusiness.status === 'SUCCESS') {
            setIsLoading(false);
            clearInterval(BusinessCreate);
            queryClient.refetchQueries(['user']);
            queryClient.refetchQueries(['business-detail']);
            queryClient.refetchQueries(['current-billing']);
            localStorage.setItem(
              'business_domain',
              newBusiness?.data.business_id
            );
            if (queryPlan.plan === 'buy_now') {
              setIsLoading(false);
              setBusinessName(newBusiness?.data?.name);
              setBusinessId(newBusiness?.data?.business_id);
              setIsBusinessCreated(true);
            } else {
              setIsLoading(false);
              window.location.href = '/dashboard';
            }
          }
        }, 5000);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const regEx = /^[0-9\b]+$/;
    if (e.target.value === '' || regEx.test(e.target.value)) {
      setNumOfEmp(e.target.value);
      setEmployeeCount(e.target.value);
      setIsEmpty(false);
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    // setCounter(billingData?.num_employees);
    setIsMonthly(isMonthly);
    setIsQuarterly(isQuarterly);
  };

  const handleChangeRecurring = () => {
    setIsRecurring(!isRecurring);
  };

  const handleSubmit = async (values) => {
    const business_plan =
      queryPlan.plan === 'buy_now'
        ? 'premium'
        : queryPlan.plan === 'free_trial'
        ? 'premium'
        : 'free';
    const subscription_info =
      queryPlan.plan === 'buy_now'
        ? {
            billing_buy_now: true,
            billing_frequency: 'monthly',
            billing_num_employees: 1
          }
        : queryPlan.plan === 'free_trial'
        ? {
            billing_buy_now: false,
            billing_frequency: 'monthly',
            billing_num_employees: 1
          }
        : null;
    const token = localStorage.getItem('access_token');
    if (token) {
      localStorage.clear();
    }
    const payload = {
      business_plan,
      subscription_info,
      email: values.email,
      password: values.password
    };

    try {
      const response = await signup(payload);
      if (response) {
        setIsComplete(true);
        setEmail(response?.user?.email);
      }
    } catch (error) {
      setServerErrors({ ...serverErrors, ...error });
      setIsComplete(false);
    }
  };
  const formik = useFormik({
    initialValues,
    validate: validateSignup,
    onSubmit: handleSubmit
  });

  const couponSchema = yup.object({
    coupon_code: yup
      .string()
      .required('Coupon code is required')
      .min(12, 'Minimum of 12 characters')
      .max(12, 'Invalid coupon code entered')
  });

  const couponForm = useFormik({
    initialValues: {
      coupon_code: ''
    },
    validationSchema: couponSchema
    // onSubmit: handleVerifyCoupon
  });

  const config = {
    email,
    amount: value === 0 ? Number(isMonthly) * 100 : Number(isQuarterly) * 100,
    reference: billingData && billingData.payment_reference,
    channels: ['card'],
    metadata: {
      bizedge_billing_type: 'billing.buy_now_subscription',
      bizedge_business_id: businessId && businessId,
      country: formik.values.country,
      // currency: props.planCode?.pricing?.currency.toUpperCase(),
      custom_fields: [
        {
          display_name: 'Business ID',
          variable_name: 'Business ID',
          value: businessId && businessId
        },
        {
          display_name: 'Business Name',
          variable_name: 'Business Name',
          value: businessName && businessName
        }
      ]
    },
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY
  };
  const initializePayment = usePaystackPayment(config);
  const handleVerifyCoupon = async () => {
    const { values } = couponForm;
    setCheckingCoupon(true);
    try {
      const coupon = await verifyCouponCode(values);
      if (coupon.can_use) {
        setDiscount(coupon.coupon_value);
        setIsValidCoupon(true);
        setFeedback({
          severity: 'success',
          message: 'Coupon code validated'
        });
      } else {
        setFeedback({
          severity: 'error',
          message: 'Enter a valid coupon code'
        });
      }
      setCheckingCoupon(false);
    } catch (error) {
      setFeedback({
        severity: 'error',
        message: error.message
      });
      setCheckingCoupon(false);
    }
    handleClick();
  };

  const hasErrors = (field) => {
    return formik.touched[field] && formik.errors[field];
  };

  if (result.data)
    return (
      <Redirect
        to={{
          pathname: '/business-registration'
        }}
      />
    );

  const plan =
    queryPlan.plan === 'buy_now'
      ? 'Create Your Account'
      : queryPlan.plan === 'free_trial'
      ? 'Start now for free, no card required'
      : 'Get Started with Free Forever';

  const handleProceed = () => {
    setBuyNowStep(2);
  };

  const handleBack = () => {
    setBuyNowStep(1);
  };

  const onSuccess = (reference) => {
    if (reference.status === 'success') {
      setIsLoading(true);
      const payload = {
        reference: billingData && billingData.payment_reference
      };
      const confirmPayment = setInterval(async () => {
        const res = await verifyPayment(payload);
        if (res.status) {
          clearInterval(confirmPayment);
          setIsLoading(false);
          window.localStorage.setItem(
            'upgrade',
            JSON.stringify({
              toast: true,
              message:
                'Your subscription for Premium Edge was successful. You can now access all the modules.'
            })
          );
          window.location.href = '/dashboard';
        }
      }, 5000);
    }
  };

  const onClose = () => {};

  const handleBuyNow = async () => {
    if (!numOfEmp) {
      setIsEmpty(true);
      return;
    } else {
      setIsEmpty(false);
    }
    let frequency = value === 0 ? 'monthly' : 'quarterly';
    setIsSubmitted(true);
    try {
      const payload = {
        frequency,
        num_employees: Number(employeeCount),
        recurring: isRecurring
      };
      const data = await billingUpdateSetup(payload);
      if (data) {
        setNumOfEmp(data.num_employees);
        queryClient.refetchQueries(['current-billing']);
        initializePayment(onSuccess, onClose);
        setIsSubmitted(false);
      }
    } catch (error) {
      setIsSubmitted(false);
    }
  };

  if (isLoading) {
    return (
      <Box className={classes.loaderWrapper}>
        <Box style={{ width: '60%', margin: '15em auto', textAlign: 'center' }}>
          <img
            src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638526892/Bizedge_Frontend_Assets/BizLoader_dzmb4i.gif'
            style={{ width: '140px', height: '140px', margin: 'auto' }}
            alt=''
          />
          <Typography variant='subtitle1'>
            Setting up your Business Account
          </Typography>
          <Typography variant='subtitle2'>
            This might take up to a minute, Please do not refresh the page or
            close this tab until the login process has completed
          </Typography>
          <Box width='40%' margin='32px auto 0'>
            <ProgressBar
              bgcolor='#2898A4'
              height='6px'
              completed={completed}
              borderRadius={50}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <title>Sign up to BizEdge</title>
        <meta property='og:title' content='Sign up to BizEdge' />
        <meta
          name='description'
          content='Sign up to the all in one HR, Payroll and Productivity Solution that lets you focus on people, not processes.'
        />
        <meta
          property='og:description'
          content='Sign up to the all in one HR, Payroll and Productivity Solution that lets you focus on people, not processes.'
        />
      </Helmet>

      <AuthFormContainer>
        <Box className='c-sideLeft'>
          <Box className='container'>
            <img
              src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638786088/Bizedge_Frontend_Assets/Bizedge_logo_axcwlg.svg'
              alt='logo'
              style={{ width: '135px', height: '100px', marginLeft: '12%' }}
              className='logo'
            />
            <Typography className='subtitle'>
              All in One for Ease and Effectiveness
            </Typography>
            <Box className='info'>
              <img src={BizedgeImage} alt='' />
            </Box>
            <Typography variant='subtitle2' className='intro'>
              All In One for Ease and Effectiveness
            </Typography>
          </Box>
        </Box>
        <Box className={`${classes.root} c-sideRight`}>
          <Box className='c-sideRight-form'>
            {isComplete && queryPlan.plan !== 'buy_now' ? (
              !isBusinessCreated ? (
                <>
                  <BusinessRegistration
                    handleSubmit={handleSubmitBusiness}
                    isLoading={isLoading}
                    {...{ products, setProducts }}
                  />
                </>
              ) : (
                <>
                  {buyNowStep === 1 ? (
                    <>
                      <Box className='test'>
                        <Typography variant='h6'>
                          Purchase Premium Edge
                        </Typography>
                        <Box className='divider'>
                          <Divider />
                        </Box>
                        <Box className='employeeNumber'>
                          <Typography variant='subtitle2'>
                            How many employees do you have?
                          </Typography>
                          <TextField
                            type='text'
                            size='small'
                            fullWidth
                            variant='outlined'
                            color='secondary'
                            placeholder='Enter the number of employees'
                            value={numOfEmp}
                            onChange={(event) => handleChange(event)}
                            error={
                              numOfEmp && numOfEmp < counter
                                ? true
                                : isEmpty
                                ? true
                                : false
                            }
                            helperText={
                              numOfEmp && numOfEmp < counter
                                ? `You have ${counter} ${
                                    counter > 1 ? 'employees' : 'employee'
                                  }, you cannot pay for less`
                                : isEmpty
                                ? 'Number of employee cannot be empty'
                                : null
                            }
                          />
                        </Box>
                        <Box className='divider'>
                          <Divider />
                        </Box>
                        <Grid
                          container
                          alignItems='center'
                          style={{
                            margin: '.8em 0px',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Grid item md={5} className='tier'>
                            <Typography variant='subtitle1'>
                              Select your billing cycle?
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Box className={classes.plan}>
                              <Tabs
                                classes={{ root: classes.tabsRoot }}
                                value={value}
                                onChange={handleChangeTab}
                              >
                                <Tab
                                  classes={{ root: classes.tabRoot }}
                                  style={{ minWidth: '50%' }}
                                  label='Monthly'
                                />
                                <Tab
                                  classes={{ root: classes.tabRoot }}
                                  style={{ minWidth: '50%' }}
                                  label='Quarterly'
                                />
                              </Tabs>
                            </Box>
                          </Grid>
                        </Grid>
                        <Box className='divider'>
                          <Divider />
                        </Box>
                        <Typography
                          variant='subtitle1'
                          className='summary'
                          style={{ marginTop: '.6em' }}
                        >
                          Summary
                        </Typography>

                        <Box className='summaryWrapper'>
                          <Box
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <Box style={{ width: '30%' }}>
                              <Typography variant='subtitle2'>
                                No. of People
                              </Typography>
                            </Box>
                            <Box style={{ width: '35%' }}>
                              <Typography variant='subtitle2'>
                                {value === 0 ? 'Monthly' : 'Quarterly'} amount
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <Box
                              style={{ width: '30%' }}
                              className={classes.people}
                            >
                              <Typography variant='subtitle2'>
                                {employeeCount === '' ? 0 : employeeCount}{' '}
                                People
                              </Typography>
                            </Box>
                            <Box
                              style={{ width: '35%' }}
                              className={classes.amount}
                            >
                              <Typography variant='subtitle2'>
                                &#8358;{value === 0 ? isMonthly : isQuarterly}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        {value !== 0 && (
                          <Box className='employeeNumber'>
                            <Typography variant='subtitle2'>
                              Coupon Code
                            </Typography>
                            <Grid
                              container
                              alignItems='center'
                              style={{
                                justifyContent: 'space-between'
                              }}
                            >
                              <TextField
                                type='text'
                                size='small'
                                variant='outlined'
                                color='secondary'
                                name='coupon_code'
                                id='coupon_code'
                                style={{
                                  width: `${
                                    couponForm.errors.coupon_code
                                      ? '100%'
                                      : '69%'
                                  }`
                                }}
                                value={couponForm.values.coupon_code}
                                onChange={couponForm.handleChange}
                                error={
                                  couponForm.touched.coupon_code &&
                                  Boolean(couponForm.errors.coupon_code)
                                }
                                helperText={couponForm.errors.coupon_code}
                                placeholder='Enter valid coupon code'
                              />
                              {!couponForm.errors.coupon_code && (
                                <Typography
                                  onClick={handleVerifyCoupon}
                                  variant='text'
                                  style={{
                                    color: '#2898A4',
                                    cursor: 'pointer',
                                    background: '#EAF8FA',
                                    borderRadius: '7px',
                                    padding: '.75rem .5rem',
                                    width: '30%',
                                    textAlign: 'center'
                                  }}
                                >
                                  Verify Code
                                </Typography>
                              )}
                            </Grid>
                            <Grid
                              container
                              alignItems='center'
                              style={{
                                color: '#2898A4',
                                cursor: 'pointer',
                                marginTop: '.8em'
                              }}
                            >
                              {isValidCoupon && (
                                <Typography
                                  variant='text'
                                  style={{
                                    color: '#2898A4'
                                  }}
                                >
                                  Code applies {discount}% discount to Quarterly
                                  Plan
                                </Typography>
                              )}
                              {isCheckingCoupon && (
                                <CircularProgress
                                  disableShrink
                                  size={25}
                                  style={{ color: '#2898a4' }}
                                />
                              )}
                            </Grid>
                          </Box>
                        )}
                        <FormGroup>
                          <Box
                            component='div'
                            style={{ margin: '.8em 0px 1.5em' }}
                          >
                            <Typography component='div'>
                              <Grid
                                component='label'
                                container
                                alignItems='center'
                                spacing={1}
                              >
                                <Grid item>
                                  <AntSwitch
                                    checked={isRecurring}
                                    onChange={handleChangeRecurring}
                                    name='recurring'
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography variant='subtitle2'>
                                    Make payment recurring{' '}
                                    {value === 0 ? 'monthly' : 'quarterly'}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Typography>
                          </Box>
                        </FormGroup>
                        <Box style={{ marginTop: '1em' }}>
                          <Button
                            variant='contained'
                            color='secondary'
                            fullWidth
                            onClick={handleProceed}
                            disabled={isSubmitted}
                          >
                            {isSubmitted ? (
                              <CircularProgress
                                style={{ color: 'white' }}
                                size={20}
                              />
                            ) : (
                              'Proceed'
                            )}
                          </Button>
                          <Link to='/dashboard'>
                            <Typography variant='subtitle2' className='trial'>
                              Cancel
                            </Typography>
                          </Link>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Grid container spacing={1} alignItems='center'>
                        <Grid item>
                          <IconButton onClick={handleBack}>
                            <KeyboardBackspaceIcon />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <Typography>Back</Typography>
                        </Grid>
                      </Grid>
                      <Typography className='welcome'>
                        Purchase Summary
                      </Typography>
                      <Box>
                        <Grid container className={classes.summary} spacing={1}>
                          <Grid item md={6}>
                            <Typography variant='subtitle2'>
                              Total People
                            </Typography>
                            <Typography variant='subtitle1'>
                              {employeeCount} People
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <Typography variant='subtitle2'>
                              Billing Cycle
                            </Typography>
                            <Typography variant='subtitle1'>
                              {value === 0 ? 'Monthly' : 'Quarterly'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box className='paymentPreview'>
                        <Typography variant='subtitle2'>
                          {value === 0 ? 'Monthly' : 'Quarterly'} Payment
                        </Typography>
                        <Box className='payment'>
                          <Typography>
                            &#8358;{value === 0 ? isMonthly : isQuarterly}
                          </Typography>
                        </Box>
                      </Box>
                      {/* <Box>
                        <Grid container className={classes.recurring}>
                          <Grid item>
                            <AntSwitch onChange={handleSwitchRecurring} />
                          </Grid>
                          <Grid item>
                            <Typography variant='subtitle2'>
                              Make Payment Recurring Monthly
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box> */}
                      <Grid container>
                        <Grid item sm={12}>
                          <SubmitButton onClick={handleBuyNow}>
                            {isPaymentLoading ? (
                              <CircularProgress
                                style={{ color: 'white' }}
                                size={20}
                              />
                            ) : (
                              'Buy Now'
                            )}
                          </SubmitButton>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )
            ) : isComplete && queryPlan.plan !== 'buy_now' ? (
              <>
                <BusinessRegistration
                  handleSubmit={handleSubmitBusiness}
                  isLoading={isLoading}
                  {...{ products, setProducts }}
                />
              </>
            ) : (
              <SignUpContainer>
                {isMobile && (
                  <ArrowBackIcon
                    onClick={() => history.push('/signup')}
                    className='back'
                  />
                )}
                <Typography className='welcome'>
                  {isMobile ? 'Create Your Bizedge Account ' : plan}
                </Typography>
                {/* <Typography className='other'>
                  Let's get right into business without delay.
                </Typography> */}
                <Typography className='future'>
                  Let's journey into the future together
                </Typography>
                {/* <form onSubmit={formik.handleSubmit}> */}
                {serverErrors.password || serverErrors.email ? (
                  <ServerError>
                    <Typography component='span'>
                      {serverErrors.email || serverErrors.password}
                    </Typography>
                  </ServerError>
                ) : null}
                <form onSubmit={formik.handleSubmit}>
                  {/* =================== EMAIL ================== */}
                  <Box className={classes.inputWrapper}>
                    <TextField
                      color='secondary'
                      error={hasErrors('email')}
                      fullWidth={true}
                      label='Email'
                      size={isWidthDown('lg', width) ? 'small' : 'small'}
                      variant='outlined'
                      placeholder='Email'
                      type='email'
                      name='email'
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      haserror={hasErrors('email')}
                      FormHelperTextProps={{ error: hasErrors('email') }}
                      helperText={
                        hasErrors('email') ? formik.errors.email : null
                      }
                    />
                  </Box>

                  <Box className={classes.inputWrapper}>
                    <Label>
                      <PasswordTooTip
                        open={showHint}
                        placement='bottom-start'
                        title={
                          <PasswordHint
                            email={formik.values.email}
                            password={formik.values.password}
                          />
                        }
                      >
                        {/* =================== PASSWORD ================== */}
                        <TextField
                          color='secondary'
                          error={hasErrors('password')}
                          fullWidth={true}
                          label='Password'
                          size={isWidthDown('lg', width) ? 'small' : 'small'}
                          variant='outlined'
                          placeholder='Password'
                          type={showPassword ? 'text' : 'password'}
                          name='password'
                          value={formik.values.password}
                          onFocus={() => setShowHint(true)}
                          onBlur={(e) => {
                            setShowHint(false);
                            return formik.handleBlur(e);
                          }}
                          onChange={formik.handleChange}
                          haserror={hasErrors('password')}
                          FormHelperTextProps={{
                            error: hasErrors('password')
                          }}
                          helperText={
                            hasErrors('password')
                              ? formik.errors.password
                              : null
                          }
                        />
                      </PasswordTooTip>

                      <ShowPassword
                        onClick={() => setShowpassword(!showPassword)}
                      >
                        {showPassword ? 'Hide' : 'Show'}
                      </ShowPassword>
                    </Label>
                  </Box>

                  <SubmitButton
                    disabled={
                      !isNaN(formik.values.password) ||
                      formik.values.password.length < 8 ||
                      formik.values.email.includes(formik.values.password)
                    }
                    type='submit'
                    onClick={formik.handleSubmit}
                    startIcon={
                      formik.isSubmitting ? (
                        <CircularProgress
                          size={25}
                          style={{ color: 'white' }}
                        />
                      ) : null
                    }
                  >
                    {formik.isSubmitting ? null : 'Sign Up'}
                  </SubmitButton>
                </form>
                <PolicyDiv>
                  By signing up, you are indicating that you have read and
                  agreed to the
                  {/* <br /> */}{' '}
                  <Link to='/terms' className='link'>
                    Terms of use
                  </Link>{' '}
                  and{' '}
                  <Link to='/privacy-policy' className='link'>
                    Privacy Policy
                  </Link>
                </PolicyDiv>
                <DividerHide className={`${classes.divider} mobile-hide`}>
                  <Divider />
                </DividerHide>
                <SignUpPrompt>
                  Already have an account? <Link to='/'>Sign in</Link>
                </SignUpPrompt>
                {/* </form> */}
              </SignUpContainer>
            )}
          </Box>
        </Box>
      </AuthFormContainer>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={feedback.severity}>
          {feedback.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default withWidth()(Signup);
