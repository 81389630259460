import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { verifySignup } from '../api/common';
import { Helmet } from 'react-helmet';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '50%',
    margin: '5em auto',
    textAlign: 'center',
    '& .MuiButton-contained': {
      width: 'auto',
      '& > *': {
        textTransform: 'capitalize',
        fontWeight: 'bold'
      }
    },
    '& .MuiTypography-h6': {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '25px',
      lineHeight: '37px',
      color: '#545454'
    },
    '& .MuiTypography-subtitle2': {
      padding: '1em 0px',
      fontSize: '16px',
      fontWeight: 400,
      fontStyle: 'normal',
      color: '#545454',
      width: '80%',
      margin: 'auto'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '2em auto',
      '& .MuiTypography-subtitle2': {
        width: '100%'
      }
    }
  },
  success: {
    width: '50%',
    margin: '10% auto',
    textAlign: 'center'
  },
  failure: {
    width: '50%',
    margin: '10% auto',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  wrapper: {
    '& > a': {
      color: theme.palette.secondary.main,
      textDecoration: 'underline',
      fontWeight: 600,
      '&:hover': {
        textDecoration: 'none'
      }
    }
    // textAlign: 'center',
    // width: '80%',
    // margin: 'auto',
    // '& .MuiTypography-subtitle1': {
    //   color: '#545454',
    //   fontsize: '16px',
    //   fontWeight: 400,
    //   fontStyle: 'normal',

    // }
  }
}));

const AccountVerification = () => {
  const classes = useStyles();
  const { token } = qs.parse(window.location.search);
  const [serverMessage, setServerMessage] = useState('');
  const [serverCode, setServerCode] = useState(null);

  useEffect(() => {
    const data = async () => {
      try {
        if (token) {
          const payload = {
            key: token
          };
          const res = await verifySignup(payload);
          if (res) {
            const { detail } = res;
            setServerCode(200);
            setServerMessage(detail);
          }
        } else {
          setServerCode(400);
          setServerMessage('Sorry, Something went wrong');
        }
      } catch (error) {
        const {
          status,
          data: { detail }
        } = error.response;
        if (status === 400) {
          setServerCode(error.response.status);
          setServerMessage(detail);
        }
      }
    };
    data();
  }, [token]);

  if (serverCode === 400) {
    return (
      <Box className={classes.failure}>
        <Typography>{serverMessage}</Typography>
      </Box>
    );
  }

  return (
    <>
      {serverCode === 200 && (
        // <Box className={classes.success}>
        //   <Typography>{serverMessage}</Typography>
        //   <Button
        //     variant='contained'
        //     color='secondary'
        //     disableElevation={true}
        //     onClick={handleRoute}
        //   >
        //     Click here to continue
        //   </Button>
        // </Box>
        <Box className={classes.root}>
          <Helmet>
            <meta
              name='viewport'
              content='width=device-width, initial-scale=1'
            />
          </Helmet>
          <img
            src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638791023/Bizedge_Frontend_Assets/email-confirm_sxdwiv.svg'
            alt='Confirmation email'
            width='260px'
            height='250px'
          />
          <Box className={classes.wrapper}>
            {/* <Typography variant='h6'>Email Confirmation</Typography> */}
            <Typography variant='subtitle2'>
              Your email address has been verified
            </Typography>
            <Link to='/login'>Click here to continue</Link>
          </Box>
          {/* <Box className={classes.divider}>
            <Divider />
          </Box> */}
          {/* <Box className={classes.action}>
            <Typography variant='subtitle1'>
              Already have an account? <Link to='/login'>Sign In</Link>
            </Typography>
          </Box> */}
        </Box>
      )}
    </>
  );
};

export default AccountVerification;
