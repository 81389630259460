import axios from 'axios';
import { useQuery } from 'react-query';
const serverStatusEndpoint =
  'https://bizedge-prod.s3.us-west-2.amazonaws.com/maintenance/index.json';
const getServerStatus = async () => {
  try {
    const response = await axios.get(serverStatusEndpoint);
    return response.data;
  } catch (error) {
    return error;
  }
};
const useServerStatus = () => {
  const { data } = useQuery(['biz-server-status'], getServerStatus, {
    refetchInterval: 60000,
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: true
  });

  return data?.server_up ?? undefined;
};

export default useServerStatus;
