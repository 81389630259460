import { DatePicker } from 'components/Inputs';
import { useFormikContext } from 'formik';
import FormSectionTitle from './FormSectionTitle';
import SelectEmployees from './SelectEmployees';
const AssignEmployee = ({ assignee }) => {
  const { setFieldValue, errors, handleBlur, touched, values } =
    useFormikContext();
  return (
    <>
      <FormSectionTitle>Assignee Information (Optional)</FormSectionTitle>

      <SelectEmployees assignee={assignee} />
      <DatePicker
        size='small'
        label='Assigned Date'
        name='date_assigned'
        value={values.date_assigned}
        onChange={(newValue) => setFieldValue('date_assigned', newValue)}
        onBlur={handleBlur}
        inputProps={{
          fullWidth: true,
          error: touched.date_assigned && Boolean(errors.date_assigned),
          info: touched.date_assigned && errors.date_assigned
        }}
      />
    </>
  );
};

export default AssignEmployee;
