import { useHistory } from 'react-router-dom';
import { runIfFn } from 'utilities/utils';
import CardGrid from 'components/CardGrid/CardGrid';
import StatCard from 'components/StatCard';
const AssetTypeList = ({ types, setToEdit, toggle }) => {
  const history = useHistory();

  const handleClick = (stat) => () => {
    history.push('/assets?tab=asset-list&view=list', {
      search: stat.title,
      id: stat.id
    });
  };

  return (
    <div className='stats_block'>
      <CardGrid cardSize={'minmax(300px, 1fr)'} gap='.5rem'>
        {types?.map((stat) => (
          <StatCard
            onClick={handleClick(stat)}
            style={{ padding: '.3rem 1rem', cursor: 'pointer' }}
            bgColor={'offBlue'}
            menu={[
              {
                label: 'Edit',
                onClick: (_, handleClose) => {
                  setToEdit(stat);
                  runIfFn(toggle, handleClose);
                }
              },
              {
                label: 'Delete',
                style: { color: 'red' },
                onClick: () => {}
              }
            ]}
            key={stat.title}
            {...stat}
          />
        ))}
      </CardGrid>
    </div>
  );
};

export default AssetTypeList;
