import axios from './axios.config';
import getBaseDomainURL from './base-domain-url';

// errors are not caught on API call level. They are being handled in the components
export function getVehicleMakes({ queryKey }) {
  const [type] = queryKey;
  if (type) {
    return axios
      .get(`${getBaseDomainURL()}/make/?type=${type}`)
      .then(({ data }) => data)
      .catch((error) => {
        throw error;
      });
  }
}

export function getModels({ queryKey }) {
  const [make, type] = queryKey;
  return axios
    .get(`${getBaseDomainURL()}/make/${type}/models/?type=${make}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getVehiclesMeta() {
  return axios
    .get(`${getBaseDomainURL()}/vehicles/choicefield-metadata/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function createVehicle(payload) {
  return axios
    .post(`${getBaseDomainURL()}/vehicles/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function uploadVehicleFile(payload) {
  return axios
    .post(`${getBaseDomainURL()}/vehicle-attachments/`, payload.fileData, {
      onUploadProgress: (progressEvent) => {
        payload.progress(
          Math.round((progressEvent.loaded / progressEvent.total) * 100)
        );
      }
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function editVehicleFile(payload) {
  return axios
    .post(`${getBaseDomainURL()}/vehicle-attachments/add/`, payload.fileData, {
      onUploadProgress: (progressEvent) => {
        payload.progress(
          Math.round((progressEvent.loaded / progressEvent.total) * 100)
        );
      }
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getVehicles(params) {
  return axios
    .get(`${getBaseDomainURL()}/vehicles/?type=truck`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function paginateVehicles(params) {
  const { newPage, status, type, search, setLoadingHandler } = params;
  const url =
    status && type
      ? `status=${status}&type=${type}`
      : status
      ? `status=${status}`
      : type
      ? `type=${type}`
      : null;
  return axios
    .get(
      `${getBaseDomainURL()}/vehicles/?page=${newPage}&search=${
        search || ''
      }&${url}`
    )
    .then(({ data }) => {
      setLoadingHandler(false);
      return data;
    })
    .catch((error) => {
      setLoadingHandler(false);
      throw error;
    });
}

export function deleteVehicle(id) {
  return axios
    .delete(`${getBaseDomainURL()}/vehicles/${id}`)
    .then(() => true)
    .catch((error) => {
      throw error;
    });
}

export function updateVehicle(payload, id) {
  return axios
    .patch(`${getBaseDomainURL()}/vehicles/${payload.id}/`, payload)
    .then(() => true)
    .catch((error) => {
      throw error;
    });
}

export function bulkDeleteVehicles(ids) {
  return axios
    .patch(`${getBaseDomainURL()}/vehicles/bulk-delete/`, { ids })
    .then(() => true)
    .catch((error) => {
      throw error;
    });
}

export function getOneVehicle({ queryKey }) {
  const { id } = queryKey[1];

  return axios
    .get(`${getBaseDomainURL()}/vehicles/${id}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function createVehicleService(payload) {
  return axios
    .post(`${getBaseDomainURL()}/vehicles/${payload.id}/services/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getVehicleServices(params) {
  const { id, search } = params.queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/vehicles/${id}/services?search=${search || ''}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function deleteOneVehicleService({ vehicleId, serviceId }) {
  return axios
    .delete(
      `${getBaseDomainURL()}/vehicles/${vehicleId}/services/${serviceId}/`
    )
    .then(() => true)
    .catch((error) => {
      throw error;
    });
}

export function updateOneVehicleService(payload) {
  const { vehicleId, serviceId } = payload;
  return axios
    .put(
      `${getBaseDomainURL()}/vehicles/${vehicleId}/services/${serviceId}/`,
      payload
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function bulkDeleteVehicleServices({ ids, vehicleId }) {
  return axios
    .patch(
      `${getBaseDomainURL()}/vehicles/${vehicleId}/services/bulk-delete/`,
      {
        ids
      }
    )
    .then(() => true)
    .catch((error) => {
      throw error;
    });
}

export function createVehicleRepairRecord(payload) {
  return axios
    .post(`${getBaseDomainURL()}/vehicles/${payload.id}/repairs/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getVehicleRepairRecords(params) {
  const { id, search } = params.queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/vehicles/${id}/repairs?search=${search || ''}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getActiveVehicleRepair(params) {
  const { id } = params.queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/vehicles/${id}/repairs/?page_size=4`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function deleteOneRepairRecord({ vehicleId, repairId }) {
  return axios
    .delete(`${getBaseDomainURL()}/vehicles/${vehicleId}/repairs/${repairId}/`)
    .then(() => true)
    .catch((error) => {
      throw error;
    });
}

export function deleteOneUploadedAttachment(id) {
  return axios
    .delete(`${getBaseDomainURL()}/vehicle-attachments/${id}/`)
    .then(() => id)
    .catch((error) => {
      throw error;
    });
}

export function updateOneVehicleRepairRecord(payload) {
  const { vehicleId, repairId } = payload;
  return axios
    .put(
      `${getBaseDomainURL()}/vehicles/${vehicleId}/repairs/${repairId}/`,
      payload
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function bulkDeleteRepairRecords({ vehicleId, ids }) {
  return axios
    .patch(`${getBaseDomainURL()}/vehicles/${vehicleId}/repairs/bulk-delete/`, {
      ids
    })
    .then(() => true)
    .catch((error) => {
      throw error;
    });
}

export function createStatutoryDocument(payload) {
  return axios
    .post(`${getBaseDomainURL()}/vehicles/${payload.id}/statutory/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getStatutoryDocuments(params) {
  const id = params.queryKey[1].id;
  return axios
    .get(`${getBaseDomainURL()}/vehicles/${id}/statutory/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getStatutoryReminders(params) {
  const id = params.queryKey[1].id;
  const url = id
    ? `${getBaseDomainURL()}/vehicle-statutory-reminders/?vehicle=${id}`
    : `${getBaseDomainURL()}/vehicle-statutory-reminders`;
  return axios.get(url).then(({ data }) => data);
}

export function updateOneStatutoryDocument(payload) {
  const { vehicleId, documentId } = payload;
  return axios
    .put(
      `${getBaseDomainURL()}/vehicles/${vehicleId}/statutory/${documentId}/`,
      payload
    )
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function deleteOneStatutoryDocument({ vehicleId, documentId }) {
  return axios
    .delete(
      `${getBaseDomainURL()}/vehicles/${vehicleId}/statutory/${documentId}/`
    )
    .then(() => true)
    .catch((error) => {
      throw error;
    });
}

export function bulkDeleteStatutoryDocuments({ vehicleId, ids }) {
  return axios
    .patch(
      `${getBaseDomainURL()}/vehicles/${vehicleId}/statutory/bulk-delete/`,
      {
        ids
      }
    )
    .then(() => true)
    .catch((error) => {
      throw error;
    });
}

export function getVehicleTypesSummary() {
  return axios
    .get(`${getBaseDomainURL()}/vehicle-dashboard/type-stats/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getServiceRemindersSummary() {
  return axios
    .get(`${getBaseDomainURL()}/vehicle-dashboard/reminder-stats/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getVehicleStatusSummary() {
  return axios
    .get(`${getBaseDomainURL()}/vehicle-dashboard/status-stats/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getAssigneeHistory({ queryKey }) {
  const { id } = queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/vehicles/${id}/assignee-history/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function addAssignee({ payload, id }) {
  return axios
    .post(`${getBaseDomainURL()}/vehicles/${id}/add-assignee/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function removeAssignee({ end_date, id }) {
  return axios
    .post(`${getBaseDomainURL()}/vehicles/${id}/remove-assignee/`, { end_date })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getVehicleServiceReminders({ queryKey }) {
  const { id } = queryKey[1];
  return axios
    .get(`${getBaseDomainURL()}/vehicle-service-reminders/?vehicle=${id}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getServiceReminders() {
  return axios
    .get(`${getBaseDomainURL()}/vehicle-service-reminders/`)
    .then(({ data }) => data);
}

export function getFilteredDocuments({ id, payload }) {
  return axios
    .get(`${getBaseDomainURL()}/vehicles/${id}/statutory/${payload}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getFilteredServices({ id, search }) {
  return axios
    .get(`${getBaseDomainURL()}/vehicles/${id}/services/?search=${search}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getFilteredRepairs({ id, search }) {
  return axios
    .get(`${getBaseDomainURL()}/vehicles/${id}/repairs/?search=${search}`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
