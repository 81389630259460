import { useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import lottie from 'lottie-web';
import reactLogo from './lottie.json';
import { CustomText } from '../../common/Reusables';

const NoData = ({ props }) => {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#react-logo'),
      animationData: reactLogo,
      renderer: 'svg', // "canvas", "html"
      loop: true, // boolean
      autoplay: true // boolean
    });
  }, []);
  return (
    <Box
      style={{
        width: '50%',
        margin: '2rem auto',
        textAlign: 'center'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <div
          id='react-logo'
          style={{
            width: 300,
            height: 300
          }}
        />
      </div>
      <CustomText
        fontSize='25px'
        lineHeight='23px'
        color='rgb(225, 225, 225)'
        textAlign='center'
        fontWeight='700'
        margin='1.4rem 0'
      >
        {props?.label && props.label}
      </CustomText>
      {props?.buttonLabel && (
        <Box style={{ marginTop: '.8rem' }}>
          {props?.buttonLabel && (
            <Button
              variant='contained'
              color='secondary'
              onClick={props?.handleClick}
            >
              {props?.buttonLabel}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default NoData;
