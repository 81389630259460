import { CustomButton as Button } from 'components/Button';
import { Dialog, DialogActions, DialogContent } from 'components/Dialog';
import Group from 'components/StyledComponents/ButtonGroup';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';

const MarkModal = ({ open, toggle, addAction, mark }) => {
  return (
    <Dialog open={open} fullWidth maxWidth='xs'>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            alignItems: 'center',
            textAlign: 'center'
          }}
        >
          <Title>Mark as {mark?.mark}</Title>
          <Text size='xs'>
            To mark an issue as {mark?.mark}, you have to record the maintenance
            done or scheduled to the asset.
          </Text>
        </div>
      </DialogContent>
      <DialogActions>
        <Group fullWidth orientation='col'>
          <Button
            onClick={() => addAction(mark)}
            size='xs'
            fullWidth
            variant='contained'
          >
            Add Maintenance
          </Button>
          <Button onClick={toggle} size='xs' fullWidth variant='outlined'>
            Cancel
          </Button>
        </Group>
      </DialogActions>
    </Dialog>
  );
};

export default MarkModal;
