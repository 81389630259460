import styled from 'styled-components';
import Box from '@material-ui/core/Box';

export const Wrapper = styled(Box)`
  ${({ theme, width, widthPercent, padding }) => `
    background: white;
    // width: ${width ? width : '656px'};
    // min-height: 300px;
    max-height: 95vh;
    max-width: ${widthPercent ? widthPercent : '60%'};
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    outline: none;
    padding:${padding ? padding : '4% 3%'}  ;
    overflow: auto;

    h2 {
      ${theme.mixins.makeResponsive({ theme, fontSize: 24 })};
      font-weight: 700;

      & + * {
        color: #7C7C7C;
        ${theme.mixins.makeResponsive({ theme, fontSize: 14 })};
        margin-top: 1%;
      }
    }

    .MuiFormGroup-root {
      width: 100%;
      ${theme.mixins.makeResponsive({ theme, fontSize: 16 })};
      // margin-top: 5%;
    }

    .MuiMenuItem-root {
      ${theme.mixins.makeResponsive({ theme, fontSize: 16 })};
    }
    .MuiSelect-root, .MuiMenuItem-dense {
      ${theme.mixins.makeResponsive({ theme, fontSize: 16 })};
      
      ${theme.breakpoints.down('lg')} {
        height: 20px;
      }
    }

    .MuiButton-root[type='submit'] {
      box-shadow: none;
      margin-top: 5%;
      height: 50px;
      font-size: 14px;
      text-transform: capitalize;
    }

    .MuiFormControl-root {
      width: 100%;
    }

    .MuiInputLabel-root {
      background: white;
    }
  `}
`;

export const Prompt = styled(Wrapper)`
  ${({ theme, widthProps, paddingProps }) => `
    background: white;
    width: 508px;
    max-width: ${widthProps ? '36%' : '25%'};
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    outline: none;
    padding: ${paddingProps ? paddingProps : '4% 3%'};

    h2 {
      font-size: 24px;
      font-weight: 700;

      & + * {
        color: #7C7C7C;
        font-size: 14px;
        margin-top: 1%;
      }
    }

    & > .MuiBox-root {
      margin-top: 4%;
    }

    .MuiButton-root {
      box-shadow: none;
      margin-top: 5%;
      height: 50px;
      font-size: 14px;
      text-transform: capitalize;
      padding: 10px 30px;
    }

    .MuiButton-root[type='submit'] {
      background: #FF3132;
      color: white;
      margin-right: 10px;
    }
  `}
`;
