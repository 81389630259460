import { CustomButton as Button } from 'components/Button';
import CircularLoader from 'components/CircularLoader';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from 'components/Dialog';
import { DatePicker } from 'components/Inputs';
import Text from 'components/Typography/Text';
import dayjs from 'dayjs';
import { useState } from 'react';
import { runIfFn } from 'utilities/utils';
import { useRemoveAssign } from '../queries';

const UnAssignModal = ({ open, toggle, refetch, data = {} }) => {
  const [end_date, setEnddate] = useState(null);

  const mutation = useRemoveAssign({
    onSuccess() {
      runIfFn(toggle, refetch);
    }
  });

  const handleSubmit = () => {
    const payload = { end_date: dayjs(end_date).format('YYYY-MM-DD') };
    mutation.mutate({ payload, id: data?.id });
  };

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={toggle}>
      <DialogTitle divider px={4} onClose={toggle}>
        Unassign User
        <Text component='div' size='xs'>
          Unassign the current user of this asset
        </Text>
      </DialogTitle>
      <DialogContent>
        <DatePicker
          minDate={Date.now()}
          value={end_date}
          label='End_date'
          inputProps={{ fullWidth: true, size: 'small' }}
          onChange={(newValue) => setEnddate(newValue)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!end_date || mutation.isLoading}
          onClick={handleSubmit}
          variant='contained'
          fullWidth
        >
          <CircularLoader size={15} show={mutation.isLoading} />
          Unassign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnAssignModal;
