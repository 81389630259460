import styled from 'styled-components';

export const AssetWrapper = styled.section`
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 100%;
  .previewWrapper {
    display: none;
    position: absolute;
    backdrop-filter: blur(4px);
    opacity: 0.8;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    padding: 0px 20px;
    z-index: 9999999px;
  }

  &:hover > .previewWrapper {
    display: flex;
    cursor: pointer;
  }

  .display {
    .asset_name_and_assign {
      display: grid;
      grid-template-columns: 1fr 10px 1fr;
      padding: 21px;
      background: #f2f2f2;
      border-radius: 10px;
      gap: 0.8rem;
      .name_info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    .middle_divider {
      width: 1px;
      background: #e1e1e1;
    }
    .assign_details {
      &_action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        margin-bottom: 0.8rem;
      }
    }

    .details_actions {
      padding-top: 36px;
      max-width: 60%;
    }

    .display_main {
      display: grid;
      grid-template-columns: 1.5fr 1fr;
      margin-top: 2rem;
      gap: 100px;
      justify-content: space-between;
      .details {
        margin-top: 2rem;
        display: flex;
        background: #f2f2f2;
        border-radius: 10px;
        padding: 21px;
        gap: 2rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        .item {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          &.item_border {
            border-right: 1px solid #e1e1e1;
          }
        }
      }
      .image_side {
        border-radius: 15px;
        position: relative;
        overflow: hidden;
        max-height: 330px;
        background: #f2f2f2;
        .image_ {
          width: 100%;
          height: 100%;
          img {
            object-fit: contain;
            object-position: center;
            width: 100%;
            height: 100%;
          }
        }
        .image_actions {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: rgba(196, 196, 196, 0.72);
          padding: 0.7rem 2rem;
        }
      }
    }
  }
  .maintenance {
    margin-top: 3rem;
    .actions {
      display: flex;
      gap: 2rem;
      align-items: center;
    }
  }
  .tables_tab {
    margin-top: 3rem;
  }
  .asset_report_empty {
    button {
      text-decoration: underline;
    }
  }
`;
