import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';

const EmptyState = () => {
  return (
    <EmptySC>
      <div>
        <SVG />
      </div>
      <Typography variant='h5' fontWeight={600}>
        Oops, You don't have benefit or Pension
      </Typography>
      <Typography textAlign={'center'}>
        You haven't created any benefit <br /> or pension
      </Typography>
    </EmptySC>
  );
};

export default EmptyState;
export const CustomEmptyState = ({ title = 'No data found', desc }) => {
  return (
    <EmptySC>
      <div>
        <SVG />
      </div>
      <Typography variant='h5' textAlign={'center'} fontWeight={600}>
        {title}
      </Typography>
      {desc && <Typography textAlign={'center'}>{desc} </Typography>}
    </EmptySC>
  );
};

const EmptySC = styled.div`
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
`;

const SVG = () => {
  return (
    <svg
      width='98'
      height='91'
      viewBox='0 0 98 91'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='44.4569' cy='44.3348' r='44.2567' fill='#C8D7FF' />
      <circle
        cx='44.0963'
        cy='44.4088'
        r='25.4644'
        fill='#C2D4FF'
        stroke='#4069D0'
        stroke-width='2'
      />
      <circle
        cx='44.0975'
        cy='44.41'
        r='17.9032'
        fill='#FFFEFF'
        stroke='#4069D0'
        stroke-width='2'
      />
      <path
        d='M50.6599 43.3695V46.329C50.2699 45.159 48.8842 45.4987 48.217 45.8242C48.2013 45.8319 48.1878 45.8416 48.1755 45.854L46.2208 47.8087C45.8262 48.2033 45.7275 48.9596 45.7275 49.2884V52.5783C45.7275 52.6682 45.8004 52.7411 45.8903 52.7411H49.5107C49.6006 52.7411 49.6732 52.6678 49.6756 52.5779C49.7035 51.5202 49.9984 50.947 50.1575 50.7776C50.1637 50.771 50.1693 50.7645 50.1745 50.7572L52.6026 47.3579C52.6223 47.3303 52.6329 47.2972 52.6329 47.2633V41.0661C52.6329 40.9762 52.5596 40.9026 52.4699 40.9094C51.0271 41.0185 50.6599 42.5765 50.6599 43.3695Z'
        fill='#C8D7FF'
      />
      <path
        d='M38.3283 43.3695V46.329C38.7183 45.159 40.1041 45.4987 40.7713 45.8242C40.787 45.8319 40.8004 45.8416 40.8128 45.854L42.7675 47.8087C43.1621 48.2033 43.2607 48.9596 43.2607 49.2884V52.5783C43.2607 52.6682 43.1879 52.7411 43.098 52.7411H39.4776C39.3877 52.7411 39.3151 52.6678 39.3127 52.5779C39.2848 51.5202 38.9899 50.947 38.8307 50.7776C38.8246 50.771 38.819 50.7645 38.8138 50.7572L36.3857 47.3579C36.366 47.3303 36.3554 47.2972 36.3554 47.2633V41.0661C36.3554 40.9762 36.4287 40.9026 36.5184 40.9094C37.9612 41.0185 38.3283 42.5765 38.3283 43.3695Z'
        fill='#C8D7FF'
      />
      <path
        d='M46.4929 37.5439C45.7659 37.5439 45.0476 37.8171 44.4945 38.2905C43.9413 37.817 43.223 37.5439 42.4959 37.5439C40.8784 37.5439 39.6113 38.8082 39.6113 40.4221C39.6113 42.373 40.9727 43.5654 43.0333 45.3703C43.2094 45.5246 43.3912 45.6838 43.5782 45.8489C43.5795 45.85 43.5808 45.8511 43.5821 45.8523L44.2043 46.3885C44.2876 46.4603 44.3911 46.4963 44.4945 46.4963C44.598 46.4963 44.7014 46.4603 44.7848 46.3885L45.4069 45.8522C45.4082 45.8511 45.4094 45.85 45.4107 45.8489C46.6423 44.7621 47.5657 43.9301 48.2338 43.1187C49.014 42.171 49.3775 41.3141 49.3775 40.4221C49.3775 38.8082 48.1104 37.5439 46.4929 37.5439Z'
        fill='#C8D7FF'
      />
      <mask id='path-7-inside-1_17_53115' fill='white'>
        <path d='M44.0934 30.5114C44.0934 30.2338 43.8682 30.0079 43.5907 30.0176C40.1177 30.1388 36.7988 31.5129 34.2533 33.8953C31.7078 36.2778 30.1173 39.4986 29.7668 42.9561C29.7388 43.2323 29.9494 43.4719 30.2264 43.4902C30.5033 43.5086 30.7418 43.2987 30.7705 43.0226C31.1037 39.8197 32.581 36.8374 34.9402 34.6293C37.2994 32.4211 40.3729 31.1442 43.5908 31.0235C43.8682 31.013 44.0934 30.789 44.0934 30.5114Z' />
      </mask>
      <path
        d='M44.0934 30.5114C44.0934 30.2338 43.8682 30.0079 43.5907 30.0176C40.1177 30.1388 36.7988 31.5129 34.2533 33.8953C31.7078 36.2778 30.1173 39.4986 29.7668 42.9561C29.7388 43.2323 29.9494 43.4719 30.2264 43.4902C30.5033 43.5086 30.7418 43.2987 30.7705 43.0226C31.1037 39.8197 32.581 36.8374 34.9402 34.6293C37.2994 32.4211 40.3729 31.1442 43.5908 31.0235C43.8682 31.013 44.0934 30.789 44.0934 30.5114Z'
        fill='#C4C4C4'
        stroke='#4069D0'
        stroke-width='2'
        mask='url(#path-7-inside-1_17_53115)'
      />
      <rect
        x='63.701'
        y='72.4736'
        width='9.39927'
        height='26.6642'
        rx='4.69964'
        transform='rotate(-45 63.701 72.4736)'
        fill='#C2D4FF'
        stroke='#4069D0'
        stroke-width='2'
      />
      <rect
        x='73.7792'
        y='74.5518'
        width='0.600685'
        height='9.47246'
        rx='0.300342'
        transform='rotate(-45 73.7792 74.5518)'
        fill='white'
        stroke='#4069D0'
        stroke-width='0.600685'
      />
      <rect
        x='63.8003'
        y='66.3652'
        width='0.616606'
        height='4.31624'
        transform='rotate(-45 63.8003 66.3652)'
        fill='#C4C4C4'
        stroke='#4069D0'
        stroke-width='0.616606'
      />
      <rect
        x='76.7624'
        y='41.612'
        width='11.7155'
        height='0.616606'
        rx='0.308303'
        fill='#C4C4C4'
        stroke='#4069D0'
        stroke-width='0.616606'
      />
      <rect
        x='0.509475'
        y='51.8122'
        width='11.7155'
        height='0.616606'
        rx='0.308303'
        fill='#C4C4C4'
        stroke='#4069D0'
        stroke-width='0.616606'
      />
      <rect
        x='91.5593'
        y='41.612'
        width='5.54945'
        height='0.616606'
        rx='0.308303'
        fill='#C4C4C4'
        stroke='#4069D0'
        stroke-width='0.616606'
      />
      <rect
        x='79.8425'
        y='46.1355'
        width='5.54945'
        height='0.616606'
        rx='0.308303'
        fill='#C4C4C4'
        stroke='#4069D0'
        stroke-width='0.616606'
      />
      <rect
        x='3.59346'
        y='56.3356'
        width='5.54945'
        height='0.616606'
        rx='0.308303'
        fill='#C4C4C4'
        stroke='#4069D0'
        stroke-width='0.616606'
      />
    </svg>
  );
};
