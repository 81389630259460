import { Box, TextField } from '@material-ui/core';
import { CustomText } from 'common/Reusables';
import { useHistory } from 'react-router-dom';
import { DebounceInput } from 'react-debounce-input';
import { publicGetMethod } from 'hooks/queries/getHook';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import Loader from 'components/Loader/Loader';
import RecruitStylesheet from 'Recruit/Layout/RecruitStylesheet';
import styled from 'styled-components';
import Empty from 'Account/Resuseable/Empty';

const RecruiterPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data, isLoading } = useQuery(
    [
      'company-overview',
      {
        route: `/recruits/${id}/jobpost/home/`,
        params: {}
      }
    ],
    publicGetMethod
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <RecruitStylesheet>
      <RecruiterPage.Wrapper>
        <div className='header d-flex align-items-center justify-content-center pb-4'>
          <div className='d-flex align-items-center gap-3'>
            {data.business.logo ? (
              <img
                src={data.business.logo}
                height='30px'
                width='30px'
                style={{ borderRadius: '10px' }}
                alt='business-logo'
              />
            ) : (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='36'
                height='36'
                viewBox='0 0 36 36'
                fill='none'
              >
                <rect width='36' height='36' rx='10' fill='#D6DFF5' />
                <rect
                  x='14.6855'
                  y='15.6846'
                  width='5.63063'
                  height='5.63063'
                  fill='#4069D0'
                />
                <path
                  d='M12.8828 6H22.6801L29.9999 13.4324H12.8828V6Z'
                  fill='#4069D0'
                />
                <path
                  d='M30 13.8828L30 23.6801L22.5676 30.9999L22.5676 13.8828L30 13.8828Z'
                  fill='#4069D0'
                />
                <path
                  d='M22.1172 31L12.3199 31L5.00007 23.5676L22.1172 23.5676L22.1172 31Z'
                  fill='#4069D0'
                />
              </svg>
            )}
            <CustomText fontSize='20px' color='#171717' fontWeight='600'>
              {data?.business.business_name || 'Daren Consulting'}
            </CustomText>
          </div>
        </div>
        <div className='content pb-4'>
          <Box margin='0 auto'>
            <CustomText
              fontSize='22px'
              color='#333E49'
              fontWeight='600'
              margin='2rem 0 1rem'
            >
              About {data?.business.business_name}
            </CustomText>
            <CustomText
              fontSize='16px'
              color='#545454'
              fontWeight='400'
              lineHeight='192.861%'
            >
              {data?.overview ||
                `Bizedge is a nationwide virtual accounting firm merging the
                convenience of technology with proactive support from a team of
                accounting professionals. We provide personalized and dedicated
                accounting services for small-business owners at an affordable
                price. McDonalds is a nationwide virtual accounting firm merging
                the convenience of technology with proactive support from a team
                of accounting professionals. We provide personalized and dedicated
                accounting services for small-business owners at an affordable
                price.`}
            </CustomText>
            <Box mt='3rem'>
              <div className='d-flex align-items-center justify-content-between mobile_block mb-5'>
                <CustomText fontSize='22px' color='#333E49' fontWeight='600'>
                  Current Job Openings ({data?.results.length})
                </CustomText>
                <div className='d-flex align-items-center gap-4'>
                  <DebounceInput
                    className='debounce_input search'
                    placeholder='Search for applicants'
                    debounceTimeout={2000}
                    // value={search}
                    // onChange={(e) => handleSearch(e)}
                  />
                  <TextField
                    className='xs_hide sm_hide'
                    variant='outlined'
                    placeholder='Filter by'
                    size='small'
                    color='secondary'
                  />
                </div>
              </div>
              {/* <CustomText
                fontSize='16px'
                color='#545454'
                fontWeight='400'
                margin='1rem 0'
              >
                Engineering (3)
              </CustomText> */}
              {data.results.length >= 1 &&
                data.results.map((job, count) => (
                  <Box
                    p='1rem'
                    borderRadius='10px'
                    border='1px solid #DFDFDF'
                    boxShadow='0px 2px 4px 0px rgba(96, 97, 112, 0.16)'
                    className='cursor'
                    mb='1rem'
                    key={count}
                    onClick={() =>
                      history.push(
                        `/recruit/${id}?job=detail&id=${job.id}&title=${job.title_slug}`
                      )
                    }
                  >
                    <div className='d-flex justify-content-between'>
                      <div>
                        <CustomText
                          fontSize='20px'
                          color='#333E49'
                          fontWeight='600'
                        >
                          {job?.title}
                        </CustomText>
                        <CustomText
                          fontSize='14px'
                          color='#545454'
                          fontWeight='400'
                        >
                          {job?.city} {job?.state} . {job?.department} .{' '}
                          {job?.employmenttype.title}
                        </CustomText>
                      </div>
                      <CustomText
                        fontSize='14px'
                        color='#878787'
                        fontWeight='600'
                        lineHeight='193%'
                      >
                        Posted {format(new Date(job.created_at), 'MMM dd yyyy')}
                      </CustomText>
                    </div>
                  </Box>
                ))}
              {data.count === 0 && (
                <Empty props={{ label: 'Job no dey ooo' }} />
              )}
            </Box>
          </Box>
        </div>
        <div className='d-flex justify-content-center align-items-center pt-4 pb-4 footer'>
          <CustomText
            fontSize='16px'
            color='#545454'
            fontWeight='400'
            lineHeight='192.861%'
          >
            Powered by <span>Bizedge</span>
          </CustomText>
        </div>
      </RecruiterPage.Wrapper>
    </RecruitStylesheet>
  );
};

export default RecruiterPage;

RecruiterPage.Wrapper = styled(Box)`
  .header {
    height: 70px;
    border-bottom: 1px solid #e1e1e1;
  }
  .content {
    height: calc(100vh - 130px);
    overflow-y: scroll;
    margin: 0 auto;
    width: 70%;
    @media (max-width: 575.98px) {
      width: 95%;
    }
  }
  .footer {
    background: #ffffff;
    position: absolute;
    height: 50px;
    width: 100vw;
    bottom: 0;
    left: 0;

    span {
      font-weight: 600;
    }
  }
`;
