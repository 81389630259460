import useDebouceSearch from 'hooks/useDebouceSearch';
import { useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { asset_major_types } from './data';

const useFilter = () => {
  const routeState = useLocation();
  const [asset_vehicle_type, setAssetVehicleType] = useState(() =>
    routeState.state?.isVehicle
      ? asset_major_types[2].value
      : asset_major_types[0].value
  );
  const [asset_type, setAssetType] = useState(routeState.state?.id ?? routeState.state?.search ?? null);
  const [location, setLocation] = useState();
  const [search, setSearch, inputValue] = useDebouceSearch(1000);
  const [status, setStatus] = useState(null);
  const [page, setPage] = useState(0);
  const rowPerPage = 10;
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  return {
    asset_type,
    setAssetType,
    location,
    setLocation,
    search,
    inputValue,
    handleSearch,
    status,
    setStatus,
    page,
    setPage,
    rowPerPage,
    asset_vehicle_type,
    setAssetVehicleType
  };
};

export default useFilter;
