import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

function VehicleStatus({ width, name, statuses = [], formik, error }) {
  return (
    <FormControl
      variant='outlined'
      size={isWidthDown('lg', width) ? 'small' : 'medium'}
      color='secondary'
      fullWidth
      error={error}
    >
      {/* <InputLabel id='vehicle-status'>Status</InputLabel> */}
      {/* <Select
        labelId='vehicle-status'
        label='Make'
        size='small'
        name={name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      >
        {statuses.map((status) => (
          <MenuItem value={status.value}>{status.display_name}</MenuItem>
        ))}
      </Select> */}
      <TextField
        id='select'
        name={name}
        variant='outlined'
        color='secondary'
        size='small'
        label='Status'
        value={formik.values[name]}
        onChange={formik.handleChange}
        select
      >
        {/* <MenuItem value="10">Ten</MenuItem>
  <MenuItem value="20">Twenty</MenuItem> */}
        {statuses.map((status) => (
          <MenuItem value={status.value}>{status.display_name}</MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
}

export default withWidth()(VehicleStatus);
