import {
  earnings_bg,
  income_bg,
  outgoing_bg,
  receivables_bg
} from 'common/images/image-component';
import CustomMenu from 'components/CustomMenu/CustomeMenu';
import Text from 'components/Typography/Text';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

const bgColors = {
  offGrey: earnings_bg,
  offYellow: income_bg,
  offBlue: outgoing_bg,
  offGreen: receivables_bg
};
 
const StatCard = ({
  title,
  titleProps = {},
  valueProps = {},
  value,
  bgColor,
  style = {},
  menu,
  nairaSign,
  onClick = () => {}
}) => {
  return (
    <StatCardWrapper
      onClick={onClick}
      style={{
        backgroundImage: `url(${bgColors[bgColor]})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'right',
        height: style.height || 120,
        borderRadius: '12.8635px',
        ...style
      }}
    >
      <div className='title-block'>
        <Text {...titleProps}>{title}</Text>
        {menu ? (
          <CustomMenu
            stopPropagation
            divideAll
            options={menu}
            style={{ width: 150 }}
          />
        ) : null}
      </div>
      <div>
        <NumberFormat
          value={value}
          displayType={'text'}
          decimalScale={0}
          fixedDecimalScale={true}
          thousandSeparator={true}
          prefix={''}
          // renderText={(value, props) => <div {...props}> {value}</div>}
          renderText={(value, props) => (
            <Text align='right' bold size='xl' {...valueProps} {...props}>
              {!nairaSign ? '' : '₦'}
              {value}
            </Text>
          )}
        />
      </div>
    </StatCardWrapper>
  );
};

export default StatCard;

const StatCardWrapper = styled.div`
  min-width: 220px;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
