import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  Header: {
    ...theme.mixins.makeResponsive({ theme, fontSize: 22, object: true }),
    fontWeight: 700
  },
  boxWrapper: {
    marginTop: '2%',
    '& .MuiTypography-h2': {
      color: '#737373'
    }
  },
  categoryWrapper: {
    margin: '3% 0px'
  },
  overviewWrapper: {
    marginTop: '1%'
  },
  assetType: {
    '& .MuiButton-outlined': {
      '& .MuiSvgIcon-root': {
        '& > *': {
          color: '#545454'
        }
      },
      '& .MuiButton-label': {
        color: '#737373'
      }
    }
  },
  popover: {
    '&.MuiPopover-root': {
      // border: '1px solid red'
      //whatever you want
      // position: 'absolute',
      // top: '20px'
    },
    '& .MuiPopover-paper': {
      position: 'absolute',
      top: '284px !important',
      left: '600px !important',
      background: theme.palette.secondary.main,
      color: 'white',
      padding: '15px',
      width: '30%',
      '& .MuiTypography-subtitle2': {
        fontSize: '14px'
      }
    }
  },
  modalWrapper: {
    '& .MuiTypography-subtitle1': {
      color: '#545454',
      fontWeight: 700,
      fontStyle: 'normal',
      fontSize: '30px',
      textAlign: 'center'
    },
    '& .MuiTypography-body2': {
      lineHeight: '1.8em'
    },
    '& .btn_container': {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  body: {
    margin: '2em 0px',
    textAlign: 'center',
    lineHeight: '1.6em'
  },
  headline: {
    margin: '1.5em 0px'
  }
}));

export const SearchResultWrapper = styled.div`
  position: relative;
  .asset_dashboard_search_field {
    position: relative;
  }

  .asset_dashboard_search_result {
    position: absolute;
    width: 100%;
    left: 1rem;
    overflow-y: auto;
    max-height: 477px;
    z-index: 400;
    border-radius: 5px;
    border: 1px solid #e1e1e1;
    background: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 1rem;
    .empty {
      padding: 1.5rem;
    }
    ul {
      display: flex;
      flex-direction: column;
      /* gap: 13px; */
      li {
        display: flex;
        cursor: pointer;
        gap: 5px;
        padding-top: 13px;
        flex-direction: column;
        padding-inline: 10px;

        &:not(:last-child) {
          padding-bottom: 1rem;
          border-bottom: 1px solid #e1e1e1;
          transition: background-color 100ms ease-in-out;
          &:hover {
            background-color: #e1e1e1;
          }
        }
        &:last-child {
          button {
            justify-content: flex-start;
            padding: 0.5rem;
          }
        }
      }
    }
  }
`;
