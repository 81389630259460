import { TextField } from '@mui/material';
import CircularLoader from 'components/CircularLoader';
import { sizes } from 'components/Typography/Text';
import { forwardRef } from 'react';
import styled from 'styled-components';

const roundedStyles = {
  md: '8px',
  sm: '6px'
};

const CustomeInputBox = (
  {
    name,
    label,
    info,
    onChange,
    rounded,
    isLoading,
    placeholder,
    colorvaraint,
    textSize,
    type = 'text',
    value,
    max,
    min,
    InputLabelProps,
    removeNumberIcon = false,
    className,
    InputProps,
    defaultNumberFormatter = false,
    ...props
  },
  ref
) => {
  let actualProps = {
    value,
    type,
    className: `${removeNumberIcon ? 'number_input_rm_icon' : ''} ${className}`,
    InputProps,
    InputLabelProps: {
      ...InputLabelProps,
      //deal with date shrink
      shrink:
        type === 'number'
          ? value === 0 ||
            !!value ||
            !!props?.inputProps?.value ||
            !!InputProps?.startAdornment
          : !!value ||
            !!props?.inputProps?.value ||
            !!InputProps?.startAdornment

      // shrink:
      //   type === 'number' && value === 0
      //     ? true
      //     : !!value ||
      //       !!props?.inputProps?.value ||
      //       !!InputProps?.startAdornment
    },

    ...props
  };

  if (type === 'number') {
    const derivedValue =
      value < min ? (min === 0 ? '' : min) : value > max ? max : value;
    actualProps = {
      ...actualProps,
      value: value === '' ? value : derivedValue,
      type,
      min,
      max
    };
  }

  const onNumberTypeValueChange = (e) => {
    const targetValue = e.target.value;

    const isStrictlyNumbers = /^[0-9]+$/.test(targetValue);
    // if (props?.formatter) {
    //   derivedValue = props.formatter(value);
    // }
    // if (props?.parser) {
    //   derivedValue = props.parser(value);
    // }

    let derivedValue = !isStrictlyNumbers ? value : targetValue;

    if (targetValue !== '' && isStrictlyNumbers) {
      derivedValue =
        +targetValue < min
          ? min === 0
            ? ''
            : min
          : +targetValue > max
          ? max
          : +targetValue;
    }

    onChange &&
      onChange({
        ...e,
        target: { name, value: targetValue === '' ? targetValue : derivedValue }
      });
  };
  return (
    <InputWrapper>
      <CustomeInputBoxSC
        inputRef={ref}
        fullWidth
        label={label}
        name={name}
        onChange={(e) => {
          if (type === 'number') {
            onNumberTypeValueChange(e);
          } else {
            onChange && onChange(e);
          }
        }}
        rounded={rounded}
        colorvaraint={colorvaraint}
        textsize={textSize}
        {...actualProps}
        placeholder={placeholder ?? label}
      />
      {info && (
        <div
          className={`helper_texts black-family ${
            props?.error ? 'error_text' : ''
          }`}
        >
          {info}
        </div>
      )}
      {isLoading ? (
        <div className='loader'>
          <CircularLoader color='blue' size={'14px'} show={isLoading} />
        </div>
      ) : null}
    </InputWrapper>
  );
};

export default forwardRef(CustomeInputBox);

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  font-family: var(--black-family) !important;
  & .helper_texts {
    font-size: 14px;
    color: #545454;
    margin-bottom: 1rem;
    &.error_text {
      color: var(--input-field-error);
    }
  }
  .loader {
    position: absolute;
    left: 90%;
  }
`;
const getBorderColor = (props) => {
  return props?.borderColor ?? '#a8a8a8';
};

const CustomeInputBoxSC = styled(TextField)`
  && {
    font-family: var(--black-family) !important;
    & label.Mui-focused {
      color: #a8a8a8 !important;
      font-family: var(--black-family) !important;
      font-size: ${(props) => props?.textsize ?? 'initial'} !important;
    }
    label.MuiInputLabel-outlined {
      font-family: var(--black-family) !important;
      font-size: ${(props) =>
        !props?.textsize
          ? 'initial'
          : (sizes[props?.textsize] ?? props?.textsize) + ' !important'};
    }
    label {
      font-family: var(--black-family) !important;
      font-size: ${(props) =>
        !props?.textsize
          ? 'initial'
          : (sizes[props?.textsize] ?? props?.textsize) + ' !important'};
    }
    &.MuiTextField-root {
      background-color: ${(props) =>
        props?.colorvaraint ?? 'initial'} !important;
      border-radius: ${(props) =>
        props?.rounded ? roundedStyles[props?.rounded] : '8px'} !important;
    }
    input::placeholder {
      font-size: ${(props) =>
        !props?.textsize
          ? 'initial'
          : (sizes[props?.textsize] ?? props?.textsize) + ' !important'};
    }
    input {
      font-size: ${(props) =>
        !props?.textsize
          ? 'initial'
          : (sizes[props?.textsize] ?? props?.textsize) + ' !important'};
    }

    .MuiInputBase-root,
    .MuiOutlinedInput-root {
      font-family: var(--black-family) !important;
      border-radius: ${(props) =>
        props?.rounded ? roundedStyles[props?.rounded] : '8px'} !important;
      border-color: ${getBorderColor} !important;
      background-color: ${(props) =>
        props?.colorvaraint ?? 'initial'} !important;
      height: ${(props) => props?.height ?? 'initial'} !important;

      font-size: ${(props) =>
        !props?.textsize
          ? 'initial'
          : (sizes[props?.textsize] ?? props?.textsize) + ' !important'};

      &:hover,
      &:focus {
        border-color: ${getBorderColor} !important;
        font-family: var(--black-family) !important;
      }

      &.Mui-focused {
        border-color: ${getBorderColor} !important;
        font-family: var(--black-family) !important;
        font-size: ${(props) =>
          !props?.textsize
            ? 'initial'
            : (sizes[props?.textsize] ?? props?.textsize) + ' !important'};
      }
      // standard input variant style starts
      &.css-l4u8b9-MuiInputBase-root-MuiInput-root {
        border-radius: ${(props) =>
          props?.rounded
            ? roundedStyles[props?.rounded]
            : '8px 8px 0px 0px'} !important;
        &::before {
          /* border-bottom: 1px solid rgba(168, 168, 168, 0.5) !important; */
          border-bottom: 1px solid ${getBorderColor} !important;
        }
        &:hover:not(.Mui-disabled, .Mui-error)::before {
          border-bottom: 1px solid ${getBorderColor} !important;
        }
        &::after {
          border-bottom: 0px solid ${getBorderColor} !important;
        }
        &.Mui-focused::after {
          transform: scaleX(0) translateX(0);
        }
      }
      // ends here
      &.Mui-focused fieldset {
        border-color: white;
        border-color: #e1e1e1 !important;
        font-family: var(--black-family) !important;
        font-size: ${(props) =>
          !props?.textsize
            ? 'initial'
            : (sizes[props?.textsize] ?? props?.textsize) + ' !important'};
      }

      & .MuiFormLabel-root-MuiInputLabel-root {
        font-family: var(--black-family) !important;
      }

      & fieldset {
        border-width: ${(props) => props.borderWidth ?? '1px'} !important;
        border-color: ${getBorderColor} !important;
      }
      &:hover fieldset {
        border-color: ${getBorderColor} !important;
      }
    }
  }
`;
