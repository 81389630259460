import { useAssetMaintenance } from 'Assets/queries';
import Table, { TableEmpty } from 'components/CustomTable/Table';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import { BLANK } from 'constant';
import dayjs from 'dayjs';
import NumberFormat from 'react-number-format';
import { capitalize } from 'utilities/Capitalized';
// import SearchFieldA from 'components/FIlters/SearchField';

const columns = [
  'Maintenance',
  'Name',
  'Last Date',
  'Cost',
  'Next Due Date',
  'Status'
  // 'Frequency'
];
const MaintenanceTable = ({ assetId }) => {
  const { data, isLoading, isError } = useAssetMaintenance({ assetId });

  const isEmpty = data?.length < 1;
  return (
    <Table>
      {isEmpty ? (
        <TableEmpty>
          <Title>You have no maintenance yet</Title>
        </TableEmpty>
      ) : (
        <>
          <Table.Head column={columns} />
          <Table.Body
            isLoading={isLoading}
            isError={isError}
            data={data || []}
            renderRow={(row) => {
              return (
                <>
                  <Text size='sm'>{capitalize(row?.type ?? BLANK)}</Text>
                  <Text size='sm'>{capitalize(row?.asset_name ?? BLANK)}</Text>
                  <Text size='sm'>
                    {row?.date
                      ? dayjs(row?.date).format('Do MMM, YYYY')
                      : BLANK}
                  </Text>
                  <NumberFormat
                    displayType='text'
                    value={row?.cost}
                    thousandSeparator=','
                    renderText={(formatedValue) => (
                      <Text size='sm'>{formatedValue}</Text>
                    )}
                  />
                  <Text size='sm'>
                    {row?.due_date
                      ? dayjs(row?.due_date).format('Do MMM, YYYY')
                      : BLANK}
                  </Text>
                  <Text size='sm'>{row?.status ?? BLANK}</Text>
                </>
              );
            }}
          />
        </>
      )}
    </Table>
  );
};

export default MaintenanceTable;
