import { Grid, Paper } from '@mui/material';
import Text from 'components/Typography/Text';
import { useContext } from 'react';
import { TableContext } from './Table';
const TableHead = ({ column, spacing, ...props }) => {
  const context = useContext(TableContext);
  const headData = context?.column ?? column ?? [];
  const space =
    context?.spacing ??
    spacing ??
    Array(headData?.length).fill(12 / headData?.length);

  return (
    <Paper
      variant='outlined'
      sx={{
        px: context?.bordered ? 0 : 2,
        py: context?.bordered ? 0 : 1.5,
        backgroundColor: props?.bgColor ?? '#FAFAFA',
        backgroundOpacity: 0.2,
        borderRadius: 0,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        overflow: 'hidden',
        borderColor: '#E1E1E1',
        borderWidth: 1
      }}
    >
      <Grid container alignItems={'center'}>
        {headData?.map((item, index) => (
          <Grid
            key={item.label}
            item
            xs={space[index]}
            sx={{
              borderLeft:
                index > 0 && context?.bordered ? '1px solid #A8A8A8' : 'none',
              pl: index === 0 ? 2 : '.8rem',
              py: context?.bordered ? 1.5 : 0
            }}
          >
            <Text
              size='md'
              textColor='#545454'
              bold
              sx={item?.style ? item.style : {}}
              {...(item?.textProps ?? {})}
            >
              {item.label ?? item}
            </Text>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default TableHead;
