import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  Wrapper: {
    // background: 'white',
    // borderRadius: '11px',
    // boxShadow: '0px 4px 10px rgba(214, 214, 214, 0.3)',
    padding: '20px 30px 20px 30px',
    height: '285px',
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 30%)',
    borderRadius: '5px',

    '& .MuiTypography-h4': {
      // ...theme.mixins.makeResponsive({ theme, fontSize: 20, object: true }),
      // fontWeight: 700,
      // color: '#7F7F7F'
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20.25px',
      lineHeight: '23px',
      color: '#545454'
    }
  },
  info: {
    '& .MuiTypography-body1': {
      color: '#878787',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '14.22px',
      lineHeight: '16px'
    },
    '& .MuiTypography-subtitle2': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '13px',
      color: '#878787'
    }
  }
}));

export default useStyles;
