import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Select as AntdSelect } from 'antd';
import { forwardRef } from 'react';
import styled from 'styled-components';

const SelectField = ({ isLoading, options, ...props }, ref) => {
  return (
    <FormControl fullWidth size={props?.size}>
      {props?.label && (
        <InputLabel id={`custome-select-${props?.label || props?.placeholder}`}>
          {props.label}
        </InputLabel>
      )}
      <StyledSelect
        labelId={`custome-select-${props?.label || props?.placeholder}`}
        id='demo-simple-select'
        // IconComponent={<ExpandMoreIcon />}
        ref={ref}
        {...props}
        InputProps={{
          startAdornment: isLoading ? (
            <InputAdornment position='start'>
              <CircularProgress size={10} />
            </InputAdornment>
          ) : null,
          endAdornment: (
            <InputAdornment position='end'>
              <ExpandMoreIcon />
            </InputAdornment>
          )
        }}
      >
        {options?.map((option) => (
          <MenuItem value={option.value}>{option.label}</MenuItem>
        ))}
      </StyledSelect>
      {props?.value && (
        <IconButton onClick>
          <ClearIcon />
        </IconButton>
      )}
    </FormControl>
  );
};

export default forwardRef(SelectField);

const StyledSelect = styled(Select)`
  && {
    font-family: var(--black-family) !important;
    border-radius: 12px;
  }
`;

export const AntSelect = (props) => {
  return <StyledAntSelect size='large' allowClear {...props} />;
};

const StyledAntSelect = styled(AntdSelect)`
  && {
    border-radius: 4px;
    min-width: 120px;
    font-family: var(--black-family) !important;
    & .ant-select-selector {
      border-radius: ${(props) => props.rounded ?? '8px'};
      border-color: rgba(168, 168, 168, 0.7) !important;
      &:hover {
        border-color: black !important;
      }
      &:focus {
        border-color: black !important;
        border-width: 2px !important;
      }
    }
    .ant-select-focus .ant-select-selector {
      border-color: black !important;
      &:hover {
        border-color: black !important;
      }
      &:focus {
        border-color: black !important;
      }
    }
    ${(props) => props.css ?? ''}
  }
`;
