import ReminderForm from 'Assets/Shared/ReminderForm';
import { useCreateRenewal } from 'Assets/queries';
import { CustomButton as Button } from 'components/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from 'components/Dialog';
import { CustomTextField, DatePicker, DragAndDrop } from 'components/Inputs';
import Group from 'components/StyledComponents/ButtonGroup';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import { useFormik } from 'formik';
import { capitalize } from 'utilities/Capitalized';
import { inputPropsSetterConfig } from 'utilities/formHelpers';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  type: yup.string().required('field is required'),
  set_reminder: yup.string().required('field is required'),
  date: yup.date().required('field is required')
});

const RenewalDetailsModal = ({ open, toggle, data = {} }) => {
  const mutation = useCreateRenewal({ editId: data?.asset });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      document: {
        name: data?.name,
        content_type: 'asset',
        object_id: data?.id
      },
      renewal_type: '',
      date_issued: null,
      expiry_date: null,
      mileage: '',
      reminder_typer: {
        asset: data?.asset,
        set_reminder: '',
        date: null,
        type: ''
      }
    },
    onSubmit(values) {
      mutation.mutate(values);
    }
  });
  const setInputProps = inputPropsSetterConfig(formik);

  return (
    <Dialog open={open} fullWidth maxWidth='xs' onClose={toggle}>
      <DialogTitle px={4} iconFlex onClose={toggle}>
        <span>
          <Title>Enter Renewal Details</Title>
          <Text size='xs'>
            Enter details for the new document to be uploaded
          </Text>
        </span>
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
          <CustomTextField
            size='small'
            value={capitalize(data?.type?.split('_').join(' '))}
          />
          <div>
            <DatePicker
              inputProps={{ fullWidth: true, size: 'small' }}
              size='small'
              fullWidth
              label='Date of issue'
              {...setInputProps('date_issued', true)}
            />
          </div>
          <DatePicker
            inputProps={{ fullWidth: true, size: 'small' }}
            size='small'
            value={null}
            fullWidth
            label='Expiry Date'
            {...setInputProps('expiry_date', true)}
          />
          <CustomTextField
            size='small'
            label='Milage (optioal)'
            {...setInputProps('mileage')}
          />
          <DragAndDrop
            component={(onClick) => <DndLabel onClick={onClick} />}
            onUpload={(files) => {}}
          />
          <hr />
          <ReminderForm />
        </div>
      </DialogContent>
      <DialogActions>
        <Group fullWidth>
          <Button size='sm' fullWidth variant='offwhite'>
            Cancel
          </Button>
          <Button
            disabled={mutation.isLoading}
            onClick={formik.submitForm}
            size='sm'
            fullWidth
            variant='contained'
          >
            Upload
          </Button>
        </Group>
      </DialogActions>
    </Dialog>
  );
};

export default RenewalDetailsModal;

const DndLabel = ({ onClick }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        padding: '1.4rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '.5rem',
        alignItems: 'center'
      }}
    >
      <Text size='sm'>Drag and Drop your asset image</Text>
      <Button size='xs' onClick={onClick} className='contained white'>
        Click here to upload document
      </Button>
    </div>
  );
};
