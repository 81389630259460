import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTypography-h2': {
      color: '#444444',
      fontWeight: 700,
      fontSize: '25px',
      fontStyle: 'normal'
    },
    '& .MuiTypography-subtitle1': {
      color: '#444444',
      fontSize: '14px',
      fontWeight: 400,
      fontStyle: 'normal',
      margin: '.4em 0px .9em'
    },
    '& .MuiButton-contained': {
      marginTop: '1em'
    }
  },

  success: {
    width: '50%',
    margin: '10% auto',
    textAlign: 'center'
  },
  failure: {
    width: '50%',
    margin: '10% auto',
    textAlign: 'center'
  },
  serverError: {
    background: '#FFEBEA',
    width: '70%',
    borderRadius: '3px',
    padding: '1% 2%',
    margin: '10px auto',
    textAlign: 'center',
    '& .MuiTypography-subtitle2': {
      fontSize: '14px',
      color: theme.palette.primary.main,
      opacity: 1
    }
  },
  logo: {
    textAlign: 'center'
  },
  forgetLink: {
    color: '#2898a4',
    fontWeight: 500,
    fontSize: '16.3221px',
    lineHeight: '18px'
  }
}));
