import { Box } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

const SingleModule = () => {
    const  { module } = useParams()
  return (
    <SingleModule.Wrapper>
      i am styled baby\h\ {module}
      <h2>asas</h2>
    </SingleModule.Wrapper>
  );
};

export default SingleModule;

SingleModule.Wrapper = styled(Box);
