import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

function VehicleMake({ width, makes = [], name, formik, error }) {
  return (
    <FormControl
      error={error}
      multiline
      variant='outlined'
      size={isWidthDown('lg', width) ? 'small' : 'medium'}
      color='secondary'
      fullWidth
    >
      <InputLabel id='vehicle-make'>Select Vehicle's Make</InputLabel>
      <Select
        labelId='vehicle-make'
        label='Make'
        size='small'
        value={formik.values[name]}
        name={name}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
      >
        <MenuItem value={null}>Choose a make</MenuItem>
        {makes.map((make) => (
          <MenuItem key={make.id} value={make.id}>
            {make.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

VehicleMake.propTypes = {
  width: PropTypes.number.isRequired,
  makes: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired
};

export default withWidth()(VehicleMake);
