import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import { useQuery } from 'react-query';
import { Cell, Pie, PieChart } from 'recharts';
import { v4 as uuid } from 'uuid';
import { getAssetBreakdown } from '../../api/asset';
import Wrapper from '../Shared/Wrapper';

const colors = {
  Assigned: '#2898A4',
  Unassigned: '#878787',
  'In repair': '#F8B636',
  Damaged: '#FF7372',
  Missing: '#56CCF2',
  Sold: '#A9FF8B',
  Discarded: '#4069D0'
};
const wheelColors = {
  Assigned: '#E0EEF0',
  Missing: '#BCEFFF',
  Discarded: '#C8D7FF',
  Unassigned: '#CCC9C9',
  'In repair': '#FCE2AF',
  Sold: '#DCFFD0',
  Condemned: '#FFE7E7'
};

const dafaultValue = [{ name: 'default', value: 100 }];

function AssetStatus() {
  const { data, isFetching } = useQuery(['asset-breakdown'], getAssetBreakdown);
  const parseData = (data = {}) => {
    return Object.keys(data).map((key) => ({
      label: key,
      value: data[key]
    }));
  };

  const response = parseData(data);
  let getTotal;

  if (response.length !== 0) {
    getTotal = response[0].value.map((dt) => dt.value).reduce((a, b) => a + b);
  }

  if (isFetching) {
    return (
      <Box style={{ height: '100%' }}>
        <Skeleton
          variant='rect'
          height={285}
          style={{ borderRadius: '10px' }}
        />
      </Box>
    );
  }

  if (getTotal === 0) {
    return (
      <Wrapper>
        <Title color='#878787' order={6}>
          Assets by Status
        </Title>
        <div style={{ display: 'flex', marginTop: '2rem' }}>
          <div
            style={{
              flexGrow: 1
            }}
          >
            <PieChart width={180} height={170} onMouseEnter={() => {}}>
              <Pie
                data={dafaultValue}
                innerRadius={50}
                outerRadius={80}
                fill='#A8A8A8'
                // fill='#A8A8A8'
                paddingAngle={0}
              ></Pie>
            </PieChart>
          </div>

          <div style={{ marginTop: '0px' }}>
            <Legends
              values={[
                { label: 'Assigned', value: 0 },
                { label: 'Unassigned', value: 0 },
                { label: 'In repair', value: 0 },
                { label: 'Damaged', value: 0 },
                { label: 'Sold', value: 0 },
                { label: 'Missing', value: 0 },
                { label: 'Discarded', value: 0 }
              ]}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  const valueArr = response[0]?.value || [];

  return (
    <Wrapper>
      <Title color='#878787' order={6}>
        Assets by Status
      </Title>
      <div style={{ display: 'flex', marginTop: '2rem' }}>
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <PieChart width={180} height={170} onMouseEnter={() => {}}>
            <Pie
              data={response[0].value}
              innerRadius={50}
              outerRadius={80}
              fill='#8884d8'
              paddingAngle={-1}
              dataKey='value'
            >
              {response[0].value.map(({ label }) => (
                <Cell key={uuid()} fill={wheelColors[label]} />
              ))}
            </Pie>
          </PieChart>
        </div>

        <div style={{ marginTop: '0px' }}>
          <Legends
            values={[
              {
                label: 'Assigned',
                value:
                  valueArr.find((el) => el.label === 'Assigned')?.value ?? 0
              },
              {
                label: 'Unassigned',
                value:
                  valueArr.find((el) => el.label === 'Unassigned')?.value ?? 0
              },
              {
                label: 'In repair',
                value:
                  valueArr.find((el) => el.label === 'In repair')?.value ?? 0
              },
              {
                label: 'Damaged',
                value:
                  valueArr.find((el) => el.label === 'Condemned')?.value ?? 0
              },
              {
                label: 'Sold',
                value: valueArr.find((el) => el.label === 'Sold')?.value ?? 0
              },
              {
                label: 'Missing',
                value: valueArr.find((el) => el.label === 'Missing')?.value ?? 0
              },
              {
                label: 'Discarded',
                value:
                  valueArr.find((el) => el.label === 'Discarded')?.value ?? 0
              }
            ]}
          />
        </div>
      </div>
    </Wrapper>
  );
}

export default AssetStatus;

const Legends = ({ values = [] }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
      {values.map((legend) => (
        <Legend key={legend?.label} {...legend} />
      ))}
    </div>
  );
};

const Legend = ({ label, value }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '.5rem'
      }}
    >
      <span
        style={{
          display: 'inline-block',
          backgroundColor: colors[label],
          width: 15,
          height: 15,
          borderRadius: '1.64486px'
        }}
      ></span>
      <strong>{value}</strong>
      <Text size='xs'>{label}</Text>
    </div>
  );
};
