import { CustomButton } from 'components/Button';
import CircularLoader from 'components/CircularLoader';
import Table from 'components/CustomTable/Table';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from 'components/Dialog';
import { DragAndDrop } from 'components/Inputs';
import Group from 'components/StyledComponents/ButtonGroup';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import { useState } from 'react';
import { convertCSVtoJSON, headerBaseOnIndex } from './helpers.js';
import { useImportAssets } from 'Assets/queries';
import { Divider, Stack } from '@mui/material';

const AssetImportModal = ({ open, toggle, refetch }) => {
  const [data, setData] = useState([]);
  const [header, setHeader] = useState([]);
  const [emptyCells, setEmptyCells] = useState(false);
  const [serverErr, setServerErr] = useState(null);

  const mutation = useImportAssets({
    onSuccess(res) {
      toggle();
      if (refetch) {
        refetch();
      }
    },
    onError(err) {
      // console.log(err?.response?.data, 'cell and row error');
      const errData = err?.response?.data;
      setEmptyCells(true);
      if (errData?.length > 0) {
        const errorRowCell = errData.map(
          (el) =>
            `${el.row} ${
              el.column === 'type'
                ? 2
                : headerBaseOnIndex.findIndex((head) => head === el.column)
            }`
        );
        setServerErr(errorRowCell);
      }
    }
  });

  const handleSubmit = () => {
    setServerErr(null);
    if (!emptyCells && data.length > 0) {
      const payload = data?.map((datum) => {
        const values = Object.entries(datum).reduce((acc, [key, value]) => {
          if (value === '') {
            return acc;
          }
          acc[key] = value;
          return acc;
        }, {});
        return values;
      });
      console.log(payload, '---> payload -->');
      mutation.mutate(payload);
    }
  };
  // console.log(serverErr, 'server Err');
  return (
    <>
      <Dialog
        open={open}
        onClose={toggle}
        fullWidth
        rounded='16px'
        maxWidth={data.length > 0 ? 'lg' : 'xs'}
        ap={'16px'}
      >
        <DialogTitle px={2} divider onClose={toggle}>
          <Title>Import </Title>
        </DialogTitle>
        <DialogContent>
          <Text color='#878787' size='sm' light mb='16px'>
            You can upload the CSV here.
          </Text>
          {data.length > 0 ? (
            <div
              style={{
                padding: '11px 15px',
                background: '#F2F2F2',
                marginTop: '11px',
                border: 10,
                borderRadius: 10
              }}
            >
              <Table
                spacing={Array(12).fill(12 / header.length ?? 12 / 11)}
                style={{ background: 'white' }}
                bordered
                minWidth={2500}
                column={header.map((el, index) => ({
                  label: el,
                  dataIndex: headerBaseOnIndex[index]
                }))}
              >
                <Table.Head
                  column={header.map((el) => ({
                    label: el
                  }))}
                />
                <Table.Body
                  marginTop='0'
                  data={data}
                  isLoading={false}
                  isError={false}
                  cellStyle={(cell, { index, rowIndex }) => {
                    const cellInd = `${rowIndex + 1} ${index}`;
                    const isCellFaulty = serverErr?.some(
                      (el) => el === cellInd
                    );
                    // console.log({ cell, cellInd, isCellFaulty }, 'cell data');
                    return isCellFaulty
                      ? { border: '3px solid #FF6666 !important' }
                      : {};
                  }}
                  renderRow={(row) => {
                    return (
                      <>
                        {Object.values(row).map((el, index) => (
                          <Text
                            style={{
                              // height: 16,
                              // textAlign: index === 4 ? 'right' : 'left',
                              paddingRight: '.5rem'
                            }}
                          >
                            {el}
                          </Text>
                        ))}
                      </>
                    );
                  }}
                />
              </Table>
            </div>
          ) : (
            <>
              <DragAndDrop
                onUpload={(file) => {
                  convertCSVtoJSON(file, {
                    setData,
                    setHeader,
                    setEmptyCells
                  });
                }}
                component={(onClick) => (
                  <DndLabel
                    onClick={() => {
                      setServerErr(null);
                      setEmptyCells(false);
                      onClick();
                    }}
                  />
                )}
              />
            </>
          )}
          <Stack spacing={'16px'} mt='16px'>
            <Divider mt={'16px'} />
            {data.length > 0 && emptyCells ? (
              <Stack direction='row' spacing={'.5rem'} alignItems='center'>
                <Text color='#FF6666' size='xs'>
                  We can’t Upload this file. There are missing information in
                  some cells. Kindly fill all cells{' '}
                </Text>
                <CustomButton
                  onClick={() => {
                    setData([]);
                  }}
                >
                  Click to here to Re-Import
                </CustomButton>
              </Stack>
            ) : // <Text color='#878787' size='xs'>
            //   Make sure the file size is below 3MB
            // </Text>
            null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Group fullWidth>
            <CustomButton
              py={'.5rem'}
              onClick={toggle}
              variant={'offwhite'}
              fullWidth
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant={'contained'}
              onClick={handleSubmit}
              disabled={data.length < 1 || emptyCells || mutation.isLoading}
              fullWidth
            >
              {mutation.isLoading ? (
                <CircularLoader show={mutation.isLoading} color='#2898A4' />
              ) : (
                'Import'
              )}
            </CustomButton>
          </Group>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AssetImportModal;

const DndLabel = ({ onClick }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        padding: '1.4rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '.5rem',
        alignItems: 'center'
      }}
    >
      <Text size='sm'>Drag and Drop a file</Text>
      <CustomButton size='xs' onClick={onClick} variant='white'>
        Select a file
      </CustomButton>
    </div>
  );
};
