import EmptyImageSvg from 'common/images/empty_image.svg';
import { CustomButton } from 'components/Button';
import CircularLoader from 'components/CircularLoader';

import { useRemoveAssetImage, useUploadAssetImage } from 'Assets/queries';
import Group from 'components/StyledComponents/ButtonGroup';
import Text from 'components/Typography/Text';
import { useRef } from 'react';
import { runIfFn } from 'utilities/utils';

const DisplayImage = ({ asset, refetch, noUpload = false }) => {
  const fileInputRef = useRef(null);

  const onSuccess = () => {
    runIfFn(refetch);
  };
  const uploadMutation = useUploadAssetImage({
    onSuccess
  });
  const removeMutation = useRemoveAssetImage({ onSuccess });
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('image', file);
      uploadMutation.mutate({
        formDataUpload: formData,
        assetId: asset.id
      });
    }
  };

  const handleUploadImage = () => {
    fileInputRef.current.click();
  };
  const handleRemoveImage = () => {
    removeMutation.mutate(asset.id);
  };
  return (
    <section className='image_side '>
      {asset?.image ? (
        <div className='image_'>
          <img src={asset?.image} alt={asset?.name} />

          {noUpload ? (
            <>
              <section className='image_actions'>
                <Group>
                  <CustomButton
                    variant='white'
                    fullWidth
                    onClick={handleRemoveImage}
                    disabled={
                      !Boolean(asset?.image) || removeMutation.isLoading
                    }
                  >
                    <CircularLoader show={uploadMutation.isLoading} />
                    Remove
                  </CustomButton>
                  <CustomButton
                    disabled={uploadMutation.isLoading}
                    variant='white'
                    fullWidth
                    onClick={handleUploadImage}
                  >
                    <CircularLoader
                      color='var(--text-color)'
                      size='12px'
                      show={uploadMutation.isLoading}
                    />
                  </CustomButton>
                </Group>
              </section>
            </>
          ) : null}
        </div>
      ) : (
        <EmptyImage
          noUpload={noUpload}
          handleUploadImage={handleUploadImage}
          isLoading={uploadMutation.isLoading}
        />
      )}

      <input
        type='file'
        accept='image/*'
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </section>
  );
};

export default DisplayImage;

const EmptyImage = ({ isLoading, handleUploadImage, noUpload = false }) => {
  return (
    <div
      className='d-flex-col align-items-center justify-content-center d-gap-xs '
      style={{
        background: '#F2F2F2',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <img src={EmptyImageSvg} alt='empty ' />
      {!noUpload ? (
        <>
          <Text className='mb-3' bold>
            No asset image yet
          </Text>
          <Text light size='xs'>
            Maximum file size and type: 5MB (Jpegs, Pdf)
          </Text>
          <CustomButton
            disabled={isLoading}
            onClick={handleUploadImage}
            variant={'outlined'}
          >
            <CircularLoader
              show={isLoading}
              size={12}
              color='var(--button-primary-color)'
            />
            {isLoading ? 'Uploading ...' : 'Upload Image'}
          </CustomButton>
        </>
      ) : null}
    </div>
  );
};
