import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ConfirmLogo from 'imageAssets/password-reset.svg';
import {
  CustomBox,
  EmailConfirmButton,
  MobileLogo
} from './forms/styled.forms';

const EmailConfirmation = () => {
  const history = useHistory();

  const isMobile = useMediaQuery({
    query: '(max-width: 640px)'
  });
  const handleClick = () => {
    history.push('/forgot-password');
  };

  const handleRedirectToUserInbox = () => {
    const emailProviders = {
      'gmail.com': 'https://mail.google.com/',
      'yahoo.com': 'https://mail.yahoo.com/',
      'outlook.com': 'https://outlook.live.com/mail/'
    };

    const userEmailAddress = sessionStorage.getItem('userEmail');
   
    if (userEmailAddress) {
      const domain = userEmailAddress.split('@')[1];
      const inboxURL = emailProviders[domain] || 'https://mail.provider.com/';
      window.open(inboxURL, '_blank');
    }
  };
  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '100vh',
        background: `linear-gradient(to top, rgba(230, 243, 244, 0) 85%, #cbe8eb 93.75%)`
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '2em 3em',
          height: { xs: '70vh', md: '60vh' },
          position: 'relative'
        }}
      >
        <CustomBox>
          <Box sx={{ display: 'grid', placeItems: 'center' }}>
            {isMobile ? (
              <MobileLogo src={ConfirmLogo} alt='check mail' />
            ) : (
              <CheckCircleIcon color='secondary' />
            )}
          </Box>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '26px', md: '32.44px' },
              lineHeight: { xs: '28.74px', md: '37px' },
              mt: 2
              //   textAlign: { xs: 'left', md: 'center' }
            }}
          >
            {isMobile ? 'Recovery link sent' : 'Check your email'}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '20.49px',
              my: 2
            }}
          >
            {isMobile
              ? 'A recovery link has been shared to your mail, click on it to recover '
              : 'A password reset link has been sent to your email address'}
          </Typography>
          {!isMobile && (
            <Button
              variant='contained'
              disableElevation
              onClick={handleClick}
              sx={{
                bgcolor: '#2898A4',
                borderRadius: '5px',
                ':hover': {
                  bgcolor: '#1c6a72'
                }
              }}
            >
              Didn't get any mail, Resend
            </Button>
          )}
        </CustomBox>
      </Box>
      {isMobile && (
        <EmailConfirmButton
          variant='contained'
          color='secondary'
          fullWidth
          disableElevation
          onClick={handleRedirectToUserInbox}
        >
          Open Gmail or Mail app
        </EmailConfirmButton>
      )}
    </Box>
  );
};

export default EmailConfirmation;
