import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Typography from '@material-ui/core/Typography';

export const Wrapper = styled(Grid)`
  ${({ theme }) => `
    padding: 0% 2%;
    padding-bottom: 5%;
    h3 {
      ${theme.mixins.makeResponsive({ theme, fontSize: 36, lineHeight: 43 })};
      font-weight: 700;
      padding-left: 0%;
    }

    .MuiTableRow-root.Mui-selected {
      background: white;
      box-shadow: 0px 4px 15px rgba(214, 214, 214, 0.31);
      border-radius: 5px;
      border: none;
    }

    .MuiTableRow-root {
      border: none;
    }

    a {
      text-decoration: none;
    }
  `}
`;

export const VehicleListTitle = styled(Grid)`
  ${({ theme }) => `
    h3 {
      ${theme.mixins.makeResponsive({ theme, fontSize: 36, lineHeight: 43 })};
      padding-top: 2%;
      font-weight: 700;
      margin-top: 20px;
    }
  `}
`;


export const SearchBarWrapper = styled(Box)`
  ${({ theme }) => `
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    padding: 5px 10px;

    & > * {
      display: block;
      margin: auto 0;
    }

    .MuiInputBase-root {
      width: 90%;
      margin-left: 10px;
      ${theme.mixins.makeResponsive({ theme, fontSize: 14 })}
    }
  `}
`;

export const TableBlock = styled(Grid)`
  ${({ theme }) => `
    margin-top: 2%;
  `}
`;

export const AddVehicleButton = styled(Button)`
  ${({ theme }) => `
    box-shadow: none;
    text-transform: none;
    ${theme.mixins.makeResponsive({ theme, fontSize: 15 })};
    padding: 10px 30px;
    font-weight: 700;
  `}
`;

export const CreateVehicleButton = styled(Button)`
  ${({ theme }) => `
    box-shadow: none;
    text-transform: none;
    ${theme.mixins.makeResponsive({ theme, fontSize: 15 })};
    font-weight: 700;
    width: 100%;
    padding: 13px 70px 13px 70px;
  `}
`;

export const IconBox = styled(Avatar)`
  ${({ theme, color }) => `
    background: ${color};
    color: #2898A4;
    font-style: normal;
    font-weight: bold;
    font-size: 14.22px;
    line-height: 16px;
  `}
`;

export const NameSection = styled(Box)`
  ${({ theme }) => `
    display: flex;

    & > .MuiTypography-body1 {
      color: #737373;
      font-weight: 700;
      margin: auto;
      margin-left: 10px;
      display: block;
    }

  `}
`;

export const RowEditIcon = styled(EditOutlinedIcon)`
  ${({ theme }) => `
    width: 18px;
    height: 18px;
  `}
`;

export const RowDeleteIcon = styled(DeleteOutlinedIcon)`
  ${({ theme }) => `
    width: 18px;
    height: 18px;
  `}
`;

export const RowIconCaption = styled(Typography)`
  ${({ theme, selected, type }) => `
    font-size: 12px;
  `}
`;
