import CircularLoader from 'components/CircularLoader';
import React from 'react';
import { apiErrorHandler } from 'utilities/apiErrorHandler';
import FailedState from './FailedState';

const wrapperStyle = {
  height: 400,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};
const ContentWrapper = ({
  currentQuery,
  errorTitle,
  customLoader,
  children,
  isResolve
}) => {
  const { isLoading, isError, error, refetch } = currentQuery ?? {};

  if (isLoading)
    return (
      customLoader ?? (
        <div style={wrapperStyle}>
          <CircularLoader />
        </div>
      )
    );

  if (isError) {
    return (
      <div style={wrapperStyle}>
        {isResolve ? (
          <small className='input-text-error p-0'>{errorTitle}</small>
        ) : (
          <div className='table__error'>
            <FailedState
              onRetry={refetch}
              title={errorTitle}
              errorMsg={apiErrorHandler(error)}
            />
          </div>
        )}
      </div>
    );
  }
  return <>{children}</>;
};

export default ContentWrapper;
