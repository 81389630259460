import { Badge, Tabs as MuiTabs, Tab } from '@mui/material';
import styled from 'styled-components';

// const tabHeight = '36px';
function a11yProps(index) {
  return {
    id: `bizedge-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const ButtonTabs = ({ value=0, handleChange, tabs, tabWidth, ...props }) => {
  const badgeStyle = {
    '& .MuiBadge-badge': {
      color: '#fff',
      backgroundColor: '#FF6666'
    }
  };
  return (
    <StyledTabs
      className={'root'}
      value={value }
      {...props}
      onChange={handleChange}
    >
      {tabs?.map((tab, index) => (
        <StyledTab
          disableRipple
          style={{
            minWidth: tabWidth || '30%',
            fontWeight: 600,
            fontSize: '14px'
          }}
          {...a11yProps(index)}
          label={
            <Badge sx={badgeStyle} badgeContent={tab?.badge}>
              {tab?.title ?? tab}
            </Badge>
          }
        />
        // {
      ))}
    </StyledTabs>
  );
};

export default ButtonTabs;

export const StyledTabs = styled(MuiTabs)(({ theme, bgColor, border }) => ({
  border: border || '1px solid #E1E1E1',
  borderRadius: '50px',
  padding: '.2rem',
  '& .MuiTabs-indicator': {
    background: bgColor || '#FDEDCE',
    borderRadius: '41px',
    height: '100%',
    zIndex: 1
  }
}));

export const StyledTab = styled(Tab)(({ theme, color }) => ({
  fontFamily: 'var(--black-family)',
  textTransform: 'none',
  minWidth: 72,
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(4),
  //   color: '#545454',
  '&:hover': {
    color: theme.palette.text.primary,
    opacity: 1
  },
  '&.Mui-selected': {
    color: color ? color : '#C58607 !important',
    backgroundColor: 'transparent',
    position: 'relative',
    zIndex: 2,
    borderRadius: '41px'
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent',
    color: color ? color : '#C58607'
  }
}));
