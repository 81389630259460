import { CustomText, FlexBox } from 'common/Reusables';
import { SendOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import moment from 'moment';

const Privacy = () => {
  const columns = [
    {
      title: 'Information Type',
      dataIndex: 'type',
      width: '300px'
    },
    {
      title: 'Purpose and Related Details',
      dataIndex: 'purpose',
      width: '350px'
    },
    {
      title: 'Justification',
      dataIndex: 'justification'
    }
  ];

  const dataSource = [
    {
      type: `Name (including business name), address, contact (including telephone number and email address), and payment details.`,
      purpose: `We collect this to provide any information and services that you have requested or any applications or services that you have ordered`,
      justification: `We intend to share this data with any company within the Torilo Group, our service providers and agents (including their subcontractors), or third parties that process information on our behalf (e.g., internet service and platform providers, payment processing providers, and those organizations we engage to help us send communications to you), partners, third parties, government bodies, and law enforcement agencies. They will be processing the data for purposes that they determine. This information may be transferred to the US and: - there are binding corporate rules in place to safeguard the data - it's subject to approved standard contractual clauses - the transfer is necessary for the performance of a contract with you, or for the implementation of pre-contractual measures taken at your request.`
    }
  ];

  return (
    <Privacy.Wrapper width='80%' m='auto' py='2rem'>
      <Box mb='2rem'>
        <img
          alt='bizedge logo'
          style={{ height: '50px' }}
          src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638786088/Bizedge_Frontend_Assets/Bizedge_logo_axcwlg.svg'
        />
      </Box>
      <Box borderBottom='.5px solid #333' mb='1rem'>
        <CustomText fontSize='20px' fontWeight='700' color='#171717'>
          Privacy Notice
        </CustomText>
        <CustomText
          fontSize='18px'
          fontWeight='600'
          margin='.6rem 0'
          color='#171717'
        >
          Last updated: 5{' '}
          {moment(new Date()).subtract('months', 6).format('MMMM')}{' '}
          {moment(new Date()).format('YYYY')}
        </CustomText>
      </Box>
      <Box>
        <FlexBox jc='flex-start' gap='.5rem' mb='.6rem'>
          <SendOutlined />
          <CustomText fontSize='18px' fontWeight='600' color='#171717'>
            Introduction
          </CustomText>
        </FlexBox>
        <Box>
          <CustomText fontSize='16px' fontWeight='400' color='#333'>
            <a
              target='_blank'
              style={{ color: 'blue' }}
              rel='noopener noreferrer'
              href='http://www.bizedgeapp.com'
            >
              bizedgeapp.com
            </a>{' '}
            ('Website') is provided by Torilo Ltd ('we'/'us'/'our'). In doing
            so, we may be in a position to receive and process personal
            information relating to you. As the controller of this information,
            we're providing this Privacy Notice ('Notice') to explain our
            approach to personal information. This Notice forms part of our{' '}
            <a
              target='_blank'
              style={{ color: 'blue' }}
              rel='noopener noreferrer'
              href='http://web.bizedgeapp.com/terms'
            >
              Terms and Conditions
            </a>
            , which govern the use of this Website.
          </CustomText>
        </Box>
        <FlexBox jc='flex-start' gap='.5rem' mb='.6rem' mt='1.2rem'>
          <SendOutlined />
          <CustomText fontSize='18px' fontWeight='600' color='#171717'>
            Our Commitment to Fair Processing
          </CustomText>
        </FlexBox>
        <CustomText fontSize='16px' fontWeight='400' color='#333'>
          We intend only to process personal information fairly and
          transparently as required by data protection law including the UK
          General Data Protection Regulation (UK GDPR). In particular, before
          obtaining information from you (including through use of cookies) we
          intend to alert you to this Notice, let you know how we intend to
          process the information (including through use of cookies) and that
          we'll only process the information as permitted by law. The UK GDPR
          also defines certain 'special categories' of personal information
          that's considered more sensitive. These categories require a higher
          level of protection, as explained below.
        </CustomText>
        <FlexBox jc='flex-start' gap='.5rem' mb='.6rem' mt='1.2rem'>
          <SendOutlined />
          <CustomText fontSize='18px' fontWeight='600' color='#171717'>
            Browsing Without Providing Information
          </CustomText>
        </FlexBox>
        <CustomText fontSize='16px' fontWeight='400' color='#333'>
          Of course, you may browse parts of this Website without providing any
          information about yourself and without accepting cookies. In that
          case, it's unlikely we'll possess and process any information relating
          to you.
        </CustomText>
        <FlexBox jc='flex-start' gap='.5rem' mb='.6rem' mt='1.2rem'>
          <SendOutlined />
          <CustomText fontSize='18px' fontWeight='600' color='#171717'>
            Conditions for Processing Your Data
          </CustomText>
        </FlexBox>
        <CustomText fontSize='16px' fontWeight='400' color='#333'>
          We'll start this Notice by setting out the conditions we must satisfy
          before processing your data. However, you may wish to start with the
          table at clause 4, which summarises what we intend to collect, or the
          table at clause 8.5, which summarises our use of cookies. The Notice
          also explains some of the security measures we take to protect your
          personal information, and tells you certain things we will or won't
          do. You should read this Notice in conjunction with the{' '}
          <a
            target='_blank'
            style={{ color: 'blue' }}
            rel='noopener noreferrer'
            href='http://web.bizedgeapp.com/terms'
          >
            Terms and Conditions
          </a>
        </CustomText>
        <FlexBox jc='flex-start' gap='.5rem' mb='.6rem' mt='1.2rem'>
          <SendOutlined />
          <CustomText fontSize='18px' fontWeight='600' color='#171717'>
            Marketing Communications
          </CustomText>
        </FlexBox>
        <Typography>
          Sometimes, when you take a new service or product from us, or discuss
          taking a new service or product but decide against it, we might wish
          to provide you with further information about similar services or
          products by email or other written electronic communication. In that
          situation, we will always give you the opportunity to refuse to
          receive that further information and if you change your mind please
          let us know. We'll endeavour to remind you of your right to opt-out on
          each occasion that we provide such information.
        </Typography>
        <Box mt='1rem'>
          <CustomText fontSize='18px' fontWeight='600' color='#171717'>
            Identity and Contact Details
          </CustomText>
          <CustomText
            my='.6rem'
            fontSize='18px'
            fontWeight='600'
            color='#171717'
          >
            Registered Number: <span style={{ fontWeight: 400 }}>08060295</span>
          </CustomText>
          <CustomText fontSize='18px' fontWeight='600' color='#171717'>
            Registered Office:{' '}
            <span style={{ fontWeight: 400 }}>
              Suite 115, 7-8 New Road Avenue, Chatham, Kent, ME4 6BB
            </span>
          </CustomText>
          <CustomText
            fontSize='18px'
            fontWeight='600'
            color='#171717'
            mt='.6rem'
          >
            Email:{' '}
            <a
              href='mailto:hello@bizedgeapp.com'
              style={{ fontWeight: 400, color: 'blue' }}
            >
              hello@bizedgeapp.com
            </a>
          </CustomText>
        </Box>
        <Box>
          <FlexBox jc='flex-start' gap='.5rem' mb='.6rem' mt='2rem'>
            <SendOutlined />
            <CustomText fontSize='18px' fontWeight='600' color='#171717'>
              When We're Allowed to Collect Information from You
            </CustomText>
          </FlexBox>
          <Box>
            <CustomText fontSize='18px' fontWeight='400' color='#333'>
              We will only collect personal information relating to you if one
              of the following conditions has been satisfied:
            </CustomText>
          </Box>
          <ol>
            <li>
              You have clearly told us that you are content for us to collect
              that information for the particular purpose or purposes that we
              will have specified.{' '}
            </li>
            <li>
              The processing is necessary for the performance of a contract that
              we have with you.
            </li>
            <li>
              The processing is necessary so that we can comply with the law.{' '}
            </li>
            <li>The processing is necessary to protect someone's life.</li>
            <li>
              The processing is necessary for the performance of a task that's
              in the public interest.{' '}
            </li>
            <li>
              The processing is necessary for our or another's legitimate
              interests - but in this case, we'll balance those interests
              against your interests.
            </li>
          </ol>
          <FlexBox jc='flex-start' gap='.5rem' mb='.6rem' mt='2rem'>
            <SendOutlined />
            <CustomText fontSize='18px' fontWeight='600' color='#171717'>
              How to Consent
            </CustomText>
          </FlexBox>
          <Box>
            <ol>
              <li>
                If we need your consent to collect and use certain information,
                we'll provide you with the opportunity to tell us that you're
                happy to provide that information at the point of collecting it.
              </li>
              <li>
                If at any point in time you change your mind and decide that you
                want to withdraw your consent, please let us know and we'll
                endeavour to stop processing your information in the specified
                manner, or we'll delete your data if there is no continuing
                reason for possessing it.{' '}
              </li>
              <li>
                If you don't consent to a particular bit of processing, we'll
                endeavour to ensure that the Website and our service continue to
                operate without the need for that information.{' '}
              </li>
            </ol>
          </Box>
          <FlexBox jc='flex-start' gap='.5rem' mb='.6rem' mt='2rem'>
            <SendOutlined />
            <CustomText fontSize='18px' fontWeight='600' color='#171717'>
              Information We Expect to Collect from You
            </CustomText>
          </FlexBox>
          <CustomText fontSize='16px' fontWeight='400' color='#171717'>
            We envisage collecting the following types of information from you:
          </CustomText>
          <Box mt='2rem'>
            <Table
              components={{
                header: {
                  row: ({ children, ...props }) => {
                    return <tr {...props}>{children}</tr>;
                  },
                  cell: ({ children, ...props }) => {
                    return (
                      <td {...props} className='header_bg'>
                        {children}
                      </td>
                    );
                  }
                },
                body: {
                  row: ({ children, ...props }) => {
                    return (
                      <tr {...props} className='body_row'>
                        {children}
                      </tr>
                    );
                  },
                  cell: ({ children, colSpan, ...props }) => {
                    return (
                      <td
                        {...props}
                        className='body_bg'
                        colSpan={colSpan}
                        style={{ verticalAlign: 'top' }}
                      >
                        {children}
                      </td>
                    );
                  }
                }
              }}
              bordered
              columns={columns}
              pagination={false}
              dataSource={dataSource}
            />
          </Box>

          <FlexBox jc='flex-start' gap='.5rem' mb='.6rem' mt='2rem'>
            <SendOutlined />
            <CustomText fontSize='18px' fontWeight='600' color='#171717'>
              Sources of Personal Information
            </CustomText>
          </FlexBox>
          <Box>
            <CustomText fontSize='16px' fontWeight='400' color='#171717'>
              We may collect personal information about you from several
              sources, including:
            </CustomText>
            <ol>
              <li>
                From you when you agree to take a service or product from us.
              </li>
              <li>
                From you when you contact us with an inquiry or in response to a
                communication from us.
              </li>
              <li>
                Automatically as you interact with this Website, using cookies
                and other similar technologies.
              </li>
              <li>From public documents such as the electoral register.</li>
              <li>
                From third parties to whom you have provided information with
                your consent to pass it on to other organizations or persons.
              </li>
            </ol>

            <CustomText fontSize='16px' fontWeight='400' color='#171717'>
              If you refuse to provide information requested, we may need to
              stop providing that service if it is necessary for a service we
              provide to you. If we invite you to provide information without
              explaining why, feel free to object and ask for our reasons.
            </CustomText>
          </Box>
          <FlexBox jc='flex-start' gap='.5rem' mb='.6rem' mt='2rem'>
            <SendOutlined />
            <CustomText fontSize='18px' fontWeight='600' color='#171717'>
              Using Your Personal Information
            </CustomText>
          </FlexBox>
          <Box>
            <CustomText fontSize='16px' fontWeight='400' color='#171717'>
              We only use your personal information for specified purposes and
              shall not keep such personal information longer than necessary to
              fulfill these purposes. Examples of these purposes include:
            </CustomText>
            <ol>
              <li>Identifying you when you contact us.</li>
              <li>
                Identifying accounts, services, and/or products you could have
                from us or selected partners.
              </li>
              <li>
                Administering and contacting you about the improved
                administration of any accounts, services, and products.
              </li>
              <li>Carrying out marketing analysis and customer profiling.</li>
              <li>Preventing and detecting fraud or loss.</li>
              <li>Contacting you about products and services offered by us.</li>
              <li>Keeping you up to date with our member benefit scheme</li>
              <li>
                Monitoring and recording communications with you for quality
                assurance and compliance.
              </li>
              <li>Checking your details with fraud prevention agencies.</li>
            </ol>
          </Box>
          <FlexBox jc='flex-start' gap='.5rem' mb='.6rem' mt='2rem'>
            <SendOutlined />
            <CustomText fontSize='18px' fontWeight='600' color='#171717'>
              Disclosure of Your Personal Information
            </CustomText>
          </FlexBox>
          <Box>
            <CustomText fontSize='16px' fontWeight='400' color='#171717'>
              We will not disclose your personal information to any third party
              except in the following circumstances:
            </CustomText>
            <ol>
              <li>
                They will be processing the data on our behalf as a data
                processor.
              </li>
              <li>
                Sharing data with another data controller, with prior notice to
                you.
              </li>
              <li>If it is in your interest, with your consent.</li>
              <li>If we are acquired by a third party.</li>
              <li>
                For legal or regulatory purposes or as part of legal
                proceedings.
              </li>
              <li>
                With companies and individuals performing functions on our
                behalf.
              </li>
              <li>Keeping you up to date with our member benefit scheme</li>
              <li>If we suspect fraud.</li>
            </ol>
          </Box>
          <FlexBox jc='flex-start' gap='.5rem' mb='.6rem' mt='2rem'>
            <SendOutlined />
            <CustomText fontSize='18px' fontWeight='600' color='#171717'>
              Protecting Information
            </CustomText>
          </FlexBox>
          <Box>
            <CustomText fontSize='16px' fontWeight='400' color='#171717'>
              We have strict security measures to protect personal information,
              including:
            </CustomText>
            <ol>
              <li>
                Using SSL software to encrypt information during transmission.
              </li>
              <li>
                Revealing only the last five digits of credit card numbers
                during order confirmation.
              </li>
              <li>
                Maintaining physical, electronic, and procedural safeguards in
                connection with the collection, storage, and disclosure of
                personally identifiable customer information.
              </li>
            </ol>
          </Box>
          <FlexBox jc='flex-start' gap='.5rem' mb='.6rem' mt='2rem'>
            <SendOutlined />
            <CustomText fontSize='18px' fontWeight='600' color='#171717'>
              The Internet
            </CustomText>
          </FlexBox>
          <Box>
            <CustomText fontSize='16px' fontWeight='400' color='#171717'>
              Communications over the internet, such as emails and webmails, are
              not secure unless encrypted. We cannot accept responsibility for
              unauthorized access or loss of personal information beyond our
              control.
            </CustomText>
          </Box>
          <FlexBox jc='flex-start' gap='.5rem' mb='.6rem' mt='2rem'>
            <SendOutlined />
            <CustomText fontSize='18px' fontWeight='600' color='#171717'>
              Cookies and Other Internet Tracking Technology
            </CustomText>
          </FlexBox>
          <Box>
            <CustomText fontSize='16px' fontWeight='400' color='#171717'>
              We use cookies and similar technologies to make our services easy,
              useful, and reliable. For more information about cookies, visit{' '}
              <a href='http://www.allaboutcookies.org'>
                www.allaboutcookies.org
              </a>
              ,{' '}
              <a href='http://www.youronlinechoices.eu'>
                www.youronlinechoices.eu
              </a>{' '}
              or{' '}
              <a href='http://www.google.com/policies/technologies/cookies/'>
                www.google.com/policies/technologies/cookies/
              </a>{' '}
              .
            </CustomText>
          </Box>
          <FlexBox jc='flex-start' gap='.5rem' mb='.6rem' mt='2rem'>
            <SendOutlined />
            <CustomText fontSize='18px' fontWeight='600' color='#171717'>
              Your Legal Rights
            </CustomText>
          </FlexBox>
          <Box>
            <CustomText fontSize='16px' fontWeight='400' color='#171717'>
              Under certain circumstances, you have rights under data protection
              laws in relation to your personal information. These include the
              right to:
            </CustomText>
            <ol>
              <li>Request access to your personal information.</li>
              <li>Request correction of your personal information.</li>
              <li>Request erasure of your personal information.</li>
              <li>Object to processing of your personal information.</li>
              <li>
                Request restriction of processing of your personal information.
              </li>
              <li>
                Request the transfer of your personal information to you or to a
                third party.
              </li>
              <li>Withdraw consent at any time.</li>
            </ol>
            <CustomText fontSize='16px' fontWeight='400' color='#171717'>
              We may charge a reasonable fee if your request is unfounded,
              repetitive, or excessive. We may also request specific information
              from you to confirm your identity and ensure your right to access
              your personal information. We try to respond to all legitimate
              requests within one month.
            </CustomText>
          </Box>
          <FlexBox jc='flex-start' gap='.5rem' mb='.6rem' mt='2rem'>
            <SendOutlined />
            <CustomText fontSize='18px' fontWeight='600' color='#171717'>
              Further Information
            </CustomText>
          </FlexBox>
          <Box>
            <CustomText fontSize='16px' fontWeight='400' color='#171717'>
              For more information or to exercise your legal rights, please
              contact us at:
            </CustomText>
            <CustomText
              mt='1rem'
              fontSize='16px'
              fontWeight='400'
              color='#171717'
            >
              Data Protection Manager
            </CustomText>
            <CustomText fontSize='16px' fontWeight='400' color='#171717'>
              Torilo Ltd, Unit 4A Centre Court, Sir Thomas Longley Road,
              Rochester, ME2 4BQ
            </CustomText>
            <CustomText fontSize='16px' fontWeight='400' color='#171717'>
              Email:{' '}
              <a href='mailto:hello@bizedgeapp.com'>hello@bizedgeapp.com</a>
            </CustomText>
          </Box>
        </Box>
      </Box>
    </Privacy.Wrapper>
  );
};

export default Privacy;

Privacy.Wrapper = styled(Box)`
  ol {
    padding-left: 2rem;

    li {
      font-size: 16px;
    }
  }

  a {
    color: blue;
  }

  .body_row {
    border-bottom: 1px solid #e1e1e1 !important;
  }

  .ant-pagination {
    li {
      padding: 0px;
    }
  }

  .header_bg {
    padding: 1rem;
    font-weight: 700;
    color: #545454;
    background: var(--table-bg);
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;

    :last-child {
      border-right: 1px solid #e1e1e1;
      border-radius: 0px 10px 0px 0px;
    }

    :first-child {
      border-left: 1px solid #e1e1e1;
      border-radius: 10px 0px 0px 0px;
    }
    input:checked {
      opacity: 1;
    }
    .ant-checkbox-inner {
      overflow: hidden !important;
    }
  }

  .body_bg {
    border-top: none !important;
    border-bottom: 1px solid #e1e1e1 !important;

    :last-child {
      border-right: 1px solid #e1e1e1;
    }

    :first-child {
      border-left: 1px solid #e1e1e1;
    }
  }
`;
