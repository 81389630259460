/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { FormikServiceCreation } from './utils';
import { useMutation, useQueryClient } from 'react-query';
import {
  createVehicleService,
  uploadVehicleFile,
  deleteOneUploadedAttachment,
  editVehicleFile
} from '../../api/fleet';
import moment from 'moment';
import useFleetControl from '../../hooks/useFleetControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams } from 'react-router';
import SetReminder from './SelectFields/SetReminder';
import Progress from '../Progress';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  Heading2: {
    ...theme.mixins.makeResponsive({ theme, fontSize: 18, object: true }),
    fontWeight: 700,
    marginBottom: '3%'
  },
  Heading6: {
    ...theme.mixins.makeResponsive({ theme, fontSize: 10, object: true }),
    fontWeight: 400,
    marginBottom: '1%'
  },
  FileName: {
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 6px rgba(116, 116, 116, 0.04)',
    borderRadius: '5px',
    padding: '10px 15px',
    color: '#2898A4',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem 0'
  },
  Link: {
    // textDecoration : "none",
    color: '#2898A4'
  },
  DeleteIcon: {
    cursor: 'pointer'
  },
  FileUploadDiv: {
    border: `1px dashed ${theme.palette.secondary.main}`,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '4% 3%',
    marginBotton: '100px',
    borderRadius: '5px',

    '& > * ': {
      display: 'block',
      margin: 'auto 0'
    },
    '& .MuiTypography-body1': {
      ...theme.mixins.makeResponsive({ theme, fontSize: 14, object: true }),
      fontWeight: 700,
      color: '#0B0B0B'
    },
    '& .MuiTypography-body2': {
      ...theme.mixins.makeResponsive({ theme, fontSize: 15, object: true }),
      fontWeight: 600,
      texAlign: 'right'
    },
    '& > .MuiBox-root': {
      background: '#E3FAFC',
      borderRadius: '4px',
      textTansform: 'capitalize',
      padding: '2% auto'
    }
  },
  UploadButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '.5rem',
    cursor: 'pointer'
  }
}));

function RenewServiceForm({ width, id, editMode = false }) {
  const classes = useStyles();
  const [servicesState, setServicesState] = useFleetControl();
  const [disableSaveButton, setDisableSaveButton] = React.useState(false);
  const { id: vehicleId } = useParams();
  const [showDelete, setShowDelete] = useState('');
  const [file, setFile] = useState('');
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState();
  const [fileId, setFileId] = useState('');

  const apiMethod = editMode ? createVehicleService : createVehicleService;
  const fileApiMethod = editMode ? editVehicleFile : uploadVehicleFile;
  const mutation = useMutation(apiMethod);
  const fileMutation = useMutation(fileApiMethod, {
    onSuccess: async () => {
      setDisableSaveButton(false);
    }
  });
  const fileDelete = useMutation(deleteOneUploadedAttachment, {
    onSuccess: async (id) => {
      if (!editMode) {
        setFiles((prev) => prev.filter((item) => item.id !== id));
        setFileId((prev) => prev.filter((item) => item !== id));
      }
    },
    onSettled: (data, error, editedValue) => {
      if (files && fileId) {
        setFiles((prev) => prev.filter((item) => item.id !== editedValue));
        setFileId((prev) => prev.filter((item) => item !== editedValue));
      }
      if (formik.values.attachments.length > 0) {
        const filtered = formik.values.attachments.filter(
          (item) => item.id !== editedValue
        );
        formik.values.attachments = filtered;
      }
      // queryCache.removeQueries(['listData', editedValue.id]);
      // queryClient.refetchQueries(['vehicle-services']);
      // queryClient.refetchQueries(['vehicle']);
      // queryCache.refetchQueries('listData');
    }
  });
  const queryClient = useQueryClient();
  const [fleetState, setFleetState] = useFleetControl();

  React.useEffect(() => {
    const formUploadData = new FormData();
    if (editMode) {
      formUploadData.append('file', file);
      formUploadData.append('content_type', 'vehicleservice');
      formUploadData.append('object_id', servicesState.editServiceDetails.id);
    } else {
      formUploadData.append('file', file);
    }
    const fileData = formUploadData;
    if (disableSaveButton) {
      fileMutation
        .mutateAsync({ fileData, progress: setProgress })
        .then((res) => {
          formik.values.file = res;
          setFiles((prev) => [...prev, res]);
          setFileId((prev) => [...prev, res.id]);
          setDisableSaveButton(false);
        });
    }
  }, [disableSaveButton, files]);

  const handleDelete = (id) => {
    fileDelete.mutateAsync(id).then((res) => {});
  };

  const formik = useFormik({
    initialValues: FormikServiceCreation.initialValues(
      editMode ? servicesState.editServiceDetails : {}
    ),
    validate: FormikServiceCreation.validate,
    onSubmit: (values) => {
      const formUploadData = new FormData();
      formUploadData.append('docs', formik.values.file);
      const payload = {
        ...values,
        id: fleetState?.editServiceDetails?.vehicle.id,
        due_date: moment(formik.values.due_date).format('YYYY-MM-DD'),
        service_date: moment(formik.values.service_date).format('YYYY-MM-DD'),
        attachments: editMode ? formik.values.attachments : fileId,
        // The next two properties are needed for updates
        vehicleId,
        serviceId: servicesState.editServiceDetails.id,
        reminder_days: formik.values.reminder
      };

      mutation.mutateAsync(payload).then(() => {
        queryClient.refetchQueries(['vehicle-services']);
        queryClient.refetchQueries(['vehicle']);
        queryClient.refetchQueries(['get-service-reminder']);
        setFleetState({ ...fleetState, modal: '' });
        editMode &&
          setServicesState({
            ...servicesState,
            editServiceDetails: {},
            modal: '',
            selectedServices: []
          });
      });
    }
  });

  const hasErrors = (key) => {
    return formik.touched[key] && !!formik.errors[key];
  };

  const handleFile = (event) => {
    if (event.target.files.length) {
      setFile(event.target.files[0]);
    }
    setDisableSaveButton(true);
  };
  return (
    <FormGroup>
      <Grid container>
        <Grid item sm={12}>
          <TextField
            color='secondary'
            size={isWidthDown('lg', width) ? 'small' : 'medium'}
            fullWidth
            multiline
            variant='outlined'
            label='Description'
            name='description'
            error={hasErrors('description')}
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {hasErrors('description') ? (
            <Typography variant='caption' color='primary'>
              {formik.errors.description}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems='center'>
        <Grid item sm={6}>
          <TextField
            color='secondary'
            size={isWidthDown('lg', width) ? 'small' : 'medium'}
            fullWidth
            name='service_date'
            variant='outlined'
            label='Vehicle Service Date'
            type='date'
            error={hasErrors('service_date')}
            value={formik.values.service_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {hasErrors('service_date') ? (
            <Typography variant='caption' color='primary'>
              {formik.errors.service_date}
            </Typography>
          ) : null}
        </Grid>
        <Grid item sm={6}>
          <TextField
            color='secondary'
            size={isWidthDown('lg', width) ? 'small' : 'medium'}
            fullWidth
            name='due_date'
            variant='outlined'
            label='Next Due Date'
            type='date'
            error={hasErrors('due_date')}
            value={formik.values.due_date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {hasErrors('due_date') ? (
            <Typography variant='caption' color='primary'>
              {formik.errors.due_date}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems='center'>
        <Grid item sm={6}>
          <TextField
            color='secondary'
            size={isWidthDown('lg', width) ? 'small' : 'medium'}
            fullWidth
            variant='outlined'
            label='Mileage'
            name='mileage'
            error={hasErrors('mileage')}
            value={formik.values.mileage}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {hasErrors('mileage') ? (
            <Typography variant='caption' color='primary'>
              {formik.errors.mileage}
            </Typography>
          ) : null}
        </Grid>
        <Grid item sm={6}>
          <SetReminder
            value={formik.values.reminder}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name='reminder'
            error={hasErrors('reminder')}
            label='Reminder'
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12}>
          <TextField
            color='secondary'
            size={isWidthDown('lg', width) ? 'small' : 'medium'}
            fullWidth
            variant='outlined'
            label='Cost'
            name='cost'
            error={hasErrors('cost')}
            value={formik.values.cost}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {hasErrors('cost') ? (
            <Typography variant='caption' color='primary'>
              {formik.errors.cost}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      <Grid container style={{ margin: '3% 0px 2%' }}>
        <Grid item sm={12}>
          <Typography variant='subtitle2' color='secondary'>
            <strong>Documents</strong>
          </Typography>
          <Divider />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item={true} xs={12} direction='column' alignItems='center'>
          {editMode &&
            formik.values.attachments.map((file) => (
              <Box
                color='secondary'
                className={classes.FileName}
                onMouseEnter={() => setShowDelete(file.name)}
                onMouseLeave={() => setShowDelete('')}
              >
                <Link
                  to={{ pathname: file.file }}
                  target='_blank'
                  className={classes.Link}
                >
                  <Typography>{file.name}</Typography>
                </Link>

                {showDelete === file.name && (
                  <img
                    src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638788581/Bizedge_Frontend_Assets/Vector_1_hzkq4t.png'
                    alt='delete'
                    className={classes.DeleteIcon}
                    onClick={() => handleDelete(file.id)}
                  />
                )}
              </Box>
            ))}
          <h2>{editMode && 'Added files'}</h2>
          {files &&
            files.map((file) => (
              <Box
                color='secondary'
                className={classes.FileName}
                onMouseEnter={() => setShowDelete(file.name)}
                onMouseLeave={() => setShowDelete('')}
              >
                <Link
                  to={{ pathname: file.file }}
                  target='_blank'
                  className={classes.Link}
                >
                  <Typography>{file.name}</Typography>
                </Link>

                {showDelete === file.name && (
                  <img
                    src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638788581/Bizedge_Frontend_Assets/Vector_1_hzkq4t.png'
                    alt='delete'
                    className={classes.DeleteIcon}
                    onClick={() => handleDelete(file.id)}
                  />
                )}
              </Box>
            ))}

          {progress && progress !== 100 && (
            <Progress strokeWidth='6' percentage={progress} />
          )}
        </Grid>
        {/* ============ DOCUMENTS SECTION ============= */}
        <Grid
          item={true}
          xs={12}
          direction='column'
          alignItems='left'
          className={classes.DocumentSection}
        >
          <Typography variant='h6' align='left' className={classes.Heading6}>
            Upload a File
          </Typography>

          <input
            type='file'
            id='docs-upload'
            accept='.pdf, .png, .jpeg, .tiff'
            style={{ display: 'none' }}
            onChange={handleFile}
            onBlur={formik.handleBlur}
            name='file'
            // error={hasErrors('file')}
            // value={formik.values.file}
            // multiple
          />

          <Box className={classes.FileUploadDiv} justifyContent='center'>
            <label htmlFor='docs-upload'>
              <Box className={classes.UploadButton}>
                <BackupOutlinedIcon
                  style={{ margin: 'auto 10px' }}
                  color='secondary'
                />
                <Typography variant='body2' color='secondary'>
                  Choose a file
                </Typography>
              </Box>
            </label>
          </Box>
        </Grid>
      </Grid>
      {/* ============== SUBMIT =============== */}
      <Button
        variant='contained'
        color='secondary'
        type='submit'
        disabled={disableSaveButton}
        onClick={formik.handleSubmit}
        startIcon={
          mutation.isLoading ? (
            <CircularProgress size={20} style={{ color: 'white' }} />
          ) : null
        }
      >
        {mutation.isLoading ? null : editMode && 'Renew maintenance'}
      </Button>
    </FormGroup>
  );
}

export default withWidth()(RenewServiceForm);
