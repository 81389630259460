import { createContext, useContext, useState } from 'react';

export const FleetContext = createContext({});

export function FleetProvider({ children }) {
  const [state, setState] = useState({
    modal: '',
    selectedVehicles: [],
    editVehicleDetails: {},
    renewVehicle: {},
    editServiceDetails: {},
    search: '',
    toast: false,
    toastMessage: '',
    severity: ''
  });

  return (
    <FleetContext.Provider value={[state, setState]}>
      {children}
    </FleetContext.Provider>
  );
}

function useFleetControl() {
  const [state, setState] = useContext(FleetContext);

  return [state, setState];
}

export default useFleetControl;
