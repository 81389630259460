import { CustomButton as Button } from 'components/Button';
import CircularLoader from 'components/CircularLoader';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from 'components/Dialog';
import { CustomTextField } from 'components/Inputs';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import { useFormik } from 'formik';
import { runIfFn } from 'utilities/utils';
import { useCreateAssetType } from '../queries';

const AddAssetTypeDialog = ({ open, toggle, refetch, editData = null }) => {
  const isEdit = Boolean(editData);
  const {
    handleChange,
    values: { name }
  } = useFormik({
    initialValues: { name: isEdit ? editData.name ?? editData?.title : '' }
  });

  const mutation = useCreateAssetType({
    editId: isEdit ? editData?.id : null,
    onSuccess(res) {
      runIfFn(refetch, toggle);
    }
  });

  return (
    <>
      <Dialog open={open} fullWidth maxWidth='xs' cp={'2rem'} onClose={toggle}>
        <DialogTitle px={4} iconFlex onClose={toggle}>
          <span>
            <Title>{isEdit ? 'Edit' : 'Add'} New Asset Type</Title>
            <Text size='xs'>
              Provide asset name to complete creating a category of asset
            </Text>
          </span>
        </DialogTitle>
        <DialogContent>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
          >
            <div>
              <CustomTextField
                size='small'
                placeholder='Monitor'
                name='name'
                value={name}
                onChange={handleChange}
              />
              <Text size='xs'>E.g Phones, Laptop, charger etc</Text>
            </div>
            <div>
              <CustomTextField
                size='small'
                disabled
                placeholder='MON'
                value={name ? name.substring(0, 3)?.toUpperCase() : ''}
                name='abbr'
              />
              <Text size='xs'>E.g Laptops as LAP</Text>
            </div>
            {/* <div>
            <CustomTextField size='small' placeholder='MON' name='abbr' />
            <Text size='xs'>E.g Laptops as LAP</Text>
          </div> */}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!name || mutation.isLoading}
            bold
            size='sm'
            fullWidth
            variant='contained'
            onClick={() => mutation.mutate({ name })}
          >
            <CircularLoader size={15} show={mutation.isLoading} />
            {isEdit ? 'Save Edit' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddAssetTypeDialog;
