import Tabs from 'components/Tabs/index.jsx';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import AddAsset from 'Assets/AddAsset';
import AssetList from 'Assets/AssetList';
import AssetType from 'Assets/AssetType';
import AssetView from 'Assets/AssetView';
import {
  AddMaintenance,
  ReportIssue,
  ServiceAndRepair,
  AddReport
} from '../Maintenance';
import Overview from 'Assets/Overview';
import Settings from '../Settings';
import AssetTypeSettings from '../Settings/AssetType';
import IssuedTypeSettings from '../Settings/IssuedType';
import StatutoryType from '../Settings/StatutoryType';

import { tabs } from './data';
import { useFetchAllAssets } from 'Assets/queries';
import { Box } from '@mui/material';
import AssetEmpty from './AssetEmpty';
import BizLoader from 'components/BizLoader';

const pages = {
  home: {
    index: <Overview />,
    create: <AddAsset />,
    edit: <AddAsset />
  },
  'asset-list': {
    list: <AssetList />,
    type: <AssetType />,
    asset: <AssetView />
  },
  maintenance: {
    servicerepair: <ServiceAndRepair />,
    reportissue: <ReportIssue />,
    createmaintenance: <AddMaintenance />,
    editmaintenance: <AddMaintenance />,
    createreport: <AddReport />
  },
  settings: {
    index: <Settings />,
    assettype: <AssetTypeSettings />,
    issuedtype: <IssuedTypeSettings />,
    statutorytype: <StatutoryType />
  }
};

function getView(tab, view) {
  let page = pages[tab];

  if (page && (page?.index || view)) {
    page = page[view ? view.split('-').join('') : 'index'];
  }

  return page || <Overview />;
}

const Dashboard = () => {
  const assetList = useFetchAllAssets();
  const { search } = useLocation();
  const { tab, view } = qs.parse(search);

  const hasAsset = assetList?.data?.results?.length > 0;
  const isLoading = assetList.isLoading;

  if (isLoading) {
    return <BizLoader />;
  }

  const isCreateRoute = tab === 'home' && view === 'create';
  if (!hasAsset && !isCreateRoute) {
    return (
      <Box>
        <AssetEmpty />;
      </Box>
    );
  }

  return (
    <div>
      <Tabs menus={tabs} />
      <div style={{ marginTop: '10rem' }}>{getView(tab, view)}</div>
    </div>
  );
};

export default Dashboard;
