import { forwardRef } from 'react';
import styled from 'styled-components';

const CardGrid = ({ children, ...props }, ref) => {
  return (
    <CardGrid.Wrapper ref={ref} {...props}>
      {children}
    </CardGrid.Wrapper>
  );
};

export default forwardRef(CardGrid);

CardGrid.Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    ${(props) => (props?.cardSize ? props?.cardSize : 'minmax(200px,1fr)')}
  );
  grid-gap: ${(props) => props.gap || '8px'};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'stretch')};
`;
