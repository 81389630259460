import {
  useCreateAssetMaintenance,
  useFetchAllAssets,
  useGetSingleMaintenance
} from 'Assets/queries';
import { CustomButton as Button } from 'components/Button';
import CircularLoader from 'components/CircularLoader';
import { CustomSelect, CustomTextField, DatePicker } from 'components/Inputs';
import Group from 'components/StyledComponents/ButtonGroup';
import { useFormik } from 'formik';
import { capitalize } from 'utilities/Capitalized';
import { inputPropsSetterConfig } from 'utilities/formHelpers';
import ReminderForm from 'Assets/Shared/ReminderForm';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Header from './Header';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  validationSchema,
  initialValues,
  maintenanceType,
  getPayload
} from './data';
import { useCallback } from 'react';

const AddMaintenance = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { id } = queryString.parse(search);

  const isEdit = !!id;

  const formik = useFormik({
    validationSchema,
    initialValues: initialValues(),
    onSubmit(values) {
      if (!isValid) {
        return validateForm();
      }
      const payload = getPayload(values);
      if (isEdit) {
        payload.editId = id;
      }
      mutation.mutate(payload);
    }
  });

  useGetSingleMaintenance(
    { asset_pk: id },
    {
      enabled: isEdit,
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        if (isEdit) {
          formik.setValues(initialValues(res));
        }
      }
    }
  );

  const assetsQuery = useFetchAllAssets();

  const assetsOptions =
    assetsQuery?.data?.results?.map((asset) => ({
      label: asset.name,
      value: asset.id
    })) ?? [];

  const { setFieldValue, values, isValid, validateForm, submitForm } = formik;

  const mutation = useCreateAssetMaintenance({
    editId: id,
    onSuccess() {
      history.goBack();
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    submitForm();
  };

  const setInputProps = inputPropsSetterConfig(formik);

  const clearReminder = useCallback(() => {
    setFieldValue('reminder', null);
  }, [setFieldValue]);

  return (
    <AddFormStyled>
      <Header />
      <form className='form_block'>
        <CustomSelect
          options={assetsOptions}
          size='small'
          label='Asset'
          {...setInputProps('asset')}
          onSelect={(value) => {
            setFieldValue('reminder.asset', value);
          }}
        />
        <CustomSelect
          options={maintenanceType}
          size='small'
          label={capitalize(values?.type)}
          defaultValue='service'
          {...setInputProps('type')}
        />

        <div>
          <DatePicker
            inputProps={{ fullWidth: true, size: 'small' }}
            size='small'
            fullWidth
            name='date'
            label={`${capitalize(values?.type)} Date`}
            value={values.date}
            onChange={(newValue) => setFieldValue('date', newValue)}
          />
        </div>
        {values.type === 'service' ? (
          <div>
            <DatePicker
              inputProps={{ fullWidth: true, size: 'small' }}
              size='small'
              fullWidth
              name='due_date'
              label={`Due Date (Optional)`}
              value={values.due_date}
              onChange={(newValue) => setFieldValue('due_date', newValue)}
            />
          </div>
        ) : null}

        <CustomTextField
          label='Mileage (Optional)'
          size='small'
          {...setInputProps('mileage')}
        />
        <CustomTextField
          label='Cost'
          size='small'
          type={'number'}
          {...setInputProps('cost')}
        />

        <CustomTextField
          label='Note'
          size='small'
          rows={3}
          multiline
          {...setInputProps('notes')}
        />
        {/* {values.type === 'service' ? ( */}
        <ReminderForm
          setReminderInput={setInputProps('reminder.set_reminder')}
          setTimeInput={setInputProps('reminder.date')}
          clearInputs={clearReminder}
          defaultEnabled={values.type === 'service'}
        />
        {/* ) : null} */}
        <Group fullWidth className='action_block'>
          <Button
            // onClick={toggle}
            size='sm'
            fullWidth
            variant='outlined'
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            size='sm'
            disabled={mutation.isLoading}
            fullWidth
            variant='contained'
          >
            <CircularLoader size={15} show={mutation.isLoading} />
            Save
          </Button>
        </Group>
      </form>
    </AddFormStyled>
  );
};

export default AddMaintenance;

export const AddFormStyled = styled.div`
  width: min(100%, 600px);
  margin-inline: auto;
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  .header_block {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .typos {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
      margin-bottom: 0.5rem;
    }
  }

  .form_block {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .action_block {
    margin-block: 1rem;
  }
`;
