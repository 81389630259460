import moment from 'moment';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import SnackBar from '../../common/SnackBar';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import {
  getVehicleMakes,
  getVehiclesMeta,
  getModels,
  updateVehicle,
  getOneVehicle
} from '../../api/fleet';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getAllEmployees } from '../../api/hr';
import useFleetControl from '../../hooks/useFleetControl';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FlexBox } from '../../HR/Home/Reusables';
import { Heading } from '../../HR/Home/Reusables';
import qs from 'query-string';
import { useLocation } from 'react-router';
import { CreateVehicleButton } from '../VehicleList/styled.vehicle-list';
// import Spinner
const useStyles = makeStyles({
  foo: {
    '& .MuiGrid-container': {
      alignItems: 'center',
      // border: '1px solid red',
      margin: '0px !important'
    },
    '& > *': {
      margin: '20px 0'
    }
    // '& .MuiGrid-item': {
    //   padding : "0 .5rem 0 0"
    // }
  }
});

function EditVehicleForm() {
  // const { search } = window.location;
  const classes = useStyles();
  const { search } = useLocation();
  const queryString = qs.parse(search);
  const [fleetState, setFleetState] = useFleetControl();
  const queryClient = useQueryClient();
  const history = useHistory();
  const mutate = useMutation(updateVehicle);
  const { data: vehiclesMeta } = useQuery('vehicles meta', getVehiclesMeta);
  const { data: allEmployeesData } = useQuery(
    ['all-employees'],
    getAllEmployees
  );
  const minYear = '1992';
  const maxYear = '2030';

  const { data, isFetching } = useQuery(
    ['vehicle', { id: queryString.vehicleId }],
    getOneVehicle
  );

  const [purchaseDate, setPurchaseDate] = useState(null);
  const [assignedDate, setAssignedDate] = useState(null);

  useEffect(() => {
    const foo = () => {
      setPurchaseDate(data?.date_purchased);
      setAssignedDate(data?.date_assigned);
    };
    foo();
  }, [data]);

  // const { data: vehicleMakes } = useQuery(
  //   ['vehicle-makes', formik.values.type],
  //   getVehicleMakes
  // );

  // const { isFetching: oneVehicleFetching } = useQuery(
  //   ['vehicle', { id: queryString.vehicleId }],
  //   getOneVehicle,
  //   {
  //     onSuccess: (res) => {
  //       setFleetState({ ...fleetState, editVehicleDetails: res });
  //     }
  //   }
  // );

  let employeeList =
    allEmployeesData &&
    allEmployeesData.map((res) => {
      return { label: `${res.first_name} ${res.last_name}`, value: res.id };
    });

  const handleForm = async (values) => {
    const payload = {
      ...values,
      id: Number(queryString.vehicleId),
      year: values.year ? moment(values.year).format('YYYY') : null,
      date_purchased: purchaseDate
        ? moment(purchaseDate).format('YYYY-MM-DD')
        : null,
      date_assigned: assignedDate
        ? moment(assignedDate).format('YYYY-MM-DD')
        : null,
      start_mileage: values.start_mileage ? values.start_mileage : null,
      purchase_price: values.purchase_price ? values.purchase_price : null
    };

    const res = await mutate.mutateAsync(payload);
    if (res) {
      queryClient.refetchQueries(['vehicles']);
      history.push('?tab=home');
      setFleetState({
        ...fleetState,
        modal: '',
        toast: true,
        toastMessage: 'vehicle has been updated sucessfully',
        severity: 'success'
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      type: data ? data?.type : '',
      make: data ? data?.model?.make?.id : '',
      model: data ? data?.model?.id : '',
      year: data?.year ? data?.year?.toString() : null,
      color: data?.color ? data?.color : '',
      plate_no: data?.plate_no ? data?.plate_no : '',
      start_mileage: data?.start_mileage ? data?.start_mileage : '',
      date_purchased: data?.date_purchased ? data?.date_purchased : null,
      purchase_price: data?.purchase_price ? data?.purchase_price : '',
      status: data?.status ? data?.status : '',
      assignee: data?.assignee ? data?.assignee?.id : '',
      date_assigned: data?.date_assigned ? data?.date_assigned : null
    },
    onSubmit: handleForm,
    enableReinitialize: true
  });

  const {
    handleChange,
    handleSubmit,
    isSubmitting,
    values: { year, color, plate_no, start_mileage, purchase_price }
  } = formik;

  // const formik = useFormik({
  //   initialValues: {
  //     type: fleetState?.editVehicleDetails?.type || '',
  //     make: fleetState?.editVehicleDetails?.model?.make.id || '',
  //     model: fleetState?.editVehicleDetails?.model?.id || '',
  //     year: fleetState?.editVehicleDetails?.year
  //       ? fleetState?.editVehicleDetails?.year.toString()
  //       : null,
  //     color: fleetState?.editVehicleDetails?.color || '',
  //     plate_no: fleetState?.editVehicleDetails?.plate_no || '',
  //     start_mileage: fleetState?.editVehicleDetails?.start_mileage || null,
  //     date_purchased:
  //       fleetState?.editVehicleDetails?.date_purchased || new Date(),
  //     purchase_price: fleetState?.editVehicleDetails?.purchase_price || null,
  //     status: fleetState?.editVehicleDetails?.status || 'active',
  //     assignee: fleetState?.editVehicleDetails?.assignee
  //       ? fleetState?.editVehicleDetails?.assignee?.id
  //       : '',
  //     date_assigned: fleetState?.editVehicleDetails?.date_assigned || null
  //   },
  //   enableReinitialize: true,
  //   validationSchema: Yup.object({
  //     type: Yup.string().required('Required'),
  //     make: Yup.string().required('Required'),
  //     model: Yup.string().required('Required'),
  //     plate_no: Yup.string().required('Required')
  //   }),
  //   onSubmit: async (values) => {
  //     const year = values.year
  //       ? moment(formik.values.year).format('YYYY')
  //       : null;
  //     const date_purchased = values.date_purchased
  //       ? moment(formik.values.date_purchased).format('YYYY-MM-DD')
  //       : null;
  //     const date_assigned = values.date_assigned
  //       ? moment(formik.values.date_assigned).format('YYYY-MM-DD')
  //       : null;

  //     const payload = {
  //       ...values,
  //       year,
  //       date_purchased,
  //       date_assigned,
  //       id: fleetState.editVehicleDetails.id
  //     };
  //     editMutation.mutateAsync(payload).then((res) => res);
  //   }
  // });
  // const { data, isFetching } = useQuery(['employees'], getEmployees);
  const { data: vehicleMakes = [] } = useQuery(
    ['vehicle-makes', formik.values.type],
    getVehicleMakes
  );
  const { data: vehicleModels = [] } = useQuery(
    ['models', formik.values.type, formik.values.make],
    getModels
  );

  // const [vehicleMakeValue, setVehicleMakeValue] = React.useState(
  //   vehicleMakes[0]
  // );
  // const [inputValue, setInputValue] = React.useState('');
  const editMutation = useMutation(updateVehicle, {
    onSuccess: () => {
      queryClient.refetchQueries(['vehicle'], {
        id: fleetState.editVehicleDetails.id
      });
      // setFleetState({ ...fleetState, modal: '' });
      history.push(`/fleet?tab=home`);
    }
  });

  // const statuses = vehiclesMeta ? vehiclesMeta.status : [{}];
  // if (oneVehicleFetching && vehicleMakeValue === undefined) {
  //   return <CircularProgress color='secondary' />;
  // }

  const vehicleStatus =
    vehiclesMeta &&
    vehiclesMeta.status.map((status) => ({
      label: status.display_name,
      value: status.value
    }));

  const vehicleType =
    vehiclesMeta &&
    vehiclesMeta.type.map((type) => ({
      label: type.display_name,
      value: type.value
    }));

  const defaultType = [
    {
      label: data && data.type_display,
      value: data && data.type
    }
  ];

  const defaultMake = [
    {
      label: data && data.model.make.name,
      value: data && data.model.make.id
    }
  ];

  const defaultModel = [
    {
      label: data && data.model.name,
      value: data && data.model.id
    }
  ];

  const defaultStatus = [
    {
      label: data && data.status,
      value: data && data.status_display
    }
  ];

  const defaultAssignee = [
    {
      label: data?.assignee
        ? `${data?.assignee?.first_name} ${data?.assignee?.last_name}`
        : 'Assignee',
      value: data ? data?.assignee?.id : null
    }
  ];

  const makes = vehicleMakes.map((make) => ({
    label: make.name,
    value: make.id
  }));

  const models = vehicleModels.map((model) => ({
    label: model.name,
    value: model.id
  }));

  if (isFetching) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <EditVehicleForm.Wrapper>
      <FlexBox jc='normal' onClick={() => history.push('/fleet?tab=home')}>
        <KeyboardBackspaceIcon
          style={{
            verticalAlign: 'middle',
            cursor: 'pointer',
            marginRight: '.5rem'
          }}
        />{' '}
        <Heading style={{ color: 'rgb(84, 84, 84)' }}>Edit Vehicle</Heading>
      </FlexBox>
      <FormGroup className={`${classes.foo} container`}>
        {editMutation.isSuccess ? (
          <SnackBar severity='success'>Vehicle Updated!</SnackBar>
        ) : null}
        <Box style={{ marginBottom: '15px' }}>
          <Typography variant='subtitle1'>
            <strong>Vehicle Details</strong>
          </Typography>
          <Divider />
        </Box>
        {/* form start here */}
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <Select
              placeholder={<>Vehicle Type</>}
              options={vehicleType}
              // value={type}
              defaultValue={defaultType}
              name='type'
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  border:
                    !state.isFocused &&
                    formik.errors.subcategory &&
                    '1px solid #f44336'
                })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#2898A4',
                  primary25: '#2898A4'
                }
              })}
              onChange={(event) => {
                formik.setFieldValue('type', event.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <Select
              placeholder={<>Select Vehicle Brand</>}
              options={makes}
              // value={make}
              defaultValue={defaultMake}
              name='make'
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  border:
                    !state.isFocused &&
                    formik.errors.subcategory &&
                    '1px solid #f44336'
                })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#2898A4',
                  primary25: '#2898A4'
                }
              })}
              onChange={(event) => {
                formik.setFieldValue('make', event.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <Select
              placeholder={<>Select Vehicle Model</>}
              options={models}
              name='model'
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              defaultValue={defaultModel}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  border:
                    !state.isFocused &&
                    formik.errors.subcategory &&
                    '1px solid #f44336'
                })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#2898A4',
                  primary25: '#2898A4'
                }
              })}
              onChange={(event) => {
                formik.setFieldValue('model', event.value);
              }}
              // value={model}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          style={{
            margin: '0px !important',
            padding: '0px'
          }}
        >
          <Grid item sm={12}>
            <TextField
              label='Registration Number'
              color='secondary'
              variant='outlined'
              fullWidth
              size='small'
              name='plate_no'
              value={plate_no}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ margin: '1em 0px 1.3em' }}>
          <Grid item sm={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <TextField
              label='Purchase Price (Optional)'
              color='secondary'
              variant='outlined'
              fullWidth
              size='small'
              name='purchase_price'
              value={purchase_price}
              onChange={handleChange}
            />
          </Grid>
          <Grid item sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                variant='inline'
                format='dd/MM/yyyy'
                margin='normal'
                inputVariant='outlined'
                size='small'
                id='date-picker-inline'
                name='date_purchased'
                label='Date of purchase (Optional)'
                value={purchaseDate}
                // value={formik.values.date_purchased}
                color='secondary'
                // onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.setFieldValue('date_purchased', event.value);
                  setPurchaseDate(event);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <TextField
              label='Mileage (Optional)'
              color='secondary'
              variant='outlined'
              fullWidth
              size='small'
              value={start_mileage}
              onChange={handleChange}
            />
          </Grid>
          <Grid item sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                variant='inline'
                format='yyyy'
                views={['year']}
                margin='normal'
                inputVariant='outlined'
                size='small'
                id='date-picker-inline'
                label='Year'
                name='year'
                minDate={new Date(minYear)}
                maxDate={new Date(maxYear)}
                onChange={(event) => {
                  formik.setFieldValue('year', event);
                }}
                // onBlur={formik.handleBlur}
                value={year}
                color='secondary'
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                style={{
                  // width: '100%',
                  margin: '10px 0 31px 0'
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <Select
              placeholder={<>Status</>}
              options={vehicleStatus}
              defaultValue={defaultStatus}
              name='status'
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  border:
                    !state.isFocused &&
                    formik.errors.subcategory &&
                    '1px solid #f44336'
                })
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#2898A4',
                  primary25: '#2898A4'
                }
              })}
              onChange={(event) => {
                formik.setFieldValue('status', event.value);
              }}
              // value={status}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              label='Colour'
              color='secondary'
              variant='outlined'
              fullWidth
              size='small'
              name='color'
              value={color}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item>
            <Typography>
              <strong>Assignee information (Optional)</strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            {/* <Select placeholder={<>Assign To</>} options={status} /> */}
            <Select
              className={classes.assigneeSelect}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#2898A4',
                  primary25: '#2898A4'
                }
              })}
              placeholder='Assignee'
              cacheOptions
              menuPortalTarget={document.body}
              defaultValue={defaultAssignee}
              menuPosition={'fixed'}
              options={employeeList}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  border:
                    !state.isFocused &&
                    formik.errors.subcategory &&
                    '1px solid #f44336'
                })
              }}
              // value={assignee}
              onChange={(event) => {
                formik.setFieldValue('assignee', event?.value);
                // setAssignee(event.value);
              }}
              name='assignee'
              // getOptionValue={(option) => option.id}
              // getOptionLabel={(option) =>
              //   `${option.first_name} ${option.last_name}`
              // }
              maxMenuHeight={190}
              isSearchable
            />
          </Grid>
          <Grid item sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                variant='inline'
                format='dd/MM/yyyy'
                margin='normal'
                name='date_assigned'
                inputVariant='outlined'
                size='small'
                id='date-picker-inline'
                label='Date Assigned (Optional)'
                value={assignedDate}
                onChange={(event) => {
                  formik.setFieldValue('date_assigned', event.value);
                  setAssignedDate(event);
                }}
                // onBlur={formik.handleBlur}
                // value={date_assigned}
                color='secondary'
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                // style={{ width: '100%' }}
                className='date'
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>

        <Box
          textAlign='end'
          display='flex'
          alignItems='center'
          justifyContent='flex-end'
          // mr='.7rem'
          // width='50%'
        >
          <CreateVehicleButton
            variant='outlined'
            color='secondary'
            // disbled={disableSubmit(formik.errors)}
            onClick={() => {
              history.goBack();
            }}
            style={{
              width: '30%',
              fontWeight: 'bold',
              height: '40px',
              marginRight: '20px'
            }}
          >
            Back
          </CreateVehicleButton>
          <Button
            color='secondary'
            variant='contained'
            fullWidth
            // disbled={disableSubmit(formik.errors)}
            disabled={isSubmitting}
            onClick={handleSubmit}
            style={{
              width: '30%',
              fontWeight: 'bold',
              height: '40px'
            }}
          >
            {isSubmitting ? (
              <CircularProgress size={20} style={{ color: 'white' }} />
            ) : (
              'Save'
            )}
          </Button>
        </Box>
      </FormGroup>
    </EditVehicleForm.Wrapper>
  );
}

export default EditVehicleForm;
EditVehicleForm.Wrapper = styled(Box)`
  margin-top: 0.5rem;
  .container {
    max-width: 52%;
    margin: 1rem auto;
  }
  .date {
    margin: 0 !important;
  }
  .flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 21px 0;
    width: 100%;
    & > * {
      width: 48%;
      margin: 0;
      margin-bottom: 0 !important;
    }
  }
  .year {
    margin-bottom: 31px !important;
  }
`;
