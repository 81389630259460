import React from 'react';

const TimeIcon = () => {
  return (
    <svg
      width='36'
      height='37'
      viewBox='0 0 36 37'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_b_1527_1713)'>
        <path
          d='M30.0835 0.5H5.91654C2.64893 0.5 0 3.14893 0 6.41654V30.5835C0 33.8511 2.64893 36.5 5.91654 36.5H30.0835C33.3511 36.5 36 33.8511 36 30.5835V6.41654C36 3.14893 33.3511 0.5 30.0835 0.5Z'
          fill='#FEF6E6'
        />
        <path
          d='M5.91654 0.75H30.0835C33.213 0.75 35.75 3.287 35.75 6.41654V30.5835C35.75 33.713 33.213 36.25 30.0835 36.25H5.91654C2.787 36.25 0.25 33.713 0.25 30.5835V6.41654C0.25 3.287 2.787 0.75 5.91654 0.75Z'
          stroke='white'
          stroke-width='0.5'
        />
      </g>
      <g filter='url(#filter1_di_1527_1713)'>
        <path
          d='M25.0106 9.5H24.2649V10.4129H23.1013V9.5H13.497V10.3614H12.3527V9.5H10.9898C10.463 9.5 9.95776 9.70928 9.58523 10.0818C9.2127 10.4543 9.00342 10.9596 9.00342 11.4864V25.5136C9.00342 26.0404 9.2127 26.5457 9.58523 26.9182C9.95776 27.2907 10.463 27.5 10.9898 27.5H25.0106C25.5374 27.5 26.0427 27.2907 26.4152 26.9182C26.7877 26.5457 26.997 26.0404 26.997 25.5136V11.4864C26.997 10.9596 26.7877 10.4543 26.4152 10.0818C26.0427 9.70928 25.5374 9.5 25.0106 9.5ZM18.1256 24.8257C17.3296 24.8257 16.5516 24.5897 15.8898 24.1475C15.228 23.7053 14.7122 23.0768 14.4076 22.3415C14.103 21.6061 14.0233 20.797 14.1786 20.0163C14.3339 19.2357 14.7172 18.5186 15.28 17.9558C15.8428 17.393 16.5598 17.0097 17.3405 16.8545C18.1211 16.6992 18.9302 16.7789 19.6656 17.0835C20.4009 17.3881 21.0294 17.9039 21.4716 18.5657C21.9138 19.2274 22.1498 20.0055 22.1498 20.8014C22.1507 21.3301 22.0472 21.8538 21.8452 22.3425C21.6433 22.8311 21.3469 23.2751 20.9731 23.6489C20.5992 24.0228 20.1552 24.3192 19.6666 24.5211C19.178 24.723 18.6543 24.8266 18.1256 24.8257ZM25.6727 13.5564C25.6727 13.6621 25.6307 13.7635 25.556 13.8383C25.4812 13.913 25.3798 13.955 25.2741 13.955H10.9898C10.8841 13.955 10.7828 13.913 10.708 13.8383C10.6333 13.7635 10.5913 13.6621 10.5913 13.5564V12.2321C10.5913 12.1264 10.6333 12.0251 10.708 11.9503C10.7828 11.8756 10.8841 11.8336 10.9898 11.8336H25.2741C25.3798 11.8336 25.4812 11.8756 25.556 11.9503C25.6307 12.0251 25.6727 12.1264 25.6727 12.2321V13.5564ZM19.8227 20.5764H18.3184V19.0143C18.3184 18.9205 18.2812 18.8306 18.2149 18.7643C18.1486 18.698 18.0586 18.6607 17.9648 18.6607C17.8711 18.6607 17.7811 18.698 17.7148 18.7643C17.6485 18.8306 17.6113 18.9205 17.6113 19.0143V21.2836H19.8227C19.9165 21.2836 20.0064 21.2463 20.0727 21.18C20.139 21.1137 20.1763 21.0238 20.1763 20.93C20.1763 20.8362 20.139 20.7463 20.0727 20.68C20.0064 20.6137 19.9165 20.5764 19.8227 20.5764Z'
          fill='#F8B636'
        />
      </g>
      <defs>
        <filter
          id='filter0_b_1527_1713'
          x='-17'
          y='-16.5'
          width='70'
          height='70'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='8.5' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_1527_1713'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur_1527_1713'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_di_1527_1713'
          x='6.00342'
          y='7.5'
          width='27.9937'
          height='25'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='1.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0.49 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_1527_1713'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_1527_1713'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='7' dy='-2' />
          <feGaussianBlur stdDeviation='3.5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.895833 0 0 0 0 0.884635 0 0 0 0 0.884635 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_1527_1713'
          />
        </filter>
      </defs>
    </svg>
  );
};

export default TimeIcon;
