import { useFetchAssetTypes2 } from 'Assets/queries';
import { AutoCompleteSelect } from 'components/Inputs';
import { filterOptions, getOptionLabel, renderOption } from './helper';
// import { addAsset, addAttachment, getAddAssetTypes } from 'api/asset'
import useDisclosure from 'hooks/useDisclosure';
import AddAssetTypeDialog from '../Modals/AddAssetTypeModal';
import { provideErrorInfo } from './data';
import { setValue } from './helper';

const AssetType = ({
  setFieldValue,
  handleBlur,
  errors,
  touched,
  name,
  values
}) => {
  const [opened, { toggle }] = useDisclosure();
  const handleChange = (name, isFormat) => {
    return isFormat
      ? setValue(setFieldValue, name, (event) => event.target?.value)
      : setValue(setFieldValue, name);
  };
  const { data: assetTypes, refetch } = useFetchAssetTypes2({
    vehicle_type_param: false
  });
  const handleCustomClick = () => {
    toggle();
  };

  const value =
    assetTypes?.find((asset) => asset.id === values.asset_type) || {};

  const formatedValue = {
    ...value,
    label: value?.name || '',
    value: value?.id || ''
  };

  return (
    <>
      <AutoCompleteSelect
        size='small'
        label='Select Asset Type'
        name='asset_type'
        value={formatedValue}
        options={
          assetTypes
            ?.map((type) => ({
              ...type,
              label: type.name,
              value: type.id
              // ...type
            }))
            ?.filter((el) => !'vehicles'.includes(el?.label?.toLowerCase()))
            ?.filter((el) => el.vehicle_type === false) || []
        }
        onChange={(_, value) => {
          handleChange(name)(value.value);
        }}
        onBlur={handleBlur}
        inputProps={{
          ...provideErrorInfo(errors.asset_type, touched.asset_type)
        }}
        filterOptions={filterOptions}
        selectOnFocus
        id='auto-complete'
        getOptionLabel={getOptionLabel}
        renderOption={renderOption(handleCustomClick)}
      />
      {opened && (
        <AddAssetTypeDialog open={opened} toggle={toggle} refetch={refetch} />
      )}
    </>
  );
};

export default AssetType;
