import { useFetchAssetTypes2, useRemoveAssetType } from 'Assets/queries';
import { CustomButton as Button } from 'components/Button';
import CustomMenu from 'components/CustomMenu/CustomeMenu';
import Table from 'components/CustomTable/Table';
import { SearchFieldAntd } from 'components/FIlters/SearchField';
import GoBack from 'components/GoBack';
import Group from 'components/StyledComponents/ButtonGroup';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import useDebouceSearch from 'hooks/useDebouceSearch';
import useDisclosure from 'hooks/useDisclosure';
import { useState } from 'react';
import styled from 'styled-components';
import AddAssetTypeModal from '../../Modals/AddAssetTypeModal.jsx';
import DeleteModal from '../../Modals/DeleteModal.jsx';
const AssetType = () => {
  const [search, handleSearch] = useDebouceSearch();
  const [toView, setToView] = useState(null);
  const { data, isLoading, isError, refetch } = useFetchAssetTypes2({ search });
  const [open, { toggle }] = useDisclosure();
  const [openDelete, { toggle: toggleDelete }] = useDisclosure();

  const handleClose = () => {
    setToView(null);
    if (open) {
      toggle();
    }
    if (openDelete) {
      toggleDelete();
    }
  };

  const mutateAssetTypeDelete = useRemoveAssetType({
    onSuccess(res) {
      handleClose();
      refetch();
    }
  });

  const handleDelete = () => {
    mutateAssetTypeDelete.mutate({ id: toView?.id });
  };
  return (
    <Wrapper>
      <GoBack title={'Back to Settings'} />

      <div className='title'>
        <Title>Asset Type & ID</Title>
        <Text>Create new asset type and edit ID for existing asset types</Text>
      </div>
      <hr />

      <Group fullWidth jc='flex-center' items='center' className='search_block'>
        <div className='search-field'>
          <SearchFieldAntd
            onChange={(event) => handleSearch(event?.target?.value)}
            size='large'
          />
        </div>
        <Button onClick={toggle} variant='contained' size='sm'>
          Add New Asset Type
        </Button>
      </Group>
      <div style={{ marginBlock: '2rem' }}>
        <Table spacing={[3, 3, 5.5, 0.5]}>
          <Table.Head
            column={[
              { label: 'Asset Type' },
              { label: 'Asset ID' },
              { label: 'Starting Number' },
              { label: '' }
            ]}
          />
          <Table.Body
            isLoading={isLoading}
            isError={isError}
            data={data || []}
            renderRow={(row, index) => (
              <>
                <Text>{row?.name}</Text>
                <Text>{row?.id}</Text>
                <Text>{row?.starting_number || index + 1}</Text>
                <div className='menu'>
                  <CustomMenu
                    size='sm'
                    divideAll={true}
                    options={[
                      {
                        label: 'Edit',
                        onClick(_, handleClose) {
                          setToView(row);
                          toggle();
                          handleClose();
                        }
                      },
                      {
                        label: 'Delete',
                        className: 'menu_delete',
                        style: {
                          color: 'red'
                        },
                        onClick(_, handleClose) {
                          setToView(row);
                          toggleDelete();
                          handleClose();
                        }
                      }
                    ]}
                  />
                </div>
              </>
            )}
          />
        </Table>
      </div>
      {open && (
        <AddAssetTypeModal
          editData={toView}
          hasStartingNumber
          open={open}
          refetch={refetch}
          toggle={handleClose}
        />
      )}
      {openDelete && (
        <DeleteModal
          open={openDelete}
          toggle={handleClose}
          isLoading={mutateAssetTypeDelete.isLoading}
          title={`Permanently Delete (${toView?.name})`}
          desc={`Are you sure you want to delete ${toView?.name} from the list of asset types?`}
          handleDelete={handleDelete}
        />
      )}
    </Wrapper>
  );
};

export default AssetType;

const Wrapper = styled.div`
  padding-top: 2rem;
  .title {
    padding-block: 0.5rem;
  }
  .search_block {
    gap: 1.5rem;
    margin-top: 2rem;
    .search-field {
      width: min(400px, 50%);
    }
  }
`;
