import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import qs from 'query-string';
import { verifyInvite, signupInvite, acceptInvite } from '../../api/settings';
import { useStyles } from './useMuiStyle';
import { validationSchemaExisting, validationSchemaNew } from './data';

const useInviteLogic = () => {
  const classes = useStyles();
  const history = useHistory();
  const { token } = qs.parse(window.location.search);
  const [serverMessage, setServerMessage] = useState('');
  const [serverError, setServerError] = useState([]);
  const [serverResponse, setServerResponse] = useState({
    emailAddress: '',
    isNewUser: false,
    errorCode: ''
  });

  useEffect(() => {
    const data = async () => {
      try {
        if (token) {
          const payload = {
            token
          };
          const res = await verifyInvite(payload);
          if (res) {
            const { email, is_new_user } = res;
            setServerResponse({
              emailAddress: email,
              isNewUser: is_new_user
            });
          }
        } else {
          setServerMessage('Sorry, Something went wrong');
        }
      } catch (error) {
        const { status } = error.response;
        if (status === 400) {
          setServerResponse({ errorCode: 400 });
          setServerMessage('Sorry, Something went wrong');
        }
      }
    };
    data();
  }, [token]);

  const handleFormSubmit = async () => {
    const payload = {
      email: formik.values.email,
      password: formik.values.password1,
      token
    };

    try {
      const data = await signupInvite(payload);

      localStorage.clear();
      const business_domain =
        data.user.business_user_memberships[0].business_id;
      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('refresh_token', data.refresh_token);
      localStorage.setItem('business_domain', business_domain);

      if (data) {
        history.push('/dashboard');
      }
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        setServerError(data.password);
      }
    }
  };

  const handleFormSubmitExisting = async () => {
    const payload = {
      email: formik.values.email,
      password: formik.values.password1,
      token
    };

    try {
      const data = await acceptInvite(payload);
      localStorage.clear();
      const business_domain =
        data?.user?.user?.business_user_memberships[0]?.business_id;
      localStorage.setItem('access_token', data?.user?.access_token);
      localStorage.setItem('refresh_token', data?.user?.refresh_token);
      localStorage.setItem('business_domain', business_domain);

      if (data) {
        history.push('/dashboard');
      }
    } catch (error) {
      const { status, data } = error.response;
      if (status === 400) {
        setServerError(data.password);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      email: serverResponse.emailAddress,
      password1: '',
      password2: ''
    },
    validationSchema: serverResponse.isNewUser
      ? validationSchemaNew
      : validationSchemaExisting,
    onSubmit: serverResponse.isNewUser
      ? handleFormSubmit
      : handleFormSubmitExisting,
    enableReinitialize: true
  });

  return {
    formik,
    serverResponse,
    serverError,
    serverMessage,
    classes
  };
};

export default useInviteLogic;
