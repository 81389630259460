import React from 'react';
import { Box, InputAdornment, Stack } from '@mui/material';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '../Shared/Button';
import useShowPassword from './useShowPassword';
import { CustomTextField } from 'components/Inputs';
import { inputProps } from './data';
import Typography from 'components/Typography';

const NewUser = ({ classes, serverError, formik }) => {
  const { showPassword, setShowPassword, showPassword2, setShowPassword2 } =
    useShowPassword();

  const {
    handleChange,
    handleBlur,
    isSubmitting,
    touched,
    handleSubmit,
    errors,
    values: { email, password1, password2 }
  } = formik;
  return (
    <Box maxWidth={'500px'}>
      <form>
        <Stack spacing={2}>
          <div>
            <Typography variant='title' order={1}>
              Create account
            </Typography>

            {serverError && (
              <Box
                className={classes.serverError}
                style={{
                  display: serverError.length > 0 ? 'block' : 'none'
                }}
              >
                {serverError.map((error) => (
                  <Typography variant='subtitle2' component=''>
                    {error}
                  </Typography>
                ))}
              </Box>
            )}
          </div>
          <div>
            <div>
              <CustomTextField
                name='email'
                label='Email'
                size='small'
                color='secondary'
                placeholder='Email'
                value={email}
                disabled={true}
                fullWidth={true}
                variant='outlined'
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.email && errors.email}
                error={touched.email && Boolean(errors.email)}
                inputProps={{
                  ...inputProps
                }}
              />
            </div>

            <div style={{ position: 'relative' }}>
              <CustomTextField
                name='password1'
                label='Password'
                size='small'
                color='secondary'
                placeholder='Password'
                type={showPassword ? 'text' : 'password'}
                fullWidth={true}
                variant='outlined'
                value={password1}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.password1 && errors.password1}
                error={touched.password1 && Boolean(errors.password1)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <button
                        type='button'
                        style={{
                          fontSize: '14px',
                          width: '40px'
                        }}
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? 'Hide' : 'Show'}
                      </button>
                    </InputAdornment>
                  )
                }}
                inputProps={{
                  ...inputProps
                }}
              />
            </div>

            <div style={{ position: 'relative' }}>
              <CustomTextField
                name='password2'
                label='Confirm Password'
                size='small'
                color='secondary'
                placeholder='Confirm Password'
                type={showPassword2 ? 'text' : 'password'}
                fullWidth={true}
                variant='outlined'
                value={password2}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.password2 && errors.password2}
                error={touched.password2 && Boolean(errors.password2)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <button
                        type='button'
                        style={{
                          fontSize: '14px',
                          width: '40px'
                        }}
                        onClick={() => {
                          setShowPassword2(!showPassword2);
                        }}
                      >
                        {showPassword2 ? 'Hide' : 'Show'}
                      </button>
                    </InputAdornment>
                  )
                }}
                inputProps={{
                  ...inputProps
                }}
              />
            </div>
          </div>

          <div item xs={12}>
            <Button
              handleClick={handleSubmit}
              type='submit'
              disabled={isSubmitting}
              color='secondary'
              variant='contained'
            >
              {isSubmitting ? (
                <CircularProgress size={20} style={{ color: 'white' }} />
              ) : (
                'Create Password'
              )}
            </Button>
          </div>
          <Typography size='sm' light>
            By signing up, you are indicating that you have read and agreed to
            the <br />
            <strong style={{ color: '#171717' }}>
              Terms Of Use and Privacy Policy your
            </strong>
          </Typography>
        </Stack>
      </form>
    </Box>
  );
};

export default NewUser;
