import React from 'react';
import { PieChart, Pie, Cell, Label } from 'recharts';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useQuery } from 'react-query';
import { getVehicleTypesSummary } from '../../../api/fleet';
import Typography from '@material-ui/core/Typography';
import { v4 as uuid } from 'uuid';
import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from './styled.charts';

const pieColors = {
  cars: '#C2D4FF',
  buses: '#E0EEF0',
  trucks: '#FFE7E7',
  bikes: '#FCE2AF'
};

const parseData = (data = {}) => {
  return Object.keys(data).map((key) => ({
    name: key,
    value: data[key]
  }));
};

function VehiclesSummaryChart() {
  const classes = useStyles();

  const response = useQuery(
    'analytics:vehicles-summary',
    getVehicleTypesSummary
  );

  let getTotal;
  if (response.isLoading) {
    return (
      <Box style={{ height: '100%' }}>
        <Skeleton
          variant='rect'
          height={285}
          style={{ borderRadius: '10px' }}
        />
      </Box>
    );
  }

  const data = parseData(response.data);
  if (data.length !== 0) {
    getTotal = data.map((dt) => dt.value).reduce((a, b) => a + b);
  }

  if (getTotal === 0) {
    return (
      <Box className={classes.Wrapper}>
        <Typography variant='h4'>Total vehicles</Typography>
        <Grid container justify='center'>
          {/* <PieChart width={180} height={170} onMouseEnter={() => {}}>
            <Pie
              data={dafaultValue}
              innerRadius={50}
              outerRadius={80}
              fill='#A8A8A8'
              paddingAngle={0}
            >
              <Label
                value={getTotal}
                position='center'
                style={{ fontWeight: 'bold', fontSize: '2em' }}
              />
            </Pie>
          </PieChart> */}
          <Grid
            container
            xs={12}
            direction='row'
            justify='space-around'
            alignItems='center'
            alignContent='center'
            spacing={2}
            style={{ marginTop: '0px' }}
          >
            <Grid item xs={3} className={classes.info}>
              <Typography>
                <Box
                  component='span'
                  display='flex'
                  style={{
                    display: 'inline-block',
                    backgroundColor: '#A8A8A8',
                    width: 15,
                    height: 15
                  }}
                ></Box>{' '}
                <strong>0</strong>
              </Typography>
              <Typography variant='subtitle2'>Cars</Typography>
            </Grid>

            <Grid item xs={3} className={classes.info}>
              <Typography>
                <Box
                  component='span'
                  display='flex'
                  style={{
                    display: 'inline-block',
                    backgroundColor: '#A8A8A8',
                    width: 15,
                    height: 15
                  }}
                ></Box>{' '}
                <strong>0</strong>
              </Typography>
              <Typography variant='subtitle2'>Buses</Typography>
            </Grid>

            <Grid item xs={3} className={classes.info}>
              <Typography>
                <Box
                  component='span'
                  display='flex'
                  style={{
                    display: 'inline-block',
                    backgroundColor: '#A8A8A8',
                    width: 15,
                    height: 15
                  }}
                ></Box>{' '}
                <strong>0</strong>
              </Typography>
              <Typography variant='subtitle2'>Trucks</Typography>
            </Grid>

            <Grid item xs={3} className={classes.info}>
              <Typography>
                <Box
                  component='span'
                  display='flex'
                  style={{
                    display: 'inline-block',
                    backgroundColor: '#A8A8A8',
                    width: 15,
                    height: 15
                  }}
                ></Box>{' '}
                <strong>0</strong>
              </Typography>
              <Typography variant='subtitle2'>Bikes</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box className={classes.Wrapper}>
      <Typography variant='h4'>Total vehicles</Typography>
      <Grid container justify='center'>
        <PieChart width={180} height={170} onMouseEnter={() => {}}>
          <Pie
            data={data}
            innerRadius={50}
            outerRadius={80}
            fill='#878787'
            paddingAngle={-1}
            dataKey='value'
          >
            {data.map(({ name }) => (
              <Cell key={uuid()} fill={pieColors[name.toLowerCase()]} />
            ))}
            <Label
              value={getTotal}
              position='center'
              style={{ fontWeight: 'bold', fontSize: '2em' }}
            />
          </Pie>
        </PieChart>
        <Grid
          container
          xs={12}
          direction='row'
          justify='space-around'
          alignItems='center'
          alignContent='center'
          spacing={2}
          style={{ marginTop: '0px' }}
        >
          <Grid item xs={3} className={classes.info}>
            <Typography>
              <Box
                component='span'
                display='flex'
                style={{
                  display: 'inline-block',
                  backgroundColor: '#4069D0',
                  width: '10.69px',
                  height: '11.51px',
                  borderRadius: '1.64486px'
                }}
              ></Box>{' '}
              <strong>{data[0]['value']}</strong>
            </Typography>
            <Typography variant='subtitle2'>Cars</Typography>
          </Grid>

          <Grid item xs={3} className={classes.info}>
            <Typography>
              <Box
                component='span'
                display='flex'
                style={{
                  display: 'inline-block',
                  backgroundColor: '#2898A4',
                  width: '10.69px',
                  height: '11.51px',
                  borderRadius: '1.64486px'
                }}
              ></Box>{' '}
              <strong>{data[1]['value']}</strong>
            </Typography>
            <Typography variant='subtitle2'>Buses</Typography>
          </Grid>

          <Grid item xs={3} className={classes.info}>
            <Typography>
              <Box
                component='span'
                display='flex'
                style={{
                  display: 'inline-block',
                  backgroundColor: '#FF7372',
                  width: '10.69px',
                  height: '11.51px',
                  borderRadius: '1.64486px'
                }}
              ></Box>{' '}
              <strong>{data[2]['value']}</strong>
            </Typography>
            <Typography variant='subtitle2'>Trucks</Typography>
          </Grid>

          <Grid item xs={3} className={classes.info}>
            <Typography>
              <Box
                component='span'
                display='flex'
                style={{
                  display: 'inline-block',
                  backgroundColor: '#F8B636',
                  width: '10.69px',
                  height: '11.51px',
                  borderRadius: '1.64486px'
                }}
              ></Box>{' '}
              <strong>{data[3]['value']}</strong>
            </Typography>
            <Typography variant='subtitle2'>Bikes</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default VehiclesSummaryChart;
