import {
  useDeleteMaintenance,
  useDeleteReminder,
  useFetchServiceAndRepair
} from 'Assets/queries';
import { CustomButton as Button } from 'components/Button';
import { SearchFieldAntd } from 'components/FIlters/SearchField';
import { AntSelect } from 'components/FIlters/SelectField';
import Loader from 'components/Loader/Loader';
import Table from 'components/Table';
import Title from 'components/Typography/Title';
import { BLANK } from 'constant';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { capitalize } from 'utilities/Capitalized';
import { columns, maintenance, status } from './tableConfig';

const ServiceAndRepair = () => {
  const [selected, setSelected] = useState([]);
  const { data, isLoading, refetch } = useFetchServiceAndRepair({});
  const deleteMaintenanceMutation = useDeleteMaintenance({
    onSuccess() {
      refetch();
    }
  });
  const deleteReminderMutation = useDeleteReminder({
    onSuccess() {
      refetch();
    }
  });
  const history = useHistory();

  const menuHandler = (asset, mark) => {
    if (mark === 'view') {
      return history.push(
        `/assets?tab=asset-list&view=asset&id=${asset.asset}`
      );
    }
    if (mark === 'maintenance') {
      return deleteMaintenanceMutation.mutate({ id: asset?.id });
    }

    if (mark === 'reminder') {
      return deleteReminderMutation.mutate({ id: asset?.id });
    }
  };
  const handleEditMaintenance = (asset) => {
    history.push(
      `/assets?tab=maintenance&view=edit-maintenance&id=${asset?.id}`
    );
  };

  const tableColumn = columns({
    markAction: menuHandler,
    isRemiderLoading: deleteReminderMutation.isLoading,
    isMaintenanceLoadingLoading: deleteMaintenanceMutation.isLoading,
    editMaintenance: handleEditMaintenance
  });

  const handleAddMaintenance = () => {
    history.push('/assets?tab=maintenance&view=create-maintenance');
  };

  const dataSource = data?.results?.map((service) => ({
    assetName: capitalize(service?.asset_name ?? BLANK),
    assetType: capitalize(service?.name ?? BLANK),
    maintenance: service?.type ?? BLANK,
    date: service?.date ? dayjs(service?.date)?.format('DD MMM, YYYY') : BLANK,
    dueDate: service?.due_date
      ? dayjs(service?.due_date)?.format('DD MMM, YYYY')
      : BLANK,
    status: service.status,
    frequency: BLANK,
    action: service
  }));

  return (
    <ServiceAndRepairWrapper>
      <div className='header_block'>
        <Title order={3}>Service And Repair</Title>
        <Button
          size='sm'
          onClick={handleAddMaintenance}
          mx='.7rem'
          variant='contained'
        >
          Add new maintenance
        </Button>
      </div>
      <div className='search_block'>
        <SearchFieldAntd placeholder='Search by name or estimate no' />
        <AntSelect placeholder='Asset Type' />
        <AntSelect placeholder='Maintenance' options={maintenance} />
        <AntSelect placeholder='Status' options={status} />
      </div>

      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <Table
            columns={tableColumn}
            dataSource={dataSource}
            onRow={(record) => ({
              onClick: () => {}
            })}
            rowSelection={{
              onSelect: (record, selected) => {},
              type: 'checkbox',
              selectedRowKeys: selected.map((el) => el.rowKey),

              rowSelection: {
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelected((prev) => [
                    ...prev,
                    { rowKey: selectedRowKeys, ...selectedRows }
                  ]);
                },
                getCheckboxProps: (record) => ({
                  name: record.id
                })
              }
            }}
          />
        )}
      </div>
    </ServiceAndRepairWrapper>
  );
};

export default ServiceAndRepair;

const ServiceAndRepairWrapper = styled.div`
  .header_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-block: 2rem;
    gap: 0.5rem;
  }
  .search_block {
    display: flex;
    flex: 1;
    gap: 0.5rem;
    max-width: 700px;
  }
`;
