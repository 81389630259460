import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { Helmet } from 'react-helmet';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '50%',
    margin: '5em auto',
    textAlign: 'center',
    '& .MuiButton-contained': {
      width: 'auto',
      '& > *': {
        textTransform: 'capitalize',
        fontWeight: 'bold'
      }
    },
    '& .MuiTypography-h6': {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '25px',
      lineHeight: '37px',
      color: '#545454'
    },
    '& .MuiTypography-subtitle2': {
      padding: '1em 0px',
      fontSize: '16px',
      fontWeight: 400,
      fontStyle: 'normal',
      color: '#545454',
      width: '80%',
      margin: 'auto'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '2em auto',
      '& .MuiTypography-subtitle2': {
        width: '100%'
      }
    }
  },
  wrapper: {
    textAlign: 'center',
    width: '80%',
    margin: 'auto',
    '& .MuiTypography-subtitle1': {
      color: '#545454',
      fontsize: '16px',
      fontWeight: 400,
      fontStyle: 'normal',
      '& > a': {
        color: theme.palette.secondary.main,
        textDecoration: 'none',
        fontWeight: 600
      }
    }
  },
  action: {
    margin: '1em 0px',
    '& .MuiTypography-subtitle1': {
      '& > a': {
        color: theme.palette.secondary.main,
        textDecoration: 'none',
        fontWeight: 600
      }
    }
  },
  divider: {
    width: '15%',
    margin: '2em auto 1em'
  }
}));

const ConfirmSignup = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </Helmet>
      <img
        src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638791023/Bizedge_Frontend_Assets/email-confirm_sxdwiv.svg'
        alt='Confirmation email'
        width='260px'
        height='250px'
      />
      <Box className={classes.wrapper}>
        <Typography variant='h6'>Thanks for signing up</Typography>
        <Typography variant='subtitle2'>
          Your account is being setup. Simply click the link in the email we
          sent you to proceed with your registration
        </Typography>
        <Typography variant='subtitle1'>
          Didn't get a link <Link to='/account/confirmation/new'>Resend</Link>
        </Typography>
      </Box>
      <Box className={classes.divider}>
        <Divider />
      </Box>
      <Box className={classes.action}>
        <Typography variant='subtitle1'>
          Already have an account? <Link to='/login'>Sign In</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default ConfirmSignup;
