import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const SettingButton = styled(Button)`
  ${({ theme }) => `
    box-shadow: none;
    text-transform: none;
    ${theme.mixins.makeResponsive({ theme, fontSize: 15 })};
    padding: 10px 30px;
    font-weight: 700;
  `}
`;
