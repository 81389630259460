import moment from 'moment';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import SnackBar from '../../common/SnackBar';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { Box } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import {
  getVehicleMakes,
  getVehiclesMeta,
  getModels,
  updateVehicle
} from '../../api/fleet';
import { getEmployees } from '../../api/hr';
import { disableSubmit } from './utils';
import useFleetControl from '../../hooks/useFleetControl';
import { MenuItem } from '@material-ui/core';

const useStyles = makeStyles({
  foo: {
    '& .MuiGrid-container': {
      alignItems: 'center'
    },
    '& > *': {
      margin: '10px 0'
    }
    // '& .MuiGrid-item': {
    //   padding : "0 .5rem 0 0"
    // }
  }
});

function EditVehicleForm() {
  const classes = useStyles();
  // const [valueMake, setValueMake] = React.useState('');
  // const [inputValueMake, setInputValueMake] = React.useState('');
  const [fleetState, setFleetState] = useFleetControl();
  const queryClient = useQueryClient();

  const headingStyle = {
    color: '#545454',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '20px',
    margin: '.5rem 0'
  };
  const { data: vehicleMakes = [] } = useQuery(
    'vehicle-makes',
    getVehicleMakes
  );
  const [val, setVal] = useState('');
  const { data: vehicleModels = [] } = useQuery(['models', val], getModels);
  const { data } = useQuery(['employees'], getEmployees);
  const { data: vehiclesMeta } = useQuery('vehicles meta', getVehiclesMeta);
  const editMutation = useMutation(updateVehicle, {
    onSuccess: () => {
      queryClient.refetchQueries(['vehicle'], {
        id: fleetState.editVehicleDetails.id
      });
      setFleetState({ ...fleetState, modal: '' });
    }
  });
  const statuses = vehiclesMeta ? vehiclesMeta.status : [{}];
  const formik = useFormik({
    initialValues: {
      type: fleetState.editVehicleDetails.type || '',
      make: fleetState.editVehicleDetails.model.make.name || '',
      model: fleetState.editVehicleDetails.model.id || '',
      year: fleetState.editVehicleDetails.year || new Date(),
      color: fleetState.editVehicleDetails.color || '',
      plate_no: fleetState.editVehicleDetails.plate_no || '',
      start_mileage: fleetState.editVehicleDetails.start_mileage || null,
      date_purchased:
        fleetState.editVehicleDetails.date_purchased || new Date(),
      purchase_price: fleetState.editVehicleDetails.purchase_price || null,
      status: fleetState.editVehicleDetails.status || 'active',
      assignee: fleetState.editVehicleDetails.assignee || '',
      date_assigned: fleetState.editVehicleDetails.date_assigned || null
    },
    validationSchema: Yup.object({
      type: Yup.string().required('Required'),
      make: Yup.string().required('Required'),
      model: Yup.string().required('Required'),
      plate_no: Yup.string().required('Required')
    }),
    onSubmit: async (values) => {
      const year = values.year
        ? moment(formik.values.year).format('YYYY')
        : null;
      const date_purchased = values.date_purchased
        ? moment(formik.values.date_purchased).format('YYYY-MM-DD')
        : null;
      const date_assigned = values.date_assigned
        ? moment(formik.values.date_assigned).format('YYYY-MM-DD')
        : null;

      const payload = {
        ...values,
        year,
        date_purchased,
        date_assigned,
        id: fleetState.editVehicleDetails.id
      };
      editMutation.mutateAsync(payload).then((res) => res);
      // const data = await updateVehicle(payload);
      // if (data) {
      //   history.push('?tab=home');
      //
      //   // setAssetControl({ ...assetControl, toast: true, toastMessage: 'Vehicle created sucessfully' });
      // }
    }
  });

  return (
    <FormGroup className={classes.foo}>
      {editMutation.isSuccess ? (
        <SnackBar severity='success'>Vehicle Updated!</SnackBar>
      ) : null}
      <Grid container>
        <Grid item sm={12}>
          <TextField
            select
            fullWidth
            size='small'
            variant='outlined'
            name='type'
            label='Select Vehicle Type'
            color='secondary'
            value={formik.values.type}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.type && Boolean(formik.errors.type)}
            helperText={
              formik.touched.type && formik.errors ? formik.errors.type : ''
            }
          >
            <MenuItem value='bus'>Bus</MenuItem>
            <MenuItem value='car'>Car</MenuItem>
            <MenuItem value='truck'>Truck</MenuItem>
            <MenuItem value='bike'>Bike</MenuItem>
          </TextField>
        </Grid>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            {/* <TextField
              select
              fullWidth
              variant="outlined"
              size="small"
              label="Select Car Make"
            >
              </TextField> */}
            <Autocomplete
              color='secondary'
              size='small'
              id='country-select-demo'
              options={vehicleMakes}
              classes={{
                option: classes.option
              }}
              onChange={(event, newvalue) => {
                formik.setFieldValue('make', newvalue.id);
                setVal(newvalue.id);
              }}
              autoHighlight
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <React.Fragment>{option.name}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Vehicle Make'
                  variant='outlined'
                  color='secondary'
                  name='make'
                  inputProps={{
                    ...params.inputProps
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.make}
                  error={formik.touched.make && Boolean(formik.errors.make)}
                  helperText={formik.touched.make ? formik.errors.make : ''}
                />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Autocomplete
              color='secondary'
              size='small'
              id='country-select-demo'
              options={vehicleModels}
              classes={{
                option: classes.option
              }}
              onChange={(event, newvalue) =>
                formik.setFieldValue('model', newvalue.id)
              }
              autoHighlight
              getOptionLabel={(option) => option.name}
              renderOption={(option) => (
                <React.Fragment>{option.name}</React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Select Model'
                  variant='outlined'
                  color='secondary'
                  name='model'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.model}
                  error={formik.touched.model && Boolean(formik.errors.model)}
                  helperText={formik.touched.model ? formik.errors.model : ''}
                  inputProps={{
                    ...params.inputProps
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                variant='inline'
                format='yyyy'
                views={['year']}
                margin='normal'
                inputVariant='outlined'
                size='small'
                id='date-picker-inline'
                label='Year'
                name='year'
                onChange={(event) => {
                  formik.setFieldValue('year', event);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.year}
                color='secondary'
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item sm={6}>
            <TextField
              name='color'
              variant='outlined'
              label='Colour'
              value={formik.values.color}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              size='small'
            />
          </Grid>
        </Grid>
        <Box style={headingStyle}>More Details</Box>
        <Divider />
        <Grid item sm={12}>
          <TextField
            fullWidth
            label='Plate Number'
            variant='outlined'
            size='small'
            name='plate_no'
            value={formik.values.plate_no}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.plate_no && Boolean(formik.errors.plate_no)}
            helperText={formik.touched ? formik.errors.plate_no : ''}
          />
        </Grid>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <TextField
              color='secondary'
              size={'small'}
              fullWidth
              name='start_mileage'
              variant='outlined'
              label={'Mileage (Optional)'}
              value={formik.values.start_mileage}
              onChange={formik.handleChange}
              //  onFocus={onFocus}
              //  onBlur={onBlur}
            />
          </Grid>
          <Grid item sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                variant='inline'
                format='dd/MM/yyyy'
                margin='normal'
                inputVariant='outlined'
                size='small'
                id='date-picker-inline'
                name='date_purchased'
                label='Date of purchase (Optional)'
                value={formik.values.date_purchased}
                color='secondary'
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.setFieldValue('date_purchased', event);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <TextField
              label='Purchase Price (Optional)'
              fullWidth
              name='purchase_price'
              size='small'
              variant='outlined'
              value={formik.values.purchase_price}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              select
              fullWidth
              variant='outlined'
              label='Status'
              size='small'
              name='status'
              color='secondary'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.status}
            >
              {statuses &&
                statuses.map((status) => (
                  <MenuItem value={status.value}>
                    {status.display_name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
        </Grid>

        <Box style={headingStyle}>Assignee information</Box>

        <Divider />
        {/* <Collapse in={true}> */}
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <TextField
              select
              fullWidth
              variant='outlined'
              size='small'
              label='Assign To (Optional)'
              color='secondary'
              name='assignee'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.assignee}
            >
              {data &&
                data.results.map((assignee) => (
                  <MenuItem value={assignee.id}>
                    {assignee.first_name} {assignee.last_name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item sm={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                variant='inline'
                format='dd/MM/yyyy'
                margin='normal'
                name='date_assigned'
                inputVariant='outlined'
                size='small'
                id='date-picker-inline'
                label='Date Assigned (Optional)'
                onChange={(event) => {
                  formik.setFieldValue('date_assigned', event);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.date_assigned}
                color='secondary'
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        {/* </Collapse> */}
      </Grid>
      <Button
        color='secondary'
        variant='contained'
        fullWidth
        disbled={disableSubmit(formik.errors)}
        onClick={formik.handleSubmit}
      >
        {editMutation.isLoading ? 'loading...' : 'Edit Vehicle'}
      </Button>
    </FormGroup>
  );
}

export default EditVehicleForm;
