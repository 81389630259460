import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import NotFoundIcon from './icons/404';

const useStyles = makeStyles({
  root: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 2rem',
    gap: '10px',
    '& .MuiTypography-subtitle2': {
      fontSize: '18px'
    },
    '& .MuiTypography-subtitle1': {
      fontSize: '16px'
    }
  }
});

const PageNotFound = () => {
  const classes = useStyles();

  const router = useHistory();
  return (
    <Box className={classes.root}>
      <img
        src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638786088/Bizedge_Frontend_Assets/Bizedge_logo_axcwlg.svg'
        alt='bizedge logo'
        style={{ width: '135.11px', height: '33.74px' }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '90vh',
          gap: '25px'
        }}
      >
        <Box>
          <NotFoundIcon />
          <Typography variant='subtitle2'>
            The requested URL/webpage was not found
          </Typography>
          <Box mt='1rem'>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => router.push('/dashboard')}
            >
              Go back to homepage
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PageNotFound;
