import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { Wrapper } from './styled.modals';
import useFleetControl from '../../hooks/useFleetControl';
import RenewServiceForm from '../Forms/RenewServiceForm';

function RenewServiceModal({ refetchKey }) {
  const [state, setState] = useFleetControl();
  const handleClick = () => setState({ ...state, modal: '' });

  return (
    <Modal
      open={state.modal === 'RenewServiceModal'}
      onBackdropClick={handleClick}
    >
      <Wrapper>
        <Typography variant='h2' style={{ color: '#545454' }}>
          Renew Maintenance
        </Typography>
        <RenewServiceForm editMode={true} refetchKey={refetchKey} />
      </Wrapper>
    </Modal>
  );
}

export default RenewServiceModal;
