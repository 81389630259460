import { Avatar, Box } from '@material-ui/core';
import { colors } from './InitialColor/index';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

export const CustomText = styled(Box)`
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '17.1382px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '151.36%')};
  color: ${(props) => (props.color ? props.color : '#444444')};
  margin: ${(props) => props.margin};
  cursor: ${(props) => props.cursor};
`;
CustomText.propTypes = {
  fontWeight: PropTypes.string,
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  color: PropTypes.string,
  margin: PropTypes.string
};
export const FlexBox = styled(Box)`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'row')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  justify-content: ${(props) => (props.jc ? props.jc : 'space-between')};
  gap: ${(props) => (props.gap ? props.gap : '')};
`;

export const CustomButton = styled.button`
  height: ${(props) => (props.height ? props.height : '40px')};
  background: ${(props) => (props.background ? props.background : '#2898a4')};
  border-radius: 49px;
  border: ${(props) => (props.border ? props.border : 'none')};
  color: ${(props) => (props.color ? props.color : 'white')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '17.1382px')};
  line-height: 16px;
  padding: ${(props) => (props.padding ? props.padding : '0 1rem')};
  cursor: pointer;
  margin: ${(props) => props.margin};
  width: ${(props) => (props.width ? props.width : 'auto')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 'normal')};
`;

CustomButton.propTypes = {
  height: PropTypes.string,
  background: PropTypes.string,
  border: PropTypes.string,
  fontWeight: PropTypes.string,
  fontSize: PropTypes.string,
  color: PropTypes.string,
  margin: PropTypes.string,
  width: PropTypes.string
};

export const textDateFormatter = (start) => {
  let refined = moment(start).format('YYYY/MM/DD');
  const splittedStartDate = refined?.split('/');
  let startDate = moment(start, 'YYYY-MM-DD');
  let startMonthName = startDate.format('MMM');
  return `${startMonthName} ${splittedStartDate[2]}, ${splittedStartDate[0]}`;
};

export const formatDate = (objectDate) => {
  let day = objectDate.getDate();
  let year = objectDate.getFullYear();
  let month = objectDate.toLocaleString('default', { month: 'short' });
  return `${day} ${month}, ${year}`;
};
export function getIconColor(first, second, type) {
  const colors = {
    car: '#C2D4FF',
    bus: '#E0EEF0',
    truck: '#FFE7E7',
    bike: '#FCE2AF'
  };
  const letter = first.trim()[0].toUpperCase() + second.trim()[0].toUpperCase();
  return {
    letter,
    color: colors[type]
  };
}

export function getIconUser(first, second) {
  const color = colors[first?.trim()[0]?.toLowerCase()];
  const letter =
    first?.trim()[0]?.toUpperCase() + second?.trim()[0]?.toUpperCase();
  return {
    letter,
    color
  };
}

export function FleetNameIcon({
  first,
  second,
  type,
  scale = 1,
  margin,
  size = '16.25'
}) {
  const { letter, color } = type
    ? getIconColor(first, second, type)
    : getIconUser(first, second);
  return (
    <Wrapper scale={scale} color={color} margin={margin} size={size}>
      {letter}
    </Wrapper>
  );
}
export function getSingleIconUser(first = 'A') {
  const letter = first?.trim()[0]?.toUpperCase();
  return letter;
}
const Wrapper = styled(Avatar)`
  ${({ theme, color, scale, margin, size }) => `
    background: ${color || theme.palette.secondary.main};
    margin : ${margin ? margin : 0};
    color: #545454;
    height: ${scale * 50}px;
    width: ${scale * 50}px;
    font-weight: bold;
    font-size: ${size}px;
    font-family: 'Black Sans';
  line-height: 23px;
  `}
`;

export const ListWrapper = styled(FlexBox)`
  flex-wrap: wrap;
  justify-content: space-between;
  & > * {
    width: 32%;
    margin-bottom: 20px;
  }
`;
