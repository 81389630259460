import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import useInviteLogic from './useInviteLogic';
import NewUser from './NewUser';
import ExistingUser from './ExistingUser';
import { AuthFormContainer } from 'Onboarding/forms/styled.forms';
import BizedgeImage from 'common/images/bizedgeRound.svg';

const InviteVerification = () => {
  const { formik, serverResponse, serverError, serverMessage, classes } =
    useInviteLogic();

  if (serverResponse.errorCode === 400) {
    return (
      <Box className={classes.failure}>
        <Typography>{serverMessage}</Typography>
      </Box>
    );
  }

  return (
    <AuthFormContainer>
      <Box className='c-sideLeft'>
        <Box className='container'>
          <img
            src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638786088/Bizedge_Frontend_Assets/Bizedge_logo_axcwlg.svg'
            alt='logo'
            style={{ width: '100px', height: '100px', marginLeft: '9%' }}
            className='logo'
          />
          <Typography className='subtitle'>
            All in One for Ease and Effectiveness
          </Typography>
          <Box className='info'>
            <img src={BizedgeImage} alt='' />
          </Box>
          <Typography variant='subtitle2' className='intro'>
            All In One for Ease and Effectiveness
          </Typography>
        </Box>
      </Box>
      <Box className='c-sideRight'>
        <Box className='c-sideRight-form-login'>
          <Box className={classes.root}>
            <Helmet>
              <meta
                name='viewport'
                content='width=device-width, initial-scale=1'
              />
            </Helmet>

            <Box>
              {serverResponse.isNewUser && (
                <NewUser
                  classes={classes}
                  formik={formik}
                  serverError={serverError}
                />
              )}

              {!serverResponse.isNewUser && (
                <ExistingUser
                  classes={classes}
                  formik={formik}
                  serverError={serverError}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </AuthFormContainer>
  );
};

export default InviteVerification;
