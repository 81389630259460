import React from 'react';

const ProgressBar = (props) => {
  const { bgcolor, completed, height, width, borderRadius } = props;

  const containerStyles = {
    height: height ? height : '25px',
    width: width ? width : '100%',
    backgroundColor: '#e0e0de',
    // borderRadius: '2px'
    borderRadius: borderRadius ? borderRadius : '2px'
    //   margin: 50
  };

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right'
  };

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold'
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}></span>
      </div>
    </div>
  );
};

export default ProgressBar;
