import { useQuery } from 'react-query';
import { getDepartments } from '../api/hr';

const useDepartments = (params = { has_employees: false }, options = {}) => {
  const { data, isLoading } = useQuery(
    ['get-departments', params],
    () =>
      getDepartments(
        params?.has_employees ? { has_employees: params.has_employees } : {}
      ),
    {
      // refetchOnMount: false,
      // refetchOnReconnect: false,
      // refetchOnWindowFocus: false
      ...options
    }
  );
  return {
    data,
    isLoading
  };
};

export default useDepartments;
