import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { useAssignEmployee } from 'Assets/queries';
import AssignDialog from 'components/AssignModal';
import useAssignModalFilters from 'components/AssignModal/useAssignModalFilters';
import { CustomButton } from 'components/Button';
import CircularLoader from 'components/CircularLoader';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from 'components/Dialog';
import { DatePicker } from 'components/Inputs';
import Text from 'components/Typography/Text';
import { useFormik } from 'formik';
import useAllEmployees from 'hooks/useAllEmployees';
import useDisclosure from 'hooks/useDisclosure';
import styled from 'styled-components';
import { formatDate, inputPropsSetterConfig } from 'utilities/formHelpers';
import { runIfFn } from 'utilities/utils';
const AssignEmployee = ({ assetId, refetch }) => {
  const [opened, { toggle }] = useDisclosure();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      }}
    >
      <CustomButton
        onClick={toggle}
        rounded=''
        size={'lg'}
        p='.7rem 3rem'
        variant='contained'
        component='contained'
      >
        Assign
      </CustomButton>
      {opened && (
        <Employees
          assetId={assetId}
          open={opened}
          toggle={toggle}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default AssignEmployee;

const Employees = ({ open, toggle, assetId, refetch }) => {
  const [opened, { toggle: toggleAssignModal }] = useDisclosure();

  const formik = useFormik({
    initialValues: {
      assignee: '',
      date_assigned: null
    }
  });
  const setInputProps = inputPropsSetterConfig(formik);

  const { searchq, departmentId, setSearchq, setDepartmentId } =
    useAssignModalFilters();

  const query = {
    q: searchq,
    department_id: departmentId?.value || null
  };
  const employeesQuery = useAllEmployees(query, { enabled: true });

  const openModal = () => {
    toggleAssignModal();
  };

  const emp = employeesQuery?.data?.find(
    (item) => item.id === formik.values?.assignee
  );

  const mutation = useAssignEmployee({
    onSuccess: () => {
      runIfFn(toggle, refetch);
    }
  });
  const handleSave = () => {
    const payload = {
      ...formik.values,
      date_assigned: formatDate(formik.values.date_assigned)
    };
    mutation.mutate({ id: assetId, payload });
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={toggle}
        cp='20px'
        ap='20px'
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle onClose={toggle} px={3}>
          <Text as='div' bold>
            Assign Asset
          </Text>
          <Text size='xs' light={true}>
            Choose to assign asset to an employee or a department
          </Text>
        </DialogTitle>
        <DialogContent>
          <Employees.Wrapper>
            <div className='select_emp' onClick={openModal}>
              <Text size='xs'>Select Employee :</Text>
              {emp && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '.5rem'
                  }}
                >
                  <Text>{emp?.first_name}</Text>
                  <Text>{emp?.last_name}</Text>
                  <IconButton
                    size='small'
                    onClick={(e) => {
                      e.stopPropagation();
                      formik.setFieldValue('assignee', '');
                    }}
                  >
                    <CloseIcon style={{ fontSize: '18px' }} />
                  </IconButton>{' '}
                </div>
              )}
            </div>

            <div>
              <DatePicker
                inputProps={{ fullWidth: true, size: 'small' }}
                size='small'
                fullWidth
                label='Date Issued'
                {...setInputProps('date_assigned', true)}
              />
            </div>
          </Employees.Wrapper>
        </DialogContent>
        <DialogActions>
          <CustomButton
            variant={'contained'}
            fullWidth={true}
            onClick={handleSave}
            disabled={mutation.isLoading}
          >
            <CircularLoader show={mutation.isLoading} size={12} />
            Save
          </CustomButton>
        </DialogActions>
      </Dialog>
      {opened ? (
        <AssignDialog
          noMultipleSelect
          open={opened}
          toggleOpenModal={toggleAssignModal}
          moduleName='asset'
          setDepartmentFilter={setDepartmentId}
          setSearchFilter={setSearchq}
          initialSelected={
            formik.values.assignee ? [formik.values.assignee] : []
          }
          title='Select Employee'
          useModule={() => ({
            ...employeesQuery,
            data: employeesQuery.data?.map((employee) => ({
              ...employee,
              job_title: employee?.job?.title,
              department_name: employee?.department?.name
            }))
          })}
          handleSubmit={(selected) => {
            formik.setFieldValue('assignee', selected[0]);
            toggleAssignModal();
          }}
          // useModuleAssign={() => {}}
        />
      ) : null}
    </>
  );
};

Employees.Wrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  margin-top: 0.5rem;
  .select_emp {
    border-radius: 8px;
    border: 1px solid #a8a8a8;
    padding: 10.5px 10px 10.5px 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
`;
