import { InputAdornment } from '@material-ui/core';
import { CustomTextField, DatePicker } from 'components/Inputs';
import Group from 'components/StyledComponents/ButtonGroup';
import { useFormikContext } from 'formik';
import FormSectionTitle from './FormSectionTitle';

const PurchaseInformation = () => {
  const {
    values: { purchase_date, cost, vendor },
    handleChange,
    setFieldValue
  } = useFormikContext();

  return (
    <>
      <FormSectionTitle>Purchase Information</FormSectionTitle>

      <DatePicker
        inputProps={{ fullWidth: true, size: 'small' }}
        fullWidth
        label='Purchase Date'
        name='purchase_date'
        value={purchase_date}
        onChange={(value) => {
          setFieldValue('purchase_date', value);
        }}
      />
      <Group>
        <CustomTextField
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>-N-</InputAdornment>
            )
          }}
          size='small'
          name='cost'
          type='number'
          min='0'
          value={cost}
          onChange={handleChange}
          label='Cost'
        />
        <CustomTextField
          size='small'
          name='vendor'
          value={vendor}
          onChange={handleChange}
          label='Vendor (Optional)'
        />
      </Group>
    </>
  );
};

export default PurchaseInformation;
