import styled from 'styled-components';
const StyledAvatar = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  .name_title {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }
`;

export default StyledAvatar;
