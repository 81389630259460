import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useLocation } from 'react-router';

export default function TabsMenu({
  anchorEl,
  open,
  handleClose,
  style,
  options
}) {
  const location = useLocation();
  return (
    <div>
      <Menu
        id='lock-menu-'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          marginTop: 1,
          width: 400,
          padding: 0,
          '& ul': {
            paddingBlock: 0
          },
          ...style
        }}
        MenuListProps={{
          'aria-labelledby': 'menu-tab',
          role: 'tab-sub-list'
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            style={{
              paddingBlock: 10,
              fontFamily: 'Black Sans'
            }}
            selected={option?.route.includes(location.search)}
            key={option?.id}
            onClick={(event) => {
              option?.onClick(event);
              handleClose();
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
