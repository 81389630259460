import * as yup from 'yup';

export const validationSchemaNew = yup.object().shape({
  email: yup.string().email('Invalid email address').required('Required'),
  password1: yup
    .string()
    .min(8, 'Password must be min of 8 characters')
    .required('Required'),
  password2: yup
    .string()
    .oneOf(
      [yup.ref('password1'), null],
      'Password and confirm password don"t match'
    )
    .required('Required')
});

export const validationSchemaExisting = yup.object().shape({
  email: yup.string().email('Invalid email address').required('Required'),
  password1: yup
    .string()
    .min(8, 'Password must be min of 8 characters')
    .required('Required')
});

export const inputProps = {
  style: {
    backgroundColor: '#F4F4F4'
  }
};
