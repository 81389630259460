import { Box } from '@material-ui/core';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import qs from 'query-string';
import RecruitStylesheet from 'Recruit/Layout/RecruitStylesheet';
import styled from 'styled-components';
import RecruiterPage from './_RecruiterPage';
import RecruitPublicJobDetail from './__JobDetail';

const JobListingPublic = () => {
  const { job } = qs.parse(useLocation().search);

  const screen = () => {
    switch (job) {
      case 'detail':
        return <RecruitPublicJobDetail />;

      default:
        return <RecruiterPage />;
    }
  };

  return <RecruitStylesheet>{screen()}</RecruitStylesheet>;
};

export default JobListingPublic;

JobListingPublic.Wrapper = styled(Box)``;
