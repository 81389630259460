import { debounce } from 'lodash';
import { useCallback, useState } from 'react';

const useDebouceSearch = (debouceTime = 500, props) => {
  const [search, setSearch] = useState(() => props?.initialValue ?? '');
  const [inputValue, setInputValue] = useState(() => props?.initialValue ?? '');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebounce = useCallback(
    debounce((value) => {
      setSearch(value);
    }, debouceTime),
    [search]
  );

  const handleSearch = (value) => {
    setInputValue(value);
    handleDebounce(value);
  };

  return [search, handleSearch, inputValue, setInputValue];
};

export default useDebouceSearch;
