import { useAssetAssignees } from 'Assets/queries';
import Avatar from 'common/Avatar/Avatar';
import Table, { TableEmpty } from 'components/CustomTable/Table';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import { NOT_AVAILABLE } from 'constant';
import dayjs from 'dayjs';

const columns = [
  'Assignee Name',
  'Start Date',
  'End Date',
  'Department',
  'Location'
];
const AssigneeHistory = ({ assetId }) => {
  const { data, isLoading, isError } = useAssetAssignees({ assetId });
  const isEmpty = data?.results?.length < 1;

  return (
    <div>
      <Table>
        {isEmpty ? (
          <TableEmpty>
            <Title>You have no assignee history</Title>
          </TableEmpty>
        ) : (
          <>
            <Table.Head column={columns} />
            <Table.Body
              isLoading={isLoading}
              isError={isError}
              data={data?.results ?? []}
              renderRow={(row) => {
                return (
                  <>
                    <Avatar {...row?.assignee} />
                    <Text>
                      {dayjs(row?.date_assigned).format('DD MMM, YYYY')}
                    </Text>
                    <Text>
                      {row.end_date
                        ? dayjs(row?.end_date).format('DD MMM, YYYY')
                        : NOT_AVAILABLE}
                    </Text>
                    <Text>{row?.assignee?.department?.name} </Text>
                    <Text>{row?.assignee?.region?.name}</Text>
                  </>
                );
              }}
            />
          </>
        )}
      </Table>
    </div>
  );
};

export default AssigneeHistory;
