import { Box, Tab, Tabs } from '@mui/material';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { indicator, tabsStyle } from './tabstyle';

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
const Label = ({ menu }) => {
  return <TabWrapper>{menu.label || menu.name} </TabWrapper>;
};

Label.propTypes = {
  menu: PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
    index: PropTypes.bool
  }).isRequired
};

export default function BasicTabs({ menus = [], variant, activeStyle }) {
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname;
  const [tabValue, setTabValue] = useState(
    () =>
      menus?.findIndex(
        (el) => el.route === path || el?.matches?.includes(path)
      ) || 0
  );

  const handleChange = (event, menu, index) => {
    setTabValue(index);
    history.push(menu?.route);
  };

  return (
    <TabNavigation sx={{ width: '100%' }}>
      <Tabs
        sx={tabsStyle}
        {...(variant ? { variant: 'scrollable' } : {})}
        scrollButtons
        allowScrollButtonsMobile
        aria-label='scrollable force tabs example'
        value={tabValue}
        indicatorColor={'#2898A4'}
        // customize the styles of the indicator
        indicator={indicator}
      >
        {menus.map((menu, index) => {
          return (
            <Tab
              className='tab'
              label={<Label menu={menu} />}
              onClick={(event) => handleChange(event, menu, menu.id ?? index)}
              key={index}
              sx={[
                {
                  fontSize: '16px  !important',
                  textTransform: 'capitalize !important'
                },
                { fontFamily: 'var(--black-family)  !important' },
                { marginRight: '1.5rem' },
                index === tabValue
                  ? activeStyle ?? defaultActiveStyle
                  : inactiveStyle
              ]}
            />
          );
        })}
      </Tabs>
    </TabNavigation>
  );
}

const defaultActiveStyle = {
  fontWeight: 600,
  color: '#2898A4',
  opacity: 1,
  background: '#EAF8FA',
  borderBottom: '1px solid #2898A4',
  borderRadius: '5px  5px 0px 0px'
};
const inactiveStyle = {
  color: '#878787'
};

BasicTabs.propTypes = {
  menus: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      route: PropTypes.string,
      index: PropTypes.bool,
      hasDropDown: PropTypes.bool
    })
  ).isRequired,
  activeStyle: PropTypes.object,
  variant: PropTypes.string,
  sx: PropTypes.object
};

const TabNavigation = styled(Box)`
  left: 0;
  top: 65px;
  z-index: 100;
  width: 100vw;
  position: fixed;
  background: #ffffff;
  padding-top: 10px;
  border-bottom: 1px solid #e1e1e1;

  .tab {
    font-family: 'Black Sans';
    font-style: normal;

    :hover {
      background: #eaf8fa;
      border-radius: 8px 8px 0px 0px;
    }
  }
`;

/**
 * origin active inactice style 
 * 
 *  // location.search === ""
                  //   ? index === 0
                  //     ? activeStyle ?? defaultActiveStyle
                  //     : location.pathname === menu.route
                  //     ? activeStyle
                  //     : inactiveStyle
                  //   : menu.route.includes(location.search.split("&view")[0])
                  //   ? activeStyle ?? defaultActiveStyle
                  //   : inactiveStyle,
 */
