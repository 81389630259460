import { SNACK_VARIANT } from 'constant';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { apiErrorHandler } from 'utilities/apiErrorHandler';
import { runIfFn } from 'utilities/utils';

const getSuccessMsg = (msg, { isEdit, res, payload }) => {
  let succMsg = '';
  if (typeof msg === 'string') {
    succMsg = msg;
  } else if (typeof msg === 'function') {
    succMsg = msg(isEdit, { res, payload });
  }

  return succMsg ?? 'Success';
};

const mutationHOC = ({
  successMsg = () => {},
  errMsg = '',
  editFn = () => {},
  createFn = () => {},
  mutateFn,
  report = true
}) => {
  return function (options) {
    const { enqueueSnackbar } = useSnackbar();
    const isEdit = Boolean(options?.editId) ?? options?.isEdit;

    return useMutation(
      isEdit
        ? (payload) => editFn(payload, options?.editId)
        : mutateFn ?? createFn,
      {
        onSuccess(res, payload) {
          runIfFn.bind([res])(options?.onSuccess);
          if (options?.successMsg || successMsg) {
            if (options?.noMessage) return;
            enqueueSnackbar(
              getSuccessMsg(options?.successMsg ?? successMsg, {
                isEdit,
                res,
                payload
              }),
              {
                variant: SNACK_VARIANT.SUCC
              }
            );
          }
        },
        onError(err) {
          const apiErrorMsg = apiErrorHandler(err);
          enqueueSnackbar(
            errMsg
              ? typeof errMsg === 'function'
                ? errMsg(err, { apiErrorMsg, isEdit })
                : errMsg
              : apiErrorHandler(err),
            {
              variant: SNACK_VARIANT.ERR
            }
          );
          runIfFn.bind([err])(options?.onError);
        }
      }
    );
  };
};

export default mutationHOC;
