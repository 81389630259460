import React from 'react';
import { ChipText } from './styled.forms';
import Box from '@material-ui/core/Box';

const options1 = [
  'Myself',
  '1-20',
  '21-50',
  '51-100',
  '101-200',
  '201-500',
  '501-999',
  '1000 and above'
];

const options2 = [
  'LinkedIn',
  'Facebook',
  'Instagram',
  'Affilate',
  'Twitter',
  'Referrals'
];
const People = ({ isSocials, formik }) => {
  const options = isSocials ? options2 : options1;
  return (
    <Box
      display='flex'
      sx={{ flexDirection: { xs: 'column', lg: 'row' } }}
      // flexDirection={{ xs: 'column', lg: 'row' }}
      style={{ gap: 11 }}
      flexWrap='wrap'
    >
      {options.map((item) => (
        <ChipText
          active={
            formik.values[isSocials ? 'point_of_contact' : 'size'] === item
          }
          onClick={() => {
            formik.setFieldValue(isSocials ? 'point_of_contact' : 'size', item);
          }}
        >
          {item}
        </ChipText>
      ))}
    </Box>
  );
};

export default People;
