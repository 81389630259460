import React from 'react';
import MaintenanceSvg from './Maintenance.svg';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
    textAlign: 'center',

    '& > h1': {
      marginBottom: '1rem',
      fontWeight: '600',
      fontSize: '4rem',
      color: theme.palette.secondary.main,
      '@media (max-width: 600px)': {
        fontSize: '2.5rem'
      }
    },

    '& > h2': {
      fontSize: '1.5rem',
      fontWeight: '400',
      lineHeight: 1.6,

      '@media (max-width: 600px)': {
        fontSize: '1.2rem'
      }
    },
    '& > p': {
      opacity: '0.9',
      fontSize: '1rem',
      '@media (max-width: 600px)': {
        fontSize: '.8rem'
      }
    }
  }
}));

const Maintenance = () => {
  const classes = useStyle();
  return (
    <Container maxWidth='sm'>
      <Box className={classes.root}>
        <img src={MaintenanceSvg} alt='maintenance logo' />
        <h1>Oops!</h1>
        <h2>
          we are under maintenance at the moment. We apologise for any
          inconvenience.
        </h2>
        <p>Please check back in a few moment.</p>
      </Box>
    </Container>
  );
};

export default Maintenance;
