import { CustomButton as Button } from 'components/Button';
import CircularLoader from 'components/CircularLoader';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from 'components/Dialog';
import { CustomTextField } from 'components/Inputs';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import { useFormik } from 'formik';
import { runIfFn } from 'utilities/utils';
import { useCreateDocumentType } from '../queries';

const AddNewStatutoryType = ({ open, toggle, refetch, editData }) => {
  const {
    values: { name },
    handleChange
  } = useFormik({
    initialValues: { name: editData?.name ?? '' },
    validator: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = 'Required';
      }
      return errors;
    }
  });

  const mutation = useCreateDocumentType({
    editId: editData?.id,
    onSuccess: () => {
      runIfFn(refetch, toggle);
    }
  });

  const isEdit = !!editData;

  return (
    <>
      <Dialog open={open} fullWidth maxWidth='xs' cp={'2rem'} onClose={toggle}>
        <DialogTitle px={4} iconFlex onClose={toggle}>
          <span>
            <Title>{isEdit ? 'Edit' : 'Add New'} Statutory Information</Title>
            <Text size='xs'>Enter details of information </Text>
          </span>
        </DialogTitle>
        <DialogContent>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
          >
            <div>
              <CustomTextField
                size='small'
                placeholder='Monitor'
                name='name'
                value={name}
                label='Enter information name'
                onChange={handleChange}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!name || mutation.isLoading}
            bold
            size='sm'
            fullWidth
            variant='contained'
            onClick={() =>
              mutation.mutate(
                editData?.id ? { name, editId: editData?.id } : { name }
              )
            }
          >
            <CircularLoader size={15} show={mutation.isLoading} />
            {isEdit ? 'Save Edit' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddNewStatutoryType;
