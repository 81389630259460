import qs from 'query-string';
import { useLocation } from 'react-router';

const useCheckEdit = (field = 'view') => {
  const { search } = useLocation();
  const isEditView = !!qs.parse(search)[field]?.includes('edit');

  return isEditView;
};

export default useCheckEdit;
