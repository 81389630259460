import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

function VehicleModel({ width, name, formik, models = [], error }) {
  return (
    <FormControl
      error={error}
      variant='outlined'
      size={isWidthDown('lg', width) ? 'small' : 'medium'}
      color='secondary'
      fullWidth
    >
      <InputLabel id='vehicle-model'>Select Vehicle Model</InputLabel>
      <Select
        labelId='vehicle-model'
        label='Make'
        size='small'
        name={name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      >
        {models.map((model) => (
          <MenuItem value={model.id}>{model.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default withWidth()(VehicleModel);
