import React from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import AuthContextProvider from './Auth/auth-context';
import PermissionContextProvider from './Auth/permission-context';
import BusinessDetailProvider from './context/Business-context';
import './index.css';

process.env.NODE_ENV !== 'development' &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  });

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={client}>
    <AuthContextProvider>
      <PermissionContextProvider>
        <BusinessDetailProvider>
          <BrowserRouter>
            <Toaster />
            <App />
          </BrowserRouter>
        </BusinessDetailProvider>
      </PermissionContextProvider>
    </AuthContextProvider>
  </QueryClientProvider>
  // </React.StrictMode>
);
