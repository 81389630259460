import React from 'react';

const HrIco = () => {
  return (
    <svg
      width='36'
      height='37'
      viewBox='0 0 36 37'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.91654 0.75H30.0835C33.213 0.75 35.75 3.287 35.75 6.41654V30.5835C35.75 33.713 33.213 36.25 30.0835 36.25H5.91654C2.787 36.25 0.25 33.713 0.25 30.5835V6.41654C0.25 3.287 2.787 0.75 5.91654 0.75Z'
        fill='#EBEFFA'
        stroke='white'
        stroke-width='0.5'
      />
      <g filter='url(#filter0_di_1527_1561)'>
        <path
          d='M10.4795 18.7828C11.7647 18.7828 12.8066 17.7409 12.8066 16.4557C12.8066 15.1704 11.7647 14.1285 10.4795 14.1285C9.19423 14.1285 8.15234 15.1704 8.15234 16.4557C8.15234 17.7409 9.19423 18.7828 10.4795 18.7828Z'
          fill='#ADBFEB'
        />
        <path
          d='M15.8861 25.4556C15.9111 24.7295 15.7897 24.0058 15.5292 23.3276C15.2686 22.6494 14.8741 22.0307 14.3693 21.5082C13.8645 20.9857 13.2597 20.5701 12.5909 20.2863C11.9221 20.0025 11.203 19.8563 10.4765 19.8563C9.75 19.8563 9.03092 20.0025 8.36212 20.2863C7.69333 20.5701 7.08851 20.9857 6.58371 21.5082C6.07891 22.0307 5.68447 22.6494 5.42388 23.3276C5.16329 24.0058 5.0419 24.7295 5.06694 25.4556C6.73382 26.3371 8.59089 26.7979 10.4765 26.7979C12.3622 26.7979 14.2192 26.3371 15.8861 25.4556Z'
          fill='#ADBFEB'
        />
        <path
          d='M25.216 19.0342C26.5707 19.0342 27.6688 17.936 27.6688 16.5814C27.6688 15.2267 26.5707 14.1285 25.216 14.1285C23.8614 14.1285 22.7632 15.2267 22.7632 16.5814C22.7632 17.936 23.8614 19.0342 25.216 19.0342Z'
          fill='#ADBFEB'
        />
        <path
          d='M30.9351 25.383C30.8841 23.9048 30.2611 22.5042 29.1973 21.4765C28.1336 20.4488 26.7124 19.8744 25.2333 19.8744C23.7542 19.8744 22.333 20.4488 21.2692 21.4765C20.2055 22.5042 19.5825 23.9048 19.5315 25.383C21.2884 26.3121 23.2458 26.7978 25.2333 26.7978C27.2208 26.7978 29.1781 26.3121 30.9351 25.383Z'
          fill='#ADBFEB'
        />
        <path
          d='M17.7958 16.4685C19.7201 16.4685 21.28 14.9085 21.28 12.9842C21.28 11.06 19.7201 9.5 17.7958 9.5C15.8715 9.5 14.3115 11.06 14.3115 12.9842C14.3115 14.9085 15.8715 16.4685 17.7958 16.4685Z'
          fill='#4069D0'
        />
        <path
          d='M25.8954 25.494C25.8954 23.3458 25.042 21.2856 23.523 19.7665C22.0039 18.2475 19.9437 17.3941 17.7955 17.3941C15.6472 17.3941 13.587 18.2475 12.068 19.7665C10.5489 21.2856 9.69556 23.3458 9.69556 25.494C12.1922 26.8114 14.9726 27.4999 17.7955 27.4999C20.6184 27.4999 23.3987 26.8114 25.8954 25.494Z'
          fill='#4069D0'
        />
      </g>
      <defs>
        <filter
          id='filter0_di_1527_1561'
          x='2.06372'
          y='7.5'
          width='35.8713'
          height='24.9999'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='1.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0.49 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_1527_1561'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_1527_1561'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='7' dy='-2' />
          <feGaussianBlur stdDeviation='3.5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.895833 0 0 0 0 0.884635 0 0 0 0 0.884635 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_1527_1561'
          />
        </filter>
      </defs>
    </svg>
  );
};

export default HrIco;
