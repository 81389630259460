import DateFnsUtils from '@date-io/date-fns';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import MuiAlert from '@material-ui/lab/Alert';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { AntdSelect } from 'components/Inputs';
import 'date-fns';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
  createVehicle,
  getModels,
  getVehicleMakes,
  getVehiclesMeta
} from '../../api/fleet';
import { getEmployees } from '../../api/hr';
import useFleetControl from '../../hooks/useFleetControl';
import { disableSubmit } from '../Forms/utils';
import { CreateVehicleButton } from '../VehicleList/styled.vehicle-list';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    '& .MuiIconButton-root': {
      padding: '6px'
    }
  },
  Header: {
    // ...theme.mixins.makeResponsive({ theme, fontSize: 22, object: true }),
    // fontWeight: 700
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20.25px',
    lineHeight: '23px'
  },
  vehicleType: {
    textTransform: 'capitalize'
  },
  foo: {
    '& .MuiFormControl-fullWidth': {
      margin: '1% 0px'
    },
    '& > *': {
      margin: '1px 0'
    },
    '& .MuiTypography-subtitle1': {
      // fontStyle: 'normal',
      // fontWeight: 'bold',
      // fontSize: '18px',
      // lineHeight: '20px',
      // color: '#545454'
    },
    '& .MuiFormControl-marginNormal': {
      width: '100%'
    },
    '& .MuiTypography-colorPrimary': {
      // color: 'blue',
      // border: '1px solid red'
    },
    '& .MuiPickersDay-daySelected': {
      color: 'green !important'
    }
  }
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      // isNumericString
    />
  );
}

function CreateVehicleForm({ width, editMode = false }) {
  const classes = useStyles();
  const history = useHistory();
  const minYear = '1992';
  const maxYear = '2030';
  const [datePurchased, setDatePurchased] = useState(null);
  const [year, setYear] = useState(null);
  const getAssigneeMutation = useMutation(getEmployees, {});
  // const apiMethod = editMode ? updateVehicreateVehicle;
  const [focused, setFocused] = useState({
    start_mileage: false,
    assignee: false,
    color: false
  });

  useEffect(() => {
    getAssigneeMutation.mutateAsync({ status: 'active' }).then((res) => res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFocus = (e) => setFocused({ ...focused, [e.target.name]: true });
  const onBlur = (e) => {
    setFocused({ ...focused, [e.target.name]: false });
  };

  const [fleetState, setFleetState] = useFleetControl();

  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: {
      type: '',
      make: '',
      model: '',
      year: '',
      color: '',
      plate_no: '',
      start_mileage: null,
      date_purchased: '',
      purchase_price: null,
      status: 'active',
      assignee: '',
      date_assigned: null
    },
    validationSchema: Yup.object({
      type: Yup.string().required('Required'),
      make: Yup.string().required('Required'),
      model: Yup.string().required('Required'),
      plate_no: Yup.string().required('Required')
    }),
    onSubmit: async (values) => {
      const year = values.year
        ? Number(moment(formik.values.year).format('YYYY'))
        : null;
      const date_purchased = values.date_purchased
        ? moment(formik.values.date_purchased).format('YYYY-MM-DD')
        : null;
      const date_assigned = values.date_assigned
        ? moment(formik.values.date_assigned).format('YYYY-MM-DD')
        : values.assignee && !values.date_assigned
        ? moment(new Date()).format('YYYY-MM-DD')
        : null;

      const payload = {
        ...values,
        year,
        date_purchased,
        date_assigned
      };
      const data = await createVehicle(payload);
      if (data) {
        history.push('?tab=home');
        queryClient.refetchQueries(['vehicles']);
        setFleetState({
          ...fleetState,
          modal: '',
          toast: true,
          toastMessage: 'Vehicle has been added sucessfully',
          severity: 'success'
        });
      }
    }
  });

  const { data } = useQuery(['employees'], getEmployees);
  const { data: vehicleMakes } = useQuery(
    ['vehicle-makes', formik.values.type],
    getVehicleMakes
  );
  const { data: vehiclesMeta } = useQuery('vehicles meta', getVehiclesMeta);
  const { data: vehicleModels } = useQuery(
    ['models', formik.values.type, formik.values.make],
    getModels
  );

  const statuses = vehiclesMeta ? vehiclesMeta.status : [{}];

  const handleBackHome = () => {
    history.goBack();
  };

  const handleClose = () => {
    setFleetState({ ...fleetState, toast: false });
  };

  const types = ['bike', 'car', 'bus', 'truck'];

  return (
    <CreateVehicleForm.Wrapper>
      <Grid container style={{ alignItems: 'center' }}>
        <Grid item>
          <IconButton aria-label='Go back' onClick={handleBackHome}>
            <KeyboardBackspaceOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography
            variant='h2'
            color='secondary'
            style={{ color: '#545454' }}
            className={classes.Header}
          >
            Add Vehicle
          </Typography>
        </Grid>
      </Grid>
      <Box
        className={classes.wrapper}
        style={{ width: '50%', margin: '.5em auto' }}
      >
        <FormGroup onSubmit={formik.handleSubmit} className={classes.foo}>
          <Box style={{ marginBottom: '10px' }}>
            <Typography variant='subtitle1' style={{ marginBottom: '10px' }}>
              <strong>Vehicle Details</strong>
            </Typography>
            <Divider />
            <Grid container spacing={2} style={{ marginTop: '.0rem' }}>
              <Grid item sm={12} className={classes.vehicleDetails}>
                <TextField
                  select
                  fullWidth
                  variant='outlined'
                  size='small'
                  name='type'
                  color='secondary'
                  label='Vehicle Type'
                  value={formik.values.type}
                  onChange={(event) => {
                    formik.setFieldValue('type', event.target.value);
                    // handleVehicleType(event.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.type && Boolean(formik.errors.type)}
                  helperText={
                    formik.touched.type && formik.errors
                      ? formik.errors.type
                      : ''
                  }
                >
                  {types.map((type) => (
                    <MenuItem value={type}>
                      <Typography className={classes.vehicleType}>
                        {type}
                      </Typography>
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid item sm={12}>
              <TextField
                select
                fullWidth
                variant='outlined'
                color='secondary'
                name='make'
                size='small'
                label='Select Vehicle Brand'
                onChange={(event) => {
                  formik.setFieldValue('make', event.target.value);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.make}
                error={formik.touched.make && Boolean(formik.errors.make)}
                helperText={formik.touched.make && formik.errors.make}
                disabled={!formik.values.type}
              >
                {vehicleMakes ? (
                  vehicleMakes.map((make) => (
                    <MenuItem key={make.id} value={make.id}>
                      {make.name}
                    </MenuItem>
                  ))
                ) : (
                  <Typography
                    variant='subtitle2'
                    style={{ padding: '.8em 1em' }}
                  >
                    Loading...
                  </Typography>
                )}
              </TextField>
            </Grid>
            <Grid item sm={12}>
              <TextField
                select
                fullWidth
                variant='outlined'
                size='small'
                name='model'
                label='Select Vehicle Model'
                color='secondary'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.model}
                error={formik.touched.model && Boolean(formik.errors.model)}
                helperText={formik.touched.model && formik.errors.model}
                disabled={!formik.values.make}
              >
                {vehicleModels ? (
                  vehicleModels.map((model) => (
                    <MenuItem value={model.id}>{model.name}</MenuItem>
                  ))
                ) : (
                  <Typography
                    variant='subtitle2'
                    style={{ padding: '.8em 1em' }}
                  >
                    Loading...
                  </Typography>
                )}
              </TextField>
            </Grid>

            <Grid item sm={12}>
              <TextField
                color='secondary'
                size={isWidthDown('lg', width) ? 'small' : 'medium'}
                fullWidth
                variant='outlined'
                label='Registration Number'
                name='plate_no'
                value={formik.values.plate_no}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.plate_no && Boolean(formik.errors.plate_no)
                }
                helperText={formik.touched.plate_no && formik.errors.plate_no}
              />
            </Grid>
            <Grid container style={{ margin: '1em 0px 1.8em' }}>
              <Grid item sm={12}>
                <Divider />
              </Grid>
            </Grid>
            <Box className='flex'>
              <TextField
                color='secondary'
                size='small'
                fullWidth
                name='purchase_price'
                variant='outlined'
                label={
                  focused.purchase_price || formik.values.purchase_price
                    ? 'Purchase Price'
                    : 'Purchase Price (Optional)'
                }
                value={formik.values.purchase_price}
                onChange={formik.handleChange}
                onFocus={onFocus}
                onBlur={onBlur}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  startAdornment: (
                    <InputAdornment position='start'>-N-</InputAdornment>
                  )
                }}
              />

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  variant='inline'
                  format='dd/MM/yyyy'
                  margin='normal'
                  inputVariant='outlined'
                  size='small'
                  id='date-picker-inline'
                  name='date_purchased'
                  label='Date of purchase (Optional)'
                  value={datePurchased}
                  color='secondary'
                  onBlur={formik.handleBlur}
                  onChange={(event) => {
                    setDatePurchased(event);
                    formik.setFieldValue('date_purchased', event);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box className='flex'>
              <TextField
                color='secondary'
                size={isWidthDown('lg', width) ? 'small' : 'medium'}
                fullWidth
                name='start_mileage'
                variant='outlined'
                label={
                  focused.start_mileage || formik.values.start_mileage
                    ? 'Mileage'
                    : 'Mileage (Optional)'
                }
                value={formik.values.start_mileage}
                onChange={formik.handleChange}
                onFocus={onFocus}
                onBlur={onBlur}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  variant='inline'
                  format='yyyy'
                  views={['year']}
                  // margin='normal'
                  inputVariant='outlined'
                  size='small'
                  id='date-picker-inline'
                  label='Year'
                  name='year'
                  minDate={new Date(minYear)}
                  maxDate={new Date(maxYear)}
                  onChange={(event) => {
                    setYear(event);
                    formik.setFieldValue('year', event);
                  }}
                  onBlur={formik.handleBlur}
                  value={year}
                  color='secondary'
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  style={{
                    // width: '100%',
                    margin: '10px 0 31px 0'
                  }}
                  className='year'
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box className='flex'>
              <TextField
                select
                fullWidth
                color='secondary'
                variant='outlined'
                size='small'
                label='Status'
                name='status'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.status}
              >
                {statuses &&
                  statuses.map((status) => (
                    <MenuItem value={status.value}>
                      {status.display_name}
                    </MenuItem>
                  ))}
              </TextField>
              <TextField
                color='secondary'
                size='small'
                fullWidth
                name='color'
                variant='outlined'
                label={
                  focused.color || formik.values.color
                    ? 'Colour'
                    : 'Colour (Optional)'
                }
                value={formik.values.color}
                onChange={formik.handleChange}
                onBlur={onBlur}
                onFocus={onFocus}
              />
            </Box>
          </Box>
          <Box>
            <Typography variant='subtitle1' style={{ marginBottom: '10px' }}>
              <strong>Assignee Information</strong> <span>(Optional)</span>
            </Typography>
            {/* <Divider /> */}
            <Box className='flex' style={{ alignItems: 'baseline' }}>
              {/* <TextField
                select
                fullWidth
                variant='outlined'
                color='secondary'
                size='small'
                label='Assignee'
                name='assignee'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.assignee}
                disabled={data?.count === 0}
              >
                {data &&
                  data.results.map((assignee) => (
                    <MenuItem value={assignee.id}>
                      {assignee.first_name} {assignee.last_name}
                    </MenuItem>
                  ))}
              </TextField> */}
              {/* <AsyncSelect
                className={classes.assigneeSelect}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#2898A4',
                    primary25: '#2898A4'
                  }
                })}
                placeholder='Assignee'
                cacheOptions
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                isClearable
                value={getAssigneeMutation?.data?.results?.find(
                  (el) => el.id === formik.values.assignee
                )}
                loadOptions={loadOptions}
                defaultOptions={getAssigneeMutation.data?.results}
                onChange={(event) => {
                  formik.setFieldValue('assignee', event.id);
                  // setAssignee(event.value);
                }}
                name='assignee'
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) =>
                  `${option.first_name} ${option.last_name}`
                }
                maxMenuHeight={190}
                isSearchable
              /> */}
              <AntdSelect
                showSearch
                allowClear
                placeholder='Assignee'
                size='large'
                name='assignee'
                loading={getAssigneeMutation.isLoading}
                options={
                  getAssigneeMutation.data?.results?.map((el) => ({
                    value: el.id,
                    label: `${el.first_name} ${el.last_name}`
                  })) || []
                }
                onChange={(event) => {
                  formik.setFieldValue('assignee', event);
                }}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />

              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                style={{ width: '100%' }}
              >
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  variant='inline'
                  name='date_assigned'
                  format='dd/MM/yyyy'
                  margin='normal'
                  inputVariant='outlined'
                  size='small'
                  id='date-picker-inline'
                  label='Date Assigned'
                  color='secondary'
                  onBlur={formik.handleBlur}
                  disabled={data?.count === 0}
                  onChange={(event) => {
                    formik.setFieldValue('date_assigned', event);
                  }}
                  value={formik.values.date_assigned}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Box>
          {/* <Box }> */}
          <Box
            textAlign='end'
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            // mr='.7rem'
            // width='50%'
          >
            <CreateVehicleButton
              variant='outlined'
              color='secondary'
              disbled={disableSubmit(formik.errors)}
              onClick={handleBackHome}
              style={{
                width: '30%',
                fontWeight: 'bold',
                height: '40px',
                marginRight: '20px'
              }}
            >
              Back
            </CreateVehicleButton>
            <CreateVehicleButton
              variant='contained'
              color='secondary'
              type='submit'
              disbled={disableSubmit(formik.errors)}
              onClick={formik.handleSubmit}
              style={{
                width: '30%',
                fontWeight: 'bold',
                height: '40px'
              }}
              // startIcon={
              //   mutation.isLoading ? (
              //     <CircularProgress style={{ color: 'white' }} />
              //   ) : null
              // }
            >
              {/* {mutation.isLoading ? null : 'Save'} */}
              {formik.isSubmitting ? (
                <CircularProgress style={{ color: 'white' }} size={20} />
              ) : (
                'Save'
              )}
            </CreateVehicleButton>
          </Box>
          {/* </Box> */}
        </FormGroup>
      </Box>
      <Snackbar
        open={fleetState.toast}
        onClose={handleClose}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert
          onClose={handleClose}
          severity={fleetState.severity}
          elevation={6}
          variant='filled'
        >
          {fleetState.toastMessage}
        </MuiAlert>
      </Snackbar>
    </CreateVehicleForm.Wrapper>
  );
}

export default withWidth()(CreateVehicleForm);

CreateVehicleForm.Wrapper = styled(Box)`
  margin-bottom: 2rem;
  .flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 0 11px 0;
    & > * {
      width: 48%;
      margin: 0;
      margin-bottom: 0 !important;
      &.MuiTextField-root {
        margin: 5px 0 !important;
      }
    }
  }

  .MuiTextField-root {
    margin: 11px 0 !important;
  }
  .year {
    margin-bottom: 21px !important;
  }
`;
