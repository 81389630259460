import CircularLoader from 'components/CircularLoader';
import CustomMenu from 'components/CustomMenu/CustomeMenu';
import StatusChip from 'components/StatusChip';
import { capitalize } from 'utilities/Capitalized';

const serviceRepairStatus = {
  pending: { bgColor: '', color: '' },
  upcoming: { bgColor: '#EBEFFA', color: '#4069D0' },
  overdue: { bgColor: '#FFE5E5', color: '#FF6666' }
};
export const columns = ({
  markAction,
  isReminderLoading,
  isMaintenanceLoading,
  editMaintenance
}) => [
  {
    title: 'Asset Name',
    dataIndex: 'assetName'
  },
  {
    title: 'Asset Type',
    dataIndex: 'assetType'
  },

  {
    title: 'Maintenance',
    dataIndex: 'maintenance'
  },
  {
    title: 'Date',
    dataIndex: 'date'
  },

  {
    title: 'Due Date ',
    dataIndex: 'dueDate'
  },
  {
    title: 'Reminder Status',
    dataIndex: 'status',
    render(status) {
      const style = serviceRepairStatus[status];
      return <StatusChip {...style}>{capitalize(status)}</StatusChip>;
    }
  },
  // {
  //   title: 'Frequency',
  //   dataIndex: 'frequency'
  // },
  {
    title: '',
    dataIndex: 'action',
    render: (asset) => {
      return (
        <CustomMenu
          size={'sm'}
          innerWidth={220}
          divideAll
          stopPropagation
          options={[
            {
              label: 'View Asset',
              onClick: () => {
                markAction(asset, 'view');
              }
            },
            {
              label: 'Edit Maintenance',
              onClick: () => {
                editMaintenance(asset);
              }
            },
            {
              label: (
                <>
                  <CircularLoader show={isReminderLoading} />
                  Cancel reminder
                </>
              ),
              onClick: (_, handleClose) => {
                markAction(asset, 'reminder');
                handleClose();
              }
            },
            {
              label: (
                <>
                  <CircularLoader show={isMaintenanceLoading} />
                  Delete maintenance
                </>
              ),
              onClick: (_, handleClose) => {
                markAction(asset, 'maintenance');
                handleClose();
              }
            }
          ]}
        />
      );
    }
  }
];

export const dataSource = [
  {
    assetName: 'Tomioka',
    assetType: 'Demon slayer',
    maintenance: 'water hashira',
    date: 'ido tensayhi',
    dueDate: 'ido tensayhi',
    status: 'bold',
    frequency: 'swordsmith arc',
    department: 'Logistics',
    assignedTo: 'Marvin gay'
  },

  {
    assetName: 'Tomioka',
    assetType: 'Demon slayer',
    maintenance: 'water hashira',
    date: 'ido tensayhi',
    dueDate: 'ido tensayhi',
    status: 'bold',
    frequency: 'swordsmith arc',
    department: 'Logistics',
    assignedTo: 'Marvin gay'
  }
].map((el) => ({ ...el, action: el }));

export const optionAll = { label: 'All', value: '' };
export const status = [
  {
    label: 'Status',
    options: [
      optionAll,
      { label: 'Overdue', value: 'overdue' },
      { label: 'Upcoming', value: 'upcoming' }
    ]
  }
];
export const maintenance = [
  {
    label: 'Maintenance',
    options: [
      optionAll,
      { label: 'Inspection', value: 'inspection' },
      { label: 'Repair', value: 'repair' },
      { label: 'Service', value: 'service' }
    ]
  }
];
