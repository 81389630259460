/* eslint-disable react-hooks/exhaustive-deps */
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { Wrapper } from './styled.modals';
import useFleetControl from '../../hooks/useFleetControl';
import EditVehicleForm from '../Forms/EditVehicleForm';
import { useEffect } from 'react';

function EditVehicleModal({ data }) {
  const [state, setState] = useFleetControl();

  useEffect(() => {
    setState({
      ...state,
      editVehicleDetails: data
    });
  }, []);

  const handleBackdropClick = () => setState({ ...state, modal: '' });
  return (
    <Modal
      open={state.modal === 'EditVehicleModal'}
      onBackdropClick={handleBackdropClick}
    >
      <Wrapper width='556px' widthPercent='70%' padding='2%'>
        <Typography variant='h2' style={{ color: '#545454' }}>
          Edit vehicle
        </Typography>
        {/* <Typography variant='subtitle1'>Edit your vehicle details</Typography> */}
        <EditVehicleForm data={data} />
      </Wrapper>
    </Modal>
  );
}

export default EditVehicleModal;
