import React from 'react';

const NotFoundIcon = (props) => {
  return (
    <svg
      width={396}
      height={221}
      {...props}
      viewBox='0 0 396 221'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M395.69 131.26H0'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-miterlimit='10'
      />
      <path
        d='M72.5801 15.94C72.5801 13.19 73.8501 11.17 76.7101 11.17C79.5701 11.17 80.8901 13.17 80.8901 15.94V25.6C80.8901 28.35 79.6201 30.37 76.7101 30.37C73.8001 30.37 72.5801 28.37 72.5801 25.6V15.94ZM73.8201 25.68C73.8201 27.68 74.6301 29.24 76.7101 29.24C78.7901 29.24 79.6501 27.73 79.6501 25.68V15.86C79.6501 13.86 78.8101 12.3 76.7101 12.3C74.6101 12.3 73.8201 13.81 73.8201 15.86V25.68Z'
        fill='#263238'
      />
      <path
        d='M82.8601 15.94C82.8601 13.19 84.1301 11.17 86.9801 11.17C89.8301 11.17 91.1601 13.17 91.1601 15.94V25.6C91.1601 28.35 89.9001 30.37 86.9801 30.37C84.0601 30.37 82.8601 28.37 82.8601 25.6V15.94ZM84.1001 25.68C84.1001 27.68 84.9101 29.24 86.9801 29.24C89.0501 29.24 89.9201 27.73 89.9201 25.68V15.86C89.9201 13.86 89.0901 12.3 86.9801 12.3C84.8701 12.3 84.1001 13.81 84.1001 15.86V25.68Z'
        fill='#263238'
      />
      <path
        d='M97.1201 11.33C100.04 11.33 101.17 13.17 101.17 15.92V17.97C101.17 20.97 99.7901 22.44 96.8001 22.44H94.4501V30.21H93.2101V11.33H97.1201ZM96.8001 21.33C98.9801 21.33 99.9301 20.33 99.9301 18.07V15.86C99.9301 13.81 99.2301 12.49 97.1201 12.49H94.4501V21.33H96.8001Z'
        fill='#263238'
      />
      <path
        d='M110.99 15.89V16.29H109.8V15.81C109.8 13.81 109.05 12.3 107 12.3C104.95 12.3 104.19 13.79 104.19 15.78C104.19 20.37 111.01 20.45 111.01 25.65C111.01 28.37 109.83 30.37 106.94 30.37C104.05 30.37 102.87 28.37 102.87 25.65V24.65H104.06V25.65C104.06 27.65 104.84 29.13 106.92 29.13C109 29.13 109.77 27.67 109.77 25.65C109.77 21.12 102.95 21.04 102.95 15.78C102.95 12.95 104.19 11.12 106.95 11.09C109.71 11.06 110.99 13.17 110.99 15.89Z'
        fill='#263238'
      />
      <path d='M114.06 27.84V30.21H112.85V27.84H114.06Z' fill='#263238' />
      <path d='M117.46 27.84V30.21H116.25V27.84H117.46Z' fill='#263238' />
      <path d='M120.83 27.84V30.21H119.62V27.84H120.83Z' fill='#263238' />
      <path
        d='M28.3201 94.77L49.5701 44.52H62.3201V94.77H67.8401V105.39H62.3201V118.88H50.8401V105.39H28.3201V94.77ZM50.8401 94.77V66.83L39.0601 94.77H50.8401Z'
        fill='#263238'
      />
      <path
        d='M72.2001 62.36C72.2001 50.47 78.4701 43.67 89.9401 43.67C101.41 43.67 107.68 50.47 107.68 62.36V101.03C107.68 112.93 101.41 119.73 89.9401 119.73C78.4701 119.73 72.2001 112.93 72.2001 101.03V62.36ZM83.8801 101.78C83.8801 107.09 86.2201 109.11 89.9401 109.11C93.6601 109.11 96.0001 107.11 96.0001 101.78V61.62C96.0001 56.31 93.6601 54.29 89.9401 54.29C86.2201 54.29 83.8801 56.29 83.8801 61.62V101.78Z'
        fill='#263238'
      />
      <path
        d='M112.46 94.77L133.71 44.52H146.46V94.77H151.98V105.39H146.46V118.88H134.98V105.39H112.46V94.77ZM134.98 94.77V66.83L123.19 94.77H134.98Z'
        fill='#263238'
      />
      <path
        d='M33.4401 136.74C36.3001 136.74 37.4401 138.54 37.4401 141.23V143.23C37.4401 146.16 36.0901 147.62 33.1601 147.62H30.8601V155.22H29.6101V136.74H33.4401ZM33.1301 146.52C35.2701 146.52 36.1901 145.52 36.1901 143.32V141.15C36.1901 139.15 35.5101 137.85 33.4401 137.85H30.8301V146.52H33.1301Z'
        fill='#263238'
      />
      <path
        d='M40.6601 151.1L39.7301 155.19H38.6301L42.6301 136.67H44.4001L48.5201 155.19H47.3401L46.4201 151.1H40.6601ZM40.8401 150.04H46.2301L43.5101 138.04L40.8401 150.04Z'
        fill='#263238'
      />
      <path
        d='M58.29 141.26V142.63H57.12V141.18C57.12 139.18 56.36 137.69 54.32 137.69C52.28 137.69 51.52 139.2 51.52 141.18V150.8C51.52 152.8 52.29 154.26 54.32 154.26C56.35 154.26 57.12 152.78 57.12 150.8V146.8H54.67V145.7H58.29V150.7C58.29 153.37 57.12 155.37 54.29 155.37C51.46 155.37 50.29 153.37 50.29 150.7V141.24C50.29 138.57 51.45 136.56 54.29 136.56C57.13 136.56 58.29 138.59 58.29 141.26Z'
        fill='#263238'
      />
      <path
        d='M66.5801 145.3V146.41H61.5801V154.1H67.6501V155.2H60.3601V136.74H67.6501V137.85H61.5801V145.3H66.5801Z'
        fill='#263238'
      />
      <path
        d='M74.8001 155.23H73.6901V136.74H75.2801L80.8301 152.35V136.74H81.9401V155.23H80.5901L74.8001 138.75V155.23Z'
        fill='#263238'
      />
      <path
        d='M83.9501 141.26C83.9501 138.56 85.1901 136.58 87.9501 136.58C90.7101 136.58 92.0401 138.58 92.0401 141.26V150.72C92.0401 153.41 90.8001 155.39 87.9501 155.39C85.1001 155.39 83.9501 153.39 83.9501 150.72V141.26ZM85.1601 150.8C85.1601 152.8 85.9501 154.28 87.9901 154.28C90.0301 154.28 90.8701 152.8 90.8701 150.8V141.18C90.8701 139.18 90.0501 137.69 87.9901 137.69C85.9301 137.69 85.1601 139.17 85.1601 141.18V150.8Z'
        fill='#263238'
      />
      <path
        d='M97.2101 155.23V137.85H93.4601V136.74H102.2V137.85H98.4301V155.23H97.2101Z'
        fill='#263238'
      />
      <path
        d='M113.81 145.75V146.86H109.06V155.23H107.85V136.74H114.85V137.85H109.06V145.75H113.81Z'
        fill='#263238'
      />
      <path
        d='M116.61 141.26C116.61 138.56 117.85 136.58 120.61 136.58C123.37 136.58 124.71 138.58 124.71 141.26V150.72C124.71 153.41 123.46 155.39 120.61 155.39C117.76 155.39 116.61 153.39 116.61 150.72V141.26ZM117.82 150.8C117.82 152.8 118.62 154.28 120.65 154.28C122.68 154.28 123.53 152.8 123.53 150.8V141.18C123.53 139.18 122.71 137.69 120.65 137.69C118.59 137.69 117.82 139.17 117.82 141.18V150.8Z'
        fill='#263238'
      />
      <path
        d='M127.92 150.77C127.92 152.77 128.66 154.28 130.69 154.28C132.72 154.28 133.47 152.75 133.47 150.77V136.77H134.6V150.77C134.6 153.41 133.49 155.44 130.67 155.44C127.85 155.44 126.67 153.44 126.67 150.77V136.77H127.89L127.92 150.77Z'
        fill='#263238'
      />
      <path
        d='M137.8 155.23H136.69V136.74H138.28L143.83 152.35V136.74H144.94V155.23H143.59L137.8 138.75V155.23Z'
        fill='#263238'
      />
      <path
        d='M151.06 136.74C153.92 136.74 155.16 138.62 155.16 141.34V150.64C155.16 153.36 153.92 155.23 151.06 155.23H147.06V136.74H151.06ZM151.06 154.1C153.12 154.1 153.96 152.69 153.96 150.66V141.25C153.96 139.25 153.09 137.82 151.06 137.82H148.23V154.1H151.06Z'
        fill='#263238'
      />
      <path
        d='M337.38 177.39C336.58 177.392 335.792 177.189 335.092 176.802C334.392 176.415 333.802 175.856 333.377 175.177C332.953 174.499 332.709 173.724 332.667 172.924C332.626 172.125 332.788 171.329 333.14 170.61C334.052 168.784 334.231 166.679 333.642 164.726C333.053 162.772 331.739 161.117 329.97 160.1C322.91 155.58 306.49 154.86 300.74 154.95H300.66C299.408 154.962 298.203 154.476 297.309 153.599C296.416 152.723 295.907 151.527 295.895 150.275C295.883 149.023 296.369 147.818 297.246 146.924C298.122 146.031 299.318 145.522 300.57 145.51C302.98 145.46 324.33 145.29 335.05 152.15C345.62 158.92 343.98 169.86 341.63 174.71C341.245 175.511 340.642 176.187 339.89 176.661C339.139 177.135 338.269 177.388 337.38 177.39Z'
        fill='#263238'
      />
      <path
        d='M359.14 202.29L358.1 205.82L342.35 179.92L341.94 174.33L359.14 202.29Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M358.1 205.82L363.02 203.39L363.9 200.02L359.14 202.29L358.1 205.82Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M339.87 171.43L358.1 170.8L363.9 200.02L359.14 202.29L339.87 171.43Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M337.38 172.67L355.61 203.33L349.61 206.23L322.67 184.27L337.38 172.67Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M349.61 206.23L348.78 209.34L321.64 187.79L322.67 184.27L349.61 206.23Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M349.61 206.23L355.61 203.33L354.79 206.44L348.78 209.34L349.61 206.23Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M347.494 183.727C351.102 180.119 351.102 174.268 347.494 170.66C343.885 167.051 338.035 167.051 334.426 170.66C330.818 174.268 330.818 180.119 334.426 183.727C338.035 187.336 343.885 187.336 347.494 183.727Z'
        fill='#F2F2F2'
        stroke='#545454'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M146.23 202.29L147.27 205.82L163.01 179.92L163.43 174.33L146.23 202.29Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M147.27 205.82L142.35 203.39L141.47 200.02L146.23 202.29L147.27 205.82Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M165.5 171.43L147.27 170.8L141.47 200.02L146.23 202.29L165.5 171.43Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M167.99 172.67L149.75 203.33L155.76 206.23L182.69 184.27L167.99 172.67Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M155.76 206.23L156.59 209.34L183.73 187.79L182.69 184.27L155.76 206.23Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M155.76 206.23L149.75 203.33L150.58 206.44L156.59 209.34L155.76 206.23Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M165 180.53C164.024 180.534 163.07 180.235 162.271 179.675C161.472 179.115 160.866 178.32 160.537 177.401C160.208 176.482 160.172 175.483 160.434 174.543C160.696 173.602 161.243 172.766 162 172.15C199.52 141.62 191.88 96.09 191.8 95.63C191.613 94.4125 191.91 93.1703 192.626 92.1684C193.343 91.1666 194.423 90.4845 195.635 90.2679C196.848 90.0512 198.097 90.3171 199.116 91.0088C200.135 91.7005 200.844 92.7632 201.09 93.97C201.46 96.06 209.75 145.47 167.96 179.47C167.124 180.153 166.079 180.527 165 180.53Z'
        fill='#263238'
      />
      <path
        d='M219.811 85.5723C221.438 75.4965 214.588 66.0099 204.513 64.3834C194.437 62.7569 184.95 69.6064 183.324 79.6822C181.697 89.758 188.547 99.2446 198.622 100.871C208.698 102.498 218.185 95.6481 219.811 85.5723Z'
        fill='#ADADAD'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M170.937 183.724C174.545 180.115 174.545 174.265 170.937 170.657C167.328 167.048 161.478 167.048 157.87 170.657C154.261 174.265 154.261 180.115 157.87 183.724C161.478 187.332 167.328 187.332 170.937 183.724Z'
        fill='#F2F2F2'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M251.05 158.73C267.248 158.73 280.38 146.481 280.38 131.37C280.38 116.259 267.248 104.01 251.05 104.01C234.851 104.01 221.72 116.259 221.72 131.37C221.72 146.481 234.851 158.73 251.05 158.73Z'
        fill='#ADADAD'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M235.77 119.68L238.51 130.37C239.319 133.015 241.035 135.291 243.356 136.796C245.677 138.301 248.455 138.94 251.2 138.6C253.942 138.257 256.475 136.958 258.354 134.931C260.233 132.905 261.336 130.28 261.47 127.52L262.01 116.74L235.77 119.68Z'
        fill='#263238'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M293.59 65.89C290.823 65.9303 288.065 65.5527 285.41 64.77L286.17 62.54C286.3 62.54 299.43 66.81 312.8 57.03L314.19 58.94C308.25 63.4137 301.026 65.8511 293.59 65.89Z'
        fill='#263238'
      />
      <path
        d='M308.33 83.63C297.51 67.82 285.2 69.01 285.08 69.02L284.82 66.68C285.38 66.62 298.67 65.33 310.27 82.3L308.33 83.63Z'
        fill='#263238'
      />
      <path
        d='M305.59 94.8C291.59 90.49 283.18 71.85 282.82 71.06L284.98 70.06C285.06 70.25 293.32 88.51 306.29 92.5L305.59 94.8Z'
        fill='#263238'
      />
      <path
        d='M312.87 58.62L316.65 56.1'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-miterlimit='10'
      />
      <path
        d='M308.25 80.87L310.77 85.9'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-miterlimit='10'
      />
      <path
        d='M303.84 92.83C305.832 93.3368 307.875 93.6185 309.93 93.67'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-miterlimit='10'
      />
      <path
        d='M197.69 62.42L201.18 86.83L288.03 77.95L287.71 52.59L197.69 62.42Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M201.18 86.83L216.71 126.13L283.59 119.47L288.03 77.95L201.18 86.83Z'
        fill='#E5E5E5'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M222.13 97.1L228.23 115.1C228.665 116.399 229.536 117.507 230.696 118.236C231.855 118.965 233.231 119.27 234.59 119.1L261.38 116.18C262.732 116.015 263.987 115.391 264.934 114.412C265.88 113.432 266.462 112.157 266.58 110.8L268.24 91.87C268.296 91.2502 268.213 90.6257 267.998 90.0418C267.782 89.458 267.439 88.9295 266.994 88.4947C266.549 88.06 266.012 87.7299 265.423 87.5284C264.835 87.3269 264.208 87.2592 263.59 87.33L225.66 91.57C225.034 91.6387 224.432 91.8468 223.897 92.179C223.363 92.5112 222.909 92.9591 222.571 93.4897C222.232 94.0202 222.017 94.62 221.94 95.2448C221.864 95.8696 221.929 96.5036 222.13 97.1Z'
        fill='#263238'
      />
      <path
        d='M222.13 106.99L233.19 106.01L236.7 96.48L244.96 111.33L249.16 100.26L251.26 103.77L271.3 101.38'
        stroke='white'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M222.13 97.1L228.23 115.1C228.665 116.399 229.536 117.507 230.696 118.236C231.855 118.965 233.231 119.27 234.59 119.1L261.38 116.18C262.732 116.015 263.987 115.391 264.934 114.412C265.88 113.432 266.462 112.157 266.58 110.8L268.24 91.87C268.296 91.2502 268.213 90.6257 267.998 90.0418C267.782 89.458 267.439 88.9295 266.994 88.4947C266.549 88.06 266.012 87.7299 265.423 87.5284C264.835 87.3269 264.208 87.2592 263.59 87.33L225.66 91.57C225.034 91.6387 224.432 91.8468 223.897 92.179C223.363 92.5112 222.909 92.9591 222.571 93.4897C222.232 94.0202 222.017 94.62 221.94 95.2448C221.864 95.8696 221.929 96.5036 222.13 97.1V97.1Z'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M277.95 94.28C280.634 94.28 282.81 92.1041 282.81 89.42C282.81 86.7359 280.634 84.56 277.95 84.56C275.266 84.56 273.09 86.7359 273.09 89.42C273.09 92.1041 275.266 94.28 277.95 94.28Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M281.91 104.62C281.91 105.522 281.643 106.404 281.142 107.153C280.64 107.903 279.928 108.488 279.095 108.833C278.262 109.178 277.345 109.268 276.46 109.092C275.576 108.916 274.763 108.482 274.126 107.844C273.488 107.207 273.054 106.394 272.878 105.51C272.702 104.625 272.792 103.708 273.137 102.875C273.482 102.042 274.067 101.33 274.817 100.828C275.567 100.327 276.448 100.06 277.35 100.06C278.559 100.06 279.719 100.54 280.574 101.396C281.43 102.251 281.91 103.411 281.91 104.62Z'
        fill='#F2F2F2'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M218.78 94.7C219.057 95.6202 219.055 96.6018 218.775 97.521C218.494 98.4401 217.947 99.2554 217.204 99.8639C216.46 100.472 215.552 100.847 214.596 100.939C213.639 101.032 212.677 100.839 211.83 100.385C210.983 99.9307 210.29 99.2356 209.838 98.3875C209.386 97.5394 209.195 96.5764 209.291 95.6201C209.386 94.6638 209.763 93.7572 210.373 93.015C210.983 92.2727 211.8 91.7281 212.72 91.45C213.331 91.2638 213.973 91.2002 214.609 91.2629C215.245 91.3256 215.862 91.5134 216.426 91.8154C216.989 92.1174 217.487 92.5277 217.891 93.0227C218.295 93.5178 218.597 94.0878 218.78 94.7Z'
        fill='#F2F2F2'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M221.758 113.333C223.13 111.22 222.53 108.396 220.417 107.025C218.305 105.653 215.481 106.253 214.11 108.365C212.738 110.478 213.338 113.302 215.45 114.673C217.562 116.045 220.387 115.445 221.758 113.333Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M233.43 163.28C240.566 163.28 246.35 157.701 246.35 150.82C246.35 143.939 240.566 138.36 233.43 138.36C226.294 138.36 220.51 143.939 220.51 150.82C220.51 157.701 226.294 163.28 233.43 163.28Z'
        fill='#F2F2F2'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M214.43 178.78L226.26 152.22C226.767 151.079 227.676 150.165 228.814 149.65C229.951 149.136 231.238 149.057 232.43 149.43C233.659 149.807 234.7 150.635 235.344 151.748C235.988 152.861 236.186 154.177 235.9 155.43L228.11 190.35L214.43 178.78Z'
        fill='#263238'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M210.48 138.1L236.31 198.89L205.31 203.15C205.31 203.15 186.77 184.3 174.31 148.44L210.48 138.1Z'
        fill='#ADADAD'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M210.48 138.05L211.69 132.28L237.53 194.28L236.31 198.84L210.48 138.05Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M211.69 132.28L176.44 142.91L174.31 148.39L210.48 138.05L211.69 132.28Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M176.74 154.16L212.3 143.22'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M179.47 160.54L215.34 150.82'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M182.21 167.23L218.38 157.51'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M186.16 175.44L221.42 165.1'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M189.9 181.96L225.07 172.7'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M193.76 187.92L228.11 179.69'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M198.21 193.65L230.23 186.08'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M201.36 198.33L233.17 193.07'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M267.84 163.28C274.975 163.28 280.76 157.701 280.76 150.82C280.76 143.939 274.975 138.36 267.84 138.36C260.704 138.36 254.92 143.939 254.92 150.82C254.92 157.701 260.704 163.28 267.84 163.28Z'
        fill='#F2F2F2'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M286.83 178.78L275 152.22C274.493 151.079 273.584 150.165 272.446 149.65C271.309 149.136 270.022 149.057 268.83 149.43C267.601 149.807 266.561 150.636 265.919 151.749C265.277 152.862 265.081 154.178 265.37 155.43L273.16 190.35L286.83 178.78Z'
        fill='#263238'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M290.79 138.1L264.95 198.89L295.95 203.15C295.95 203.15 314.49 184.3 326.95 148.44L290.79 138.1Z'
        fill='#ADADAD'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M290.79 138.05L289.57 132.28L263.73 194.28L264.95 198.84L290.79 138.05Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M289.57 132.28L324.83 142.91L326.96 148.39L290.79 138.05L289.57 132.28Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M324.52 154.16L288.96 143.22'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M321.79 160.54L285.92 150.82'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M319.05 167.23L282.88 157.51'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M315.1 175.44L279.84 165.1'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M311.36 181.96L276.2 172.7'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M307.5 187.92L273.16 179.69'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M303.06 193.65L271.03 186.08'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M299.9 198.33L268.09 193.07'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M314.34 86.11L317.7 87.58'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-miterlimit='10'
      />
      <path
        d='M312.24 87.37L315.6 92.62'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-miterlimit='10'
      />
      <path
        d='M310.35 87.58L309.72 90.94'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-miterlimit='10'
      />
      <path
        d='M318.54 65.75H326.72'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-miterlimit='10'
      />
      <path
        d='M312.24 66.17L323.99 69.53'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-miterlimit='10'
      />
      <path
        d='M316.65 61.77L324.2 60.72'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-miterlimit='10'
      />
      <path
        d='M289.57 87.79L291.67 93.88'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-miterlimit='10'
      />
      <path
        d='M292.3 87.37L298.17 97.03'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-miterlimit='10'
      />
      <path
        d='M294.4 81.71L300.06 83.6'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-miterlimit='10'
      />
      <path
        d='M207.36 53.55C215.153 53.55 221.47 47.3044 221.47 39.6C221.47 31.8956 215.153 25.65 207.36 25.65C199.567 25.65 193.25 31.8956 193.25 39.6C193.25 47.3044 199.567 53.55 207.36 53.55Z'
        fill='#ADADAD'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M268.85 49.74C276.643 49.74 282.96 43.4944 282.96 35.79C282.96 28.0857 276.643 21.84 268.85 21.84C261.057 21.84 254.74 28.0857 254.74 35.79C254.74 43.4944 261.057 49.74 268.85 49.74Z'
        fill='#ADADAD'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M240.48 82.07C250.283 82.07 258.23 74.4768 258.23 65.11C258.23 55.7433 250.283 48.15 240.48 48.15C230.677 48.15 222.73 55.7433 222.73 65.11C222.73 74.4768 230.677 82.07 240.48 82.07Z'
        fill='#F2F2F2'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M227.49 56.4L229.94 67.16C230.672 69.8269 232.323 72.1494 234.601 73.7175C236.879 75.2856 239.637 75.9984 242.39 75.73C245.141 75.463 247.71 74.2348 249.645 72.2614C251.58 70.288 252.757 67.6956 252.97 64.94L253.8 54.18L227.49 56.4Z'
        fill='#263238'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M211.32 11.07L204.67 37.7L270.91 32.31L261.09 6.95001L211.32 11.07Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M204.67 37.7L216.39 66.86L268.38 61.79L270.91 32.31L204.67 37.7Z'
        fill='#E5E5E5'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M234.46 49.59C234.28 53.73 230.66 56.99 226.33 57.32C222 57.65 217.76 54.94 216.83 50.84C215.83 46.14 219.34 41.52 224.89 41.17C230.44 40.82 234.65 44.87 234.46 49.59Z'
        fill='#F2F2F2'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M231.15 49.82C231.096 51.1288 230.552 52.3694 229.626 53.2956C228.699 54.2218 227.459 54.7659 226.15 54.82C224.821 54.9636 223.486 54.6141 222.399 53.8379C221.311 53.0618 220.546 51.913 220.25 50.61C219.76 47.77 221.99 45.1 225.25 44.88C225.965 44.8001 226.688 44.8659 227.377 45.0735C228.066 45.2812 228.705 45.6263 229.257 46.088C229.808 46.5498 230.26 47.1186 230.586 47.76C230.911 48.4015 231.103 49.1023 231.15 49.82Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M263.93 46.74C263.76 50.88 260.14 54.14 255.81 54.47C251.48 54.8 247.23 52.09 246.31 47.98C245.26 43.29 248.82 38.67 254.37 38.31C259.92 37.95 264.13 42.02 263.93 46.74Z'
        fill='#F2F2F2'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M260.62 46.97C260.568 48.2795 260.025 49.5214 259.098 50.4481C258.171 51.3748 256.929 51.9182 255.62 51.97C254.29 52.111 252.955 51.7592 251.868 50.9814C250.78 50.2036 250.016 49.0538 249.72 47.75C249.23 44.91 251.46 42.25 254.72 42.03C255.435 41.9471 256.16 42.0109 256.85 42.2174C257.54 42.4239 258.18 42.7688 258.732 43.2311C259.285 43.6934 259.737 44.2634 260.061 44.9062C260.386 45.549 260.576 46.2512 260.62 46.97Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M234.78 61.61C234.171 61.6163 233.583 61.3867 233.139 60.9692C232.695 60.5517 232.43 59.9788 232.399 59.3702C232.368 58.7617 232.573 58.1648 232.972 57.7043C233.371 57.2439 233.933 56.9557 234.54 56.9L247.22 55.63C247.843 55.5677 248.466 55.7555 248.951 56.1521C249.435 56.5488 249.743 57.1218 249.805 57.745C249.867 58.3683 249.679 58.9908 249.283 59.4755C248.886 59.9603 248.313 60.2677 247.69 60.33L235.06 61.6L234.78 61.61Z'
        fill='#263238'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M212.54 24.17C215.185 24.17 217.33 22.0255 217.33 19.38C217.33 16.7346 215.185 14.59 212.54 14.59C209.895 14.59 207.75 16.7346 207.75 19.38C207.75 22.0255 209.895 24.17 212.54 24.17Z'
        fill='#F2F2F2'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M209.58 20.68C211.839 20.68 213.67 18.8489 213.67 16.59C213.67 14.3312 211.839 12.5 209.58 12.5C207.321 12.5 205.49 14.3312 205.49 16.59C205.49 18.8489 207.321 20.68 209.58 20.68Z'
        fill='#F2F2F2'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M208.79 12.24C208.788 12.8764 208.597 13.498 208.242 14.0262C207.887 14.5544 207.384 14.9656 206.795 15.2078C206.207 15.4499 205.56 15.5123 204.936 15.3869C204.312 15.2615 203.739 14.9541 203.29 14.5034C202.84 14.0526 202.535 13.4789 202.411 12.8545C202.288 12.2302 202.352 11.5833 202.596 10.9955C202.84 10.4077 203.253 9.90536 203.782 9.55199C204.311 9.19861 204.934 9.01001 205.57 9.01001C205.994 9.01001 206.413 9.09363 206.805 9.25607C207.196 9.41852 207.551 9.65659 207.85 9.95665C208.15 10.2567 208.386 10.6129 208.548 11.0047C208.709 11.3965 208.791 11.8163 208.79 12.24Z'
        fill='#F2F2F2'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M204.79 11.11L197.47 4.31'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M259.58 20.86C262.225 20.86 264.37 18.7155 264.37 16.07C264.37 13.4246 262.225 11.28 259.58 11.28C256.935 11.28 254.79 13.4246 254.79 16.07C254.79 18.7155 256.935 20.86 259.58 20.86Z'
        fill='#F2F2F2'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M262.54 17.37C264.799 17.37 266.63 15.5388 266.63 13.28C266.63 11.0212 264.799 9.19 262.54 9.19C260.281 9.19 258.45 11.0212 258.45 13.28C258.45 15.5388 260.281 17.37 262.54 17.37Z'
        fill='#F2F2F2'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M263.33 8.93001C263.332 9.56643 263.523 10.188 263.878 10.7162C264.233 11.2444 264.736 11.6556 265.325 11.8978C265.913 12.1399 266.56 12.2023 267.184 12.0769C267.808 11.9515 268.381 11.6441 268.83 11.1934C269.28 10.7426 269.585 10.1689 269.709 9.54453C269.832 8.92018 269.768 8.27327 269.524 7.68547C269.28 7.09767 268.867 6.59537 268.338 6.24199C267.809 5.88861 267.186 5.70002 266.55 5.70001C266.126 5.70001 265.707 5.78362 265.315 5.94606C264.924 6.10851 264.569 6.34659 264.27 6.64665C263.97 6.94672 263.733 7.30286 263.572 7.69469C263.411 8.08651 263.329 8.50631 263.33 8.93001Z'
        fill='#F2F2F2'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M267.33 7.79001L274.65 1'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M256.61 183.68C256.61 185.99 255.08 187.86 253.19 187.86C251.3 187.86 249.78 185.99 249.78 183.68C249.78 181.37 251.31 179.5 253.19 179.5C255.07 179.5 256.61 181.37 256.61 183.68Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M263.37 187.18H263.11L253.62 185.79C253.171 185.705 252.772 185.451 252.507 185.079C252.242 184.707 252.13 184.247 252.196 183.795C252.262 183.343 252.5 182.934 252.86 182.654C253.221 182.373 253.676 182.243 254.13 182.29L263.62 183.67C264.079 183.714 264.503 183.936 264.801 184.289C265.099 184.641 265.248 185.096 265.215 185.557C265.183 186.017 264.972 186.447 264.627 186.754C264.282 187.061 263.831 187.221 263.37 187.2V187.18Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-miterlimit='10'
      />
      <path
        d='M130.74 203.22C129.5 201.27 129.74 198.87 131.38 197.87C133.02 196.87 135.26 197.62 136.5 199.56C137.74 201.5 137.45 203.91 135.85 204.92C134.25 205.93 131.97 205.17 130.74 203.22Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M123.15 203.89C123.223 203.844 123.3 203.804 123.38 203.77L132.14 199.86C132.564 199.693 133.036 199.697 133.458 199.869C133.88 200.042 134.219 200.37 134.404 200.787C134.59 201.203 134.608 201.675 134.454 202.104C134.301 202.533 133.987 202.886 133.58 203.09L124.82 207C124.412 207.181 123.951 207.201 123.528 207.057C123.106 206.912 122.754 206.613 122.543 206.22C122.331 205.826 122.277 205.368 122.389 204.936C122.502 204.504 122.774 204.13 123.15 203.89Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-miterlimit='10'
      />
      <path
        d='M357.37 158.42C356.13 156.47 356.42 154.08 358.01 153.07C359.6 152.06 361.89 152.82 363.13 154.76C364.37 156.7 364.08 159.11 362.49 160.12C360.9 161.13 358.6 160.37 357.37 158.42Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M349.79 159.1L350.01 158.98L358.77 155.07C358.984 154.963 359.217 154.9 359.456 154.886C359.694 154.872 359.934 154.906 360.159 154.986C360.384 155.067 360.59 155.192 360.766 155.355C360.941 155.517 361.082 155.714 361.179 155.932C361.276 156.15 361.328 156.386 361.332 156.625C361.336 156.864 361.291 157.102 361.201 157.323C361.11 157.544 360.976 157.745 360.805 157.913C360.635 158.081 360.433 158.213 360.21 158.3L351.46 162.21C351.052 162.391 350.591 162.411 350.168 162.267C349.746 162.122 349.394 161.823 349.183 161.43C348.971 161.036 348.917 160.578 349.029 160.146C349.142 159.714 349.414 159.34 349.79 159.1Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-miterlimit='10'
      />
      <path
        d='M188.24 205.1C184.24 205.1 181.02 207.02 181.02 209.38C181.02 211.74 184.25 213.66 188.24 213.66C192.23 213.66 195.47 211.74 195.47 209.38C195.47 207.02 192.23 205.1 188.24 205.1ZM188.24 211.59C186.18 211.59 184.51 210.59 184.51 209.38C184.51 208.17 186.18 207.17 188.24 207.17C190.3 207.17 191.98 208.17 191.98 209.38C191.98 210.59 190.31 211.59 188.24 211.59Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-miterlimit='10'
      />
      <path
        d='M253.32 205.79L248.39 203.79L243.45 205.79L242.23 210.19L245.65 213.72H251.13L254.54 210.19L253.32 205.79ZM249.78 211.41H247L245.26 209.62L245.88 207.39L248.39 206.39L250.89 207.39L251.51 209.62L249.78 211.41Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M346.88 141.9L341.94 139.9L337 141.9L335.78 146.31L339.2 149.84H344.68L348.1 146.31L346.88 141.9ZM343.33 147.53H340.55L338.82 145.74L339.43 143.5L341.94 142.5L344.45 143.5L345.07 145.74L343.33 147.53Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M286.33 215.83C286.19 215.83 286.052 215.807 285.92 215.76C285.248 215.477 284.682 214.991 284.3 214.37C283.75 213.52 283.3 212.01 284.17 209.67C284.475 208.814 284.985 208.046 285.657 207.434C286.328 206.822 287.14 206.385 288.02 206.16C289.15 205.887 290.327 205.879 291.46 206.138C292.593 206.396 293.651 206.914 294.55 207.65C294.671 207.748 294.77 207.869 294.844 208.005C294.917 208.142 294.963 208.292 294.978 208.446C294.993 208.601 294.977 208.757 294.931 208.905C294.885 209.053 294.81 209.191 294.71 209.31C294.511 209.551 294.224 209.703 293.913 209.733C293.602 209.763 293.291 209.669 293.05 209.47C292.436 208.981 291.72 208.636 290.955 208.463C290.189 208.29 289.394 208.292 288.63 208.47C288.114 208.603 287.638 208.862 287.245 209.223C286.853 209.584 286.556 210.036 286.38 210.54C285.49 212.89 286.56 213.54 286.78 213.6C287.061 213.725 287.284 213.954 287.401 214.239C287.518 214.524 287.521 214.843 287.41 215.13C287.319 215.34 287.168 215.519 286.976 215.644C286.784 215.768 286.559 215.833 286.33 215.83Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M310.71 220.17C310.337 220.172 309.965 220.114 309.61 220L290.42 213.75C289.966 213.616 289.544 213.392 289.178 213.092C288.813 212.793 288.511 212.422 288.29 212.004C288.07 211.585 287.936 211.127 287.896 210.655C287.856 210.184 287.91 209.709 288.057 209.26C288.203 208.81 288.438 208.394 288.748 208.037C289.058 207.679 289.436 207.387 289.861 207.179C290.285 206.97 290.747 206.848 291.219 206.821C291.691 206.794 292.164 206.861 292.61 207.02L311.81 213.27C312.607 213.534 313.284 214.074 313.721 214.791C314.157 215.509 314.324 216.358 314.192 217.187C314.059 218.017 313.637 218.772 312.999 219.318C312.361 219.865 311.55 220.167 310.71 220.17Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M289.84 213.59C289.84 213.59 290.6 208.71 296.7 208.59'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M293.95 214.65C293.95 214.65 294.72 209.78 300.81 209.65'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M297.61 216.02C297.61 216.02 298.37 211.15 304.46 211.02'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M301.42 217.1C301.42 217.1 302.18 212.23 308.27 212.1'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M305.23 218.46C305.23 218.46 305.99 213.59 312.08 213.46'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M310.7 220.1C310.563 220.098 310.427 220.071 310.3 220.02C309.625 219.743 309.054 219.26 308.67 218.64C308.12 217.79 307.67 216.28 308.54 213.93C308.848 213.076 309.359 212.31 310.03 211.698C310.701 211.086 311.511 210.648 312.39 210.42C313.52 210.148 314.698 210.142 315.831 210.403C316.964 210.663 318.021 211.182 318.92 211.92C319.04 212.018 319.14 212.139 319.214 212.275C319.287 212.412 319.333 212.562 319.348 212.716C319.362 212.871 319.346 213.027 319.301 213.175C319.255 213.323 319.18 213.461 319.08 213.58C318.881 213.821 318.594 213.973 318.283 214.003C317.972 214.033 317.661 213.939 317.42 213.74C316.807 213.25 316.09 212.905 315.325 212.732C314.559 212.559 313.764 212.562 313 212.74C312.483 212.87 312.007 213.126 311.615 213.486C311.222 213.845 310.925 214.297 310.75 214.8C309.86 217.16 310.93 217.8 311.15 217.86C311.398 217.969 311.602 218.159 311.728 218.399C311.853 218.639 311.893 218.915 311.841 219.181C311.788 219.447 311.646 219.687 311.439 219.861C311.232 220.035 310.971 220.134 310.7 220.14V220.1Z'
        fill='white'
        stroke='#263238'
        stroke-width='1.08162'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};

export default NotFoundIcon;
