import axios from './axios.config';
import getBaseDomainURL from './base-domain-url';

export async function signup(payload) {
  try {
    const { data } = await axios.post('/accounts/auth/signup/', payload);
    localStorage.setItem('access_token', data.access_token);
    localStorage.setItem('refresh_token', data.refresh_token);
    return data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function login(payload) {
  try {
    const res = await axios.post('/accounts/auth/login/?device=web', payload);
    const { data } = res;
    const user = JSON.stringify(data.user);

    if (data.user.business_user_memberships.length >= 1) {
      const business_domain =
        data.user.business_user_memberships[0]?.business_id;
      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('refresh_token', data.refresh_token);
      localStorage.setItem('user', user);
      business_domain &&
        localStorage.setItem('business_domain', business_domain);

      if (business_domain === 'd741f131-55ff-4a11-b0b2-14e0c0ac10bb') {
        window.location.href = '/payroll?tab=home';
        return;
      }
    }

    return data.user;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getBusinessTypes() {
  return axios.get('/businesses/types').then(({ data }) => data);
}

export async function createBusiness(payload) {
  try {
    const { data } = await axios.post('/businesses/', payload);
    // localStorage.setItem('business_domain', data.business_id);
    return data;
  } catch (error) {
    throw error;
  }
}

export async function createPremiumBusiness(payload) {
  try {
    const { data } = await axios.post(
      '/businesses/create_premium_business/',
      payload
    );
    // localStorage.setItem('business_domain', data.business_id);
    return data;
  } catch (error) {
    throw error;
  }
}

export function updatePremiumBusiness(payload) {
  return axios
    .put(`/businesses/update_business_during_onboarding/`, payload)
    .then(({ data }) => data);
}

export function getUser() {
  return axios.get(`/accounts/auth/user/`).then(({ data }) => data);
}

export function editUser(payload) {
  return axios.put(`/accounts/auth/user/`, payload).then(({ data }) => data);
}

export function logout() {
  return axios.post(`/accounts/auth/logout/`).then(({ data }) => data);
}

export function resetPassword(payload) {
  return axios
    .post(`/accounts/auth/password/reset/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function verifySignup(token) {
  return axios
    .post(`accounts/auth/signup/confirm_email/`, token)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function resetPasswordConfirm(payload) {
  return axios
    .post(`accounts/auth/password/reset/confirm`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function resendConfirmationMail(payload) {
  return axios
    .post(`accounts/auth/signup/resend_confirmation_email/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function setupBusiness(payload) {
  return axios
    .post(`${getBaseDomainURL()}/businesses/setup/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function changePassword(payload) {
  return axios
    .post(`/accounts/auth/password/change/`, payload)
    .then(({ data }) => data);
}

export function quickStartOnboarding() {
  return axios
    .get(`${getBaseDomainURL()}/business_onboarding/quickstart/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function clearDemoData() {
  return axios
    .post(`${getBaseDomainURL()}/businesses/clear_demo_data_v2/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function deleteBusinessById(domain) {
  return axios
    .delete(`/businesses/delete_whole_business/`, {
      data: { domain }
    })
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function getBusinessPlan(country) {
  return axios
    .get(`/businesses/get_business_plan/?country_name=nigeria`)
    .then(({ data }) => data);
}

export function getPaymentReference() {
  return axios
    .get('/businesses/generate_payment_reference/')
    .then(({ data }) => data);
}
