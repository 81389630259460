import GoBack from 'components/GoBack';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import useCheckEdit from 'hooks/useCheckEdit';

const Header = () => {
  const isEditView = useCheckEdit('view');

  return (
    <section>
      <div className='header_block'>
        <div className='typos'>
          <Title order={4}>{isEditView ? 'Edit' : 'Add'} Maintenance</Title>

          <Text size='sm'>Add a maintenance to a asset</Text>
        </div>
        <GoBack color='#2898A4' title='Back to Dashboard' />
      </div>
      <hr />
    </section>
  );
};

export default Header;
