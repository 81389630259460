import createInstance, { getBaseDomainURL } from '../../api/axios.config';

export const getMethod = async ({ queryKey }) => {
  let { route, params } = queryKey[1];
  try {
    const { data } = await createInstance.get(`${getBaseDomainURL()}${route}`, {
      params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const postMethod = async ({ payload, url }) => {
  try {
    const response = await createInstance.post(
      `${getBaseDomainURL()}${url}`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const publicGetMethod = async ({ queryKey }) => {
  const { route, params } = queryKey[1];
  try {
    const { data } = await createInstance.get(`${route}`, {
      params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const taskGetMethod = async ({ queryKey }) => {
  const { route, params } = queryKey[1];
  try {
    const { data } = await createInstance.get(`${route}`, {
      params
    });
    if (data.status === 'SUCCESS') {
      return data;
    } else {
      throw new Error('Task not completed');
    }
  } catch (error) {
    throw error;
  }
};
