import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CustomeInputBox from './CustomeInputBox';
import { Text } from 'components/Typography';

export default function ControlledComponent({
  value,
  setValue,
  onChange,
  outerLabel = '',
  inputProps = {
    fullWidth: true,
    size: 'small'
  },
  ...rest
}) {
  const actualInputProps = {
    ...inputProps,
    helperText: rest?.helperText,
    error: rest?.error
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {outerLabel && (
        <Text as='label' size='xs'>
          {outerLabel}
        </Text>
      )}
      <DatePicker
        {...rest}
        value={value === '' ? null : value}
        onChange={(newValue) => onChange(newValue)}
        size='small'
        renderInput={(props) => {
          const adornmentClickHandler =
            props.InputProps?.endAdornment?.props?.children?.props.onClick;

          return (
            <CustomeInputBox
              onClick={adornmentClickHandler}
              {...props}
              inputProps={{
                ...props.inputProps,
                readOnly: true
              }}
              {...actualInputProps}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
}
