import Text from 'components/Typography/Text';

const FormSectionTitle = ({ children }) => {
  return (
    <Text bold style={{ marginBlock: '.6rem' }}>
      {children}
    </Text>
  );
};

export default FormSectionTitle;
