import dayjs from 'dayjs';

export const inputPropsSetterConfig =
  ({ touched, getFieldProps, errors, setFieldValue, getFieldMeta }) =>
  (name, isManual = false) => {
    const props = getFieldProps(name);
    const { touched, error } = getFieldMeta(name);

    const errMesage = typeof error !== 'string' ? error?.message : error;
    return {
      ...props,
      onChange: !isManual
        ? props.onChange
        : (value) => {
            setFieldValue(name, value);
          },
      error: touched && Boolean(error),
      helperText: touched && errMesage
    };
  };

export const formatDate = (date) =>
  date ? dayjs(date).format('YYYY-MM-DD') : null;

export const formatDateTime = (time, date = new Date()) => {
  const formattedDate = formatDate(date);
  return time ? `${formattedDate}T${time}` : formattedDate;
};

export const currentTime = () => dayjs().format('HH:mm:ss');

export const defaultTime = () =>
  new Date().toLocaleTimeString('en-US', {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric'
  });
