import React from 'react';
import { PieChart, Pie, Cell, Label } from 'recharts';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useQuery } from 'react-query';
import { getServiceRemindersSummary } from '../../../api/fleet';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { v4 as uuid } from 'uuid';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  Wrapper: {
    background: 'white',
    // borderRadius: '11px',
    // boxShadow: '0px 4px 10px rgba(214, 214, 214, 0.3)',
    // padding: '30px 40px',
    padding: '20px 30px 20px 30px',
    height: '285px',
    // width: '328px',
    textAlign: '-webkit-right',
    // border: '2px solid red',
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 30%)',
    borderRadius: '5px',

    '& .MuiTypography-h4': {
      // ...theme.mixins.makeResponsive({ theme, fontSize: 20, object: true }),
      // fontWeight: 700,
      // color: '#7F7F7F',
      // textAlign: 'left'
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20.25px',
      lineHeight: '23px',
      color: '#545454',
      textAlign: 'left'
    },
    '& .MuiGrid-item': {
      textAlign: 'left'
    }
  },
  info: {
    '& .MuiTypography-body1': {
      color: '#878787',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '14.22px',
      lineHeight: '16px'
    },
    '& .MuiTypography-subtitle2': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '13px',
      color: '#878787'
    }
  }
}));

// const colors = {
//   due: '#F8B636',
//   overdue: '#FF7372'
// };

const pieColors = {
  overdue: '#FFE7E7',
  due: '#FCE2AF'
};

// this transforms the api response data (object)  to
// the required recharts array structure
const parseData = (data = {}) => {
  return Object.keys(data).map((key) => ({
    name: key,
    value: data[key]
  }));
};

export default function ServiceReminder() {
  const classes = useStyles();

  const response = useQuery(
    'analytics:reminder-summary',
    getServiceRemindersSummary
  );

  if (response.isLoading) {
    return (
      <Box style={{ height: '100%' }}>
        <Skeleton
          variant='rect'
          height={285}
          style={{ borderRadius: '10px' }}
        />
      </Box>
    );
  }

  const data = parseData(response.data);
  const getTotal = data.map((dt) => dt.value).reduce((a, b) => a + b);

  const dafaultValue = [{ name: 'default', value: 100 }];

  if (getTotal === 0) {
    return (
      <Box className={classes.Wrapper}>
        <Typography variant='h4'>Reminders</Typography>
        <Grid container justify='center'>
          <PieChart width={180} height={170} onMouseEnter={() => {}}>
            <Pie
              data={dafaultValue}
              innerRadius={50}
              outerRadius={80}
              fill='#A8A8A8'
              paddingAngle={0}
            >
              <Label
                value={getTotal}
                position='center'
                style={{ fontWeight: 'bold', fontSize: '2em' }}
              />
            </Pie>
          </PieChart>
        </Grid>
        <Grid
          container
          xs={12}
          direction='row'
          justify='space-around'
          alignItems='center'
          alignContent='center'
          spacing={2}
          style={{ marginTop: '0px' }}
        >
          <Grid item className={classes.info}>
            <Typography>
              <Box
                component='span'
                display='flex'
                style={{
                  display: 'inline-block',
                  backgroundColor: '#A8A8A8',
                  width: 15,
                  height: 15
                }}
              ></Box>{' '}
              <strong>0</strong>
            </Typography>
            <Typography variant='subtitle2'>Due Soon</Typography>
          </Grid>
          <Grid item className={classes.info}>
            <Typography>
              <Box
                component='span'
                display='flex'
                style={{
                  display: 'inline-block',
                  backgroundColor: '#A8A8A8',
                  width: 15,
                  height: 15
                }}
              ></Box>{' '}
              <strong>0</strong>
            </Typography>
            <Typography variant='subtitle2'>Overdue</Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box className={classes.Wrapper}>
      <Typography variant='h4'>Reminders</Typography>
      <Grid container justify='center'>
        <PieChart width={180} height={170} onMouseEnter={() => {}}>
          <Pie
            data={data}
            innerRadius={50}
            outerRadius={80}
            fill='#8884d8'
            paddingAngle={-1}
            dataKey='value'
          >
            {data.map(({ name }) => (
              <Cell key={uuid()} fill={pieColors[name.toLowerCase()]} />
            ))}
            <Label
              value={getTotal}
              position='center'
              style={{ fontWeight: 'bold', fontSize: '2em' }}
            />
          </Pie>
        </PieChart>
      </Grid>
      <Grid
        container
        xs={12}
        direction='row'
        justify='space-around'
        alignItems='center'
        alignContent='center'
        spacing={2}
        style={{ marginTop: '0px' }}
      >
        <Grid item className={classes.info}>
          <Typography>
            <Box
              component='span'
              display='flex'
              style={{
                display: 'inline-block',
                backgroundColor: '#FF7372',
                width: '10.69px',
                height: '11.51px',
                borderRadius: '1.64486px'
              }}
            ></Box>{' '}
            <strong>{data[1]['value']}</strong>
          </Typography>
          <Typography variant='subtitle2'>Due Soon</Typography>
        </Grid>
        <Grid item className={classes.info}>
          <Typography>
            <Box
              component='span'
              display='flex'
              style={{
                display: 'inline-block',
                backgroundColor: '#F8B636',
                width: '10.69px',
                height: '11.51px',
                borderRadius: '1.64486px'
              }}
            ></Box>{' '}
            <strong>{data[0]['value']}</strong>
          </Typography>
          <Typography variant='subtitle2'>Overdue</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
