import DeleteModal from 'Assets/Modals/DeleteAssetModal';
import { CustomButton } from 'components/Button';
import Group from 'components/StyledComponents/ButtonGroup';
import useDisclosure from 'hooks/useDisclosure';
import { useHistory } from 'react-router-dom';
const DisplayActions = ({ asset }) => {
  const [open, { toggle }] = useDisclosure();
  const history = useHistory();
  const handleEdit = () => {
    history.push(`/assets?tab=home&view=edit&id=${asset.id}`);
  };
  return (
    <section className='details_actions'>
      <Group fullWidth gap='2rem'>
        <CustomButton
          rounded='md'
          py='.8rem'
          bold
          onClick={handleEdit}
          fullWidth
          variant={'outlined'}
        >
          Edit
        </CustomButton>
        <CustomButton
          onClick={toggle}
          rounded='md'
          bold
          fullWidth
          variant={'warning'}
        >
          Delete
        </CustomButton>
      </Group>
      {open && (
        <DeleteModal
          open={open}
          toggle={toggle}
          data={[asset.id]}
          onDeleteComplete={() => {
            history.push(-1);
          }}
        />
      )}
    </section>
  );
};

export default DisplayActions;
