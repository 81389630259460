import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CircularLoader from 'components/CircularLoader';
import CustomeInputBox from './CustomeInputBox';
import Typography from 'components/Typography';
const filter = createFilterOptions();

const AutoCompelete = ({
  loading,
  inputProps,
  renderOptions,
  getOptionLabel,
  filterOptions = () => {},
  options,
  size = 'small',
  label,
  name,
  addMessage,
  defaultAddNew = true,
  ...props
}) => {
  const actualProps = {
    renderOptions,
    getOptionLabel,
    inputProps,
    options,
    size,
    name,
    defaultAddNew,
    ...(filterOptions
      ? {
          filterOptions: (options, params) =>
            filterOptions(options, params, { addMessage, defaultAddNew })
        }
      : {}),
    ...props
  };
  return (
    <Autocomplete
      autoHighlight
      popupIcon={
        loading ? (
          <CircularLoader show={loading} color='blue' size={20} />
        ) : (
          <KeyboardArrowDownIcon />
        )
      } // Loading icon
      {...actualProps}
      renderInput={(params) => (
        <CustomeInputBox
          {...params}
          label={label}
          inputProps={{
            ...params?.inputProps,

            IconComponent: KeyboardArrowDownIcon
          }}
        />
      )}
    />
  );
};

export default AutoCompelete;

export const getOptionLabel = (option) => {
  // Value selected with enter, right from the input
  if (typeof option === 'string') {
    return option;
  }
  // Add "xxx" option created dynamically
  if (option.inputValue) {
    return option.inputValue;
  }
  // Regular option
  return option.label;
};

export const renderOption = (handleCustomClick) => (props, option) => {
  return option?.isAdd ? (
    <li
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingInline: 0,
        background: !option.nonExisting ? '#EAF8FA' : 'inherit',
        ...props.style
      }}
      {...props}
    >
      {option.nonExisting ? (
        <Typography
          size='sm'
          onClick={() => handleCustomClick(option?.inputValue ?? props?.key)}
          style={{ paddingBlock: '.5rem' }}
        >
          No results found
        </Typography>
      ) : null}
      <Typography
        size='md'
        onClick={() => handleCustomClick(option?.inputValue ?? props?.key)}
        style={{
          backgroundColor: '#EAF8FA',
          color: '#2898A4',
          width: '100%',
          flex: 1,
          height: option.nonExisting ? 'auto' : '100%',
          padding: '.5rem'
        }}
      >
        {option?.label}
      </Typography>
    </li>
  ) : (
    <li
      style={{ borderBottom: '1px solid #F2F2F2', ...props?.style }}
      {...props}
    >
      {option?.label}
    </li>
  );
};

export const filterOptions = (options, params, otherOptions) => {
  const filtered = filter(options, params);
  const { inputValue } = params;
  if (otherOptions?.defaultAddNew) {
    const isExisting = options.some((option) =>
      option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
    );

    const addMessage = otherOptions.addMessage;

    const nonExisting = inputValue?.trim() !== '' && !isExisting;
    filtered?.push({
      inputValue,
      nonExisting,
      isAdd: true,
      label: addMessage
        ? addMessage(nonExisting, inputValue)
        : nonExisting
        ? `Add ${inputValue}`
        : 'Add New'
    });
  }

  return filtered;
};
