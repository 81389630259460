export const tabsStyle = {
  '& button:hover': {
    background: '#D6F2F5',
    borderRadius: '10px 10px 0 0'
  },
  '& button.Mui-selected': {
    color: '#2898A4',
    fontWeight: 600
  }
};

export const indicator = {
  // customize the styles of the indicator
  backgroundColor: '#2898A4',
  height: '4px',
  borderRadius: '10px'
};

export const indicatorColor = '#2898A4';

export const tabActiveStyle = {
  fontSize: '16px  !important',
  textTransform: 'capitalize !important',

  fontFamily: 'var(--black-family)  !important',
  marginRight: '1.5rem',
  fontWeight: 600,
  color: '#2898A4',
  opacity: 1,
  background: '#EAF8FA',
  borderBottom: '1px solid #2898A4',
  borderRadius: '5px  5px 0px 0px'
};
export const tabInactiveStyle = {
  fontSize: '16px  !important',
  textTransform: 'capitalize !important',
  fontFamily: 'var(--black-family)  !important',
  marginRight: '1.5rem',
  color: '#878787'
};

export function a11yProps(index, name) {
  return {
    id: `${name ?? 'simple'}-tab-${index}`,
    'aria-controls': `${name ?? 'simple'}-tabpanel-${index}`
  };
}
