import Text from 'components/Typography/Text';
import { useEffect, useState } from 'react';

const OfflineDetector = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
  return (
    <div>
      {!isOnline ? (
        <div
          style={{
            position: 'fixed',
            top: '0px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '300px',
            padding: '2px 20px',
            background: '#ffe7e7',
            zIndex: '999999'
          }}
        >
          <Text style={{ textAlign: 'center' }}>You are currently offline</Text>
        </div>
      ) : null}
    </div>
  );
};

export default OfflineDetector;
