import GoBack from 'components/GoBack';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import { BLANK } from 'constant';
import dayjs from 'dayjs';
import { capitalize, capitalizeAll } from 'utilities/Capitalized';
import AssignEmployee from './AssignEmployee';
import AssigneeDetails from './AssigneeDetails';
import DisplayActions from './DisplayActions';
import DisplayImage from './DisplayImage';
import NumberFormat from 'react-number-format';

const Display = ({ asset, refetch }) => {
  const assignee = asset?.assignee;

  const details = [
    { title: 'Location', value: asset?.location?.name ?? BLANK },
    {
      title: 'Status',
      value: capitalize(asset?.status.replaceAll('_', ' ') ?? BLANK)
    },
    {
      title: 'Purchase',
      value: asset?.purchase_date
        ? dayjs(asset?.purchase_date).format('DD, MMM YYYY')
        : BLANK
    },
    {
      title: 'Cost',
      value: (
        <NumberFormat
          value={asset?.cost ?? 0}
          displayType={'text'}
          thousandSeparator={true}
          decimalScale='2'
          fixedDecimalScale={true}
          prefix={'₦'}
        />
      )
    }
  ];

  return (
    <section className='display'>
      <GoBack title='Back to Asset List' />
      <section className='display_main'>
        <section>
          <section className='asset_name_and_assign'>
            <section className='name_info'>
              <Title fs={'32px'}> {capitalizeAll(asset?.name || '')}</Title>
              <Text size='sm'>
                {asset?.serial_no || BLANK} {'  '} |{'  '}
                {asset?.color ? asset?.color : BLANK}
              </Text>
            </section>
            <section className='middle_divider'></section>
            <section>
              {!assignee?.employee_id ? (
                <AssignEmployee assetId={asset.id} refetch={refetch} />
              ) : (
                <AssigneeDetails
                  assetId={asset.id}
                  {...assignee}
                  refetch={refetch}
                />
              )}
            </section>
          </section>
          <section className='details'>
            {details.map((el) => (
              <section
                key={el.title}
                className={`item ${el.title !== 'Cost' ? 'item_border' : ''}`}
              >
                <Text size='xs' bold>
                  {el.title}
                </Text>
                <Text size='sm'>{el.value}</Text>
              </section>
            ))}
          </section>
          <DisplayActions asset={asset} />
        </section>

        <DisplayImage asset={asset} refetch={refetch} />
      </section>
    </section>
  );
};

export default Display;
