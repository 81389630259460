import {
  FormControl as MFormControl,
  MenuItem as MMenuItem
} from '@material-ui/core';
import styled from 'styled-components';

export const FormControl = styled(MFormControl)`
  ${({ theme }) => `
    width: 100%;
    // margin: 1.7% auto;
  
    & > * {
      font-family: 'Black Sans';
      ${theme.mixins.makeResponsive({ theme, fontSize: 22 })}

      ${theme.breakpoints.down('lg')} {
        // height: 2.3pc;
        // height : 46px;
      }
    }

    ${theme.breakpoints.down('lg')} {
      .MuiInputLabel-formControl {
        // margin-top: -6px;
      }
    }
  `}
`;

export const MenuItem = styled(MMenuItem)`
  ${({ theme }) => `
    ${theme.mixins.makeResponsive({ theme, fontSize: 16 })};
  `}
`;
