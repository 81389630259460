const getObjectErrorString = (erd) => {
  let key = Object.keys(erd)[0];
  const firstErr = erd[key];
  let errorString;
  if (typeof firstErr === 'string') {
    errorString = erd[key][0];
  } else if (Array.isArray(firstErr)) {
    errorString = firstErr[0];
  } else {
    return getObjectErrorString(firstErr);
  }

  return { errorString, key };
};

export const apiErrorHandler = (error) => {
  try {
    const status = error?.response?.status;
    if (status === 500) {
      throw new Error('something went wrong, please report');
    }

    console.log('error', error);

    if (!error.response) {
      return 'Error: Network Error';
    } else if (error.response.data) {
      const erd = error.response.data;
      if (erd.detail || erd.details) {
        if (erd.detail === 'Invalid token.') {
          return 'Invalid Token. Please re-login.';
        }
        return erd.detail || erd.details;
      }
      if (Array.isArray(erd)) {
        const errDataWithDetails =
          erd[0]?.detail || erd[0]?.details || undefined;
        return errDataWithDetails ?? erd[0];
      }
      // if (erd[0]) return erd[0];
      const { key, errorString } = getObjectErrorString(erd);
      if (['non_field_errors', 'detail'].indexOf(key) >= 0) {
        return errorString;
      }
      const errorField = String(key).replace('_', ' ');
      return (
        errorField.charAt(0).toUpperCase() +
        errorField.substring(1) +
        ': ' +
        errorString
      );
    }
  } catch (error) {
    return 'Something went wrong. Please report this.';
  }
};
