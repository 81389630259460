import { CustomButton as Button } from 'components/Button';
import CircularLoader from 'components/CircularLoader';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from 'components/Dialog';
import { CustomSelect, CustomTextField, DatePicker } from 'components/Inputs';
import Group from 'components/StyledComponents/ButtonGroup';
import Text from 'components/Typography/Text';
import { useFormik } from 'formik';
import { inputPropsSetterConfig } from 'utilities/formHelpers';
import * as yup from 'yup';
import { useCreateReminder } from '../queries';

export const reminderType = [
  {
    label: 'Document renewal',
    value: 'document_renewal'
  },
  {
    label: 'Service',
    value: 'service'
  },
  {
    label: 'Inspection',
    value: 'inspection'
  }
];

export const reminderSet = [
  {
    label: '1 day before',
    value: '1d'
  },
  {
    label: '2 day before',
    value: '2d'
  },
  {
    label: '3 day before',
    value: '3d'
  },
  {
    label: '4 day before',
    value: '4d'
  },
  {
    label: '5 day before',
    value: '5d'
  }
];
export const validationSchema = yup.object().shape({
  type: yup.string().required('field is required'),
  set_reminder: yup.string().required('field is required'),
  date: yup.date().required('field is required')
});
const ReminderModal = ({ open, toggle, data = {} }) => {
  const mutation = useCreateReminder({
    onSuccess() {
      toggle();
    }
  });
  const formik = useFormik({
    validationSchema,
    initialValues: {
      asset: data?.id || '',
      type: '',
      date: null,
      set_reminder: ''
    },
    onSubmit(values) {
      mutation.mutate(values);
    }
  });

  const setInputProps = inputPropsSetterConfig({ ...formik });

  const handleSubmit = () => {
    formik.submitForm();
  };

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={toggle}>
      <DialogTitle divider px={4} onClose={toggle}>
        Set Reminder
        <Text component='div' size='xs'>
          Set a reminder for your service or documenet renewal
        </Text>
      </DialogTitle>
      <DialogContent>
        <CustomTextField
          disabled
          size='small'
          value={data?.name || ''}
          label={data?.name}
          // label='Epson Workforce (Pro WF - 6090)'
        />
        <Text size='sm'>Reminder Type</Text>
        <CustomSelect
          size='small'
          options={reminderType}
          label='Service'
          {...setInputProps('type')}
        />

        <Group>
          <CustomSelect
            size='small'
            options={reminderSet}
            label='Set reminder'
            {...setInputProps('set_reminder')}
          />
          <DatePicker
            inputProps={{ fullWidth: true, size: 'small' }}
            size='small'
            fullWidth
            label='Service Time'
            {...setInputProps('date', true)}
          />
        </Group>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          disabled={mutation.isLoading}
          variant='contained'
          fullWidth
        >
          <CircularLoader size={15} show={mutation.isLoading} />
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReminderModal;
