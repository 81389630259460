import CustomMenu from 'components/CustomMenu/CustomeMenu';
import StatusChip from 'components/StatusChip';
import { capitalize } from 'lodash';

export const statusColor = {
  resolved: { text: '#4069D0', bg: '#EBEFFA' },
  inspected: { text: '#9629EB', bg: '#F5EEFA' },
  pending: { text: '#FF6666', bg: '#FFE5E5' },
  'is repair': { text: '#946405', bg: '#FCE2AF' },
  'out for repair': { text: '#946405', bg: '#FCE2AF' },
  damaged: { text: '#FF6666', bg: '#FFE5E5' }
};

export const columns = ({ viewAssetAction }) => [
  {
    label: 'Asset Name',
    dataIndex: 'assetName'
  },
  {
    label: 'Asset Type',
    dataIndex: 'assetType'
  },

  {
    label: 'Issue Type',
    dataIndex: 'issuedType'
  },
  {
    label: 'Reported by',
    dataIndex: 'reportedBy'
  },

  {
    label: 'Status',
    dataIndex: 'status',
    render: (status) => {
      return (
        <StatusChip
          label={capitalize(status)}
          color={statusColor[status]?.text}
          bgColor={statusColor[status]?.bg}
        />
      );
    }
  },
  {
    label: 'Reported Date',
    dataIndex: 'reportedDate'
  },
  // {
  //   label: 'Location',
  //   dataIndex: 'location'
  // },
  {
    label: 'Priority',
    dataIndex: 'priority'
  },
  {
    label: '',
    dataIndex: 'action',
    render: (asset) => {
      return (
        <CustomMenu
          divideAll
          options={[
            {
              label: 'View Report',
              onClick: () => {
                viewAssetAction(asset);
              }
            },
            {
              label: 'Mark as sent for repair'
            },

            {
              label: 'Delete Report',
              style: { color: 'red' }
            }
          ]}
        />
      );
    }
  }
];

export const dataSource = [
  {
    assetName: 'Giyu Tomioka',
    assetType: 'Demon slayer',
    issuedType: 'Water hashira',
    issuedDate: '20/06/2021',
    reportedBy: 'ido tensayhi',
    status: 'resolved',
    reportedDate: 'ido tensayhi',
    location: 'swordsmith arc',
    priority: 'high',
    assignedTo: 'Marvin gay'
  },
  {
    assetName: 'Saname Shinasugawwa',
    assetType: 'Demon slayer',
    issuedType: 'Wind hashira',
    issuedDate: '20/06/2021',
    reportedBy: 'ido tensayhi',
    status: 'inspected',
    reportedDate: 'ido tensayhi',
    location: 'swordsmith arc',
    priority: 'low',
    assignedTo: 'Tangiro Kamadon'
  },
  {
    assetName: 'Rangoku Kiogiro',
    assetType: 'Demon slayer',
    issuedType: 'Flame hashira',
    issuedDate: '20/06/2021',
    reportedBy: 'ido tensayhi',
    status: 'pending',
    reportedDate: 'ido tensayhi',
    location: 'swordsmith arc',
    priority: 'N/A',
    assignedTo: 'Tangiro Kamadon'
  }
].map((el) => ({ ...el, action: el }));

export const optionAll = { label: 'All', value: '' };

export const maintenance = [
  {
    label: 'Maintenance',
    options: [
      optionAll,
      { label: 'Inspection', value: 'inspection' },
      { label: 'Repair', value: 'repair' },
      { label: 'Service', value: 'service' }
    ]
  }
];

export const tabs = ['All', 'In Repair', 'Inspected', 'Pending', 'Resolved'];
