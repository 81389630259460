import { FormControl } from './styled.select-fields';
import { InputLabel, Select, MenuItem } from '@material-ui/core';
import { Country } from 'country-state-city';

function Countries({ value, onChange, onBlur, ...props }) {
  return (
    <FormControl variant='outlined' color='secondary'>
      <InputLabel id='demo-simple-select-filled-label'>
        {props.label ?? 'Business country'}
      </InputLabel>
      <Select
        {...props}
        name='country'
        value={value}
        color='secondary'
        onChange={onChange}
        onBlur={onBlur}
      >
        <MenuItem></MenuItem>
        {Country.getAllCountries().map((country) => (
          <MenuItem key={country.name} value={country.name}>
            {country.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

//TODO: use error boundaries to prevent crash

export default Countries;
