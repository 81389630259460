import InfoIcon from '@mui/icons-material/Info';
import LockIcon from '@mui/icons-material/Lock';
import AddIssueTypeModal from 'Assets/Modals/IssueTypeModal';
import { useFetchIssueType } from 'Assets/queries';
import { CustomButton as Button } from 'components/Button';
import CustomMenu from 'components/CustomMenu/CustomeMenu';
import Table from 'components/CustomTable/Table';
import { SearchFieldAntd } from 'components/FIlters/SearchField';
import GoBack from 'components/GoBack';
import Group from 'components/StyledComponents/ButtonGroup';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import useDebouceSearch from 'hooks/useDebouceSearch';
import useDisclosure from 'hooks/useDisclosure';
import { useState } from 'react';
import styled from 'styled-components';

const IssuedType = () => {
  const [search, handleSearch] = useDebouceSearch();
  const { data, isLoading, isError, refetch } = useFetchIssueType({ search });
  const [open, { toggle }] = useDisclosure();
  const [toView, setToView] = useState(null);
  const closeIssueModal = () => {
    setToView(null);
    toggle();
  };
  return (
    <Wrapper>
      <GoBack title={'Back to Settings'} />
      <div className='title'>
        <Title>Issue Type</Title>
        <Text>Create new issue type </Text>
      </div>
      <hr />
      <Group fullWidth jc='flex-center' items='center' className='search_block'>
        <div className='search-field'>
          <SearchFieldAntd
            onChange={(event) => {
              handleSearch(event?.target?.value);
            }}
            size='large'
            placeholder='search for name'
          />
        </div>
        <Button variant='contained' size='sm' onClick={toggle}>
          Add New Issue Type
        </Button>
      </Group>

      <div style={{ marginBlock: '2rem' }}>
        <Table spacing={[10.6, 1.4]}>
          <Table.Head column={[{ label: 'Issue Type' }, { label: '' }]} />
          <Table.Body
            isLoading={isLoading}
            isError={isError}
            data={data?.results || []}
            renderRow={(row, index) => {
              const isDefault = row?.is_default;
              return (
                <>
                  <Text>{row.name}</Text>
                  {isDefault ? (
                    <div className='action_block'>
                      <div className='default'>
                        <Text size='xs'>Locked</Text>
                        <LockIcon style={{ fontSize: 16 }} />
                      </div>
                      <InfoIcon className='info' style={{ fontSize: 16 }} />
                    </div>
                  ) : (
                    <div className='menu'>
                      <CustomMenu
                        size='sm'
                        divideAll={true}
                        options={[
                          {
                            label: 'Edit',
                            onClick(_, handleClose) {
                              setToView({ ...row });
                              toggle();
                              handleClose();
                            }
                          },
                          {
                            label: 'Delete',
                            className: 'menu_delete',
                            style: {
                              color: 'red'
                            },
                            onClick() {}
                          }
                        ]}
                      />
                    </div>
                  )}
                </>
              );
            }}
          />
        </Table>
      </div>
      {open && (
        <AddIssueTypeModal
          editData={toView}
          refetch={refetch}
          open={open}
          toggle={closeIssueModal}
        />
      )}
    </Wrapper>
  );
};

export default IssuedType;

const Wrapper = styled.div`
  padding-top: 2rem;
  .title {
    padding-block: 0.5rem;
  }
  .search_block {
    gap: 1.5rem;
    margin-top: 2rem;
    .search-field {
      width: min(400px, 50%);
    }
  }
  .action_block {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    .default {
      display: flex;
      align-items: center;
      gap: 0.2rem;
      border-radius: 6px;
      background: #fafafa;
      padding: 10px;
    }
    .info {
      fill: #2898a4;
    }
  }
  .menu_delete {
    color: red;
  }
  .menu {
    text-align: right;
  }
`;
