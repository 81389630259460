import LockIcon from '@mui/icons-material/Lock';
import { maintenanceType } from 'Assets/Modals/AddMaintenanceModal';
import AddNewStatutoryType from 'Assets/Modals/AddNewStatutoryType';
import { useGetDocumnentType, useRemoveDocumentType } from 'Assets/queries';
import { CustomButton as Button } from 'components/Button';
import CustomMenu from 'components/CustomMenu/CustomeMenu';
import Table from 'components/CustomTable/Table';
import { SearchFieldAntd } from 'components/FIlters/SearchField';
import GoBack from 'components/GoBack';
import Group from 'components/StyledComponents/ButtonGroup';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import useDebouceSearch from 'hooks/useDebouceSearch';
import useDisclosure from 'hooks/useDisclosure';
import { useState } from 'react';
import styled from 'styled-components';
import DeleteModal from '../../Modals/DeleteModal.jsx';
const StatutoryType = () => {
  const [search, handleSearch] = useDebouceSearch();
  const [toView, setToView] = useState(null);
  const {
    data: documentType,
    isLoading,
    isError,
    refetch
  } = useGetDocumnentType({ search });
  const [open, { toggle }] = useDisclosure();
  const [openDelete, { toggle: toggleDelete }] = useDisclosure();

  const handleClose = () => {
    setToView(null);
    if (open) {
      toggle();
    }
    if (openDelete) {
      toggleDelete();
    }
  };

  const deleteMutate = useRemoveDocumentType({
    onSuccess: () => {
      handleClose();
      refetch();
    }
  });

  const handleDelete = () => {
    deleteMutate.mutate({ id: toView?.id });
  };

  const data = [
    ...maintenanceType.map((el) => ({
      ...el,
      name: el.label,
      is_default: true
    }))
  ].concat(documentType ? documentType : []);

  return (
    <Wrapper>
      <GoBack title={'Back to Settings'} />

      <div className='title'>
        <Title>Statutory Information</Title>
        <Text>You can add to our already preset information</Text>
      </div>
      <hr />
      <Group fullWidth jc='flex-center' items='center' className='search_block'>
        <div className='search-field'>
          <SearchFieldAntd
            onChange={(event) => {
              handleSearch(event?.target?.value);
            }}
            size='large'
            placeholder='search for name'
          />
        </div>
        <Button variant='contained' size='sm' onClick={toggle}>
          Add New Statutory
        </Button>
      </Group>
      <div style={{ marginBlock: '2rem' }}>
        <Table spacing={[11, 1]} minWidth='800px'>
          <Table.Head
            column={[{ label: 'Statutory Information' }, { label: '' }]}
          />
          <Table.Body
            isLoading={isLoading}
            isError={isError}
            data={data || []}
            renderRow={(row, index) => {
              const isDefault = row?.is_default;

              return (
                <>
                  <Text>{row?.name}</Text>
                  {isDefault ? (
                    <div className='action_block'>
                      <div className='default'>
                        <Text size='xs'>Locked</Text>
                        <LockIcon style={{ fontSize: 16 }} />
                      </div>
                    </div>
                  ) : (
                    <div className='menu'>
                      <CustomMenu
                        size='sm'
                        divideAll={true}
                        options={[
                          {
                            label: 'Edit',
                            onClick(_, handleClose) {
                              setToView({ ...row });
                              toggle();
                              handleClose();
                            }
                          },
                          {
                            label: 'Delete',
                            className: 'menu_delete',
                            style: {
                              color: 'red'
                            },
                            onClick() {
                              setToView({ ...row });
                              toggleDelete();
                            }
                          }
                        ]}
                      />
                    </div>
                  )}
                </>
              );
            }}
          />
        </Table>
      </div>
      {open && (
        <AddNewStatutoryType
          editData={toView}
          open={open}
          refetch={refetch}
          toggle={handleClose}
        />
      )}
      {openDelete && (
        <DeleteModal
          open={openDelete}
          toggle={handleClose}
          isLoading={deleteMutate.isLoading}
          title={`Permanently Delete (${toView?.name})`}
          desc={`Are you sure you want to delete ${toView?.name} from the list of Statutory types?`}
          handleDelete={handleDelete}
        />
      )}
    </Wrapper>
  );
};

export default StatutoryType;

const Wrapper = styled.div`
  padding-top: 2rem;
  .title {
    padding-block: 0.5rem;
  }
  .search_block {
    gap: 1.5rem;
    margin-top: 2rem;
    .search-field {
      width: min(400px, 50%);
    }
  }
  .action_block {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    justify-content: flex-end;
    .default {
      display: flex;
      align-items: center;
      gap: 0.2rem;
      border-radius: 6px;
      background: #fafafa;
      padding: 10px;
    }
    .info {
      fill: #2898a4;
    }
  }
  .menu_delete {
    color: red;
  }
  .menu {
    text-align: right;
  }
`;
