import { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import lottie from 'lottie-web';
import reactLogo from './lottie.json';

const Empty = ({ props }) => {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#empty'),
      animationData: reactLogo,
      renderer: 'svg',
      loop: true,
      autoplay: true
    });
  }, []);
  const { label } = props;
  return (
    <Box
      style={{
        width: '50%',
        margin: '1rem auto',
        textAlign: 'center'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <div
          id='empty'
          style={{
            width: 180,
            height: 180
          }}
        />
      </div>
      <Typography
        style={{ fontFamily: 'Black Sans', fontWeight: 600, color: '#e3e3e3' }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default Empty;
