import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis
} from 'recharts';

import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import { useQuery } from 'react-query';
import { getAssetFlow } from '../../api/asset';

const useStyles = makeStyles((theme) => ({
  Wrapper: {
    background: 'white',
    padding: '20px',
    height: '285px',
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 30%)',
    borderRadius: '5px',

    '& .MuiTypography-h4': {
      // ...theme.mixins.makeResponsive({ theme, fontSize: 20, object: true }),
      // fontWeight: 700,
      // color: '#7F7F7F'
      // paddingBottom: '10px',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20.25px',
      lineHeight: '23px',
      color: '#545454'
    }
  },
  EmptyStateWrapper: {
    background: 'white',
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 30%)',
    borderRadius: '5px',
    padding: '20px',
    height: '285px',

    '& .MuiTypography-h4': {
      ...theme.mixins.makeResponsive({ theme, fontSize: 20, object: true }),
      fontWeight: 700,
      color: '#7F7F7F'
    },
    '& .MuiTypography-subtitle2': {
      color: '#545454'
    }
  },
  emptyState: {
    marginTop: '2em'
  }
}));

const sum = [
  {
    name: 'Jan',
    pv: 0
  },
  {
    name: 'Feb',
    pv: 0
  },
  {
    name: 'Mar',
    pv: 0
  },
  {
    name: 'Apr',
    pv: 0
  },
  {
    name: 'May',
    pv: 0
  },
  {
    name: 'Jun',
    pv: 0
  }
];

function TotalAsset() {
  const classes = useStyles();
  const { data, isFetching } = useQuery(['get-asset-flow'], getAssetFlow);

  if (isFetching) {
    return (
      <Box>
        <Skeleton
          variant='rect'
          height={285}
          style={{ borderRadius: '10px' }}
        />
      </Box>
    );
  }

  if (data && data.asset_count === 0) {
    return (
      <Box className={classes.EmptyStateWrapper}>
        <Typography variant='h4'>Total Asset</Typography>
        <Box className={classes.emptyState}>
          <ResponsiveContainer width='' height={200}>
            <LineChart width={730} height={250} data={sum}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name' />
              {/* <Tooltip /> */}
              <Line type='monotone' dataKey='pv' stroke='#8884d8' />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    );
  }

  const assetFlow = data?.asset_flow_entries.map((flow) => ({
    name: flow.period_end,
    total: flow.total
  }));

  return (
    <Box className={classes.Wrapper}>
      <Grid container>
        <Grid item>
          <Title color='#878787' light order={6}>
            Total Asset
          </Title>
        </Grid>
      </Grid>
      <Grid container justify='space-between' alignItems='center'>
        <Grid item>
          <Title order={2}>{data?.asset_count}</Title>
        </Grid>
        {data && data?.increase_percent !== null && (
          <Grid item>
            <Text
              size='sm'
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {data.increase_percent}%
              <ArrowUpwardIcon fontSize='small' />
            </Text>
            <Text size='xs'>{data.increase_percent_period}</Text>
          </Grid>
        )}
      </Grid>
      <div
        style={{
          overflowX: 'auto'
        }}
      >
        <ResponsiveContainer height={200}>
          <LineChart
            width={200}
            height={250}
            data={assetFlow}
            margin={{ top: 5, right: 4, left: 4, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray='1 1 1 1' />
            <XAxis dataKey='name' />
            {/* <Tooltip /> */}
            <Line
              type='monotone'
              dataKey='total'
              stroke='#56ccf2'
              dot={false}
              strokeWidth={4}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Box>
  );
}

export default TotalAsset;
