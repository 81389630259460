import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { getEmployees } from '../../../api/hr';

function VehicleAssignee({
  width,
  value,
  onChange,
  onBlur,
  onFocus,
  focused,
  setFocused
}) {
  const { data, isFetching } = useQuery(['employees'], getEmployees);
  return (
    <FormControl
      variant='outlined'
      size={isWidthDown('lg', width) ? 'small' : 'medium'}
      color='secondary'
      fullWidth
    >
      <InputLabel id='vehicle-assignee'>
        {focused?.assignee || value ? 'Assignee' : 'Assignee (Optional)'}
      </InputLabel>
      <Select
        labelId='vehicle-assignee'
        label={
          focused?.assignee || value ? 'Assigned To' : 'Assigned To (Optional)'
        }
        size='small'
        name='assignee'
        onChange={onChange}
        // onFocus={() =>
        //   setFocused({
        //     ...focused,
        //     assignee: true
        //   })
        // }
        // onBlur={() =>
        //   setFocused({
        //     ...focused,
        //     assignee: false
        //   })
        // }
        value={value}
      >
        <MenuItem value={null}></MenuItem>
        {isFetching ? (
          <CircularProgress color='secondary' />
        ) : !data.results ? (
          'An error occured'
        ) : (
          data.results.map((assignee) => {
            return (
              <MenuItem value={assignee.id}>
                {assignee.first_name} {assignee.last_name}
              </MenuItem>
            );
          })
        )}
      </Select>
    </FormControl>
  );
}

VehicleAssignee.propTypes = {
  width: PropTypes.string
};

export default withWidth()(VehicleAssignee);
