import React from "react";
import { Skeleton, Stack } from "@mui/material";
const LoadingRow = ({ rowDataLength = 4 }) => {
  const loaderArr = [];

  for (let i = 0; i < rowDataLength; i++) {
    loaderArr.push(
      <Skeleton key={i} sx={{ width: 200, height: 30 }} animation="wave" />
    );
  }
  return (
    <Stack
      direction={"row"}
      justifyContent="space-around"
      sx={{
        width: "100%",
        paddingBlock: 1,
      }}>
      {/* <Skeleton sx={{ width: 200, height: 30 }} animation="wave" />
      <Skeleton sx={{ width: 200, height: 30 }} animation="wave" />
      <Skeleton sx={{ width: 200, height: 30 }} animation="wave" />
      <Skeleton sx={{ width: 200, height: 30 }} animation="wave" /> */}
      {loaderArr}
    </Stack>
  );
};

export default LoadingRow;
