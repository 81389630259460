import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { CustomText, FlexBox } from 'common/Reusables';
import React from 'react';
import Box from '@material-ui/core/Box';
import NotFoundIcon from '../common/images/permission_icon.svg';

const useStyles = makeStyles({
  root: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 2rem',
    gap: '10px',
    '& .MuiTypography-subtitle2': {
      fontSize: '18px'
    },
    '& .MuiTypography-subtitle1': {
      fontSize: '16px'
    }
  }
});

const UnauthorizedAccess = () => {
  const classes = useStyles();

  const router = useHistory();
  return (
    <Box className={classes.root}>
      <img
        src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638786088/Bizedge_Frontend_Assets/Bizedge_logo_axcwlg.svg'
        alt='bizedge logo'
        style={{ width: '135.11px', height: '33.74px' }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '90vh',
          gap: '25px'
        }}
      >
        <Box>
          <FlexBox jc='center' mb='2rem'>
            <img src={NotFoundIcon} alt='' />
          </FlexBox>
          <CustomText
            fontWeight='600'
            textAlign='center'
            fontSize='24px'
            color='#171717'
          >
            Uh oh! Looks like you're trying to <br /> reach a restricted area.
          </CustomText>
          <CustomText
            fontWeight='400'
            textAlign='center'
            fontSize='14px'
            color='#878787'
            margin='1rem 0'
          >
            This section is currently unavailable. To get back to your dashboard{' '}
            <br />
            and explore the features you have access to, click the button below.
          </CustomText>
          <Box mt='1rem'>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => router.push('/dashboard')}
            >
              Back to Dashboard
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UnauthorizedAccess;
