import { Checkbox } from '@material-ui/core';
import checkIcon from '../../common/images/checked.svg';
import uncheckIcon from '../../common/images/uncheck.svg';
import { Box, Typography } from '@mui/material';
// import AccountingIcon from 'Onboarding/icons/AccountingIcon';
import AssetsIcon from 'Onboarding/icons/AssetsIcon';
import HrIco from 'Onboarding/icons/HrIco';
import PayrollIcon from 'Onboarding/icons/PayrollIcon';
import TaskIcon from 'Onboarding/icons/TaskIcon';
import TimeIcon from 'Onboarding/icons/TimeIcon';
import React from 'react';

const Packages = (props) => {
  const packages = [
    // {
    //   name: 'Accounting',
    //   icon: <AccountingIcon />
    // },
    {
      name: 'Core HR',
      icon: <HrIco />
    },
    {
      name: 'Payroll',
      icon: <PayrollIcon />
    },
    {
      name: 'Assets ',
      icon: <AssetsIcon />
    },
    {
      name: 'Task',
      icon: <TaskIcon />
    },
    {
      name: 'Time & Attendance',
      icon: <TimeIcon />
    }
  ];

  const handleChange = (e, product) => {
    e.target.checked
      ? props.setProducts([...props.products, product])
      : props.setProducts(
          props.products.filter((products) => products !== product)
        );
  };

  return (
    <Box
      display='flex'
      sx={{ flexDirection: { xs: 'column', lg: 'row' }, width: '621px' }}
      // flexDirection={{ xs: 'column', lg: 'row' }}
      style={{ gap: 11 }}
      flexWrap='wrap'
    >
      {packages.map((item, index) => (
        <Box
          key={index}
          sx={{
            bgcolor: '#FAFAFA',
            border: '1px solid #E1E1E1',
            width: item.name.includes('Time')
              ? '250px'
              : item.name.includes('Task')
              ? '154px'
              : '194px',
            height: '59px',
            borderRadius: '10px',
            display: 'flex',
            gap: item.name.includes('Time') ? '5px' : '16px',
            alignItems: 'center',
            padding: '10.0026px 0px 10.0026px 13px'
          }}
        >
          {item.icon}
          <Typography
            sx={{
              fontFamily: 'Black Sans',
              fontWeight: 600,
              fontSize: '15px',
              lineHeight: '18px'
            }}
          >
            {item.name}
          </Typography>
          <Checkbox
            icon={<img src={uncheckIcon} alt='' />}
            checkedIcon={<img src={checkIcon} alt='' />}
            onChange={(e) => handleChange(e, item.name)}
          />
        </Box>
      ))}
    </Box>
  );
};

export default Packages;
