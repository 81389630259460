import { reminderType } from 'Assets/Modals/ReminderModal';
import { useFetchAssetReminders } from 'Assets/queries';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ButtonTabOffWhite from 'components/ButtonTab';
import Title from 'components/Typography/Title';
import useDisclosure from 'hooks/useDisclosure';
import RenewalDetailsModal from './RenewalDetailsModal';
import ReminderList from './ReminderList';
import ContentWrapper from 'components/ContentWrapper/ContentWrapper';
const tabs = ['Upcoming', 'Overdue'];

const documentRenewal = reminderType[0].value;

const Reminder = () => {
  const [tab, setTab] = useState(0);
  const [isOpen, { toggle }] = useDisclosure();
  const [toRenew, setToRenew] = useState(null);
  const history = useHistory();
  const handleChange = (event, value) => {
    setTab(value);
  };

  const reminderQuery = useFetchAssetReminders();

  const { data } = reminderQuery ?? {};
  const upcoming = (data ? data[0] : []).reverse();
  const due = (data ? data[1] : []).reverse();

  const handleClick = (reminder) => {
    return () => {
      if (reminder.type === documentRenewal) {
        setToRenew(reminder);
        toggle();
      } else {
        history.push(`/assets?tab=asset-list&view=asset&id=${reminder?.asset}`);
      }
    };
  };
  return (
    <div
      style={{
        border: '1px solid #E1E1E1',
        borderRadius: '15px',
        padding: '1rem',
        width: '100%',
        minWidth: '350px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '.5rem',
        maxHeight: '500px',
        overflowY: 'auto'
      }}
    >
      <div>
        <Title color='#878787' order={6}>
          Reminder
        </Title>
        <ButtonTabOffWhite
          value={tab}
          onChange={handleChange}
          tabs={tabs}
          counts={[upcoming.length, due.length]}
        />
      </div>
      <div style={{ flex: 1, overflowY: 'auto' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            marginTop: '.5rem',
            paddingRight: '5px'
          }}
        >
          <ContentWrapper
            currentQuery={reminderQuery}
            errorTitle='Error getting reminders'
          >
            {tab === 1 ? (
              <ReminderList
                reminders={due}
                overdue={true}
                handleClick={handleClick}
              />
            ) : (
              <ReminderList reminders={upcoming} handleClick={handleClick} />
            )}
          </ContentWrapper>
        </div>
      </div>
      {isOpen ? (
        <RenewalDetailsModal open={isOpen} toggle={toggle} data={toRenew} />
      ) : null}
    </div>
  );
};

export default Reminder;
