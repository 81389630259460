import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
// import styled from 'styled-components';

const GoBack = ({
  title,
  icon,
  onClick,
  color,
  p,
  spacing,
  iconOnly = false
}) => {
  const history = useHistory();
  const handleClick = () => {
    if (onClick) {
      onClick();
      return;
    }
    history.goBack();
  };
  return (
    <Button
      sx={{
        color: color ? color : '#545454'
      }}
      p={p}
      variant='text'
      onClick={handleClick}
    >
      {icon ? icon : <KeyboardBackspaceIcon />}
      {!iconOnly && (
        <span style={{ marginLeft: spacing ?? '0.5rem' }} className='title'>
          {title ? title : 'Back'}
        </span>
      )}
    </Button>
  );
};

export default GoBack;
