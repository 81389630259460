import React, { useRef } from 'react';
import styled from 'styled-components';
import checkIcon from '../../common/images/checked.svg';

import { Box, Button, Divider } from '@material-ui/core';

const colors = [
  { bg: '#D6F2F5', color: '#2898A4' },
  { bg: '#FFCCCC', color: '#FF7272' },
  { bg: '#D6DFF5', color: '#4069D0' },
  { bg: '#CCF3FF', color: '#009CCC' },
  { bg: '#FCDC9C', color: '#946405' }
];

const BusinessLogo = ({ formik, imageData, setImageData, EmptyState }) => {
  const inputRef = useRef();

  function handleClick() {
    inputRef.current.click();
  }

  function handleFile(event) {
    if (event.target.files.length) {
      setImageData({
        ...imageData,
        fileupload: URL.createObjectURL(event.target.files[0]),
        file: event.target.files[0]
      });
      formik.setFieldValue('logo', event.target.files[0]);
      formik.setFieldValue('avatar', '');
    }
  }

  const handleRemove = () => {
    formik.setFieldValue('logo', '');
    setImageData({
      fileupload: EmptyState,
      file: ''
    });
  };

  const handleAvatarSelect = (item) => {
    handleRemove();
    formik.setFieldValue('avatar', item.bg);
  };

  return (
    <BusinessLogo.Wrapper>
      <input
        type='file'
        accept='image/*'
        ref={inputRef}
        // value={csv}
        onChange={handleFile}
      />
      <Box
        display='flex'
        justifyContent={{ xs: 'center', lg: 'left' }}
        style={{ gap: 25 }}
      >
        <img src={imageData.fileupload} alt='' className='image' />
        <div className='buttons'>
          <Button
            variant='contained'
            color='secondary'
            className='choose-file'
            onClick={handleClick}
          >
            Choose a file
          </Button>
          {imageData.file !== '' && (
            <Button
              variant='contained'
              color='secondary'
              className='remove'
              onClick={handleRemove}
            >
              Remove Avatar
            </Button>
          )}
        </div>
      </Box>
      <Box m='29px 0 37px' width='75%' className='divider'>
        <Divider />
      </Box>
      <div className='text'>Don’t have a logo? Select any of the avatars</div>

      <Box display='flex' style={{ gap: 21, marginTop: 21 }}>
        {colors.map((item) => (
          <Text
            color={item.color}
            bg={item.bg}
            onClick={() => handleAvatarSelect(item)}
          >
            {formik?.values?.name?.charAt(0)}
            {formik.values.avatar === item.bg && <img src={checkIcon} alt='' />}
          </Text>
        ))}
      </Box>
      <Button
        variant='contained'
        color='secondary'
        className='mobile-choose-file choose-file'
        onClick={handleClick}
      >
        Choose a file
      </Button>
    </BusinessLogo.Wrapper>
  );
};

export default BusinessLogo;

BusinessLogo.Wrapper = styled.div`
  .image {
    width: 120px;
    height: 120px;
    border-radius: 18.8573px;
  }
  .buttons {
    display: flex;
    flex-direction: column;
    gap: 34px;
  }
  .choose-file {
    background: #f2f2f2;
    border-radius: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 148.19%;
    color: #878787;
    height: 41px;
    @media (max-width: 640px) {
      display: none;
    }
  }
  .mobile-choose-file {
    display: none;
    @media (max-width: 640px) {
      display: block;
      margin-top: 95px;
      width: 100% !important;
    }
  }
  .remove {
    background: #ffeded;
    border-radius: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 148.19%;
    color: #f04949;
    height: 41px;
  }
  .divider {
    @media (max-width: 640px) {
      width: 100%;
    }
  }
  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: #545454;
  }
  input[type='file'] {
    display: none;
  }
`;

const Text = styled.p`
  margin: 0;
  padding: 13.1px;
  color: ${({ color }) => color};
  background: ${({ bg }) => bg};
  border-radius: 8.42256px;
  font-weight: 600;
  font-size: 25.2677px;
  line-height: 114.33%;
  width: 50.54px;
  height: 50.54px;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;
  img {
    position: absolute;
    top: -7%;
    right: -13%;
  }
`;
