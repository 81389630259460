import { useState } from 'react';
import { useFormik } from 'formik';
import qs from 'query-string';
import * as yup from 'yup';
import Box from '@material-ui/core/Box';
import {
  FormDiv,
  SubmitButton,
  PasswordTooTip,
  FormHeaderReset,
  ServerError
} from './styled.forms';
import { CircularProgress, TextField, Typography } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { useHistory } from 'react-router-dom';
import { resetPasswordConfirm } from '../../api/common';
import { Helmet } from 'react-helmet';

function PasswordHint() {
  return (
    <ul>
      <li>Your password must contain at least 8 characterss</li>
      <li>
        Your password can't be too similar to your other personal information
      </li>
      <li>Your password can't be a commonly used password</li>
      <li>Your password can't be entirely numeric</li>
    </ul>
  );
}

// const initialValues = {
//   email: '',
//   password: ''
// };

const validationSchema = yup.object().shape({
  password: yup.string().min(8, 'Password is too short').required('Required'),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('password'), null],
      'Password and Confirm password don"t match'
    )
    .required('Required')
});

function ResetPassword({ width }) {
  const { uid, token } = qs.parse(window.location.search);
  const [showHint, setShowHint] = useState(false);
  const history = useHistory();
  const [serverErrors, setServerErrors] = useState([]);

  const handleForm = async () => {
    try {
      const payload = {
        token,
        uid,
        new_password: formik.values.password
      };
      const data = await resetPasswordConfirm(payload);
      if (data) {
        history.push('/password-reset/successful');
      }
    } catch (error) {
      const {
        status,
        data: { new_password, detail, non_field_errors }
      } = error.response;
      if (status === 400 && new_password) {
        setServerErrors(new_password);
      }
      if (status === 400 && detail) {
        setServerErrors([detail]);
      }
      if (status === 400 && non_field_errors) {
        setServerErrors(non_field_errors);
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema,
    onSubmit: handleForm
  });

  const hasErrors = (field) => {
    return formik.touched[field] && formik.errors[field];
  };

  const disableButton =
    hasErrors('password') ||
    hasErrors('confirmPassword') ||
    !formik.values.password ||
    !formik.values.confirmPassword ||
    formik.isSubmitting;

  if (serverErrors[0] === 'Invalid reset link') {
    return (
      <Box
        style={{
          borderRadius: '5px',
          width: '50%',
          background: 'white',
          textAlign: 'center',
          height: '100px',
          margin: '10% auto'
        }}
      >
        <Typography variant='h6'>Sorry, something went wrong</Typography>
      </Box>
    );
  }
  return (
    <FormDiv>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </Helmet>
      <FormHeaderReset>Reset Password</FormHeaderReset>
      {/* <FormDescription>
        You enter your email for a recovery link to be sent to reset your password.
      </FormDescription> */}

      {/* {serverErrors.password || serverErrors.email ? (
        <ServerError>
          <Typography component='span'>
            {serverErrors.email || serverErrors.password}
          </Typography>
        </ServerError>
      ) : null} */}
      {serverErrors.length > 0 && (
        <ServerError>
          {serverErrors &&
            serverErrors.map((error) => (
              <Typography variant='subtitle2'>{error}</Typography>
            ))}
        </ServerError>
      )}
      <form onSubmit={formik.handleSubmit}>
        <PasswordTooTip
          open={showHint}
          placement='bottom-start'
          title={<PasswordHint />}
        >
          {/* =================== Password ================== */}
          <TextField
            color='secondary'
            error={hasErrors('password')}
            fullWidth={true}
            label='Password'
            size={isWidthDown('lg', width) ? 'small' : 'medium'}
            variant='outlined'
            placeholder='Password'
            type='password'
            name='password'
            value={formik.values.password}
            onFocus={() => setShowHint(true)}
            onBlur={(e) => {
              setShowHint(false);
              return formik.handleBlur(e);
            }}
            onChange={formik.handleChange}
            haserror={hasErrors('password')}
            FormHelperTextProps={{ error: hasErrors('password') }}
            helperText={hasErrors('password') ? formik.errors.password : null}
          />
        </PasswordTooTip>

        {/* =================== Confirm Password ================== */}
        <TextField
          color='secondary'
          error={hasErrors('confirmPassword')}
          fullWidth={true}
          label='Confirm Password'
          size={isWidthDown('lg', width) ? 'small' : 'medium'}
          variant='outlined'
          placeholder='Confirm Password'
          type='password'
          name='confirmPassword'
          value={formik.values.confirmPassword}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          haserror={hasErrors('confirmPassword')}
          FormHelperTextProps={{ error: hasErrors('confirmPassword') }}
          helperText={
            hasErrors('confirmPassword') ? formik.errors.confirmPassword : null
          }
        />

        <SubmitButton
          disabled={disableButton}
          type='submit'
          onClick={formik.handleSubmit}
        >
          {formik.isSubmitting ? (
            <CircularProgress size={20} style={{ color: 'white' }} />
          ) : (
            'Reset Password'
          )}
        </SubmitButton>
      </form>
    </FormDiv>
  );
}

export default withWidth()(ResetPassword);
