/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  FormDiv,
  FormHeader,
  FormDescription,
  SubmitButton,
  ServerError
} from './styled.forms';
import { CircularProgress, TextField, Typography } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { useHistory, Redirect } from 'react-router-dom';
import { resetPassword } from '../../api/common';
import { useAuth } from '../../Auth/auth-context';
import { Helmet } from 'react-helmet';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useMediaQuery } from 'react-responsive';
// function PasswordHint() {
//   return (
//     <ul>
//       <li>Your must contain at least 8 characterss</li>
//       <li>
//         Your password can't be too similar to your other personal information
//       </li>
//       <li>Your password can't be a commonly used password</li>
//       <li>Your password can't be entirely numeric</li>
//     </ul>
//   );
// }

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email address').required('Required')
});

function ForgotPassword({ width }) {
  const result = useAuth();
  const history = useHistory();
  const [serverErrors, setServerErrors] = useState({
    password: ''
  });

  const isMobile = useMediaQuery({
    query: '(max-width: 640px)'
  });

  const handleSubmit = async () => {
    try {
      const payload = {
        email: formik.values.email
      };
      const data = await resetPassword(payload);
      if (data) {
        sessionStorage.setItem('userEmail', formik.values.email);
        history.push('/forgot-password-confirm');
      }
    } catch (error) {
      const { status } = error.response;
      if (status === 400) {
        formik.setFieldError('email', error.response.data.email[0]);
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    onSubmit: handleSubmit,
    validationSchema
  });

  const hasErrors = (field) => {
    return formik.touched[field] && formik.errors[field];
  };

  const disableButton = hasErrors('email') || formik.isSubmitting;

  if (result.data)
    return (
      <Redirect
        to={{
          pathname: '/dashboard'
        }}
      />
    );

  return (
    <FormDiv>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </Helmet>
      {isMobile && (
        <ArrowBackIcon
          onClick={() => history.push('/login')}
          className='back'
        />
      )}
      <FormHeader>
        {isMobile ? 'Reset your password' : 'Forgot Password'}
      </FormHeader>

      <FormDescription>
        {isMobile
          ? 'Reset your password with few click without any hassle'
          : '  Enter your email we will send you a link to reset your password.'}
      </FormDescription>

      {serverErrors.password || serverErrors.email ? (
        <ServerError>
          <Typography component='span'>
            {serverErrors.email || serverErrors.password}
          </Typography>
        </ServerError>
      ) : null}
      <form onSubmit={formik.handleSubmit}>
        {/* =================== Email ================== */}
        <TextField
          color='secondary'
          error={hasErrors('email')}
          fullWidth={true}
          label='Email'
          size={isWidthDown('lg', width) ? 'small' : 'medium'}
          variant='outlined'
          placeholder='Email'
          type='email'
          name='email'
          value={formik.values.email}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          haserror={hasErrors('email')}
          FormHelperTextProps={{ error: hasErrors('email') }}
          helperText={hasErrors('email') ? formik.errors.email : null}
        />

        {isMobile ? (
          <SubmitButton
            disabled={
              formik.isSubmitting || !formik.values.email || formik.errors.email
            }
            type='submit'
            onSubmit={formik.handleSubmit}
            className='reset-password'
            style={{
              position: 'fixed !important',
              bottom: '70px !important',
              width: '80% !important',
              left: '50% !important',
              transform: 'translateX(-50%) !important'
            }}
          >
            {formik.isSubmitting ? (
              <CircularProgress size={20} style={{ color: 'white' }} />
            ) : (
              'Reset'
            )}
          </SubmitButton>
        ) : (
          <SubmitButton
            disabled={
              formik.isSubmitting || !formik.values.email || formik.errors.email
            }
            type='submit'
            onSubmit={formik.handleSubmit}
          >
            {formik.isSubmitting ? (
              <CircularProgress size={20} style={{ color: 'white' }} />
            ) : (
              'Reset Password'
            )}
          </SubmitButton>
        )}
      </form>
    </FormDiv>
  );
}

export default withWidth()(ForgotPassword);
