import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Wrapper = styled(Box)`
  ${({ theme }) => `
    position: relative;
    // background: #F9F9F9;
    // padding: 4em;
    // width: 100vw;
    height: 100vh;
    // max-width: 100vw;
    // max-height: 100vh;
    display: flex;
    justify-content: space-between;
    overflow: scroll;
    // background-image: url('/Background.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 640px) {
    // padding: 0px 1em;
  }
    ${theme.breakpoints.down('lg')} {
      // padding: 0 7.5% 0 7.5%; 
    }
  `}
`;

export const LogoDiv = styled(Box)`
  ${({ theme }) => `
    position: absolute;
    top: 9.5%;
    left: 5.1%;
    
    ${theme.breakpoints.down('lg')} {
      width: 150px;
    }
    img {
      width: 100%;
      
    }
  `}
`;

export const Logo = styled.img``;

export const DescriptionDiv = styled(Box)`
  ${({ theme }) => `
    
    width: 30%;
    min-width: 30%;
    margin-top: 10%;
    margin-right: auto;
    margin-left: auto;
  `}
`;

export const DescriptionHeader = styled(Typography)`
  ${({ theme }) => `
    color: ${theme.palette.dark.black};
    ${theme.mixins.makeResponsive({ theme, fontSize: 56, lineHeight: 66 })};
    font-weight: 700;
    font-family: 'Black Sans';
  `}
`;

export const DescriptionCaption = styled(Typography)`
  ${({ theme }) => `
    font-family: 'Black Sans';
    color: ${theme.palette.dark.main};
    ${theme.mixins.makeResponsive({ theme, fontSize: 18, lineHeight: 27 })};
    font-weight: 400;
    margin-top: 33px;
    ${theme.breakpoints.down('lg')} {
      margin-top: 20px;
    }
  `}
`;

export const IllustrationDiv = styled(Box)`
  ${({ theme }) => `
    overflow: hidden;
    margin-top: 5%;
    width: 350px;
    margin: 0 auto;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    ${theme.breakpoints.down('lg')} {
      width: 350px;
    }
  `}
`;

export const Text = styled(Typography)`
  font-weight: 600;
  font-size: 26px;
  line-height: 138.83%;
  text-align: center;
  color: #444444;
`;

export const Illustration = styled.img``;
