import createInstance, { getBaseDomainURL } from '../../api/axios.config';

export const postHook = async (val) => {
  const { route, payload } = val;
  try {
    const { data } = await createInstance.post(
      `${getBaseDomainURL()}${route}`,
      payload
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const publicPostHook = async (val) => {
  const { route, payload } = val;
  try {
    const { data } = await createInstance.post(`${route}`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const publicPutHook = async (val) => {
  const { route, payload } = val;
  try {
    const { data } = await createInstance.put(`${route}`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export function postReq(params) {
  const { route, payload } = params;
  return createInstance
    .post(`${getBaseDomainURL()}${route}`, payload)
    .then((res) => res);
}
export function patchReq(params) {
  const { route, payload } = params;
  return createInstance
    .patch(`${getBaseDomainURL()}${route}`, payload)
    .then((res) => res);
}

export function deleteReq(params) {
  const { route } = params;
  return createInstance
    .delete(`${getBaseDomainURL()}${route}`)
    .then((res) => res);
}
