import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Prompt } from './styled.modals';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMutation, useQueryClient } from 'react-query';
import { deleteVehicle, paginateVehicles } from '../../api/fleet';
import useFleetControl from '../../hooks/useFleetControl';

function ConfirmDelete({ open, toggle, id, setPaginateResult, setLoading }) {
  const mutation = useMutation(deleteVehicle);
  const queryClient = useQueryClient();
  const [fleetState, setFleetState] = useFleetControl();

  const handleDelete = () => {
    mutation.mutateAsync(id).then(() => {
      queryClient.refetchQueries(['vehicles']);
      queryClient.refetchQueries(['analytics:vehicles-summary']);
      queryClient.refetchQueries(['analytics:vehicle-status']);

      toggle();
      resetState();
      setFleetState({
        ...fleetState,
        toast: true,
        toastMessage: 'Vehicle has been deleted successfully',
        severity: 'success'
      });
    });
  };

  const resetState = async () => {
    const response = await paginateVehicles({
      newPage: 1,
      search: '',
      setLoadingHandler: setLoading
    });

    setPaginateResult(response);
  };

  return (
    <Modal open={open} onBackdropClick={toggle} style={{ textAlign: 'center' }}>
      <Prompt
        style={{
          minHeight: 'auto',
          padding: '30px 20px',
          width: 608,
          fontSize: 20,
          cursor: 'pointer'
        }}
      >
        <p onClick={toggle} style={{ textAlign: 'end', margin: 0 }}>
          X
        </p>
        <Typography variant='h2' color='secondary'>
          Delete vehicle
        </Typography>
        <Typography variant='subtitle1'>
          Are you sure you want to delete this Vehicle?
        </Typography>

        <Box>
          <Button
            variant='outlined'
            onClick={toggle}
            disabled={mutation.isLoading}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            type='submit'
            onClick={handleDelete}
            disabled={mutation.isLoading}
            startIcon={
              mutation.isLoading ? (
                <CircularProgress style={{ color: 'white' }} size={20} />
              ) : null
            }
            style={{ marginLeft: '1rem' }}
          >
            {mutation.isLoading ? null : 'Delete'}
          </Button>
        </Box>
      </Prompt>
    </Modal>
  );
}

export default ConfirmDelete;
