import { createContext, useContext, useState } from 'react';

export const BillingContext = createContext({});

export function BillingProvider({ children }) {
  const [state, setState] = useState({
    toast: false,
    toastMessage: 'Account upgraded successfully',
    isExpiry: false,
    isModalOpen: true,
    isUpgrade: false
  });
  return (
    <BillingContext.Provider value={[state, setState]}>
      {children}
    </BillingContext.Provider>
  );
}

function useBillingControl() {
  const [state, setState] = useContext(BillingContext);
  return [state, setState];
}

export default useBillingControl;
