import * as yup from 'yup';
import { formatDate, formatDateTime, defaultTime } from 'utilities/formHelpers';
import dayjs from 'dayjs';
export const requiredMsg = 'Feild is required';

export const validationSchema = yup.object().shape({
  // name: yup.string().required(requiredMsg),
  type: yup.string().required(requiredMsg),
  cost: yup.string().required(requiredMsg),
  notes: yup.string().required(requiredMsg),
  asset: yup.string().required(requiredMsg)
});

export const maintenanceType = [
  { label: 'Service', value: 'service' },
  { label: 'Repair', value: 'repair' },
  { label: 'Inspection', value: 'inspection' }
];

export const initialValues = (res) => ({
  asset: res?.asset ?? '',
  issue: res?.issue ?? '',
  type: res?.type?.toLowerCase() ?? res?.name?.toLowerCase() ?? 'service',
  date: res?.date ? new Date(res?.date) : null,
  due_date: res?.due_date ? new Date(res?.due_date) : null,
  cost: res?.cost ?? '',
  mileage: res?.mileage ?? '',
  notes: res?.notes ?? '',
  reminder: {
    asset: res?.asset ?? '',
    type: '',
    set_reminder: res?.reminder?.set_reminder
      ? res?.reminder?.set_reminder?.length > 2
        ? `${res?.reminder?.set_reminder.split(' ')[0]}d`
        : res?.reminder?.set_reminder
      : '',
    date: res?.reminder?.date
      ? dayjs(res?.reminder?.date).format('HH:mm:ss')
      : defaultTime()
  }
});

export const getPayload = (values) => {
  const mDate = values.date ? formatDate(values.date) : null;
  const dDate = values?.due_date ? formatDate(values?.due_date) : null;

  if (!values.issue) {
    delete values.issue;
  }
  if (!values.reminder.set_reminder) {
    delete values.reminder;
  }

  const payload = {
    ...values,
    date: mDate
  };

  if (values.type === 'service') {
    const rDate = formatDateTime(
      values?.reminder?.date ?? defaultTime(),
      values.due_date
    );
    payload.due_date = dDate;
    payload.reminder = {
      ...values.reminder,
      type: values.type,
      date: rDate
    };
  }

  return payload;
};
