import { createFilterOptions } from '@mui/material/Autocomplete';
const filter = createFilterOptions();

export const renderOption = (handleCustomClick) => (props, option) => {
  return option?.isAdd ? (
    <li
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingInline: 0,
        background: !option.nonExisting ? '#EAF8FA' : 'inherit',
        ...props.style
      }}
      {...props}
    >
      {option.nonExisting ? (
        <div onClick={handleCustomClick} style={{ paddingBlock: '.5rem' }}>
          No results found
        </div>
      ) : null}
      <div
        onClick={handleCustomClick}
        style={{
          backgroundColor: '#EAF8FA',
          color: '#2898A4',
          width: '100%',
          flex: 1,
          height: option.nonExisting ? 'auto' : '100%',
          padding: '.5rem'
        }}
      >
        {option?.label}
      </div>
    </li>
  ) : (
    <li
      style={{ borderBottom: '1px solid #F2F2F2', ...props?.style }}
      {...props}
    >
      {option?.label}
    </li>
  );
};

export const getOptionLabel = (option) => {
  // Value selected with enter, right from the input
  if (typeof option === 'string') {
    return option;
  }
  // Add "xxx" option created dynamically
  if (option.inputValue) {
    return option.inputValue;
  }
  // Regular option
  return option.label;
};

export const filterOptions = (options, params) => {
  const filtered = filter(options, params);

  const { inputValue } = params;
  const isExisting = options.some((option) =>
    option.label.toLowerCase().includes(inputValue.toLowerCase())
  );

  const nonExisting = inputValue.trim() !== '' && !isExisting;
  filtered.push({
    inputValue,
    nonExisting,
    isAdd: true,
    label: nonExisting ? `Add "${inputValue}"` : `Add new asset type`
  });
  //   }

  return filtered;
};

export const setValue =
  (setFieldValue, name, format = (value) => value) =>
  (value) => {
    setFieldValue(name, format(value));
  };
