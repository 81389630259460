import MuiAvatar from '@mui/material/Avatar';
import Text from 'components/Typography/Text';
import StyledAvatar from './Styled.Avatar';

const Avatar = ({
  height = 40,
  width = 40,
  first_name,
  last_name,
  photo,
  job,
  text_sizes = {
    name: 'sm',
    job: 'sm'
  }
}) => {
  return (
    <StyledAvatar>
      <MuiAvatar
        sx={{ width: width, height: height }}
        src={photo}
        alt={`${first_name} ${last_name}`}
      >
        {first_name?.split('')[0]}
        {last_name?.split('')[0]}
      </MuiAvatar>

      <div className='name_title'>
        <Text
          size={text_sizes?.name || 'sm'}
          bold
        >{`${first_name} ${last_name}`}</Text>
        <Text size={text_sizes?.name || 'sm'}>{job?.title || '_ _ _ _'}</Text>
      </div>
    </StyledAvatar>
  );
};

export default Avatar;
