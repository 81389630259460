import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useAssetControl from '../../hooks/useAssetControl';
import { Wrapper } from '../Dashboard/styled.dashboard';
import { CustomButton } from 'components/Button';
import AddAssetBtn from '../Shared/AddAssetBtn';
import AssetStatus from './AssetStatus.jsx';
import RecentAsset from './RecentAsset.jsx';
import Reminder from './Reminder';
import SearchResults from './SearchResults';
import { useStyles } from './Styled.Wrapper';
import TotalAsset from './TotalAsset.jsx';
import ImportAssestBtn from './ImportAssestBtn';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import BizLoader from 'components/BizLoader';
import { getMethod } from 'hooks/queries/getHook';
import { postHook } from 'hooks/mutations/postHook';

const asset_onboarding_key = 'asset_widget_onboarded';

function Overview() {
  const classes = useStyles();
  const [assetControl, setAssetControl] = useAssetControl();
  const [isAddAsset, setIsAddAsset] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const queryClient = useQueryClient();

  const { data: onboarding_Data, isLoading } = useQuery(
    [
      'asset_onboarding',
      {
        route: '/app_onboarding/',
        params: {
          type: 'asset_onboarding',
          business_id: localStorage.getItem('business_domain')
        }
      }
    ],
    getMethod
  );

  const data = onboarding_Data?.[0] ?? {
  };

  const createOnboardingConfig = useMutation(postHook, {
    onSuccess: (res) => {
      queryClient.refetchQueries(['asset_onboarding']);
      setAssetControl({ ...assetControl, modal: '' });
    },
    onError: () => {
      setAssetControl({ ...assetControl, modal: '' });
    }
  });

  const isAssetOnboarded =
    data?.web_onboarding?.[asset_onboarding_key] ?? false;

  useEffect(() => {
    setAssetControl({
      ...assetControl,
      modal: isAssetOnboarded ? '' : 'OnboardingModal'
    });

    // eslint-disable-next-line
  }, [data]);

  const handleCreateOnboarding = () => {
    return createOnboardingConfig.mutateAsync({
      route: '/app_onboarding/',
      payload: {
        type: 'asset_onboarding',
        business: localStorage.getItem('business_domain'),
        web_onboarding: {
          [asset_onboarding_key]: true
        }
      }
    });
  };

  const handleBackdropClick = () => {
    setAssetControl({ ...assetControl, modal: '' });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsAddAsset(false);
  };

  const handleGetStarted = () => {
    handleCreateOnboarding()
      .then(() => {
        setIsAddAsset(true);
      })
      .catch(() => {
        setAssetControl({ ...assetControl, modal: '' });
      });
  };

  if (isLoading) {
    return <BizLoader />;
  }

  return (
    <>
      <MainWrapper>
        <section className='filter-action'>
          <div className='search'>
            <SearchResults />
          </div>
          <div className='action'>
            <AddAssetBtn title='Add New Assets' />
            <ImportAssestBtn />
          </div>
        </section>
        <div className='grid-sys'>
          <div className='item-1'>
            <RecentAsset />
            <div className='grid-sys_sub'>
              <TotalAsset />
              <AssetStatus />
            </div>
          </div>
          <div className='item-2'>
            <Reminder />
          </div>
        </div>
      </MainWrapper>
      {/* {isOpen && <OnboardingModal handleClick={handleGetStarted} />} */}
      <Modal
        open={assetControl.modal === 'OnboardingModal'}
        // open={true}
        onBackdropClick={handleBackdropClick}
      >
        <Wrapper>
          <Box className={classes.modalWrapper}>
            <Typography variant='subtitle1'>
              Welcome to Bizedge Assets
            </Typography>
            <Box className={classes.body}>
              <Typography variant='body2'>
                Keep track of the assets within your organization. Bizedge asset
                module gives you an intuitive and easy way to get the job done,
                by automating away the pain of paper work.
              </Typography>
              <Box className={classes.headline}>
                <Typography variant='body2'>
                  Welcome to the future of work!
                </Typography>
              </Box>

              <div className='btn_container'>
                <CustomButton
                  variant='contained'
                  onClick={handleGetStarted}
                  isLoading={createOnboardingConfig.isLoading}
                >
                  Get Started
                </CustomButton>
              </div>
            </Box>
          </Box>
        </Wrapper>
      </Modal>
      <Popover
        open={isAddAsset}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        className={classes.popover}
      >
        <Typography variant='subtitle2'>
          <strong>Click Add Asset </strong> <br /> Click the add asset button to
          enter your assets details.
        </Typography>
      </Popover>
    </>
  );
}

export default Overview;

export const MainWrapper = styled.div`
  box-sizing: border-box;
  .filter-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    .search {
      flex: 1;
      &-input {
        border-radius: 10px;
        border: 0px solid #e1e1e1 !important;
        outline: 0px solid #e1e1e1 !important;
        background: #f2f2f2;
      }
    }
    button {
      padding-inline: 1rem;
    }
    .action {
      min-width: 350px;
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }
  }

  .grid-sys {
    padding-top: 47px;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: strech;
    .item-1 {
      flex: 1;
    }
    &_sub {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      gap: 0.5rem;
    }
  }
`;
