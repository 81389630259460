import { debounce } from 'lodash';
import { useState } from 'react';

const useAssignModalFilters = () => {
  const [searchq, setSearchq] = useState(null);
  const [departmentId, setDepartmentId] = useState(null);
  const debounceSearchHandler = debounce((event) => {
    if (!event?.target)
      throw new Error('func should be pass to an input field');
    setSearchq(event?.target?.value);
  }, 500);

  return {
    searchq,
    debounceSearchHandler,
    departmentId,
    setDepartmentId,
    setSearchq
  };
};

export default useAssignModalFilters;
