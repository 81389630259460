import React from 'react';
import Text from 'components/Typography/Text';
import { SearchFieldAntd } from 'components/FIlters/SearchField';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircle';
import { CustomButton } from 'components/Button';
const typeFilter = [
  { label: 'All', value: null },
  { label: 'Assets', value: false },
  { label: 'Vehicles', value: true }
];
const Header = ({ setType, type, setSearch, toggle }) => {
  return (
    <div className='filter'>
      <div className='filter_type'>
        {typeFilter?.map((el, index) => (
          <Text
            onClick={() => {
              setType(el.value);
            }}
            light
            className={`asset_type_filter_button  ${
              type === el.value ? 'type_active' : ''
            }`}
            key={el}
          >
            {el.label}
          </Text>
        ))}
      </div>
      <div className='search'>
        <SearchFieldAntd
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          placeholder='Search by asset name, tag, status, employee and category '
        />
        {/* <AntSelect options=[] placeholder='Asset Type' /> */}
      </div>
      <CustomButton variant='contained' onClick={toggle} size='sm'>
        <AddCircleOutlineIcon fontSize='small' />
        Add Asset Type
      </CustomButton>
    </div>
  );
};

export default Header;
