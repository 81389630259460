export const CustomTypographyStyle = {
  // Font Size
  f_12: {
    fontSize: '12px'
  },

  f_14: {
    fontSize: '14px'
  },

  f_16: {
    fontSize: '16px'
  },

  f_17: {
    fontSize: '17px'
  },

  f_20: {
    fontSize: '20px'
  },

  f_22: {
    fontSize: '22px'
  },

  f_26: {
    fontSize: '26px'
  },

  // Font Family
  sans: {
    fontFamily: 'Black Sans'
  },

  // Font Weight
  fw_400: {
    fontWeight: 400
  },

  fw_600: {
    fontWeight: 600
  },

  fw_700: {
    fontWeight: 700
  },

  // Color
  secondary_bg: {
    background: '#D6F2F5'
  },

  primary_bg: {
    background: '#2898A4'
  },

  secondary: {
    color: '#D6F2F5'
  },

  primary: {
    color: '#2898A4'
  },

  text_white: {
    color: '#ffffff'
  },

  muted_text: {
    color: '#a8a8a8'
  },

  // Status Color
  // colors
  draft: {
    background: '#C58607',
    color: '#ffffff'
  },
  sent: {
    background: '#849EE1',
    color: '#ffffff'
  },
  partial: {
    background: '#009CCC',
    color: '#ffffff'
  },
  paid: {
    background: '#2898A4',
    color: '#ffffff'
  },
  overpaid: {
    background: '#E70000',
    color: '#ffffff'
  },

  activeTab: {
    fontWeight: 600,
    color: '#2898A4 !important'
  },
  heavyLight: {
    color: '#878787'
  },

  // Others
  cursor: {
    cursor: 'pointer'
  },
  fontFamily: {
    fontFamily: 'Black Sans'
  }
};
