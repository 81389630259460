import Success from 'common/images/success_icon.svg';
import { CustomButton as Button } from 'components/Button';
import { Dialog, DialogActions, DialogContent } from 'components/Dialog';
import Group from 'components/StyledComponents/ButtonGroup';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import { useHistory } from 'react-router-dom';

const AssetAddSuccessModal = ({ open, toggle, toggleAddDoc }) => {
  const history = useHistory();
  return (
    <Dialog open={open} fullWidth maxWidth='xs'>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            alignItems: 'center',
            textAlign: 'center'
          }}
        >
          <img src={Success} alt='success icon' />
          <Title>Well Done !</Title>
          <Text size='xs'>
            You’ve successfully added an asset. Would you like to add statutory
            documents to this asset ?
          </Text>
        </div>
      </DialogContent>
      <DialogActions>
        <Group fullWidth>
          <Button
            onClick={() => {
              history.push('/assets?tab=asset-list&view=list');
            }}
            size='xs'
            fullWidth
            variant='outlined'
          >
            Cancel
          </Button>
          <Button
            onClick={toggleAddDoc}
            size='xs'
            fullWidth
            variant='contained'
          >
            Add Statutory Information
          </Button>
        </Group>
      </DialogActions>
    </Dialog>
  );
};

export default AssetAddSuccessModal;
