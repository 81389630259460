import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 20px;
  height: 285px;
  box-shadow: 0 1px 3px 0 rgba(0 0 0 / 30%);
  border-radius: 5px;
`;

export default Wrapper;
