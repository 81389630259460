import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  FormDiv,
  FormHeader,
  FormDescription,
  SubmitButton
} from './styled.forms';
import { CircularProgress, TextField } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { useHistory, Redirect } from 'react-router-dom';
import { resendConfirmationMail } from '../../api/common';
import { useAuth } from '../../Auth/auth-context';
import { Helmet } from 'react-helmet';
// function PasswordHint() {
//   return (
//     <ul>
//       <li>Your must contain at least 8 characterss</li>
//       <li>
//         Your password can't be too similar to your other personal information
//       </li>
//       <li>Your password can't be a commonly used password</li>
//       <li>Your password can't be entirely numeric</li>
//     </ul>
//   );
// }

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email address').required('Required')
});

function ResendConfirmation({ width }) {
  const result = useAuth();
  const history = useHistory();

  const handleSubmit = async () => {
    try {
      const payload = {
        email: formik.values.email
      };
      const data = await resendConfirmationMail(payload);
      if (data) {
        history.push('/signup/confirm');
      }
    } catch (error) {
      const { status } = error.response;
      if (status === 400) {
        formik.setFieldError('email', error.response.data.email[0]);
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    onSubmit: handleSubmit,
    validationSchema
  });

  const hasErrors = (field) => {
    return formik.touched[field] && formik.errors[field];
  };

  if (result.data)
    return (
      <Redirect
        to={{
          pathname: '/dashboard'
        }}
      />
    );

  return (
    <FormDiv>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </Helmet>
      <FormHeader>Resend Confirmation</FormHeader>
      <FormDescription>
        Enter your email address and we will send you a link to activate your
        account.
      </FormDescription>

      <form onSubmit={formik.handleSubmit}>
        {/* =================== Email ================== */}
        <TextField
          color='secondary'
          error={hasErrors('email')}
          fullWidth={true}
          label='Email'
          size={isWidthDown('lg', width) ? 'small' : 'medium'}
          variant='outlined'
          placeholder='Email'
          type='email'
          name='email'
          value={formik.values.email}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          haserror={hasErrors('email')}
          FormHelperTextProps={{ error: hasErrors('email') }}
          helperText={hasErrors('email') ? formik.errors.email : null}
        />

        <SubmitButton
          disabled={
            formik.isSubmitting || !formik.values.email || formik.errors.email
          }
          type='submit'
          onSubmit={formik.handleSubmit}
        >
          {formik.isSubmitting ? (
            <CircularProgress size={20} style={{ color: 'white' }} />
          ) : (
            'Resend confirmation'
          )}
        </SubmitButton>
      </form>
    </FormDiv>
  );
}

export default withWidth()(ResendConfirmation);
