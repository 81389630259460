import { CustomButton } from 'components/Button';
import Typography from 'components/Typography';
import React from 'react';

const FailedState = ({
  cls,
  errorMsg,
  title,
  onRetry,
  btnText = 'Retry',
  removeBtn = false
}) => {
  return (
    <div className={`text__center mt-3  ${cls}`}>
      {title && (
        <Typography size='sm' className='mt-5' bold>
          {title}
        </Typography>
      )}

      {errorMsg && (
        <Typography size='sm' className='mt-3 mb-4'>
          {errorMsg}
        </Typography>
      )}
      <div className='d-flex flex__center'>
        {!removeBtn && (
          <CustomButton
            variant='contained'
            p=' .8rem 1.5rem'
            className='mt-'
            onClick={onRetry}
          >
            {btnText}
          </CustomButton>
        )}
      </div>
    </div>
  );
};

export default FailedState;
