import React from 'react';
import { useQuery } from 'react-query';
import { makeStyles } from '@material-ui/core/styles';
import { getBusinessOwner } from '../api/settings';

const useStyles = makeStyles((theme) => ({
  loader: {
    width: '20%',
    margin: '10em auto',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '2em auto'
    }
  }
}));

const Index = ({ children, path }) => {
  const classes = useStyles();
  const { data, isSuccess } = useQuery(['current_user'], getBusinessOwner);
  if (!isSuccess && !data) {
    return (
      <div className={classes.loader}>
        <img
          src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638526892/Bizedge_Frontend_Assets/BizLoader_dzmb4i.gif'
          width={250}
          height={250}
          alt='loading spinner'
        />
      </div>
    );
  }
  //  else if (isSuccess && data && data.business.billing.plan === 'premium') {
  //   return <Redirect to='/dashboard' />;
  // }

  return <div>{children}</div>;
};

export default Index;
