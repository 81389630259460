import AddIcon from '@material-ui/icons/Add';
import { SettingButton } from './styled.button';

function index({
  children,
  type,
  variant,
  icon,
  handleClick,
  color,
  component,
  ...props
}) {
  return (
    <SettingButton
      startIcon={icon ? <AddIcon /> : ''}
      color={color}
      variant={variant}
      onClick={handleClick}
      size='small'
      type={type}
      fullWidth
      component={component}
      disableElevation
      {...props}
    >
      {children}
    </SettingButton>
  );
}

export default index;
