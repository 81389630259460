// const colors = ['#FCE2AF', '#FFE7E7', '#BCEFFF', '#E0EEF0'];

export const colors = {
  a: '#FCE2AF',
  b: '#FFE7E7',
  c: '#BCEFFF',
  d: '#E0EEF0',
  e: '#FCE2AF',
  f: '#FFE7E7',
  g: '#BCEFFF',
  h: '#E0EEF0',
  i: '#FCE2AF',
  j: '#FFE7E7',
  k: '#BCEFFF',
  l: '#E0EEF0',
  m: '#FCE2AF',
  n: '#FFE7E7',
  o: '#BCEFFF',
  p: '#E0EEF0',
  q: '#FCE2AF',
  r: '#FFE7E7',
  s: '#BCEFFF',
  t: '#E0EEF0',
  u: '#FCE2AF',
  v: '#FFE7E7',
  w: '#BCEFFF',
  x: '#E0EEF0',
  y: '#FCE2AF',
  z: '#BCEFFF'
};
