import { createContext, useContext, useState } from 'react';

export const AssetContext = createContext({});

export function AssetProvider({ children }) {
  const [state, setState] = useState({
    modal: '',
    id: [],
    selectedId: '',
    data: '',
    toast: false,
    toastMessage: '',
    severity: ''
  });
  return (
    <AssetContext.Provider value={[state, setState]}>
      {children}
    </AssetContext.Provider>
  );
}

function useAssetControl() {
  const [state, setState] = useContext(AssetContext);
  return [state, setState];
}

export default useAssetControl;
