import React from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";
const useStyles = makeStyles({
  root: {},
  success: {
    width: '50%',
    margin: '10% auto',
    textAlign: 'center'
  },
  failure: {
    width: '50%',
    margin: '10% auto',
    textAlign: 'center'
  }
});

const PasswordResetSuccess = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleRoute = () => {
    history.push('/login');
  };
  return (
    <>
          <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Box className={classes.success}>
        <Typography>Password reset successfull</Typography>
        <Button
          variant='contained'
          color='secondary'
          disableElevation={true}
          onClick={handleRoute}
        >
          Click here to continue
        </Button>
      </Box>
    </>
  );
};

export default PasswordResetSuccess;
