import styled from 'styled-components';

const GroupStyle = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.orientation === 'col' ? 'column' : 'row'};
  gap: ${(props) => (props.spacing ? props.spacing : '0.5rem')};
  justify-content: ${(props) => props.jc || 'center'};
  align-items: ${(props) => props.items || 'stretch'};
  flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'nowrap')};
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  // height: ${(props) => props.h | 'auto'};
`;

const Group = ({ jc, spacing, orientation, fullWidth, items, ...props }) => {
  const actualProps = { jc, spacing, orientation, fullWidth, items, ...props };
  return <GroupStyle {...actualProps} />;
};

export default Group;
