import { Box, Grid, TextField } from '@material-ui/core';
import { CustomText } from 'common/Reusables';
import { useFormik } from 'formik';
import { useState } from 'react';
import Button from 'components/Button';
import styled from 'styled-components';
import ApplicationDocument from './ApplicationDocument';
import ReferenceForm from 'Recruit/components/ReferenceForm';

const PreviewApplication = ({ isOpen, values, setIsOpen, applicationForm }) => {
  const [documentIds, setDocumentIds] = useState([]);

  const personalDetailFormik = useFormik({
    validateOnMount: true,
    initialValues: {}
  });

  const education = useFormik({
    validateOnMount: true,
    initialValues: {}
  });

  const experience = useFormik({
    validateOnMount: true,
    initialValues: {}
  });

  const questions = useFormik({
    validateOnMount: true,
    initialValues: {}
  });

  return (
    <PreviewApplication.Wrapper>
      <div className='mt-5'>
        <CustomText color='#545454' fontWeight='600' fontSize='24px'>
          Submit your application
        </CustomText>
        <div className='d-flex justify-content-between align-items-center mt-4 border-bottom'>
          <CustomText color='#545454' fontWeight='400' fontSize='16px'>
            {values?.title}
          </CustomText>
          <CustomText color='#545454' fontWeight='400' fontSize='14px'>
            {`${values?.city} ${values?.state}`} . {values?.department} .
            {values?.employmenttype?.title}
          </CustomText>
        </div>
        <div>
          <div className='mt-4 mb-4 border-bottom'>
            <CustomText color='#545454' fontWeight='600' fontSize='18px'>
              Personal Details
            </CustomText>
            <CustomText color='#545454' fontWeight='400' fontSize='12px'>
              All fields marked (*) are mandatory.
            </CustomText>
          </div>
          <Grid container spacing={1}>
            {(
              applicationForm?.application_form?.personal_detail ||
              values?.applicationform?.personal_detail
            )
              ?.filter((item) => item?.is_checked === true)
              .map((item) => (
                <Grid
                  item
                  sm={item.display_name === 'Residential Address' ? 12 : 6}
                  style={{
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    margin: '0px'
                  }}
                >
                  <TextField
                    required={item.required}
                    variant='outlined'
                    label={item.display_name}
                    name={item.field_name}
                    disabled
                    fullWidth
                    size='small'
                    color='secondary'
                    onChange={(e) => {
                      personalDetailFormik.setFieldValue(
                        item.field_name,
                        e.target.value
                      );
                    }}
                  />
                </Grid>
              ))}
          </Grid>
          {(applicationForm?.application_form?.application_style ||
            values?.applicationform?.application_style) !== 'form' && (
            <ApplicationDocument
              applicationForm={applicationForm}
              values={values}
              documentIds={documentIds}
              setDocumentIds={setDocumentIds}
            />
          )}
          {(
            applicationForm?.application_form?.educational_background ||
            values?.applicationform?.educational_background
          )?.filter((item) => item.is_checked === true).length >= 1 && (
            <div className='border-bottom mt-5 mb-4'>
              <CustomText color='#545454' fontWeight='600' fontSize='18px'>
                Educational Details
              </CustomText>
              <CustomText color='#545454' fontWeight='400' fontSize='12px'>
                All fields marked (*) are mandatory.
              </CustomText>
            </div>
          )}
          <Grid container spacing={1} className='mb-4'>
            {(
              applicationForm?.application_form?.educational_background ||
              values?.applicationform?.educational_background
            )
              ?.filter((item) => item.is_checked === true)
              .map((item, index) => (
                <Grid
                  item
                  sm={
                    item.field_name === 'name_of_institution' ||
                    item.field_name === 'course_of_study'
                      ? 12
                      : 4
                  }
                  style={{
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    margin: '0px'
                  }}
                >
                  <TextField
                    disabled
                    required={item.required}
                    variant='outlined'
                    label={item.display_name}
                    fullWidth
                    size='small'
                    color='secondary'
                    onChange={(e) => {
                      education.setFieldValue(
                        `${item.field_name}`,
                        e.target.value
                      );
                    }}
                  />
                </Grid>
              ))}
          </Grid>
          {(
            applicationForm?.application_form?.work_experience ||
            values?.applicationform?.work_experience
          )?.filter((item) => item.is_checked === true).length >= 1 && (
            <div className='border-bottom mt-5 mb-4'>
              <CustomText color='#545454' fontWeight='600' fontSize='18px'>
                Work Experience
              </CustomText>
              <CustomText color='#545454' fontWeight='400' fontSize='12px'>
                All fields marked (*) are mandatory.
              </CustomText>
            </div>
          )}
          <Grid container spacing={1} className='mb-3'>
            {(
              applicationForm?.application_form?.work_experience ||
              values?.applicationform?.work_experience
            )
              ?.filter((item) => item.is_checked === true)
              ?.map((item) => (
                <Grid
                  item
                  sm={
                    item.field_name === 'start_date' ||
                    item.field_name === 'end_date'
                      ? 6
                      : 12
                  }
                  style={{
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    margin: '0px'
                  }}
                >
                  <TextField
                    disabled
                    required={item.required}
                    variant='outlined'
                    label={item.display_name}
                    name={item.field_name}
                    fullWidth
                    size='small'
                    multiline={item.field_name === 'job_description'}
                    rows={item.field_name === 'job_description' && 4}
                    color='secondary'
                    onChange={(e) => {
                      experience.setFieldValue(
                        `${item.field_name}`,
                        e.target.value
                      );
                    }}
                  />
                </Grid>
              ))}
          </Grid>
          {(applicationForm?.set_personal_reference ||
            values?.set_personal_reference ||
            applicationForm?.set_professional_reference ||
            values?.set_professional_reference) && (
            <>
              <div className='border-bottom mt-5 mb-4'>
                <CustomText color='#545454' fontWeight='600' fontSize='18px'>
                  References
                </CustomText>
                <CustomText color='#545454' fontWeight='400' fontSize='12px'>
                  All fields marked (*) are mandatory.
                </CustomText>
              </div>

              {[...Array(Number(values?.num_of_personal_reference))].map(
                (ref, index) => (
                  <ReferenceForm title={`Personal reference ${index + 1}`} />
                )
              )}
              {[...Array(Number(values?.num_of_professional_reference))].map(
                (ref, index) => (
                  <ReferenceForm
                    title={`Professional reference ${index + 1}`}
                  />
                )
              )}
            </>
          )}

          {(
            applicationForm?.application_form?.additional_question ||
            values?.applicationform?.additional_question
          )?.length >= 1 && (
            <div className='mt-5 mb-4'>
              <CustomText
                color='#545454'
                fontWeight='600'
                fontSize='18px'
                margin='3rem 0 0'
              >
                Additional Question(s)
              </CustomText>
              {(
                applicationForm?.application_form?.additional_question ||
                values?.applicationform?.additional_question
              )?.map((question, index) => (
                <div className='mb-3 mt-3'>
                  <CustomText color='#545454' fontWeight='400' fontSize='14px'>
                    {question.display_name}
                  </CustomText>
                  <TextField
                    variant='outlined'
                    placeholder={question.display_name}
                    // label={question.display_name}
                    fullWidth
                    disabled
                    multiline
                    rows={4}
                    size='small'
                    color='secondary'
                    onChange={(e) => {
                      questions.setFieldValue(
                        `${question.field_name}`,
                        e.target.value
                      );
                    }}
                  />
                </div>
              ))}
              {isOpen && setIsOpen && (
                <Button
                  label='Close'
                  type='secondary'
                  onClick={() => setIsOpen(!isOpen)}
                  style={{
                    width: '100%',
                    height: '45px'
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </PreviewApplication.Wrapper>
  );
};

export default PreviewApplication;

PreviewApplication.Wrapper = styled(Box)`
  border-top: 1px solid #e1e1e1;

  .select-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    background: #ffffff;
    border: 1px dashed #e1e1e1;
    border-radius: 5px;
    padding: 30px;

    button {
      padding: 10.5px 15px;
      background: transparent;
      border-radius: 5px;
      border: none;
    }

    h1 {
      font-weight: 600;
      font-size: 14px;
      color: #878787;
    }
  }

  .dragging {
    border: 2px dashed #999;
    background: red;
  }

  .border-bottom {
    border-bottom: 1px solid #e1e1e1;
  }

  .mb-0 {
    margin-bottom: 0 !important ;
  }

  .pb-0 {
    padding-bottom: 0 !important ;
  }
`;
