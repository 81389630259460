export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function formatString(string, length) {
  if (!string) return '';
  if (length < 5) return string;
  if (string.length <= length) return string;
  return (
    string.slice(0, 8) +
    '...' +
    string.slice(string.length - length + 5, string.length)
  );
}
