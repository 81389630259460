import { Box, IconButton } from '@material-ui/core';
import { publicGetMethod } from 'hooks/queries/getHook';
import { CustomText } from 'common/Reusables';
import { ArrowBack } from '@material-ui/icons';
import { useQuery } from 'react-query';
import {
  useLocation,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min';
import SinglePageJobDetail from 'Recruit/Jobs/SingleJob/JobDetails';
import Loader from 'components/Loader/Loader';
import styled from 'styled-components';
import qs from 'query-string';

const RecruitPublicJobDetail = () => {
  const { id: code } = useParams();
  const { id } = qs.parse(useLocation().search);

  const { data, isLoading } = useQuery(
    [
      'company-overview',
      {
        route: `/recruits/${code}/jobpost/${id}/get/`,
        params: {}
      }
    ],
    publicGetMethod
  );

  const handleBack = () => {
    window.history.back();
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <RecruitPublicJobDetail.Wrapper>
      <div className='page_content'>
        <Box boxShadow='box-shadow: 0px 2px 4px 0px rgba(96, 97, 112, 0.16)'>
          <div className='d-flex align-items-center'>
            <IconButton onClick={() => handleBack()}>
              <ArrowBack />
            </IconButton>
            <CustomText fontSize='20px' color='#171717' fontWeight='600'>
              Back
            </CustomText>
          </div>
        </Box>
        <div className='mobile_header_divider pt-1 md_hide lg_hide xl_hide'></div>
        <div className='mt-5 detail_content'>
          <SinglePageJobDetail formik={data} isPublic={true} />
        </div>
      </div>
    </RecruitPublicJobDetail.Wrapper>
  );
};

export default RecruitPublicJobDetail;

RecruitPublicJobDetail.Wrapper = styled(Box)`
  .page_content {
    margin: 0 auto;
    width: 70%;

    @media (max-width: 700px) {
      position: absolute;
      padding: 1rem 0;
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  .detail_content {
    width: 80%;
    overflow-y: scroll;
    margin: 1.5rem auto;
    height: calc(100vh - 90px);

    @media (max-width: 700px) {
      width: 100%;
    }
  }

  .mobile_header_divider {
    margin: 0.5rem 0 2.5rem;
    box-shadow: 0px 2px 4px 0px rgba(96, 97, 112, 0.16);
  }
`;
