import { SnackbarProvider } from 'notistack';
import { AssetProvider } from '../hooks/useAssetControl';
import Dashboard from './Dashboard/Dashboard.jsx';

function AssetsRoutes({ base }) {
  return (
    <AssetProvider>
      <SnackbarProvider
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <Dashboard />
      </SnackbarProvider>
    </AssetProvider>
  );
}

export default AssetsRoutes;
