import ExpandableButton from 'components/Button/ExpandableButton';
import ButtonTabOffWhite from 'components/ButtonTab';
import { STEPS } from 'constant';
import useDisclosure from 'hooks/useDisclosure';
import { useState } from 'react';
import AssetMaintenance from '../Modals/AddMaintenanceModal';
import AddNormalDoc from '../Modals/AddNormalDoc/AddNormalDoc';
import AssetDocumentModal from '../Modals/AssetDocument/AssetDocumentModal';
import AddIssueModal from '../Modals/IssueModal';
import Tables from './Tables';
const tabs = [
  'Maintenance',
  'Assignee History',
  'Reported issues',
  'Statutory',
  'Other Documents'
];

const Maintenance = ({ asset }) => {
  const [tab, setTab] = useState(STEPS.one);
  const [open, { toggle }] = useDisclosure();
  const [openIssue, { toggle: toggleIssue }] = useDisclosure();
  const [openStatutory, { toggle: toggleStatuory }] = useDisclosure();
  const [openDocument, { toggle: toggleDocument }] = useDisclosure();
  const [markType, setMarkType] = useState(null);

  const toggleMaintenanceType = (mark) => {
    if (open) {
      setMarkType(null);
    } else {
      setMarkType(mark);
    }
    toggle();
  };

  const handleChange = (event, value) => {
    setTab(value);
  };
  return (
    <div className='maintenance'>
      <div className='actions'>
        <ButtonTabOffWhite
          styles={{ root: { flex: 1 } }}
          value={tab}
          onChange={handleChange}
          tabs={tabs}
        />
        <ExpandableButton
          rounded='md'
          py={'.8rem'}
          menuProps={{
            divideAll: true,
            width: '21ch',
            size: 'sm'
          }}
          variant='contained'
          options={[
            {
              label: 'Maintenance',
              onClick: (_, handleClose) => {
                toggle();
                handleClose();
              }
            },
            {
              label: 'Issue',
              onClick: (_, handleClose) => {
                toggleIssue();
                handleClose();
              }
            },
            {
              label: 'Statutory Information',
              onClick: (_, handleClose) => {
                toggleStatuory();
                handleClose();
              }
            },
            {
              label: 'Document',
              onClick: (_, handleClose) => {
                toggleDocument();
                handleClose();
              }
            }
          ]}
        >
          Add New
        </ExpandableButton>
      </div>
      <Tables
        tab={tab}
        asset={asset}
        toggleStatuory={toggleStatuory}
        toggleMaintenance={toggleMaintenanceType}
      />
      {open && (
        <AssetMaintenance
          open={open}
          toggle={toggle}
          data={asset}
          mark={markType}
        />
      )}
      {openIssue && (
        <AddIssueModal open={openIssue} toggle={toggleIssue} asset={asset} />
      )}
      {openStatutory && (
        <AssetDocumentModal
          open={openStatutory}
          toggle={toggleStatuory}
          allowOthersDoc
          data={asset}
        />
      )}
      {openDocument && (
        <AddNormalDoc
          open={openDocument}
          toggle={toggleDocument}
          allowOthersDoc
          data={asset}
        />
      )}
    </div>
  );
};

export default Maintenance;
