import axios from './axios.config';
import getBaseDomainURL from './base-domain-url';

export function getBilling() {
  return axios
    .get(`${getBaseDomainURL()}/billing/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function verifyPayment(payload) {
  return axios
    .post(`${getBaseDomainURL()}/billing/check_payment_status/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function billingUpdateSetup(payload) {
  return axios
    .post(`${getBaseDomainURL()}/billing/update_setup/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}
export function cancelRecurringBilling(payload) {
  return axios
    .post(`${getBaseDomainURL()}/billing/update_recurring_payment/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function startFreeTrial() {
  return axios
    .post(`${getBaseDomainURL()}/billing/start_free_trial/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function expiryNotice() {
  return axios
    .post(`${getBaseDomainURL()}/billing/hide_expiry_notice/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function billingHistory() {
  return axios
    .get(`${getBaseDomainURL()}/billing/subscriptions/`)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function verifyCouponCode(payload) {
  return axios
    .post(`${getBaseDomainURL()}/billing/coupon/validate_coupon/`, payload)
    .then(({ data }) => data)
    .catch((error) => {
      throw error;
    });
}

export function paginateBilling(params) {
  const { newPage, status, type, search, setLoadingHandler } = params;
  const url =
    status && type
      ? `status=${status}&type=${type}`
      : status
      ? `status=${status}`
      : type
      ? `type=${type}`
      : null;
  return axios
    .get(
      `${getBaseDomainURL()}/accounting_v2/bill/?page=${newPage}&search=${
        search || ''
      }&${url}`
    )
    .then(({ data }) => {
      setLoadingHandler(false);
      return data;
    })
    .catch((error) => {
      setLoadingHandler(false);
      throw error;
    });
}
