import useDepartments from 'hooks/useDepartments';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { isFunc } from '../../utilities/utils';

const useAssignLogic = ({
  toggleOpenModal: toggle,
  useModule = () => {},
  useModuleAssign = () => {},
  selectedDepartment,
  searchFilter,
  setSearchFilter = () => {},
  onSelect,
  initialSelected = [],
  allowDepartment = true,
  maxAssignable = Infinity
}) => {
  const [selected, setSelected] = useState(
    initialSelected ? [...initialSelected] : []
  );

  useEffect(() => {
    if (isFunc(onSelect)) {
      onSelect(selected);
    }
    // eslint-disable-next-line
  }, [selected]);

  const [isAllSelected, setSelectAll] = useState(false);

  const searchRef = useRef(null);
  const debouceSearch = _.debounce((value) => {
    setSearchFilter(value);
  }, 500);

  const module = useModule();

  // get departments
  const { data: departmentData, isLoading: isDepartmentLoading } =
    useDepartments({ has_employees: true }, { enabled: allowDepartment });

  const depData = useMemo(() => {
    return (
      departmentData?.map((department) => ({
        label: department.name,
        value: department.id
      })) || []
    );
    // eslint-disable-next-line
  }, [departmentData]);

  // isSelect here  represents the evt checked
  const handleSelect = (evt, id) => {
    if (!!evt?.target?.checked && selected.length < maxAssignable) {
      return setSelected((prev) => [...prev, id]);
    }

    setSelected(selected?.filter((ids) => ids !== id));
  };

  const handleSelectAll = () => {
    if (!isAllSelected) {
      setSelected(module?.data.map((el) => el.id));
    } else {
      setSelected([]);
    }
    setSelectAll(!isAllSelected);
  };

  const assignMutation = useModuleAssign();

  const errMsg =
    module?.data?.length < 1 && !!selectedDepartment
      ? `All employee in ${selectedDepartment.label} Department has been assigned`
      : module?.data?.length < 1 && !!searchFilter
      ? "Couldn't find employee with the searched name"
      : '';

  return {
    errMsg,
    assignMutation,
    handleSelectAll,
    handleSelect,
    depData,
    isDepartmentLoading,
    searchRef,
    debouceSearch,
    selected,
    isAllSelected,
    setSelected,
    module
  };
};

export default useAssignLogic;
