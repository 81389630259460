/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Wrapper, TableBlock, AddVehicleButton } from './styled.vehicle-list';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CreateVehicleModal from '../Modals/CreateVehicleModal';
import Button from '@material-ui/core/Button';
import VehiclesTable from './VehiclesTable';
import useFleetControl from '../../hooks/useFleetControl';
import VehicleListEmptyState from './VehicleListEmptyState';
import { getVehicles, paginateVehicles } from '../../api/fleet';

const StyledMenuType = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: '5px',
    width: '150px'
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
));

const StyledMenuStatus = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: '5px',
    width: '150px'
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  Header: {
    ...theme.mixins.makeResponsive({ theme, fontSize: 22, object: true }),
    fontWeight: 700,
    color: '#A8A8A8'
  },
  emptyState: {
    '& .MuiButton-contained': {
      marginTop: '5%',
      width: 'auto'
    }
  },
  boxWrapper: {
    marginTop: '2%'
  },
  paginate: {
    '& .MuiTablePagination-select': {
      backgroundColor: '#E0EEF0'
    },
    '& .Mui-disabled': {
      color: '#E1E1E1'
    }
  },
  tableWrapper: {
    marginTop: '2%',
    '& .MuiSelect-root': {
      backgroundColor: 'none'
    },
    '& .MuiTableCell-body': {
      padding: '10px 10px',
      color: '#737373',
      '& a': {
        textDecoration: 'none',
        color: '#737373'
      },
      '& .MuiTableRow-root': {
        backgroundColor: 'red'
      }
    },
    '& .MuiTableCell-head': {
      padding: '10px 10px'
    }
  },
  popOverWrapper: {
    '& .MuiPopover-paper': {
      width: '15%'
    },
    '& .MuiButton-text': {
      textAlign: 'left',
      textTransform: 'capitalize'
    }
  },
  tableData: {
    '& .MuiTableCell-body': {
      backgroundColor: 'white',
      '& > *': {
        color: '#737373',
        textDecoration: 'none'
      }
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  btnAction: {
    '& .MuiButton-text ': {
      justifyContent: 'flex-start',
      color: '#737373'
    },
    '& > :nth-child(even)': {
      borderTop: '1px solid #E1E1E1',
      borderBottom: '1px solid #E1E1E1'
    },
    '& > :nth-child(1)': {
      color: 'red'
    }
  },
  actionText: {
    color: '#878787',
    fontSize: '12px'
  },
  dataHead: {
    '& .MuiTableCell-head': {
      padding: '8px 0px'
    }
  },
  dataRow: {
    '& .MuiTableCell-body': {
      padding: '8px 0px'
    }
  },
  btnTypeFilter: {
    '& .MuiButton-outlined': {
      color: '#878787',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '14px',
      padding: '8px 15px',
      width: '100%',
      fontWeight: 500,
      '& .MuiButton-label': {
        textTransform: 'capitalize'
      }
    }
  },
  btnStatusFilter: {
    '& .MuiButton-outlined': {
      color: '#878787',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '14px',
      padding: '8px 15px',
      width: '100%',
      fontWeight: 500,
      '& .MuiButton-label': {
        textTransform: 'capitalize'
      }
    }
  },
  filterContainer: {
    '& .MuiListItem-root': {
      padding: '0px'
    }
  },
  menuWrapper: {
    marginLeft: '0px'
  }
}));

const TableControl = ({
  count,
  handleChangePage,
  rowsPerPage,
  page,
  handleChangeRowsPerPage,
  filters,
  setFilters
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorTypeEl, setAnchorTypeEl] = useState(null);
  const [anchorStatusEl, setAnchorStatusEl] = useState(null);
  const [selectedType, setSelectedType] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  const [inputText, setInputText] = useState(false);

  const handleTypeMenu = (event) => {
    setAnchorTypeEl(event.currentTarget);
  };

  const handleStatusMenu = (event) => {
    setAnchorStatusEl(event.currentTarget);
  };

  const handleTypeClose = () => {
    setAnchorTypeEl(null);
  };

  const handleStatusClose = () => {
    setAnchorStatusEl(null);
  };

  const handleSearchFocus = () => {
    setInputText(true);
  };

  const handleSearchBlur = () => {
    setInputText(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleFilter = (...id) => {
    const [event, value] = id;
    if (event.target.checked) {
      setFilters({
        ...filters,
        type: value
      });
      setSelectedType(value);
      setAnchorTypeEl(!event.currentTarget);
    }
  };

  const handleFilterStatus = (...id) => {
    const [event, value] = id;
    if (event.target.checked) {
      setFilters({
        ...filters,
        status: value
      });
      setSelectedStatus(value);
      setAnchorStatusEl(!event.currentTarget);
    }
  };

  return (
    <>
      {(count > 0 || filters.name || filters.type || filters.status) && (
        <Grid container justify='space-between' alignItems='center'>
          <Grid item sm={7}>
            <Grid container spacing={1} alignItems='center'>
              <Grid item sm={6}>
                <TextField
                  variant='outlined'
                  color='secondary'
                  name='name'
                  value={filters.name}
                  onChange={handleChange}
                  fullWidth
                  label={inputText ? 'Search' : 'Search by Name'}
                  onBlur={handleSearchBlur}
                  onFocus={handleSearchFocus}
                  inputProps={{
                    style: {
                      textOverflow: 'ellipsis'
                    }
                  }}
                  size='small'
                />
              </Grid>
              <Grid item sm={3}>
                <Box className={classes.btnTypeFilter}>
                  <Button
                    variant='outlined'
                    size='small'
                    onClick={handleTypeMenu}
                  >
                    Type <ExpandMoreIcon fontSize='small' />
                  </Button>
                  <StyledMenuType
                    id='customized-menu'
                    anchorEl={anchorTypeEl}
                    keepMounted
                    open={Boolean(anchorTypeEl)}
                    onClose={handleTypeClose}
                  >
                    <RadioGroup>
                      <FormControlLabel
                        className={classes.menuWrapper}
                        control={
                          <Radio
                            icon={<CheckBoxOutlineBlankIcon />}
                            checked={selectedType === '' ? true : false}
                            checkedIcon={<CheckBoxIcon />}
                            onClick={(e) => handleFilter(e, '')}
                          />
                        }
                        label='All'
                      />
                      <FormControlLabel
                        className={classes.menuWrapper}
                        control={
                          <Radio
                            icon={<CheckBoxOutlineBlankIcon />}
                            checked={selectedType === 'car' ? true : false}
                            checkedIcon={<CheckBoxIcon />}
                            onClick={(e) => handleFilter(e, 'car')}
                          />
                        }
                        label='Car'
                      />
                      <FormControlLabel
                        className={classes.menuWrapper}
                        control={
                          <Radio
                            icon={<CheckBoxOutlineBlankIcon />}
                            checked={selectedType === 'bus' ? true : false}
                            checkedIcon={<CheckBoxIcon />}
                            onClick={(e) => handleFilter(e, 'bus')}
                          />
                        }
                        label='Bus'
                      />
                      <FormControlLabel
                        className={classes.menuWrapper}
                        control={
                          <Radio
                            icon={<CheckBoxOutlineBlankIcon />}
                            checked={selectedType === 'truck' ? true : false}
                            checkedIcon={<CheckBoxIcon />}
                            onClick={(e) => handleFilter(e, 'truck')}
                          />
                        }
                        label='Trucks'
                      />
                      <FormControlLabel
                        className={classes.menuWrapper}
                        control={
                          <Radio
                            icon={<CheckBoxOutlineBlankIcon />}
                            checked={selectedType === 'bike' ? true : false}
                            checkedIcon={<CheckBoxIcon />}
                            onClick={(e) => handleFilter(e, 'bike')}
                          />
                        }
                        label='Bikes'
                      />
                    </RadioGroup>
                  </StyledMenuType>
                </Box>
              </Grid>
              <Grid item sm={3}>
                <Box className={classes.btnStatusFilter}>
                  <Button
                    variant='outlined'
                    size='small'
                    onClick={handleStatusMenu}
                  >
                    Status <ExpandMoreIcon fontSize='small' />
                  </Button>
                  <StyledMenuStatus
                    id='customized-menu'
                    anchorEl={anchorStatusEl}
                    keepMounted
                    open={Boolean(anchorStatusEl)}
                    onClose={handleStatusClose}
                  >
                    <RadioGroup>
                      <FormControlLabel
                        className={classes.menuWrapper}
                        control={
                          <Radio
                            icon={<CheckBoxOutlineBlankIcon />}
                            checked={selectedStatus === '' ? true : false}
                            checkedIcon={<CheckBoxIcon />}
                            onClick={(e) => handleFilterStatus(e, '')}
                          />
                        }
                        label='Reset'
                      />
                      <FormControlLabel
                        className={classes.menuWrapper}
                        control={
                          <Radio
                            icon={<CheckBoxOutlineBlankIcon />}
                            checked={selectedStatus === 'active' ? true : false}
                            checkedIcon={<CheckBoxIcon />}
                            onClick={(e) => handleFilterStatus(e, 'active')}
                          />
                        }
                        label='Active'
                      />
                      <FormControlLabel
                        className={classes.menuWrapper}
                        control={
                          <Radio
                            icon={<CheckBoxOutlineBlankIcon />}
                            checked={
                              selectedStatus === 'inactive' ? true : false
                            }
                            checkedIcon={<CheckBoxIcon />}
                            onClick={(e) => handleFilterStatus(e, 'inactive')}
                          />
                        }
                        label='Inactive'
                      />
                      <FormControlLabel
                        className={classes.menuWrapper}
                        control={
                          <Radio
                            icon={<CheckBoxOutlineBlankIcon />}
                            checked={
                              selectedStatus === 'in-shop' ? true : false
                            }
                            checkedIcon={<CheckBoxIcon />}
                            onClick={(e) => handleFilterStatus(e, 'in-shop')}
                          />
                        }
                        label='In-Shop'
                      />
                      <FormControlLabel
                        className={classes.menuWrapper}
                        control={
                          <Radio
                            icon={<CheckBoxOutlineBlankIcon />}
                            checked={selectedStatus === 'sold' ? true : false}
                            checkedIcon={<CheckBoxIcon />}
                            onClick={(e) => handleFilterStatus(e, 'sold')}
                          />
                        }
                        label='Sold'
                      />
                    </RadioGroup>
                  </StyledMenuStatus>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {/* <Button type="buttton" variant="contained" color="secondary" icon={true} handleClick={addAsset}>Add Asset</Button> */}
            <AddVehicleButton
              variant='contained'
              color='secondary'
              startIcon={<AddCircleOutlineIcon />}
              onClick={
                () => history.push('?tab=add-vehicle')
                // setFleetState({ ...fleetState, modal: 'CreateVehicleModal' })
              }
            >
              Add Vehicle
            </AddVehicleButton>
          </Grid>
        </Grid>
      )}
      <Grid container justify='space-between' alignItems='center'>
        <Grid item sm={6}></Grid>
        <Grid item className={classes.paginate} sm={6}>
          {count > 0 && (
            <TablePagination
              component='div'
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
              count={count}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

function VehicleList() {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fleetState, setFleetState] = useFleetControl();
  const [filters, setFilters] = useState({
    name: '',
    status: '',
    type: ''
  });
  const [paginateResult, setPaginateResult] = useState([]);
  const { isFetching } = useQuery(['vehicles'], getVehicles);

  const dataHandler = async () => {
    const response = await paginateVehicles({
      newPage: 1,
      type: filters.type,
      status: filters.status,
      search: filters.name,
      setLoadingHandler: setLoading
    });
    setPaginateResult(response);
  };
  useEffect(() => {
    setLoading(true);

    dataHandler();
  }, [filters.name, filters.status, filters.type]);

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    const data = await paginateVehicles({
      newPage: newPage + 1,
      setLoadingHandler: setLoading
    });
    setPaginateResult(data);
  };

  const handleClose = () => {
    setFleetState({ ...fleetState, toast: false });
  };

  if (isFetching) return <CircularProgress color='secondary' />;

  return (
    <Box>
      {/* ======= VEHICLE LIST HEADER ========== */}
      <Wrapper container direction='row' alignItems='center'>
        {/* ========== TABLE CONTROL ============= */}
        <TableBlock
          container
          spacing={2}
          direction='row'
          alignItems='stretch'
          justify='space-between'
        >
          <TableControl
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            count={paginateResult && paginateResult.count}
            page={page}
            filters={filters}
            setFilters={setFilters}
          />
          {loading ? (
            <CircularProgress color='secondary' />
          ) : paginateResult?.results?.length === 0 &&
            !filters.name &&
            !filters.type &&
            !filters.status ? (
            <VehicleListEmptyState />
          ) : paginateResult?.results?.length === 0 &&
            (filters.name || filters.type || filters.status) ? (
            <p
              style={{
                textAlign: 'center',
                width: '100%',
                fontWeight: 'bold',
                fontSize: '20px'
              }}
            >
              No vehicle matches your filter
            </p>
          ) : (
            <VehiclesTable
              paginateResult={paginateResult}
              setPaginateResult={setPaginateResult}
              setLoading={setLoading}
            />
          )}
        </TableBlock>
        <CreateVehicleModal />
      </Wrapper>
      <Snackbar
        open={fleetState.toast}
        onClose={handleClose}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert
          onClose={handleClose}
          severity={fleetState.severity}
          elevation={6}
          variant='filled'
        >
          {fleetState.toastMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
}

export default VehicleList;
