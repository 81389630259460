import { Box } from '@mui/system';
import styled from 'styled-components';

const RecruitStylesheet = ({ children }) => {
  return <RecruitStylesheet.Wrapper>{children}</RecruitStylesheet.Wrapper>;
};

export default RecruitStylesheet;

RecruitStylesheet.Wrapper = styled(Box)`
  padding-top: 1rem;

  /* Structure */
  .d-flex {
    display: flex;
  }

  .align-items-center {
    align-items: center;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .justify-content-center {
    justify-content: center;
  }

  .ms-2 {
    margin-left: 0.5rem;
  }

  .ms-3 {
    margin-left: 0.75rem;
  }

  .ms-4 {
    margin-left: 1rem;
  }

  .me-2 {
    margin-right: 0.5rem;
  }

  .me-4 {
    margin-right: 1rem;
  }
  .mt-3 {
    margin-top: 0.75rem;
  }
  .mt-4 {
    margin-top: 1rem;
  }
  .mt-5 {
    margin-top: 2rem;
  }
  .pt-4 {
    padding-top: 1rem;
  }
  .mb-3 {
    margin-bottom: 0.75rem;
  }
  .mb-4 {
    margin-bottom: 1rem;
  }
  .mb-5 {
    margin-bottom: 2rem;
  }

  /* Typography */
  .text-capitalize {
    text-transform: capitalize;
  }

  .regular-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: #545454;
  }

  .title {
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    color: #545454;
  }

  .body_header {
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    color: #333e49;
    margin-bottom: 0.7rem;
  }

  .body_content {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #545454;
  }

  /* Buttons */
  .primary-btn {
    height: 45px;
    background: #2898a4;
    border-radius: 7px;
    font-weight: 600;
    font-size: 16px;
    line-height: 128.86%;
    color: #ffffff;
    padding: 0 1.5rem;
    border: none;
  }

  .secondary-btn {
    background: #d6f2f5;
    border-radius: 7px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #2898a4;
    padding: 0 1.5rem;
    height: 45px;
    border: none;
  }

  .cursor {
    cursor: pointer;
  }

  .w-100 {
    width: 100% !important;
  }

  /* Colors */
  .primary {
    color: #2898a4;
  }

  .bg-primary {
    background: #2898a4;
  }

  /* Spacing */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
  }

  /* Others */
  .cursor {
    cursor: pointer;
  }

  /* Extra small devices (portrait phones, less than 576px) */
  @media (max-width: 575.98px) {
    .xs_hide {
      display: none;
    }
    .mobile_block {
      display: block;
    }
    .debounce_input {
      width: 100%;
      margin: 1rem 0 0.5rem;
    }
  }

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    .mobile_block {
      display: block;
    }
    .debounce_input {
      width: 100%;
      margin: 1rem 0 0.5rem;
    }
    .sm_hide {
      display: none;
    }
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    .md_hide {
      display: none;
    }
    .mobile_block {
      display: block;
    }
    .debounce_input {
      width: 100%;
    }
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .lg_hide {
      display: none;
    }
  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    .xl_hide {
      display: none;
    }
  }
`;
