import AssetImportInfo from 'Assets/Modals/ImportModal/AssetImportInfo';
import AssetImportModal from 'Assets/Modals/ImportModal/AssetListImport';
import { CustomButton } from 'components/Button';
import useDisclosure from 'hooks/useDisclosure';
import React from 'react';

const ImportAssestBtn = () => {
  const [open, { toggle }] = useDisclosure();
  const [openProceed, { toggle: toggleProceed }] = useDisclosure();

  const handleProceed = () => {
    toggle();
    toggleProceed();
  };

  return (
    <>
      <CustomButton onClick={toggle} variant={'offwhite'}>
        Import Asset List
      </CustomButton>
      {open ? (
        <AssetImportInfo
          open={open}
          toggle={toggle}
          handleProceed={handleProceed}
        />
      ) : null}

      {openProceed ? (
        <AssetImportModal open={openProceed} toggle={toggleProceed} />
      ) : null}
    </>
  );
};

export default ImportAssestBtn;
