import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    width: '90%',
    margin: '2em auto',
    '& .MuiTypography-body1': {
      margin: '1.5em 0px'
    }
  },
  caption: {
    textAlign: 'left',
    fontWeight: 600,
    fontSize: '20px'
  },
  subCaption: {
    textAlign: 'left',
    fontWeight: 600,
    fontSize: '18px'
  }
});

const TermsPolicy = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography className={classes.caption}>
        TORILO TERMS OF SERVICE FOR
      </Typography>
      <Typography className={classes.subCaption}>
        BizEdge &amp; MyEdge
      </Typography>

      <Box>
        <Typography>
          Thank you for selecting the Services offered by Torilo Limited and/or
          its subsidiaries and affiliates (referred to as &quot;Torilo&quot;,
          &quot;we&quot;, &quot;our&quot;, or &quot;us&quot;). Review these
          Terms of Service (&quot;Agreement&quot;) thoroughly. This Agreement is
          a legal agreement between you and Torilo. By accepting electronically
          (for example, clicking &quot;I Agree&quot;), installing, accessing or
          using the Services, you agree to these terms. If you do not agree to
          this Agreement, then you may not use the Services. If you are entering
          into this Agreement on behalf of a company or other legal entity, you
          represent that you have the authority to bind such entity and its
          affiliates to these terms and conditions as its authorized
          representative, in which case the terms &quot;you&quot; or
          &quot;your&quot; will refer to such entity and its affiliates as well
          as you. If the legal entity that you represent does not agree with
          these terms and conditions, you must not accept this Agreement,
          register, and use or access the Services as an authorized
          representative.
        </Typography>
        <Typography>
          If you are an individual located in the European Economic Area
          (&quot;EEA&quot;) who is accessing or using Torilo services, sites,
          products, mobile applications, desktop applications, or software
          directed to the EEA (collectively, &quot;Services&quot;) your
          relationship is with Torilo Ltd. (referred to as &quot;Torilo&quot;,
          &quot;we&quot;, &quot;our&quot;, or &quot;us&quot;).
        </Typography>
        <Typography>
          <strong>A. GENERAL TERMS</strong>
        </Typography>
        <Typography>
          <strong>1. AGREEMENT</strong>
        </Typography>
        <Typography>
          This Agreement describes the terms governing your use of the Torilo
          online services provided to you on this website, including content,
          updates and new releases, (collectively, the "Services"). It includes
          by reference:
        </Typography>
        <Typography>
          Torilo's{' '}
          <a href='/privacy-policy' target='_blank'>
            Privacy Statement
          </a>
          <br />
          Additional Terms and Conditions, which may include those from third
          parties. Any terms provided separately to you for the Services,
          including product or program terms, ordering, activation, payment
          terms, etc.
        </Typography>
        <Typography>
          You must be at least 18 years of age to use our Services. By accessing
          or using our Services you agree that:
        </Typography>
        <Typography>
          You can form a binding contract with Torilo; You are not a person who
          is prohibited from receiving the Services under the laws of the United
          States, England, Wales, or any other applicable jurisdiction; and You
          will comply with this Agreement and all applicable local, state,
          national, and international laws, rules, and regulations, including
          import and export regulations.
        </Typography>
        <Typography>
          <strong>2. YOUR RIGHTS TO USE THE SERVICES.</strong>
        </Typography>
        <Typography>
          2.1 The Services are protected by copyright, trade secret, and other
          intellectual property laws. You are only granted the right to use the
          Services and only for the purposes described by Torilo. Torilo
          reserves all other rights in the Services. Until termination of this
          Agreement and as long as you meet any applicable payment obligations
          and comply with this Agreement, Torilo grants to you a personal,
          limited, nonexclusive, nontransferable right and license to use the
          Services.
        </Typography>
        <Typography>
          2.2 You agree not to use, nor permit any third party to use, the
          Services or content in a manner that violates any applicable law,
          regulation or this Agreement. You agree you will not:
        </Typography>
        <Typography>
          Provide access to or give any part of the Services to any unauthorized
          third party. Reproduce, modify, copy, deconstruct, sell, trade or
          resell the Services. Make the Services available on any file-sharing
          or application hosting service.
        </Typography>
        <Typography>
          <strong>3. PAYMENT.</strong>
        </Typography>
        <Typography>
          For Services offered on a payment or subscription basis, the following
          terms apply if you are the User paying for the Services, unless Torilo
          or its third party affiliate notifies you otherwise in writing. This
          Agreement also incorporates by reference and includes program ordering
          and payment terms provided to you on the website for the Services:
        </Typography>
        <Typography>
          Payments will be billed to you by Torilo in pounds sterling (£), U.S.
          Dollars, or other currencies which may be made available (plus any and
          all applicable taxes, including without limitation VAT and GST) as
          shown in the product ordering and subscription terms, and your account
          will be debited when you subscribe and provide your payment
          information, unless stated otherwise in the program ordering or
          payment terms on the website for the Services. You must pay with one
          of the following:
        </Typography>
        <Box>
          <ol>
            <li>A valid credit card acceptable to Torilo;</li>
            <li>A valid debit card acceptable to Torilo;</li>
            <li>
              Sufficient funds in a checking or savings account to cover an
              electronic debit of the payment due; or
            </li>
            <li>
              By another payment option Torilo provides to you in writing.
            </li>
          </ol>
        </Box>
        <Typography>
          If your payment and registration information is not accurate, current,
          and complete and you do not notify us promptly when such information
          changes, we may suspend or terminate your account and refuse your use
          of the Services. If you do not notify us of updates to your payment
          method (e.g., credit card expiration date), to avoid interruption of
          your service, we may participate in programs supported by your card
          provider (e.g., updater services, recurring billing programs, etc.) to
          try to update your payment information, and you authorize us to
          continue billing your account with the updated information that we
          obtain. Torilo will automatically renew your monthly, quarterly, or
          annual Services at the then-current rates, unless the Services are
          cancelled or terminated under this Agreement. Additional cancellation
          or renewal terms may be provided to you on the website for the
          Services.
        </Typography>
        <Typography>
          <strong>4. USE WITH YOUR MOBILE DEVICE.</strong>
        </Typography>
        <Typography>
          Use of these Services may be available through a compatible mobile
          device, the Internet, and may require software. You agree that you are
          solely responsible for these requirements, including any applicable
          changes, updates and fees as well as the terms of your agreement with
          your mobile device and telecommunications provider.
        </Typography>
        <Typography>
          Torilo MAKES NO WARRANTIES OR REPRESENTATIONS OF ANY KIND, EXPRESS,
          STATUTORY OR IMPLIED AS TO:
        </Typography>
        <Typography>
          THE AVAILABILITY OF TELECOMMUNICATION SERVICES FROM YOUR PROVIDER AND
          ACCESS TO THE SERVICES AT ANY TIME OR FROM ANY LOCATION; ANY LOSS,
          DAMAGE, OR OTHER SECURITY INTRUSION OF THE TELECOMMUNICATION SERVICES;
          AND ANY DISCLOSURE OF INFORMATION TO THIRD PARTIES OR FAILURE TO
          TRANSMIT ANY DATA, COMMUNICATIONS OR SETTINGS CONNECTED WITH THE
          SERVICES.
        </Typography>
        <Typography>
          <strong>5. DATA PROCESSING AND PRIVACY.</strong>
        </Typography>
        <Typography>
          5.1{' '}
          <strong>
            Data Processing and Privacy (All Services except Payroll Services
            and BizEdge Time).
          </strong>{' '}
          Except as described in Section 5.2: (a) Torilo is the controller of
          the personal information it processes about you when you use the
          Services and (b) You acknowledge that Torilo will process your
          personal information as described in our{' '}
          <a href='/privacy-policy'> Privacy Statement</a> when you use our
          Services.
        </Typography>
        <Typography>
          5.2 <strong>Data Processing and Privacy.</strong> If you use our
          Payroll Services, you will be the controller of the personal
          information that we process on your behalf and we will process that
          personal information as a processor.
        </Typography>
        <Typography>
          <strong>6. CONTENT</strong>
        </Typography>
        <Typography>
          6.1 <strong>You are responsible for your content.</strong> You are
          responsible for all materials, data, and personal information (
          <strong>"Content"</strong>) uploaded, posted or stored through your
          use of the Services. You grant Torilo a worldwide, royalty-free,
          non-exclusive license to host and use any Content provided through
          your use of the Services. Archive your Content frequently. You are
          responsible for any lost or unrecoverable Content. You must provide
          all required and appropriate warnings, information and disclosures.
          Torilo is not responsible for the Content or data you submit through
          the Services. You agree not to use, nor permit any third party to use,
          the Services to upload, post, distribute, link to, publish, reproduce,
          engage in or transmit any of the following, including but not limited
          to:
        </Typography>
        <Typography>
          Illegal, fraudulent, defamatory, obscene, pornographic, profane,
          threatening, abusive, hateful, harassing, offensive, inappropriate or
          objectionable information or communications of any kind, including
          without limitation conduct that would encourage "flaming" others, or
          criminal or civil liability under any local, state, federal or foreign
          law;
        </Typography>
        <Typography>
          Content that would impersonate someone else or falsely represent your
          identity or qualifications, or that constitutes a breach of any
          individual's privacy; Except as permitted by Torilo in writing,
          investment opportunities, solicitations, chain letters, pyramid
          schemes, other unsolicited commercial communication or engage in
          spamming or flooding; Virus, trojan horse, worm or other disruptive or
          harmful software or data; and Any information, software or Content
          which is not legally yours and without permission from the copyright
          owner or intellectual property rights owner.
        </Typography>
        <Typography>
          6.2 <strong>Community forums.</strong> The Services may include a
          community forum or other social features to exchange Content and
          information with other users of the Services and the public (
          <strong>"Community Forum"</strong>). Torilo does not support and is
          not responsible for the content in these Community Forums. Please use
          respect when you interact with other users in a Community Forum. Do
          not reveal confidential or other information that you do not want to
          make public. Users may post hypertext links to content of third
          parties for which Torilo is not responsible.
        </Typography>
        <Typography>
          6.3 Torilo may freely use feedback you provide. You agree that Torilo
          may use your feedback, suggestions, or ideas in any way, including in
          future modifications of the Services, other products or services,
          advertising or marketing materials. You grant Torilo a perpetual,
          worldwide, fully transferable, sublicensable, non-revocable, fully
          paid up, royalty free license to use the feedback you provide to
          Torilo in any way.
        </Typography>
        <Typography>
          6.4 Torilo may monitor your Content. Torilo may, but has no obligation
          to, monitor content on the Services. We may disclose any information
          necessary to satisfy our legal obligations, protect Torilo or its
          customers, or operate the Services properly. Torilo, in its sole
          discretion, may refuse to post, remove, or refuse to remove, any
          Content, in whole or in part, alleged to be unacceptable, undesirable,
          inappropriate, or in violation of this Agreement.
        </Typography>
        <Typography>7. ADDITIONAL TERMS</Typography>
        <Typography>
          7.1 Torilo does not give professional advice. Unless specifically
          included with the Services, Torilo is not in the business of providing
          legal, financial, accounting, tax, health care, real estate or other
          professional services or advice. Consult the services of a competent
          professional when you need this type of assistance.
        </Typography>
        <Typography>
          7.2 We may tell you about other Torilo services. You may be offered
          other services, products, or promotions by Torilo ("Torilo Services").
          Additional terms and conditions and fees may apply. With some Torilo
          Services you may upload or enter data from your account(s) such as
          names, addresses and phone numbers, purchases, etc., to the Internet.
          This information will be dealt with in accordance with Torilo's
          Privacy Policy. You grant Torilo permission to use information about
          your business and experience to help us to provide the Torilo Services
          to you and to enhance the Services. You grant Torilo permission to
          combine your business data, if any, with that of others in a way that
          does not identify you or any individual personally. You also grant
          Torilo permission to share or publish summary results relating to
          research data and to distribute or license such data to third parties.
        </Typography>
        <Typography>
          7.3 <strong>Communications.</strong> Torilo may be required by law to
          send you communications about the Services or third party products.
          You agree that Torilo may send these communications to you via email
          or by posting them on our websites.
        </Typography>
        <Typography>
          7.4{' '}
          <strong>You will manage your passwords and accept updates.</strong>{' '}
          You are responsible for securely managing your password(s) for the
          Services and to contact Torilo if you become aware of any unauthorized
          access to your account. The Services may periodically be updated with
          tools, utilities, improvements, third party applications, or general
          updates to improve the Services. You agree to receive these updates.
        </Typography>
        <Typography>8. DISCLAIMER OF WARRANTIES</Typography>
        <Typography>
          8.1 YOUR USE OF THE SERVICES, SOFTWARE, AND CONTENT IS ENTIRELY AT
          YOUR OWN RISK. EXCEPT AS DESCRIBED IN THIS AGREEMENT, THE SERVICES ARE
          PROVIDED "AS IS." TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
          Torilo, ITS AFFILIATES, AND ITS THIRD PARTY PROVIDERS, LICENSORS,
          DISTRIBUTORS OR SUPPLIERS (COLLECTIVELY,"SUPPLIERS") DISCLAIM ALL
          WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTY THAT THE
          SERVICES ARE FIT FOR A PARTICULAR PURPOSE, TITLE, MERCHANTABILITY,
          DATA LOSS, NON-INTERFERENCE WITH OR NON-INFRINGEMENT OF ANY
          INTELLECTUAL PROPERTY RIGHTS, OR THE ACCURACY, RELIABILITY, QUALITY OR
          CONTENT IN OR LINKED TO THE SERVICES. Torilo AND ITS AFFILIATES AND
          SUPPLIERS DO NOT WARRANT THAT THE SERVICES ARE SECURE, FREE FROM BUGS,
          VIRUSES, INTERRUPTION, ERRORS, THEFT OR DESTRUCTION. TO THE EXTENT
          PERMITTED BY THE APPLICABLE LAWS OF ENGLAND AND WALES ANY IMPLIED
          WARRANTIES IN RESPECT TO THE SERVICES, SOFTWARE, AND CONTENT ARE
          LIMITED TO 60 DAYS FROM THE DATE OF PURCHASE OR DELIVERY OF THE
          SERVICES, WHICHEVER IS SOONER.
        </Typography>
        <Typography>
          8.2 Torilo, ITS AFFILIATES AND SUPPLIERS DISCLAIM ANY REPRESENTATIONS
          OR WARRANTIES THAT YOUR USE OF THE SERVICES WILL SATISFY OR ENSURE
          COMPLIANCE WITH ANY LEGAL OBLIGATIONS OR LAWS OR REGULATIONS.YOU ARE
          SOLELY RESPONSIBLE FOR ENSURING THAT YOUR USE OF THE SERVICES IS IN
          ACCORDANCE WITH APPLICABLE LAW.
        </Typography>
        <Typography>9. LIMITATION OF LIABILITY AND INDEMNITY.</Typography>
        <Typography>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE ENTIRE
          LIABILITY OF Torilo, ITS AFFILIATES AND SUPPLIERS FOR ALL CLAIMS
          RELATING TO THIS AGREEMENT SHALL BE LIMITED TO THE AMOUNT YOU PAID FOR
          THE SERVICES DURING THE TWELVE (12) MONTHS PRIOR TO SUCH CLAIM.
          SUBJECT TO APPLICABLE LAW, Torilo, ITS AFFILIATES AND SUPPLIERS ARE
          NOT LIABLE FOR ANY OF THE FOLLOWING: (A) INDIRECT, SPECIAL,
          INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES; (B) DAMAGES RELATING TO
          FAILURES OF TELECOMMUNICATIONS, THE INTERNET, ELECTRONIC
          COMMUNICATIONS, CORRUPTION, SECURITY, LOSS OR THEFT OF DATA, VIRUSES,
          SPYWARE, LOSS OF BUSINESS, REVENUE, PROFITS OR
        </Typography>
        <Typography>
          INVESTMENT, OR USE OF SOFTWARE OR HARDWARE THAT DOES NOT MEET Torilo
          SYSTEMS REQUIREMENTS. THE ABOVE LIMITATIONS APPLY EVEN IF Torilo AND
          ITS AFFILIATES AND SUPPLIERS HAVE BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES. THIS AGREEMENT SETS FORTH THE ENTIRE LIABILITY OF
          Torilo, ITS AFFILIATES AND YOUR EXCLUSIVE REMEDY WITH RESPECT TO THE
          SERVICES AND ITS USE. NOTWITHSTANDING THE FOREGOING, NO PROVISION OF
          THIS AGREEMENT SHALL EXCLUDE OR LIMIT LIABILITY TO THE EXTENT THAT
          SUCH EXCLUSION OR LIMITATION IS PROHIBITED BY THE APPLICABLE LAWS OF
          ENGLAND AND WALES AND FOR THE AVOIDANCE OF DOUBT Torilo DOES NOT
          EXCLUDE LIABILITY FOR: (I) DEATH OR PERSONAL INJURY CAUSED BY ITS
          NEGLIGENCE OR THE NEGLIGENCE OF ITS OFFICERS, EMPLOYEES, CONTRACTORS
          OR AGENTS; (II) FRAUD OR FRAUDULENT MISREPRESENTATION; (III) ANY OTHER
          LIABILITY WHICH CANNOT BE LAWFULLY EXCLUDED BY CONTRACTUAL AGREEMENT
          OF THE PARTIES.
        </Typography>
        <Typography>
          You agree to indemnify and hold Torilo and its affiliates and
          Suppliers harmless from any and all claims, liability and expenses,
          including reasonable attorneys' fees and costs, arising out of your
          use of the Services or breach of this Agreement (collectively referred
          to as "Claims"). Torilo reserves the right, in its sole discretion and
          at its own expense, to assume the exclusive defense and control of any
          Claims. You agree to reasonably cooperate as requested by Torilo in
          the defense of any Claims.
        </Typography>
        <Typography>10. CHANGES.</Typography>
        <Typography>
          We reserve the right to change this Agreement at any time, and the
          changes will be effective when posted through the Services, on our
          website for the Services or when we notify you by other means. We may
          also change or discontinue the Services, in whole or in part. Your
          continued use of the Services indicates your agreement to the changes.
        </Typography>
        <Typography>11. TERMINATION.</Typography>
        <Typography>
          Torilo may immediately, in its sole discretion and without notice
          terminate this Agreement or suspend the Services if you fail to comply
          with this Agreement or if you no longer agree to receive electronic
          communications. Upon termination you must immediately stop using the
          Services and any outstanding payments will become due. Any termination
          of this Agreement shall not affect Torilo's rights to any payments due
          to it. Torilo may terminate a free account at any time. Sections 2.2,
          3 through 15 will survive and remain in effect even if the Agreement
          is terminated.
        </Typography>
        <Typography>12. EXPORT AND TRADE RESTRICTIONS.</Typography>
        <Typography>
          You acknowledge that the Services, including the mobile application,
          and the underlying software may include U.S. technical data subject to
          restrictions under export control laws and regulations administered by
          the United States government. You agree that you and your personnel
          who use the Services are not prohibited from receiving the Services
          under the laws of the United States, England, Wales, or other
          applicable jurisdiction. For example, you are not on the U.S. Treasury
          Department’s list of Specially Designated Nationals or the United
          Kingdom HM Treasury Financial Sanctions List, or are subject to any
          other similar prohibition. You will not export or re-export any part
          of the Services, in violation of these laws and regulations, directly
          or indirectly.
        </Typography>
        <Typography>13. GOVERNING LAW AND JURISDICTION.</Typography>
        <Typography>
          This Agreement will be governed by the laws of England and Wales,
          without regard to its conflicts of law principles. Notwithstanding the
          foregoing, you acknowledge that your breach (or an apprehension of
          breach) of any provisions of this Agreement, or any infringement (or
          apprehension of infringement) of Torilo's or its Suppliers'
          intellectual property rights may cause Torilo irreparable damage for
          which recovery of money damages would be inadequate. Accordingly, you
          agree that Torilo shall be entitled, in addition to any other remedies
          available to it, to seek (in any court of competent jurisdiction,
          notwithstanding the previous sentence) any relief (whether equitable
          or otherwise) to prevent or restrain any such breach or apprehended
          breach by you or otherwise to protect Torilo's rights under this
          Agreement. The parties hereby submit to the exclusive jurisdiction of
          the courts of England and Wales and you agree that you will procure
          that any third party making a claim against Torilo arising out of this
          Agreement shall bring such claim exclusively in the English courts and
          subject to the limitations and exclusions of liabilities provided for
          in this Agreement.
        </Typography>
        <Typography>
          Torilo does not represent that the Services and/or content within the
          Services is appropriate or available for use in all jurisdictions or
          countries. Torilo prohibits accessing content from within countries or
          states where such content is illegal. You are responsible for
          compliance with all applicable laws pertaining to your use and access
          to the Services in your jurisdiction.
        </Typography>
        <Typography>14. COMPLIANCE WITH PRESERVATION ORDERS.</Typography>
        <Typography>
          Torilo accepts no liability to you under this Agreement, or otherwise
          at law, for compliance by Torilo with any order or notice, whether
          issued to Torilo or to you, and whether issued by any court, tribunal,
          regulatory authority, arbitral tribunal or any body or authority
          exercising judicial, quasi-judicial, regulatory, or arbitral power or
          authority, requiring the preservation of any data, information
          documents or Content (a "Preservation Order"). To the extent that a
          Preservation Order has been issued to you, you acknowledge that you
          are solely responsible for your compliance with it.
        </Typography>
        <Typography>15. LANGUAGE.</Typography>
        <Typography>
          Any translation of this Agreement is done for local requirements and
          in the event of a dispute between the English and any non-English
          version, the English version of this Agreement shall govern. In the
          event of a dispute the parties confirm that they have requested that
          this Agreement and all related documents be drafted in English.
        </Typography>
        <Typography>16. GENERAL.</Typography>
        <Typography>
          This Agreement, including the Additional Terms below, is the entire
          agreement between you and Torilo and replaces all prior
          understandings, communications and agreements, oral or written,
          regarding its subject matter. If any court of law, having
          jurisdiction, rules that any part of this Agreement is invalid, that
          section will be removed without affecting the remainder of the
          Agreement. The remaining terms will be valid and enforceable. The
          United Nations Convention on Contracts for the International Sale of
          Goods does not apply to this Agreement. You cannot assign or transfer
          ownership of this Agreement to anyone without written approval of
          Torilo. However, Torilo may assign or transfer it without your consent
          to (a) an affiliate, (b) a company through a sale of assets by Torilo
          or (c) a successor by merger. Any assignment in violation of this
          Section shall be void. If you want to request a transfer of this
          Agreement, contact Torilo via an email to: support@torilo.co.uk.
        </Typography>
        <Typography>November 2021</Typography>
        <Typography>B. ADDITIONAL TERMS AND CONDITIONS</Typography>
        <Typography>
          Your use of the following Services provided by Torilo are subject to
          the General Terms of Service above and these Additional Terms and
          Conditions. These Additional Terms and Conditions will prevail over
          any conflict or inconsistency with the General Terms of Service.
        </Typography>
        <Typography>1. SERVICES.</Typography>
        <Typography>
          Each of the following products and services are referred to in this
          Agreement as a "Service" and together as the "Services":
        </Typography>
        <Typography>
          1.1. BizEdge Online. BizEdge is an online solution for businesses to
          perform operational and business tasks through an online account. Each
          BizEdge Account may only be used to support one business.
        </Typography>
        <Typography>
          1.2. BizEdge Accountant. BizEdge Accountant is an online solution for
          accountants, bookkeepers and other individuals or entities that
          provide accounting and other financial services to their clients. If
          you register for BizEdge Accountant, you will be able to create and
          access new and existing BizEdge Accounts of your clients. BizEdge
          Accountant also provides you with professional tools.
        </Typography>
        <Typography>
          1.3 MyEdge - BizEdge Portal for Employees. Is an online or mobile
          solution for employees to manage information and documents related to
          them
        </Typography>
        <Typography>
          1.4. Modification to Services. We have the right, in our sole
          discretion, to revise, update, or otherwise modify the Services or
          alter your access to the Services; and for material changes, to the
          extent reasonably possible, we will provide you with reasonable notice
          either posted on the website hosting the Services or to the
          Administrator's (as defined below) email address. Note that we may
          modify the amount of storage space you have through the Services and
          the number of times (and the maximum duration for which) you may
          access the Services in a given period of time. In the event we need to
          maintain the security of the system or comply with any laws or
          regulations, we reserve the right to modify the Services immediately,
          and to provide the Administrator with electronic or written notice
          within thirty (30) days after any material modifications. You may
          reject any changes to the Services by discontinuing use of the
          Services to which such changes relate. Your continued use of the
          Services will constitute your acceptance of and agreement to such
          changes.
        </Typography>
        <Typography>2. USERS.</Typography>
        <Typography>2.1. Types of Users.</Typography>
        <Typography>
          The Services allow the following types of access and user rights: When
          you initially register for and create an account for a Service, you
          are, or a party that you authorize is, the administrator
          ("Administrator"). Administrators may authorize additional individuals
          to access the Services through the same account ("Additional Users").
          The number of Additional Users may be limited based upon the
          subscription you purchase. Additional Users may include, for example,
          your employees, accountant, contractors, agents, and clients. You may
          be referred to in this Agreement as "you", "your", or "User", or you
          may be referred to specifically in your applicable role as an
          Additional User or an Administrator. All Users will be required to
          accept this Agreement before accessing the Services. With respect to
          BizEdge or MyEdge Account you access, you agree to these terms as an
          Administrator or an Additional User, as applicable. As any User of the
          Services, unless otherwise explicitly stated in this Agreement, all of
          these terms apply to you each time you access the Services.
        </Typography>
        <Typography>2.2. For Administrators.</Typography>
        <Typography>
          As an Administrator, the following applies to you: Only Administrators
          may designate another individual as a replacement Administrator. You
          agree that Additional Users are Torilo customers, but that you are
          responsible for your Additional Users' access to the Services.
          Depending on the types of access rights you grant to Additional Users,
          Additional Users may be able to delete, copy, or view the Content and
          data accessible in your account and add charges to the subscription.
          As Administrator, you are responsible for the access to the Services
          you grant to Additional Users. If you choose to close or terminate
          your access to a Service, Additional Users will no longer be able to
          access such Service or any of the Content within such Service. You
          agree to provide sufficient notice to Additional Users of your desire
          to terminate access to the Services before taking such actions. A
          violation of any terms of this Agreement by an Additional User may
          result in the termination of an Administrator's or any Additional
          User's access to the Services. If you are a user of our Payroll
          Services, you may be required to contact the Master Administrator of
          your account to control certain aspects of your personal information
          in accordance with our Terms &amp; Privacy Policy
        </Typography>
        <Typography>2.3. For Additional Users.</Typography>
        <Typography>
          As an Additional User, the following applies to you: When you register
          to access an account for which you are not an Administrator, you
          understand that you are accessing the Service as an Additional User
          and you may not have the same level of access or the same rights as an
        </Typography>
        <Typography>
          Administrator. Depending on the types of rights you are granted by the
          Administrator, you may be able to delete, copy, or view other User's
          Content and data. Please make sure that you have an agreement with the
          Administrator about your role and rights. If an Administrator chooses
          to close or terminate access to a Service, Additional Users will no
          longer be able to access such Service or any of the Content within
          such Service. As an Additional User, you understand that if you
          violate this Agreement, your access to our Services may be terminated,
          and we retain the right to also terminate access for any other Users
          of the same account.
        </Typography>
        <Typography>3. SUBSCRIPTION.</Typography>
        <Typography>
          3.1. Payment for Services. The Services are licensed on a monthly or
          yearly subscription basis to the User that pays for the Service. As
          the Administrator, you may choose whether you or another User pays for
          the license. Please review all of the details of the subscription that
          you purchase; some subscriptions provide access to one Service and
          others provide access to more than one Service.
        </Typography>
        <Typography>3.2. Subscription Cancellation.</Typography>
        <Typography>
          The Administrator may notify us if he or she wants to cancel the
          subscription prior the beginning of the new subscription period. In
          the event that Torilo is unable to charge a User's chosen payment
          method in accordance with this Agreement, we may terminate this
          Agreement and access to the Services immediately, without notice. If
          you stop using the Services, Torilo has fulfilled your subscription
          term. Fees for the Services are not eligible for any proration of
          unused subscriptions or refunds, even if access to the Services is
          cancelled or terminated. After your access to the Services is
          terminated, you may no longer have access to any of the data or
          Content in the Services. We suggest you retain your own copies of any
          data or Content that you may need as Torilo is not responsible for
          providing you with access to your Content or the Services after any
          cancellation or termination of this Agreement.
        </Typography>
        <Typography>4. ACCOUNT FEATURES.</Typography>
        <Typography>4.1. Trial Version.</Typography>
        <Typography>
          If you registered for a trial use of the Services, you will have
          access to the Services for the specified period of the trial ("Trial
          Period"). You must decide to purchase a license to the Services within
          the Trial Period in order to retain access to any Content or data
          provided or created during the Trial Period. If you do not purchase a
          license to the Services by the end of the Trial Period, you will not
          be able to access or retrieve any of the data or Content you added to
          or created with the Services during the trial.
        </Typography>
        <Typography>4.2. Beta Features.</Typography>
        <Typography>
          From time to time, we may include new or updated beta features in the
          Services ("Beta Features"). Beta Features may have associated fees,
          which will be disclosed to you at the time you choose to use the Beta
          Features. We understand that your use of any Beta Feature is
          voluntary. You understand that once you use a Beta Feature, you may be
          unable to revert back to a prior non-beta version of the same or
          similar feature. Additionally, if such reversion is possible, you may
          not be able to return or restore data created within the Beta Feature
          back to the prior non-beta version. The Beta Features are provided on
          an "as is" basis and may contain errors or inaccuracies that could
          cause failures, corruption or loss of data and information from any
          connected device. You acknowledge and agree that all use of any Beta
          Feature is at your sole risk.
        </Typography>
        <Typography>5. PERSONAL INFORMATION.</Typography>
        <Typography>
          5.1. The terms "Controller," Processor," "data subject," "personal
          data (also referred to as Personal Information in the Agreement)" and
          "processing" (and "process") shall have the meanings given in the
          Regulation 2016/679 of the European Parliament and of the Council on
          the protection of natural persons with regard to the Processing of
          Personal Data and on the free movement of such data (General Data
          Protection Regulation).
        </Typography>
        <Typography>5.2. Our Role.</Typography>
        <Typography>
          If you are a user of our Payroll Services, we are a Processor of the
          Personal Information we process on your behalf through the Services.
          Our processing of such Personal Information is governed by our Terms
          &amp; privacy policy
        </Typography>
        <Typography>5.3. Personal Information.</Typography>
        <Typography>You represent and warrant to us that:</Typography>
        <Typography>
          You will provide appropriate notice and have obtained (or will obtain)
          all consents and rights necessary for us to Process the Personal
          Information in accordance with this Agreement and our{' '}
          <a href='/privacy-policy'>Privacy Statement</a>; If you are providing
          Personal Information to us that is not personal to you, you agree that
          you have either provided the data subject notice or received
          permission from the data subject and have the appropriate legal basis,
          as required by applicable law, for us to: (a) use, and/or disclose the
          personal information in accordance with our Privacy Statement, (b)
          move the data outside of the country of residence of such owner of the
          personal data, if applicable, pursuant to our Privacy Statement, (c)
          provide the Personal Information to Third Party Products that you
          approve, and (d) otherwise use and disclose the personal information
          in accordance with this Agreement; and If there is any discrepancy
          between this Agreement and the Torilo Privacy Statement with respect
          the collection, use, and/or disclosure of the personal information,
          the Privacy Statement will control. You further acknowledge and agree
          that Torilo may provide data in your account to any Additional Users
          to which that data is applicable or personal to.
        </Typography>
        <Typography>
          5.4. Public Content. As a User you may have the opportunity to share
          your data, Content, or ways in which you aggregate data (“Account
          Content”) with other Users, other Torilo customers, and other third
          parties. When sharing any Account Content, you agree not to share any
          confidential information. If you have the option of accessing another
          User’s Account Content, you understand and agree that the Account
          Content is being provided by the User, and not Torilo, for information
          and guidance purposes only, and Torilo and such User are not
          responsible in any way for your use the Account Content.
        </Typography>
        <Typography>5.5. Telephone numbers.</Typography>
        <Typography>
          You may provide us with your telephone number as part of your customer
          record or registration or via other methods. You understand and agree
          that Torilo may use your telephone number for "multi-factor
          authentication” (“MFA"), to confirm your identity and help protect the
          security of your account. Part of the MFA identity verification
          process may involve Torilo sending text messages containing security
          codes to your telephone number. You agree to receive these texts from
          Torilo containing security codes as part of the MFA process. In
          addition, you agree that Torilo may send automated text messages and
          pre-recorded voice messages to the telephone number you provide for
          other limited purposes, including: providing you with important
          critical notices regarding your use of the Services, or fulfilling a
          request made by you through the Services. Additionally, Torilo may use
          your telephone number to contact you about special offers or other
          Torilo or third party products or services unless you opt out of such
          marketing. With MFA, you also agree and consent to us obtaining and
          using information from your mobile phone service provider, solely for
          the purposes of verifying your identity and to compare information you
          have provided to Torilo (such information may include from your mobile
          phone service provider account record: your name, address, email,
          customer type, customer role, billing type, mobile device identifiers
          (IMSI and IMEI) and other mobile phone subscriber details).
        </Typography>
        <Typography>6. OTHER PRODUCTS AND SERVICES.</Typography>
        <Typography>6.1. Third Party Products.</Typography>
        <Typography>
          By using these Services, you agree that we may market to you or offer
          you access to products or services from third parties ("Third Party
          Products"). If you decide to use or access any Third Party Products,
          you agree that you are solely responsible for your relationship with
          the provider of the product. Torilo is not affiliated with Third Party
          Products and does not endorse or recommend any Third Party Products.
          You agree that the providers of the Third Party Products, and not
          Torilo, are solely responsible for their own actions or inactions.
          Torilo is not liable for any damages, claims or liabilities arising
          out of or related to any Third Party Products. You agree that you will
          (a) review and comply with all Third Party Product terms and
          conditions, and (b) not use the Third Party Product in any manner that
          would infringe or violate the rights of Torilo or any other party or
          in furtherance of criminal, fraudulent or other unlawful activity.
        </Typography>
        <Typography>6.2. Data Transfer Service.</Typography>
        <Typography>
          We may provide you with the opportunity to transfer your data and
          Content from the Services to certain supported online Third Party
          Products or other online Torilo services (the "Ancillary Services")
          that you sign up for or use in connection with the Services (the "
          Data Transfer Service"). You may need to be an active subscriber of
          the Third Party Product or Ancillary Services to use the Data Transfer
          Service. In order to access a Third Party Product or an Ancillary
          Service on your behalf to provide the Data Transfer Service, you may
          need to provide us with your account number, password, security
          questions and answers, and any other necessary log in information from
          time to time (" Login Details"). We will maintain your Login Details
          in encrypted form, and will only use them in connection with the Data
          Transfer Service or otherwise at your direction. You hereby represent
          that you have the authority to provide the Login Details to Torilo and
          you expressly appoint Torilo as your, or the third party who owns the
          Login Details', agent with limited power of attorney to access any
          Third Party Products or Ancillary Services on your behalf. With
          respect to each Data Transfer Service, you grant Torilo the right to
          transfer data to the Third Party Product or Ancillary Service, and to
          reformat and manipulate the data as reasonably necessary for the data
          to function with such product or service. After the transfer occurs,
          the original data and Content will remain in the Services unless we
          disclose to you otherwise. You agree that you will (a) review and
          comply with all Third Party Product or Ancillary Service terms and
          conditions before you access the Data Transfer Service, and (b) not
          use the Data Transfer Service in any manner that would infringe or
          violate the rights of Torilo or any other party, or in furtherance of
          criminal, fraudulent or other unlawful activity. Depending on the
          Third Party Product or Ancillary Service you choose, you further agree
          and acknowledge that your data, including your financial or personal
          information, may be transferred through the Data Transfer Service to
          another country where security and privacy controls may not be
          adequate for data protection. We do not guarantee that you will be
          able to use the Data Transfer Service with any specific products or
          services. You will only have access to the Data Transfer Services
          during the period for which you have paid for a subscription. Third
          Party Products may make changes to their websites, with or without
          notice to us, that may prevent or delay the overall performance of the
          Data Transfer Service.
        </Typography>
        <Typography>6.3. Data Receipt Service.</Typography>
        <Typography>
          We may provide you with the opportunity to transfer certain data from
          a Third Party Product or an Ancillary Service to these Services (the "
          Data Receipt Service"). You may need to be an active subscriber of the
          Third Party Product or Ancillary Services to use the Data Receipt
          Service. In order to access a Third Party Product or an Ancillary
          Service on your behalf, you may need to provide us with your Login
          Details. We will maintain your Login Details in encrypted form, and
          will only use them in connection with the Data Receipt Service or
          otherwise at your direction. You hereby represent that you have the
          authority to provide the Login Details to Torilo and you expressly
          appoint Torilo as your, or the third party who owns the Login
          Details', agent with limited power of attorney to access any Third
          Party Products or Ancillary Services and retrieve data on your behalf.
          With respect to each Data Receipt Service, you grant Torilo the right
          to transfer data to the Services, and to reformat and manipulate your
          data as reasonably necessary for the data to function with the
          Services. After the transfer occurs, your original data and Content
          may not remain in the Third Party Product or the Ancillary Services;
          please review the terms of those products and services to confirm. You
          agree that you will (i) review and comply with all Third Party Product
          and Ancillary Service terms and conditions before you access the Data
          Receipt Services, and (ii) not use the Data Receipt Services in any
          manner that would infringe or violate the rights of Torilo or any
          other party, or in furtherance of criminal, fraudulent or other
          unlawful activity. In the event that any Third Party Product charges
          for access to data, you agree that you are responsible for any fees
          due and owing. You agree that the providers of the Third Party
          Products, and not Torilo, are solely responsible for their own actions
          or inactions. Torilo is not liable for any damages, claims or
          liabilities arising out of or related to any Third Party Products. We
          do not guarantee that you will be able to use the Data Receipt Service
          with any specific products or services. You will only have access to
          the Data Receipt Services during the period for which you have paid
          for a subscription. Third Party Products may make changes to their
          websites, with or without notice to us, that may prevent or delay
          aggregation of data or the overall performance of the Data Receipt
          Service. Your most recent data from a Third Party Product or Ancillary
          Service may not always be available in the Services. Any data obtained
          through the Data Receipt Service will be made available in the
          Services, and will be subject to the terms and conditions of this
          Agreement, including our Privacy Policy.
        </Typography>
        <Typography>6.4. Service Providers.</Typography>
        <Typography>
          We may use third parties in the operation of our Services or to
          perform any of our obligations in this Agreement (each a "Service
          Provider"). In order for our Service Providers to be able to provide
          you with certain aspects of the Services, we may share your data or
          Content with such Service Provider.
        </Typography>
        <Typography>7. COMMUNICATION SERVICES.</Typography>
        <Typography>
          We may provide you with the opportunity to communicate with other
          Users within the Services, or with other third parties through the
          Services ("Communication Services"). You may choose whether or not you
          would like to use the Communication Services. You agree that you have
          received permission to communicate with any third parties who are not
          Users through the Communication Services, and that such third parties
          have agreed to Torilo's use and disclosure of data available in the
          Communication Services in accordance with this Agreement. Users who
          you communicate with may have access in the future to the data
          provided through the Communication Services, and you may not have the
          ability to restrict that access. Torilo's systems may extract certain
          data (e.g., numbers, names, or attachments) from the Communication
          Services and provide it to you in other parts of the Services.
        </Typography>
        <Typography>8. BizEdge ONLINE PAYROLL.</Typography>
        <Typography>
          If you elect to enroll with the Payroll Services, the following
          additional terms apply.
        </Typography>
        <Typography>8.1 Users.</Typography>
        <Typography>
          User. These terms apply to all Payroll Service users, including
          Additional Client Users as described below. Additional Client User.
          You may use Payroll Services for your own business or for the benefit
          of your clients as Additional Users (each an “Additional Client
          User”). If you are using Payroll Services as the agent of your
          Additional Client Users, you warrant that you have obtained all
          necessary and proper rights and authorizations from your client to:
        </Typography>
        <Box>
          <ul>
            <li>
              act as their agent in authorizing and using Payroll Services on
              your client’s behalf, and accept all liability arising from using
              Payroll Services as your client’s agent; and
            </li>
            <li>allow Torilo to provide the Payroll Service.</li>
          </ul>
        </Box>
        <Typography>8.2 General Terms.</Typography>
        <Typography>
          Subscription Payroll Services, Restrictions, and Responsibility for
          Payment.
        </Typography>
        <Box>
          <ul>
            <li>
              You must purchase a separate Payroll Service subscription for your
              own business and each Additional Client User you support. Please
              review all of the details of the Payroll Service subscription that
              you purchase; each subscription type is distinct and may provide
              access to different features.
            </li>
            <li>
              When you enroll in a Payroll Service subscription and pay the
              applicable fees, Torilo grants you a limited non-exclusive,
              non-transferable license to use the Payroll Service subscription
              in the United Kingdom and you acknowledge that the Service is only
              suitable in relation to legislation of the United Kingdom. Should
              the Payroll Service be accessed and used from outside the United
              Kingdom it is your responsibility to ensure that your access to
              and use of the Service complies with local law requirements. Your
              use of Payroll Services: (a) is governed by the latest version of
              the terms and conditions for Payroll Services, and (b) constitutes
              your acceptance of those terms and conditions. You acknowledge and
              agree that Torilo cannot represent you in tax matters or, except
              as authorized by section 8.3, file and pay taxes on your behalf.
            </li>
            <li>
              You are responsible for payment of all fees and amounts: (a) due
              for each Payroll Service subscription, or (b) payable to any 3rd
              party on your behalf as agreed by a Payroll Service subscription,
              including those arising from or related to Additional Client Users
              subscriptions. You may have the option of causing an Additional
              Client User to be liable for any fees and amounts payable, if you:
              (x) give the Additional Client User access to the Payroll Service,
              and (y) require the Additional Client User to sign into the
              Payroll Service subscription, create a username and password, and
              agree to this Agreement. Torilo will have no responsibility for
              requesting that any Additional Client User sign into the Service
              or agree to this Agreement. You acknowledge and agree that if an
              Additional Client User does not accept this Agreement or if Torilo
              is not able to collect amounts from an Additional Client User for
              any reason, you, as the service provider are responsible for
              paying Torilo all amounts owed by the Additional Client User. We
              reserve all rights, including termination of this Agreement for
              you or any/all Additional Client Users, discontinuation of Payroll
              Services, in whole or in part in our sole discretion, and avail
              ourselves of any other available remedy. This Section will, to the
              extent applicable, survive the termination of this Agreement.
            </li>
          </ul>
        </Box>
        <Typography>
          Torilo will not be liable for any penalty, interest or other liability
          that results from inaccurate or incomplete information you supply. In
          the event that Torilo becomes aware of any tax agency information that
          is inaccurate, Torilo reserves the right to input the correct
          information. Torilo will not be liable in any way if Payroll Services
          cannot be performed completely or accurately because of anything not
          reasonably within our control, including problems with the Internet or
          inaccurate or incomplete information you provide to us. If any Torilo
          error occurs in performing Payroll Services, our only responsibility
          will be to make the correct payment or file the correct report and pay
          any resulting tax interest or penalty. In no event will we be liable
          for any indirect, special or consequential damages. If these remedies
          fail of their essential purpose, Torilo’s maximum liability will equal
          the fees you have paid to Torilo for the applicable Service
          subscription. <br />
          DISCLAIMER. YOU ASSUME FULL RESPONSIBILITY FOR THE USE OF THE PAYROLL
          SERVICE TO ACHIEVE YOUR INTENDED PURPOSES, FOR THE PROPER INSTALLATION
          AND USE OF THE PAYROLL SERVICE AND FOR VERIFYING RESULTS FROM USE OF
          THE PAYROLL SERVICE. Torilo AND ITS SUPPLIERS DISCLAIM ANY WARRANTY
          THAT THE FUNCTIONS CONTAINED IN THE PAYROLL SERVICE WILL MEET YOUR
          REQUIREMENTS OR THAT THE OPERATION OF THE PAYROLL SERVICE WILL BE
          UNINTERRUPTED OR ERROR FREE.
        </Typography>
        <Typography>8.3 Electronic Filing of Payroll.</Typography>
        <Typography>
          We do not as at the date of this Terms provide filing services..
        </Typography>
        <Typography>8.4 Setup Payroll Services.</Typography>
        <Typography>
          If Torilo provides you with Setup Payroll Services and support
          services on the Payroll Services the following additional terms will
          apply:
        </Typography>
        <Typography>
          Setup and Support Information. To enable you to more rapidly begin
          utilizing BizEdge Online Payroll and obtain support services when
          needed you agree to provide Torilo with all information necessary to
          enable Torilo to establish your subscription and support you in the
          use of the Product. (collectively, "Setup and Support Information").
          Torilo will suggest the most accurate and rapid method for completing
          your Setup Payroll Services from the following options:
        </Typography>
        <Box>
          <ul>
            <li>
              Establishing Torilo as an authorized user of your existing payroll
              service offering with unique login credential and limited access
              rights to the records necessary to enable the Setup Payroll
              Services;
            </li>
            <li>
              Providing uninterrupted access to your BizEdge or non-Torilo
              financial management software data files, either via upload to
              Torilo's FTP site in accordance with Torilo's instructions or via
              remote desktop connection access; and
            </li>
            <li>
              Responding to Torilo communications and requests for information,
              and reviewing information provided or prepared by Torilo promptly
              and reasonably in advance of and during the Setup Service process,
              notifying Torilo of any errors.
            </li>
          </ul>
        </Box>
        <Typography>
          <strong>Representations, Grant of Rights to Torilo.</strong> You
          hereby represent and warrant that you: (a) are the legal owner of the
          Setup Information, (b) have the necessary rights to grant us the
          authorizations to act on behalf of you or your business, (c) hereby
          authorize government agencies to disclose your confidential
          information to us, (d) as the business owner, remain solely liable to
          compliance with applicable laws and regulations regarding your
          business, and (e) are responsible for ensuring that the grant of these
          rights is permissible under the terms of any applicable agreements.
          You acknowledge and agree that we do not provide any advice or
          recommendation relating to government registration, and that we are
          relying on information you provide to us. <br />
          <strong>Information Verification.</strong> Torilo will reasonably
          ensure the accuracy of information received, but cannot ensure that
          the source files used, information provided by other service providers
          or company personnel are accurate. Accordingly, Torilo may require you
          to verify the accuracy of all the information before commencing use of
          BizEdge Online Payroll. <br />
          <strong>Completion of Setup Payroll Services.</strong> After you have
          verified your information, Torilo will assist you in the steps
          necessary to commence use of BizEdge Online Payroll. At that time the
          Setup Payroll Services will be complete. <br />
          <strong>No Obligation to Provide Payroll Services.</strong> Torilo
          reserves the right to refuse to provide Setup Service to you and, in
          such instances, will refund any fees you paid to Torilo for Setup
          Service.
        </Typography>
        <Typography>8.5 MyEdge Online.</Typography>
        <Typography>
          If you are a current customer of the Payroll Services, you may choose
          to provide your employees with access to an online employee website
          called MyEdge. Your employee(s) may choose to enter their personal,
          National Insurance Number and starter declaration depending upon their
          circumstance (collectively “Information”) at the time they set up
          their account. The Information will facilitate direct deposit of pay
          slips. You may also have the option to furnish your employee(s) with
          documents such as their pay slips in an electronic format in lieu of a
          paper format through the MyEdge Services (collectively “Documents”).
          If you elect to invite employee(s) to exchange Information and/or
          receive such electronic Documents through the MyEdge Services, you
          must notify your employee(s) of the option to exchange Information
          and/or receive electronic Documents in a manner that allows the
          employee(s) to link to an electronic consent that demonstrates that
          the employee(s) can provide Information and access the Documents
          electronically. Torilo is not responsible for any Information
          submitted through the MyEdge Services or liable for any claims related
          to the amounts withheld from pay slips. You must also consent
          (electronically) to these terms pertaining to the Payroll Services.
          You may choose to terminate your participation (and your employee(s))
          at any time. If you choose to furnish electronic Documents and your
          employee(s) choose to participate in the MyEdge Services, the
          following also applies:
        </Typography>
        <Typography>
          Change in Hardware. You must provide notice to your employee(s) prior
          to changing any hardware or software needed to access Documents
          through the MyEdge Services. The notice must: (i) describe the revised
          hardware and/or software required to access Documents, (ii) inform
          your employee(s) that a new consent to receive Documents in the
          revised electronic format will be provided, and (iii) be agreed to by
          the employee in order to continue electronic delivery/receipt of
          Documents.
          <br /> Change in Preference. In the event your employee(s) change
          their preference to receive Documents from electronic to paper format,
          you must provide email or paper confirmation regarding the employee(s)
          changed preference. This notice must include the date by which the
          employee(s) will begin receiving paper format again.
          <br />
          Change Due to Termination/to Contact Information. You must send email
          or paper notification to your employee(s) of: (i) the termination of
          your use of MyEdge for any employee(s) whose employment has been
          terminated, and (ii) any changes to your contact information by which
          the employee(s) could contact you about their Documents. Notice that
          Documents Are Available/Modified. You must notify your employee(s):
          (i) when the electronic Documents are available, and (ii) within
          30-days of any corrected/modified Documents. Notices regarding
          corrected/modified Documents should include advice to the employee(s)
          to use the Payroll Services website to access, print, and retain the
          corrected/modified Document(s).
        </Typography>
        <Typography>9. ACCOUNT INFORMATION SERVICES</Typography>
        <Typography>
          9.1 Users of BizEdge Services may opt to use Account Information
          Services to automatically import transaction and other information
          ("transaction information") into the relevant BizEdge Account as an
          alternative to you manually entering this transaction information.
        </Typography>
        <Typography>
          9.2 Account Information Services allows you to connect certain bank or
          other payment accounts you hold ("account") with your BizEdge Account
          to automatically import transaction information from that account. You
          can choose whether to connect with your bank account using the direct
          feed option or the web feed option. <br /> <br />
          For the direct feed option, you will be required to complete either a
          customer authority form or follow your bank’s authentication process
          which instructs your bank (or other financial institution) to share
          transaction information with Torilo Limited. Your bank (or other
          financial institution) may request that you authorise this by
          completing its own authority as well or instead. Once authorised, your
          bank will provide Torilo Limited with transaction information. You
          hereby acknowledge that Torilo Limited will receive this information
          more than four times a day. For the web feed option we will request
          your online banking security credentials. Once we connect to your
          bank, our product downloads your transaction information automatically
          and securely. Each time you sign-in to your BizEde Account, we will
          use your stored online banking credentials to connect to your bank and
          download your transaction information so that you have the latest
          information. You may be required to submit additional credentials,
          such as from multi-factor authentication devices.
          <br /> We will process the transaction information obtained from your
          bank and present this to you via your BizEdge Account [or Payroll
          Services account] and, where relevant, any Additional Users that have
          been granted access to your BizEdge Account [or Payroll Services
          account] in accordance with section 2 or, if you have granted your
          accountant access to your BizEdge Account, your accountant or
          financial advisor.
        </Typography>
        <Typography>
          9.7 Please see Torilo's Privacy Statement for more information on how
          your data is handled, including in the context of Account Information
          Services.
          <br />
          In the event of any conflict with any other provision of this
          Agreement, the provisions of this Section 9 shall prevail as regards
          the provision of the regulated activity of Account Information
          Services.
        </Typography>
        <Typography>
          <strong>10. MyEdge ACCOUNT</strong>
        </Typography>
        <Typography>
          <strong>10.1 MyEdge Account Services.</strong>
        </Typography>
        <Typography>
          By accessing and/or making use of any service made available via the
          MyEdge Account, you hereby agree to adhere to the additional terms
          contained in this terms.
        </Typography>
        <Typography>
          <strong>11. APPLE REQUIREMENTS.</strong>
        </Typography>
        <Typography>
          If you downloaded the Services from the Apple iTunes Store the
          following apply:
        </Typography>
        <Typography>
          <strong>11.1. Acknowledgement:</strong>
        </Typography>
        <Typography>
          You acknowledge that this Agreement is between you and Torilo only,
          and not with Apple, and Torilo, not Apple, is solely responsible for
          the Services and the content thereof.
        </Typography>
        <Typography>
          <strong>11.2. Scope of License:</strong>
        </Typography>
        <Typography>
          The license granted to you for the Services is a limited,
          non-transferable license to use the Services on an iOS product that
          you own or control and as permitted by the Usage Rules set forth in
          the Apple iTunes App Store Terms of Service.
        </Typography>
        <Typography>
          <strong>11.3. Maintenance and Support:</strong>
        </Typography>
        <Typography>
          Torilo and not Apple is solely responsible for providing any
          maintenance and support services with respect to the Services. You
          acknowledge that Apple has no obligation whatsoever to furnish any
          maintenance and support services with respect to the Services.
        </Typography>
        <Typography>
          <strong>11.4. Warranty:</strong>
        </Typography>
        <Typography>
          Torilo is solely responsible for any product warranties, whether
          express or implied by law, to the extent not effectively disclaimed.
          In the event of any failure of the Services to conform to any
          applicable warranty, you may notify Apple, and Apple will refund the
          purchase price for the Services in the Apple iTunes App Store to you.
          To the maximum extent permitted by applicable law, Apple will have no
          other warranty obligation whatsoever with respect to the Services, and
          any other claims, losses, liabilities, damages, costs or expenses
          attributable to any failure to conform to any warranty will be
          Torilo's sole responsibility, as between Torilo and Apple.
        </Typography>
        <Typography>
          <strong>11.5. Product Claims:</strong>
        </Typography>
        <Typography>
          Torilo, not Apple, is responsible for addressing any user or third
          party claims relating to the Services or the user's possession and/or
          use of the Services, including, but not limited to: (a) product
          liability claims; (b) any claim that the Services fail to conform to
          any applicable legal or regulatory requirement; and (c) claims arising
          under consumer protection or similar legislation.
        </Typography>
        <Typography>
          <strong>11.6. Intellectual Property Rights:</strong>
        </Typography>
        <Typography>
          You acknowledge that, in the event of any third party claim that the
          Services or your possession and use of the Services infringes that
          third party's intellectual property rights Apple will not be
          responsible for the investigation, defense, settlement and discharge
          of any such intellectual property infringement claim.
        </Typography>
        <Typography>
          <strong>11.7. Legal Compliance:</strong>
        </Typography>
        <Typography>
          You represent and warrant that (a) you are not located in a country
          that is subject to a U.S. Government embargo, or that has been
          designated by the U.S. Government as a "terrorist supporting" country;
          and (b) you are not listed on any U.S. Government list of prohibited
          or restricted parties.
        </Typography>
        <Typography>11.8. Developer Contact Info:</Typography>
        <Typography>
          Direct any questions, complaints or claims to: Torilo Ltd, 4a Centre
          Court, Sir Thomas Longley Road, Rochester, Kent.
        </Typography>
        <Typography>
          <strong>11.9. Third Party Beneficiary:</strong>
        </Typography>
        <Typography>
          You acknowledge and agree that Apple and Apple's subsidiaries are
          third party beneficiaries of this Agreement, and that, upon your
          acceptance of the terms and conditions of the Agreement, Apple will
          have the right (and will be deemed to have accepted the right) to
          enforce the Agreement against you as a third party beneficiary
          thereof.
        </Typography>
        <Typography>
          <strong>November 2021</strong>
        </Typography>
      </Box>
    </Box>
  );
};

export default TermsPolicy;
