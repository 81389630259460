const fontFamily = ['Black Sans', 'Roboto', 'sans-serif'].join(',');
// #878787
const bizColors = {
  primary: '#171717',
  white: '#FFFFFF',
  biz_grey: '#e1e1e1',
  biz_lightest_grey: '#FAFAFA',
  off_white: '#d6f2f5',
  biz_main_black: '#545454',
  biz_btn_primary: '#2898A4',
  biz_light: '#878787'
};
export const bizPaletteColors = {
  biz_yellow: '#FDEDCE',
  biz_green: '#D6F2F5',
  biz_green_light: '#EAF8FA',
  biz_light_grey: '#F2F2F2'
};

const paletteColorGenerator = () => {
  return Object.keys(bizPaletteColors)?.reduce((acc, color) => {
    acc[color] = {
      main: bizPaletteColors[color],
      contrastText: bizColors.biz_main_black
    };
    return acc;
  }, {});
};

const main = {
  colors: bizColors,
  palette: {
    primary: {
      main: '#FF7372',
      white: '#FFFFFF'
    },
    secondary: {
      main: '#2898A4',
      light1: '#31B9C7',
      light2: '#E0EEF0',
      light3: '#2898A4' // #2898A4
    },
    dark: {
      main: '#000C11',
      black: '#000000'
    },
    backgrounds: {
      main: '#FFFFFF',
      grey: '#F9F9F9',
      gray: '#F4F4F4'
    },
    ...paletteColorGenerator()
  },
  typography: {
    fontFamily,
    color: '#171717'
  },
  overrides: {
    '@global': {
      'font-family': 'Black Sans'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  shape: {
    borderRadius: 5,
    topNavHeight: '60px',
    sideNavWidth: '264px',
    layoutGap: '10px'
  },
  spacing: 16
};

const makeResponsive = ({ fontSize, lineHeight, theme, object }) => {
  const scaleValue = 0.7;
  if (object)
    return {
      fontSize: `${fontSize}px`,
      lineHeight: `${lineHeight ? lineHeight + 'px' : 'auto'}`,
      [theme.breakpoints.down('lg')]: {
        fontSize: `${fontSize <= 10 ? fontSize : fontSize * scaleValue}px`,
        lineHeight: `${lineHeight ? lineHeight * scaleValue + 'px' : 'auto'}`
      }
    };
  return `
    font-size: ${fontSize}px;
    line-height: ${lineHeight ? lineHeight + 'px' : 'auto'};
    ${theme.breakpoints.down('lg')} {
      font-size: ${fontSize * scaleValue}px;
      line-height: ${lineHeight ? lineHeight * scaleValue + 'px' : 'auto'};
    }
  `;
};

const composed = {
  ...main,
  shape: {
    borderRadius: 5,
    ...main.shape
  },
  typography: {
    fontFamily: 'Black Sans'
  },
  mixins: {
    makeResponsive
  }
};
export default composed;

export const muiV5Theme = {
  ...composed,
  components: {
    // Name of the component
    MuiButtonBase: {
      variants: [
        {
          props: { variant: '' },
          style: {
            // The styles
            backgroundColor: bizColors.biz_btn_primary
          }
        }
      ],
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              backgroundColor: bizColors.biz_btn_primary,
              color: bizColors.white
            })
        })
      }
      // defaultProps: {
      //   // The props to change the default for.
      //   disableRipple: true // No more ripple, on the whole application 💣!
      // }
    }
  }
};
