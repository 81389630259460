import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { CustomTypographyStyle as style } from '../../Account/Resuseable/typography';
import styled from 'styled-components';
import TabsMenu from '../TabsMenu';

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
const Label = ({ menu }) => {
  return (
    <TabWrapper>
      {menu.label || menu.name}{' '}
      {menu.hasDropDown ? <KeyboardArrowDownIcon /> : null}
    </TabWrapper>
  );
};

const TabNavigation = ({ menus, variant }) => {
  const history = useHistory();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenu, setCurrent] = useState(null);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (event, menu) => {
    setCurrent(menu);
    if (menu.hasDropDown) {
      handleOpen(event);
      return;
    }
    history.push(menu?.route);
  };

  return (
    <>
      {menus?.length && (
        <TabNavigation.Wrapper
          variant='scrollable'
          sx={{
            '& button:hover': {
              background: '#D6F2F5',
              borderRadius: '10px 10px 0 0'
            },
            '& button.Mui-selected': {
              color: '#2898A4',
              fontWeight: 600
            }
          }}
          scrollButtons
          allowScrollButtonsMobile
          aria-label='scrollable force tabs example'
        >
          {menus.map((menu, index) => {
            return (
              <Tab
                className='tab'
                label={<Label menu={menu} />}
                onClick={(event) => handleChange(event, menu)}
                key={index}
                sx={[
                  style.sans,
                  style.f_16,
                  { marginRight: '1.5rem' },
                  menu.route.includes(location.search.split('&view')[0])
                    ? {
                        fontWeight: 600,
                        color: '#2898A4',
                        opacity: 1,
                        background: '#EAF8FA',
                        borderBottom: '1px solid #2898A4',
                        borderRadius: '5px  5px 0px 0px'
                      }
                    : {
                        color: '#878787'
                      }
                ]}
              />
            );
          })}
        </TabNavigation.Wrapper>
      )}
      {open && (
        <TabsMenu
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          style={currentMenu?.dropStyle || {}}
          options={currentMenu?.dropOptions.map((route) => ({
            ...route,
            onClick: () => {
              history.push(route?.route);
            }
          }))}
        />
      )}
    </>
  );
};

export default TabNavigation;

TabNavigation.Wrapper = styled(Tabs)`
  left: 0;
  top: 55px;
  z-index: 100;
  width: 100vw;
  position: fixed;
  background: #ffffff;
  padding-top: 10px;
  border-bottom: 1px solid #e1e1e1;

  .tab {
    font-family: 'Black Sans';
    font-style: normal;

    :hover {
      background: #eaf8fa;
      border-radius: 8px 8px 0px 0px;
    }
  }
`;
