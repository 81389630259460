import { uploadOnboardingDocs } from 'api/hr';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { apiErrorHandler } from 'utilities/apiErrorHandler';
import { runIfFn } from 'utilities/utils';
import { SNACK_VARIANT } from '../../constant';

export const useUploadTemporaryDocument = ({
  onSuccess,
  onError,
  successMsg,
  noMsg = false,
  report
}) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(
    async ({ file, progressSetter }) => {
      const formDataUpload = new FormData();
      formDataUpload.append('file', file);
      const res = await uploadOnboardingDocs(
        { file: formDataUpload },
        progressSetter
      );
      return { fileInfo: file, ...res };
    },
    {
      onSuccess(res) {
        if (!noMsg) {
          enqueueSnackbar(successMsg || 'Document uploaded...', {
            variant: SNACK_VARIANT.SUCC
          });
        }
        runIfFn.bind([res])(onSuccess);
      },
      onError(err) {
        enqueueSnackbar(apiErrorHandler(err), { variant: SNACK_VARIANT.ERR });
        runIfFn.bind([err])(onError);
      }
    }
  );
};
