import moment from 'moment';
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { CreateVehicleButton } from '../VehicleList/styled.vehicle-list';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import VehicleMake from './SelectFields/VehicleMake';
import VehicleModel from './SelectFields/VehicleModel';
import VehicleAssignee from './SelectFields/VehicleAssignee';
import VehicleStatus from './SelectFields/VehicleStatus';
import VehicleTypes from './SelectFields/VehicleTypes';
import {
  getVehicleMakes,
  getVehiclesMeta,
  getModels,
  createVehicle,
  updateVehicle
} from '../../api/fleet';
import { FormikVehicleCreation, disableSubmit } from './utils';
import useFleetControl from '../../hooks/useFleetControl';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles({
  foo: {
    '& .MuiFormControl-fullWidth': {
      margin: '1% 0px'
    },
    '& > *': {
      margin: '1px 0'
    }
    // '& .MuiGrid-item': {
    //   padding : "0 .5rem 0 0"
    // }
  }
});

function CreateVehicleForm({ width, editMode = false }) {
  const classes = useStyles();
  const apiMethod = editMode ? updateVehicle : createVehicle;
  const mutation = useMutation(apiMethod);
  const [focused, setFocused] = useState({
    start_mileage: false,
    assignee: false
  });

  const onFocus = (e) => setFocused({ ...focused, [e.target.name]: true });
  const onBlur = (e) => {
    setFocused({ ...focused, [e.target.name]: false });
  };

  const [fleetState, setFleetState] = useFleetControl();

  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: FormikVehicleCreation.getInitialValues(
      editMode ? fleetState.editVehicleDetails : {}
    ),
    validate: FormikVehicleCreation.validate,
    onSubmit: (values) => {
      mutation
        .mutateAsync({
          ...values,
          date_purchased: moment(formik.values.date_purchased).format(
            'YYYY-MM-DD'
          ),
          id: fleetState.editVehicleDetails?.id
        })
        .then(() => {
          setFleetState({ ...fleetState, modal: '' });
          queryClient.refetchQueries(['vehicles']);
          queryClient.refetchQueries(['analytics:vehicles-summary']);
          queryClient.refetchQueries(['analytics:vehicle-status']);
        });
    }
  });

  const { data: vehicleMakes } = useQuery('vehicle-makes', getVehicleMakes);
  const { data: vehiclesMeta } = useQuery('vehicles meta', getVehiclesMeta);
  const { data: vehicleModels } = useQuery(
    ['models', formik.values.make],
    getModels
  );

  const statuses = vehiclesMeta ? vehiclesMeta.status : [{}];

  const hasErrors = (key) => {
    return formik.touched[key] && !!formik.errors[key];
  };

  const removePaddingLeft = {
    paddingLeft: 0
  };
  const removePaddingRight = {
    paddingRight: '0.9rem'
  };

  const flexBox = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  };
  return (
    <FormGroup onSubmit={formik.handleSubmit} className={classes.foo}>
      <Grid container spacing={1} alignItems='center' >
        <Grid item sm={6} style={removePaddingLeft}>
          <VehicleMake
            name='make'
            makes={vehicleMakes}
            formik={formik}
            error={hasErrors('make')}
          />
          {hasErrors('make') ? (
            <Typography variant='caption' color='primary'>
              {formik.errors.make}
            </Typography>
          ) : null}
        </Grid>
        <Grid item sm={6} style={removePaddingRight}>
          <TextField
            color='secondary'
            size={isWidthDown('lg', width) ? 'small' : 'medium'}
            fullWidth
            name='start_mileage'
            variant='outlined'
            label={
              focused.start_mileage || formik.values.start_mileage
                ? 'Mileage'
                : 'Mileage (Optional)'
            }
            value={formik.values.start_mileage}
            onChange={formik.handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            // error={hasErrors('start_mileage')}
          />
          {/* {hasErrors('start_mileage') ? (
            <Typography variant='caption' color='primary'>
              {formik.errors.start_mileage}
            </Typography>
          ) : null} */}
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems='center'>
        <Grid style={{ ...flexBox, ...removePaddingLeft }} item sm={6}>
          <Grid item sm={9} style={{ marginRight: '.5rem' }}>
            <VehicleModel
              name='model'
              formik={formik}
              selectedMake={formik.values.make}
              models={vehicleModels}
              error={hasErrors('model')}
            />
            {hasErrors('model') ? (
              <Typography variant='caption' color='primary'>
                {formik.errors.model}
              </Typography>
            ) : null}{' '}
          </Grid>

          <Grid sm={3}>
            <TextField
              color='secondary'
              size={isWidthDown('lg', width) ? 'small' : 'medium'}
              fullWidth
              variant='outlined'
              label='Year'
              name='year'
              value={formik.values.year}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={hasErrors('year')}
            />
            {hasErrors('year') ? (
              <Typography variant='caption' color='primary'>
                {formik.errors.year}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
        <Grid item sm={6} style={removePaddingRight}>
          <TextField
            color='secondary'
            size={isWidthDown('lg', width) ? 'small' : 'medium'}
            fullWidth
            name='purchase_price'
            variant='outlined'
            label={
              focused.purchase_price || formik.values.purchase_price
                ? 'Purchase Price'
                : 'Purchase Price (Optional)'
            }
            value={formik.values.purchase_price}
            onChange={formik.handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            // error={hasErrors('purchase_price')}
          />
          {/* {hasErrors('purchase_price') ? (
            <Typography variant='caption' color='primary'>
              {formik.errors.purchase_price}
            </Typography>
          ) : null} */}
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems='center'>
        <Grid item sm={6} style={removePaddingLeft}>
          <VehicleTypes
            name='type'
            formik={formik}
            value={formik.values.type}
            error={hasErrors('type')}
          />
          {hasErrors('type') ? (
            <Typography variant='caption' color='primary'>
              {formik.errors.type}
            </Typography>
          ) : null}
        </Grid>
        <Grid item sm={6} style={removePaddingRight}>
          <TextField
            color='secondary'
            size={isWidthDown('lg', width) ? 'small' : 'medium'}
            fullWidth
            name='date_purchased'
            variant='outlined'
            label={
              focused.date_purchased || formik.values.date_purchased
                ? 'Date of purchase'
                : 'Date of purchase (Optional)'
            }
            type='date'
            value={formik.values.date_purchased}
            onChange={formik.handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {/* {hasErrors('date_purchased') ? (
            <Typography variant='caption' color='primary'>
              {formik.errors.date_purchased}
            </Typography>
          ) : null} */}
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems='center'>
        <Grid item sm={6} style={removePaddingLeft}>
          <TextField
            color='secondary'
            size={isWidthDown('lg', width) ? 'small' : 'medium'}
            fullWidth
            variant='outlined'
            label='Plate Number'
            name='plate_no'
            value={formik.values.plate_no}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={hasErrors('plate_no')}
          />
          {hasErrors('plate_no') ? (
            <Typography variant='caption' color='primary'>
              {formik.errors.plate_no}
            </Typography>
          ) : null}
        </Grid>
        <Grid style={{ ...flexBox, ...removePaddingRight }} item sm={6}>
          <Grid item sm={7} style={{ marginRight: '.5rem' }}>
            <VehicleStatus
              name='status'
              statuses={statuses}
              formik={formik}
              error={hasErrors('status')}
            />
            {hasErrors('status') ? (
              <Typography variant='caption' color='primary'>
                {formik.errors.status}
              </Typography>
            ) : null}
          </Grid>
          <Grid item sm={5}>
            <TextField
              color='secondary'
              size={isWidthDown('lg', width) ? 'small' : 'medium'}
              fullWidth
              name='color'
              variant='outlined'
              label='Colour'
              value={formik.values.color}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={hasErrors('color')}
            />
            {hasErrors('color') ? (
              <Typography variant='caption' color='primary'>
                {formik.errors.color}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ margin: '1% 0px' }}>
        <Grid item sm={12}>
          <Typography variant='subtitle2'>
            <strong>Assignee information</strong>
          </Typography>
          <Divider />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item sm={6} style={removePaddingLeft}>
          <VehicleAssignee
            value={formik.values.assignee}
            onChange={formik.handleChange}
            onFocus={(e) => onFocus(e)}
            onBlur={(e) => onBlur(e)}
            focused={focused}
            setFocused={setFocused}
          />
        </Grid>
        <Grid item sm={6} style={removePaddingRight}>
          <TextField
            color='secondary'
            size={isWidthDown('lg', width) ? 'small' : 'medium'}
            fullWidth
            name='date_assigned'
            variant='outlined'
            label={
              focused.date_assigned || formik.values.date_assigned
                ? 'Date Assigned'
                : 'Date Assigned (Optional)'
            }
            type='date'
            value={formik.values.date_assigned}
            onChange={formik.handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </Grid>
      </Grid>

      <CreateVehicleButton
        variant='contained'
        color='secondary'
        type='submit'
        disbled={disableSubmit(formik.errors)}
        onClick={formik.handleSubmit}
        startIcon={
          mutation.isLoading ? (
            <CircularProgress style={{ color: 'white' }} />
          ) : null
        }
      >
        {mutation.isLoading ? null : 'Save'}
      </CreateVehicleButton>
    </FormGroup>
  );
}

export default withWidth()(CreateVehicleForm);
