import React from 'react';
import { Box, InputAdornment, Stack } from '@mui/material';
import { CustomTextField } from 'components/Inputs';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '../Shared/Button';
import useShowPassword from './useShowPassword';
import { Link } from 'react-router-dom';
import Typography from 'components/Typography';
import { inputProps } from './data';

const ExistingUser = ({ formik, classes, serverError }) => {
  const { showPassword, setShowPassword } = useShowPassword();
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    errors,
    values: { password1, email }
  } = formik;
  return (
    <Box>
      <form>
        <Stack spacing={2}>
          <div>
            <Typography variant='title' order={1} bold>
              Welcome,
            </Typography>
            <Typography size='sm' light>
              We Noticed you already have an account with us. You can sign In
              with your{' '}
              <strong style={{ color: '#171717' }}>MyEdge Details.</strong>
            </Typography>
            {serverError && (
              <Box
                className={classes.serverError}
                style={{
                  display: serverError.length > 0 ? 'block' : 'none'
                }}
              >
                {serverError.map((error) => (
                  <Typography variant='subtitle2' component=''>
                    {error}
                  </Typography>
                ))}
              </Box>
            )}
          </div>
          <Stack spacing={1}>
            <div xs={12}>
              <CustomTextField
                name='email'
                label='Email'
                size='small'
                color='secondary'
                placeholder='Email'
                value={email}
                disabled={true}
                fullWidth={true}
                inputProps={inputProps}
                variant='outlined'
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.email && errors.email}
                error={touched.email && Boolean(errors.email)}
              />
            </div>
            <div style={{ position: 'relative' }}>
              <CustomTextField
                name='password1'
                label='Password'
                size='small'
                color='secondary'
                placeholder='Password'
                type={showPassword ? 'text' : 'password'}
                fullWidth={true}
                variant='outlined'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <button
                        type='button'
                        style={{
                          fontSize: '14px',
                          width: '40px'
                        }}
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? 'Hide' : 'Show'}
                      </button>
                    </InputAdornment>
                  )
                }}
                inputProps={{
                  ...inputProps
                }}
                value={password1}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.password1 && errors.password1}
                error={touched.password1 && Boolean(errors.password1)}
              />
            </div>
          </Stack>

          <Box>
            <Link
              className={classes.forgetLink}
              style={{ fontSize: '14px' }}
              to='/forgot-password'
            >
              Forgot Password?
            </Link>
          </Box>
          <div>
            <Button
              handleClick={handleSubmit}
              type='submit'
              disabled={isSubmitting}
              color='secondary'
              variant='contained'
            >
              {isSubmitting ? (
                <CircularProgress size={20} style={{ color: 'white' }} />
              ) : (
                'Sign In'
              )}
            </Button>
          </div>
          <Typography size='sm' light>
            By signing up, you are indicating that you have read and agreed to
            the <br />
            <strong style={{ color: '#171717' }}>
              Terms Of Use and Privacy Policy your
            </strong>
          </Typography>
        </Stack>
      </form>
    </Box>
  );
};

export default ExistingUser;
