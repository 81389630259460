/* eslint-disable react-hooks/exhaustive-deps */
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import VehicleMoveIcon from 'common/images/VehicleMoveIcon.svg';
import { CustomButton } from 'components/Button';
import Text from 'components/Typography/Text';
import Title from 'components/Typography/Title';
import qs from 'query-string';
import { useEffect } from 'react';
import { useJwt } from 'react-jwt';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import TabNavigation from '../../common/TabNavigation';
import useFleetControl from '../../hooks/useFleetControl';
import CreateVehicleForm from '../AddVehicle';
import EditVehicleForm from '../EditVehicle/EditVehicle';
import VehicleReminder from '../Reminders';
import VehicleList from '../VehicleList';
import ServiceReminder from './Charts/ServiceReminders';
import VehicleStatusSummary from './Charts/VehicleStatus';
import VehiclesChart from './Charts/Vehicles';

const useStyles = makeStyles((theme) => ({
  Header: {
    // ...theme.mixins.makeResponsive({ theme, fontSize: 40, object: true }),
    // fontWeight: 700
    color: '#545454',
    fontSize: '22.78px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: '26px',
    marginBottom: '3px'
  },
  subtitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12.64px',
    lineHeight: '14px',
    color: '#A8A8A8'
  },
  modalWrapper: {
    '& .MuiTypography-subtitle1': {
      color: '#545454',
      fontWeight: 700,
      fontStyle: 'normal',
      fontSize: '30px',
      textAlign: 'center'
    },
    '& .MuiTypography-body2': {
      lineHeight: '1.8em'
    },
    '& .MuiButton-contained': {
      width: '30%',
      [theme.breakpoints.down('sm')]: {
        width: '50%'
      }
      // marginTop: '2em'
    }
  },
  body: {
    margin: '2em 0px',
    textAlign: 'center',
    lineHeight: '1.6em'
  },
  headline: {
    margin: '1.5em 0px'
  }
}));

const tabs = [
  {
    name: 'Vehicle List',
    view: '?tab=home',
    active: true
  },
  {
    name: 'Reminder',
    view: '?tab=reminder'
  }
];

function getView(data) {
  const { tab } = qs.parse(window.location.search);
  switch (tab) {
    case 'home':
      return <VehicleList />;
    case 'reminder':
      return <VehicleReminder />;
    default:
      return <VehicleList />;
  }
}

function Dashboard() {
  const getToken = localStorage.getItem('access_token');
  const classes = useStyles();
  // const { data } = useQuery(['user'], getUser);
  // const firstName = data?.profiles[0]?.first_name;
  const { isExpired } = useJwt(getToken);
  const { tab } = qs.parse(window.location.search);
  const [fleetState, setFleetState] = useFleetControl();
  const onboarding = localStorage.getItem('onboarding-bizedge');
  let is_ready = JSON.parse(onboarding);

  const history = useHistory();

  useEffect(() => {
    if (!is_ready?.fleet_get_started) {
      setFleetState({ ...fleetState, modal: 'OnboardingFleetModal' });
      localStorage.setItem(
        'onboarding-bizedge',
        JSON.stringify({
          ...is_ready,
          fleet_get_started: true
        })
      );
    }
  }, [is_ready]);

  useEffect(() => {
    if (!getToken && isExpired) {
      history.push('/');
    }
    // if (!isExpired){
    //   history.push('/quick-actions')
    // }
  }, [getToken]);

  const { pathname } = useLocation();

  if (pathname !== '/fleet') return <></>;

  if (tab === 'add-vehicle') {
    return <CreateVehicleForm />;
  }

  if (tab === 'edit-vehicle') {
    return <EditVehicleForm />;
  }

  const handleClose = () => {
    setFleetState({ ...fleetState, toast: false });
  };

  const handleGetStarted = () => {
    setFleetState({ ...fleetState, modal: '' });
  };

  const handleBackdropClick = () => setFleetState({ ...fleetState, modal: '' });

  return (
    <Box style={{ width: '98%', margin: '1rem auto 0' }}>
      <Grid container justify='space-between' alignItems='center'>
        <Grid item sm={12}>
          <Typography variant='h2' color='secondary' className={classes.Header}>
            Vehicles
          </Typography>
          <Typography variant='subtitle2' className={classes.subtitle}>
            Here is how your vehicles are looking today
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        justify='space-between'
        style={{ marginTop: '20px' }}
      >
        <Grid item sm={4}>
          <VehiclesChart />
        </Grid>
        <Grid item sm={4}>
          <ServiceReminder />
        </Grid>
        <Grid item sm={4}>
          <VehicleStatusSummary />
        </Grid>
      </Grid>
      <Box style={{ marginTop: '4%' }}>
        <TabNavigation tabs={tabs} />
        {getView()}
      </Box>
      <Snackbar
        open={fleetState.toast}
        onClose={handleClose}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert
          onClose={handleClose}
          severity={fleetState.severity}
          elevation={6}
          variant='filled'
        >
          {fleetState.toastMessage}
        </MuiAlert>
      </Snackbar>
      <Modal
        // open={fleetState.modal === 'OnboardingFleetModal'}
        open={false}
        // open={true}
        onBackdropClick={handleBackdropClick}
      >
        <Wrapper>
          <Box className={classes.modalWrapper}>
            <Typography variant='subtitle1'>
              Welcome to Bizedge Vehicles
            </Typography>
            <Box className={classes.body}>
              <Typography variant='body2'>
                Keep track of the vehicles within your organization and automate
                maintenance and statutory document renewal. Bizedge Vehicle
                module gives you an intuitive and easy way to get the job done,
                by automating away the pain of paper work.
              </Typography>
              <Box className={classes.headline}>
                <Typography variant='body2'>
                  Welcome to the future of work!
                </Typography>
              </Box>
              <Button
                variant='contained'
                color='secondary'
                fullWidth
                disableElevation
                onClick={handleGetStarted}
              >
                Get Started
              </Button>
            </Box>
          </Box>
        </Wrapper>
      </Modal>

      {/* disable vehicle */}

      <Modal
        open={true}
        // open={true}
        // onBackdropClick={handleBackdropClick}
      >
        <Wrapper>
          <Box className={classes.modalWrapper}>
            <div
              className='d-flex justify-content-center '
              style={{ marginBlock: '1rem  2rem' }}
            >
              <img src={VehicleMoveIcon} alt='vehicle move icon' />
            </div>
            <Title order={5} textAlign='center'>
              Vehicles has a New Home.
            </Title>
            <Box className={classes.body}>
              <Text size='xs' light style={{ marginBottom: '2rem' }}>
                Hello, we've moved a few things around to give you a better
                experience. You can now view all your vehicles along with your
                assets in one place in the Assets App.
              </Text>
              <CustomButton
                variant='contained'
                fullWidth
                onClick={() => history.push('/assets?tab=home')}
              >
                View Vehicles
              </CustomButton>
            </Box>
          </Box>
        </Wrapper>
      </Modal>
    </Box>
  );
}

export default Dashboard;

const Wrapper = styled(Box)`
  ${({ theme }) => `
    background: white;
    width: 656px;
    min-height: 300px;
    max-height: 95vh;
    max-width: 40%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    outline: none;
    padding: 2% 3% 4%;
    // overflow: scroll;

    h2 {
      ${theme.mixins.makeResponsive({ theme, fontSize: 24 })};
      font-weight: 700;
      & + * {
        color: #7C7C7C;
        ${theme.mixins.makeResponsive({ theme, fontSize: 14 })};
        margin-top: 1%;
      }
    }
 `}
`;
