export const tabs = [
  {
    name: 'Home',
    view: '?tab=home',
    route: '?tab=home',
    active: true
  },
  {
    name: 'Assets List',
    view: '?tab=asset-list',
    hasDropDown: true,
    route: '?tab=asset-list',
    dropStyle: {
      minWidth: '400px',
      width: '400px',
      marginLeft: 5,
      borderRadius: '5px'
      // "&:hover": {

      // }
      // marginTop: 5
    },
    dropOptions: [
      {
        id: 1,
        name: 'Asset list',
        route: '?tab=asset-list&view=list'
      },
      {
        id: 2,
        name: 'Asset type',
        route: '?tab=asset-list&view=type'
      }
    ]
  },
  {
    name: 'Maintenance',
    view: '?tab=maintenance',
    route: '?tab=maintenance',
    hasDropDown: true,
    dropOptions: [
      {
        id: 1,
        name: 'Services & Repairs',
        route: '?tab=maintenance&view=service-repair'
      },
      {
        id: 2,
        name: 'Reported Issues',
        route: '?tab=maintenance&view=report-issue'
      }
    ]
  },
  // {
  //   name: 'Reports',
  //   view: '?tab=reports',
  //   route: '?tab=reports'
  // },
  {
    name: 'Settings',
    view: '?tab=settings',
    route: '?tab=settings'
  }
];
