import { useCreateReminder, useCreateStatutoryDocument } from 'Assets/queries';
import { defaultTime } from 'Assets/Shared/ReminderForm';

import { useFormik } from 'formik';

import { useEffect } from 'react';
import {
  formatDate,
  formatDateTime,
  inputPropsSetterConfig
} from 'utilities/formHelpers';

const useAddNormalDoc = ({ data, assetId, toggle }) => {
  const formik = useFormik({
    initialValues: {
      name: '',
      file: null,
      date_of_issue: null,
      expiry_date: null,
      content_type: 'asset',
      attachment_type: '',
      object_id: data?.id ?? assetId,
      reminder: null
    },
    validate: (values, props) => {
      const errors = {};
      if (!values.file) {
        errors.file = true;
      } else {
        errors.file = false;
      }

      return errors;
    }
  });

  const { setFieldValue, values, touched, setFieldTouched, validateForm } =
    formik;

  useEffect(() => {
    if (!values.file && touched.file) {
      validateForm();
      return;
    }
  }, [values.file, validateForm, touched.file]);

  const setInputProps = inputPropsSetterConfig({ ...formik });

  const mutation = useCreateStatutoryDocument({
    onSuccess(data) {
      toggle();
    }
  });

  const handleSubmit = (reminderId) => {
    const payload = structuredClone(values);
    payload.reminder = reminderId;
    payload.date_of_issue = formatDate(values.date_of_issue);
    payload.expiry_date = formatDate(values.expiry_date);
    const formData = new FormData();
    for (let [key, value] of Object.entries(payload)) {
      formData.append(key, value);
    }
    mutation.mutate(formData);
  };
  const reminderMutation = useCreateReminder({
    onSuccess(res) {
      const reminderId = res?.id;
      handleSubmit(reminderId);
    }
  });

  const handleFileUpload = async (files, progressSetter) => {
    setFieldValue('file', files);
    setFieldTouched('file');
  };

  const handleAddDoc = async () => {
    if (!values.file) {
      validateForm();
      return;
    }
    if (values.reminder) {
      const rPayload = structuredClone(values.reminder);
      rPayload.date = formatDateTime(
        rPayload?.time ?? defaultTime(),
        values.expiry_date
      );
      rPayload.type = values.name;
      reminderMutation.mutate(rPayload);
    } else {
      handleSubmit(null);
    }
  };
  return {
    formik,
    handleAddDoc,
    handleFileUpload,
    setInputProps,
    mutation,
    reminderMutation
  };
};

export default useAddNormalDoc;
