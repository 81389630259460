export const transformIssueType = (data) =>
  data?.results?.map((el, index) => ({
    ...el,
    label: el.name,
    value: index,
    id: index
  }));

export const statutoryDocType = [
  { label: 'Insurance', value: 'insurance' },
  { label: 'Vehicle Inspection', value: 'vehicle_inspection' },
  { label: 'Lincense', value: 'license' }
];
