/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Switch,
  TextField
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import BizedgeImage from '../../common/images/bizedgeRound.svg';
import { withStyles } from '@material-ui/styles';
import { useQuery, useQueryClient } from 'react-query';
import {
  createPremiumBusiness,
  getBusinessPlan,
  getPaymentReference,
  signup,
  updatePremiumBusiness
} from '../../api/common';
import _ from 'lodash';
import { MenuItem, Typography } from '@mui/material';
import {
  BackBtn,
  BigTitle,
  ContinueBtn,
  FormDivStarter,
  Label,
  PasswordTooTip,
  RateContainer,
  ServerError,
  ShowPassword,
  SignUpPrompt,
  SubmitButton,
  WhiteChipText
} from '../forms/styled.forms';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import { Link, useLocation } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { validateSignup } from '../forms/validator';
import { useFormik } from 'formik';
import { Countries, States } from '../forms/SelectFields';
import { usePaystackPayment } from 'react-paystack';
import { checkCompanyAddStatus } from '../../api/hr';
import { verifyPayment } from '../../api/billing';
import Title from '../../components/Typography/Title';
import BusinessLogo from '../forms/BusinessLogo';
import EmptyState from '../../common/images/Rectangle 752.svg';
import People from '../forms/People';
import qs from 'query-string';
import ProgressBar from '../../HR/Home/ProgressBar';
import CheckIcon from './CheckIcon';
import { CustomButton, CustomText } from 'common/Reusables';
import { Country, State } from 'country-state-city';
import { toTitleCase } from 'utils/format';
import Packages from 'Onboarding/forms/Packages';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 30,
    height: 16,
    padding: 0,
    marginRight: '.3em'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  checked: {}
}))(Switch);

function PasswordHint({ email, password }) {
  const classes = useStyles();

  return (
    <ul className={classes.root}>
      <li>
        {password.length > 8 ? (
          <CheckCircleIcon fontSize='small' />
        ) : (
          <RadioButtonUncheckedIcon fontSize='small' />
        )}{' '}
        Your password must contain at least 8 characters
      </li>
      <li>
        {!email.includes(password) && password.length !== 0 ? (
          <CheckCircleIcon fontSize='small' />
        ) : (
          <RadioButtonUncheckedIcon fontSize='small' />
        )}{' '}
        Your password can't be too similar to your other personal information
      </li>
      {/* <li>Your password can't be a commonly used password</li> */}
      <li>
        {isNaN(password) ? (
          <CheckCircleIcon fontSize='small' />
        ) : (
          <RadioButtonUncheckedIcon fontSize='small' />
        )}{' '}
        Your password can't be entirely numeric
      </li>
    </ul>
  );
}

const SignUp = () => {
  const { search } = useLocation();
  const { plan, bundle, cycle, country } = qs.parse(search);
  let bundleName = bundle?.includes('_') ? bundle?.replace('_', ' ') : bundle;
  const [billingCycle, setBillingCycle] = useState('Yearly');
  const [numberOfEmployees, setNumberOfEmployees] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [defaultPlan, setDefaultPlan] = useState(
    bundleName ? bundleName : 'BizEdge Suite'
  );
  const [isRecurring, setIsRecurring] = useState(false);
  const { data } = useQuery(['business-plan'], () => getBusinessPlan(country));
  const { data: PaymentReference } = useQuery(
    ['payment-reference'],
    getPaymentReference
  );

  const [finalizing, setFinalizing] = useState(false);
  const [step, setStep] = useState(1);
  let plans = _.filter(data, { name: defaultPlan });
  let planInfo = plan ? _.filter(plans, { public_name: plan }) : '';
  const [business, setBusiness] = useState({ country: toTitleCase(country) });
  const [planCode, setPlanCode] = useState(planInfo[0]);

  const handleChangeTab = (value) => {
    setBillingCycle(value);
    if (value === 'Yearly' && numberOfEmployees) {
      if (bundle !== 'Accounting' || planCode?.name !== 'Accounting') {
        setTotalCost(planCode.pricing.yearly_amount * numberOfEmployees * 12);
      } else {
        setTotalCost(planCode.pricing.yearly_amount * numberOfEmployees);
      }
    } else {
      setTotalCost(planCode.pricing.amount * numberOfEmployees);
    }
  };

  useEffect(() => {
    if (plan && data && country) {
      let planName = plan?.includes('_') ? plan?.replace('_', ' ') : plan;
      let planInfo = _.filter(plans, { public_name: planName });
      setPlanCode(planInfo[0]);
      setBillingCycle(cycle);
    }
  }, [plan, data, defaultPlan]);

  const handlePlanTabChange = (newValue) => {
    setPlanCode(newValue);
    setBillingCycle('Yearly');
    if (bundle !== 'Accounting' || planCode?.name !== 'Accounting') {
      setTotalCost(newValue.pricing.yearly_amount * numberOfEmployees * 12);
    } else {
      setTotalCost(newValue.pricing.yearly_amount * numberOfEmployees);
    }
  };

  const handleNumberOfEmployee = (e) => {
    const { value } = e.target;
    setNumberOfEmployees(value);
    if (billingCycle === 'Yearly' && planCode) {
      let cost = planCode?.pricing?.yearly_amount;
      let monthlyCost = planCode?.pricing?.amount * 12 * value;
      setDiscountedAmount((planCode?.pricing?.discount / 100) * monthlyCost);
      if (bundle !== 'Accounting') {
        // cost = cost * 12;
        setTotalCost(cost * value * 12);
      } else {
        setTotalCost(cost * value);
      }
    } else {
      let cost = planCode?.pricing?.amount;
      setTotalCost(cost * value);
      setDiscountedAmount(0);
    }
  };

  const handleProceed = () => {
    setStep(step + 1);
  };

  // Sign up implementation

  const [email, setEmail] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const [showPassword, setShowpassword] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [serverErrors, setServerErrors] = useState({
    password: ''
  });

  const [addDemoData, setAddDemoData] = useState(false);
  const [businessData, setBusinessData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const initialValues = {
    email: '',
    password: ''
  };

  const hasErrors = (field) => {
    return formik.touched[field] && formik.errors[field];
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setBusiness({
      ...business,
      [name]: value
    });
  };
  let taxAmount = 0.075 * totalCost;
  const config = {
    email,
    amount: (totalCost + taxAmount) * 100,
    currency: 'NGN',
    reference: PaymentReference?.reference,
    channels: ['card'],
    metadata: {
      bizedge_billing_type: 'billing.buy_now_subscription',
      bizedge_business_id: businessData && businessData.business_id,
      num_employees: numberOfEmployees,
      frequency: billingCycle,
      plan_code: planCode?.plan_code,
      actual_amount: totalCost,
      recurring: isRecurring,
      is_demo: addDemoData,
      country: planCode?.pricing?.country,
      currency: 'NGN',

      custom_fields: [
        {
          display_name: 'Business ID',
          variable_name: 'Business ID',
          value: businessData && businessData.business_id
        },
        {
          display_name: 'Business Name',
          variable_name: 'Business Name',
          value: businessData && businessData.name
        }
      ]
    },
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY
  };

  const initializePayment = usePaystackPayment(config);

  const onSuccess = (reference) => {
    if (reference.status === 'success') {
      setIsLoading(true);
      const payload = {
        reference: PaymentReference?.reference
      };
      const confirmPayment = setInterval(async () => {
        const res = await verifyPayment(payload);
        if (res.status) {
          clearInterval(confirmPayment);
          setIsLoading(false);
          setStep(step + 1);
          window.localStorage.setItem(
            'upgrade',
            JSON.stringify({
              toast: true,
              message:
                'Your subscription for Premium Edge was successful. You can now access all the modules.'
            })
          );
        }
      }, 5000);
    }
  };

  const onClose = () => {};

  const handleBusinessCreation = async () => {
    const formData = new FormData();
    formData.append('name', business?.name);
    formData.append('type', 'partnership');
    formData.append('state', business?.state);
    formData.append('phone_number', business?.phone_number);
    formData.append('last_name', business?.last_name);
    formData.append('first_name', business?.first_name);
    formData.append('country', business?.country);
    formData.append('city', business?.city);
    formData.append('address2', business?.address2);
    formData.append('address1', business?.address1);
    setIsLoading(true);
    try {
      const res = await createPremiumBusiness(formData);
      if (res) {
        let formData = new FormData();
        formData.append('task_id', res?.task_id);
        const BusinessCreate = setInterval(async () => {
          const newBusiness = await checkCompanyAddStatus(formData);
          if (newBusiness.status === 'SUCCESS') {
            setIsLoading(false);
            clearInterval(BusinessCreate);
            setBusinessData(newBusiness.data);
            queryClient.refetchQueries(['user']);
            queryClient.refetchQueries(['business-detail']);
            queryClient.refetchQueries(['current-billing']);
            localStorage.setItem(
              'business_domain',
              newBusiness?.data.business_id
            );

            //   if (queryPlan.plan === 'buy_now') {
            //     setIsLoading(false);
            //     setBusinessName(newBusiness?.data?.name);
            //     setBusinessId(newBusiness?.data?.business_id);
            //     setIsBusinessCreated(true);
            //   } else {
            //     setIsLoading(false);
            //     window.location.href = '/dashboard';
            //   }
          }
        }, 5000);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    const business_plan = 'premium';
    const subscription_info = {
      billing_buy_now: true,
      // billing_frequency: billingCycle.toLowerCase(),
      billing_num_employees: numberOfEmployees
    };

    const token = localStorage.getItem('access_token');
    if (token) {
      localStorage.clear();
    }
    const payload = {
      business_plan,
      subscription_info,
      email: values.email,
      password: values.password
    };

    try {
      const response = await signup(payload);
      if (response) {
        setIsComplete(true);
        setEmail(response?.user?.email);
      }
    } catch (error) {
      setServerErrors({ ...serverErrors, ...error });
      setIsComplete(false);
    }
  };

  useEffect(() => {
    if (businessData) {
      initializePayment(onSuccess, onClose);
    }
  }, [businessData]);
  const formik = useFormik({
    initialValues,
    validate: validateSignup,
    onSubmit: handleSubmit
  });
  const [completed, setCompleted] = useState(5);
  useEffect(() => {
    if (isLoading) {
      setInterval(() => {
        setCompleted((prev) => (prev >= 95 ? prev : prev + 5));
      }, 5000);
    }
  }, [isLoading]);
  let actualCost = planCode?.amount * numberOfEmployees * 12;
  const getContent = (active) => {
    switch (active) {
      case 1:
        return (
          <PaymentForm
            {...{
              taxAmount,
              actualCost,
              handleChangeTab,
              handleNumberOfEmployee,
              handlePlanTabChange,
              plans,
              planCode,
              billingCycle,
              totalCost,
              discountedAmount,
              isRecurring,
              setIsRecurring,
              setDefaultPlan,
              setPlanCode,
              numberOfEmployees,
              handleProceed,
              defaultPlan,
              initializePayment
            }}
          />
        );
      case 2:
        return (
          <SignupForm
            {...{
              taxAmount,
              actualCost,
              formik,
              hasErrors,
              showPassword,
              showHint,
              setShowHint,
              setShowpassword,
              serverErrors,
              totalCost,
              discountedAmount,
              planCode,
              billingCycle,
              numberOfEmployees,
              isRecurring,
              business,
              handleChange,
              handleBusinessCreation,
              isComplete,
              addDemoData,
              setAddDemoData,
              isLoading,
              country
            }}
          />
        );
      case 3:
        return <Interlude {...{ handleProceed }} />;
      case 4:
        return <Continue {...{ businessData, setFinalizing }} />;
      default:
        return (
          <PaymentForm
            {...{
              handleChangeTab,
              handleNumberOfEmployee,
              handlePlanTabChange,
              plans,
              planCode,
              billingCycle,
              totalCost,
              discountedAmount,
              isRecurring,
              setIsRecurring,
              setDefaultPlan,
              setPlanCode,
              numberOfEmployees,
              handleProceed,
              defaultPlan
            }}
          />
        );
    }
  };

  if (finalizing) {
    return (
      <Box>
        <Box style={{ width: '60%', margin: '15em auto', textAlign: 'center' }}>
          <img
            src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638526892/Bizedge_Frontend_Assets/BizLoader_dzmb4i.gif'
            style={{ width: '140px', height: '140px', margin: 'auto' }}
            alt=''
          />
          <Typography variant='subtitle1'>
            Setting up your Business Account
          </Typography>
          <Typography variant='subtitle2'>
            This might take up to a minute, Please do not refresh the page or
            close this tab until the login process has completed
          </Typography>
          <Box width='40%' margin='32px auto 0'>
            <ProgressBar
              bgcolor='#2898A4'
              height='6px'
              completed={completed}
              borderRadius={50}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  return <>{getContent(step)}</>;
};

const PaymentForm = (props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100vh'
      }}
    >
      <Box width={'60%'} bgcolor={'#fff'}>
        <img
          src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638786088/Bizedge_Frontend_Assets/Bizedge_logo_axcwlg.svg'
          alt='logo'
          style={{ width: '135px', height: '100px', marginLeft: '12%' }}
          className='logo'
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '443px'
            }}
          >
            <TextField
              select
              color='secondary'
              variant='standard'
              value={props.defaultPlan}
              onChange={(e) => {
                props.setDefaultPlan(e.target.value);
                props.setPlanCode();
              }}
            >
              {['BizEdge Suite', 'People', 'Payroll', 'Accounting'].map(
                (data) => (
                  <MenuItem value={data} key={data}>
                    <Typography
                      sx={{
                        fontSize: '22px',
                        color: '#171717',
                        fontWeight: '600',
                        fontFamily: 'Black Sans'
                      }}
                    >
                      {data}
                    </Typography>
                  </MenuItem>
                )
              )}
            </TextField>
          </Box>

          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
                width: '443px',
                bgcolor: '#FAFAFA',
                height: '47px',
                borderRadius: '42px',
                paddingX: '10px'
              }}
            >
              {_.orderBy(props.plans, ['amount', 'asc'])
                .filter((item) => !item.public_name.includes('free'))
                .map((plan, index) => (
                  <Box
                    key={plan.id}
                    onClick={() => props.handlePlanTabChange(plan)}
                    sx={
                      plan.plan_code === props.planCode?.plan_code
                        ? {
                            bgcolor: '#fce2af',
                            height: '33px',
                            borderRadius: '32px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            width: index === 2 ? '180px' : '90px'
                          }
                        : { cursor: 'pointer' }
                    }
                  >
                    <Typography
                      style={{
                        textTransform: 'capitalize',
                        fontFamily: 'Black Sans',
                        fontWeight: '600'
                      }}
                    >
                      {' '}
                      {plan.public_name.includes('_')
                        ? plan.public_name.replace('_', ' ')
                        : plan.public_name}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </Box>
          <Box
            sx={{
              width: '443px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <label>Preferred billing cycle: </label>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
                width: '385px',
                bgcolor: '#FAFAFA',
                height: '47px',
                borderRadius: '42px',
                justifyContent: 'space-between',
                paddingX: '10px'
              }}
            >
              {['Yearly', 'Monthly'].map((billing, index) => (
                <Box
                  onClick={() => props.handleChangeTab(billing)}
                  key={index}
                  sx={
                    billing === props.billingCycle
                      ? {
                          bgcolor: '#fce2af',
                          height: '33px',
                          borderRadius: '32px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                          gap: '20px',
                          width: index === 0 ? '174px' : '187px'
                        }
                      : {
                          height: '33px',
                          cursor: 'pointer',
                          width: '187px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }
                  }
                >
                  <Typography style={{ fontWeight: 600, fontSize: '14px' }}>
                    {billing}{' '}
                    {billing === 'Yearly' && (
                      <span
                        style={{
                          background: '#ff6666',
                          width: '89px',
                          height: '18px',
                          color: '#fff',
                          borderRadius: '28px',
                          padding: '2px',
                          fontSize: '10px',
                          fontFamily: 'Black Sans'
                        }}
                      >
                        Up to {props.planCode?.pricing?.discount}% Off
                      </span>
                    )}
                  </Typography>
                </Box>
              ))}
              {/* <Tab style={{ minWidth: '20%' }} label='Quarterly' /> */}
            </Box>
          </Box>
          {props.planCode && (
            <Box
              sx={{
                width: '443px',
                height: '39px',
                bgcolor: '#eaf8fa',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '8px 107px 8px 85px',
                gap: '2px'
              }}
            >
              Amount per employee{' '}
              <span
                style={{
                  fontFamily: 'Black Sans',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '18px',
                  lineHeight: '126.86%',
                  color: '#2898A4'
                }}
              >
                {`${props.planCode?.pricing?.currency?.toUpperCase()} ${
                  props.billingCycle === 'Yearly'
                    ? new Intl.NumberFormat().format(
                        props.planCode?.pricing?.yearly_amount
                      )
                    : new Intl.NumberFormat().format(
                        props.planCode?.pricing?.amount
                      )
                }`}
              </span>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '443px'
            }}
          >
            <label htmlFor='numberOfEmployees'>Select no of Employee(s)</label>
            <TextField
              color='secondary'
              variant='outlined'
              type='number'
              name='numberOfEmployees'
              value={props.numberOfEmployees}
              onChange={(e) => props.handleNumberOfEmployee(e)}
              inputProps={{
                style: {
                  height: '6px'
                }
              }}
            />

            {props.numberOfEmployeeError && (
              <Typography>
                Number of employee cannot be less than{' '}
                {props.initalNumOfEmployee}
              </Typography>
            )}
          </Box>
          {props.billingCycle === 'Yearly' && (
            <Box
              sx={{
                height: '56px',
                width: '443px',
                padding: '10.5px 10px 10.5px 12px',
                bgcolor: '#FEF6E6',
                borderRadius: '10px'
              }}
            >
              <CustomText
                fontSize='12px'
                fontFamily='Black Sans'
                color='#545454'
                fontWeight='400'
              >
                For choosing the yearly plan
              </CustomText>
              <CustomText
                fontSize='14px'
                fontFamily='Black Sans'
                color='#946405'
                fontWeight='600'
              >
                You Save{' '}
                <span
                  style={{
                    fontSize: '18px'
                  }}
                >
                  ₦
                  {new Intl.NumberFormat().format(
                    Number(props.actualCost - props.totalCost)
                  )}
                </span>
              </CustomText>
            </Box>
          )}
          {/* <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '443px'
          }}
        >
          <label htmlFor='subTotal'>Subtotal</label>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '443px',
              borderRadius: '10px',
              height: '51px',
              gap: '2px',
              border: '1px solid #e1e1e1',
              padding: '10px'
            }}
          >
            <Typography
              sx={{
                color: '#878787',
                fontWeight: '600',
                fontFamily: 'Black Sans'
              }}
            >
              {props.planCode?.pricing?.currency?.toUpperCase()}
            </Typography>
            <Typography
              sx={{
                color: '#878787',
                fontWeight: '600',
                fontFamily: 'Black Sans'
              }}
            >
              {props.totalCost ?? '0'}
            </Typography>
          </Box>
        </Box> 

        {props.billingCycle === 'Yearly' && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '443px'
            }}
          >
            <label htmlFor='numberOfEmployees'>
              {`Discount amount ${
                props.planCode?.discount !== 0 &&
                props.planCode?.pricing?.discount
                  ? `(${props.planCode?.pricing?.discount}% Off)`
                  : ''
              }`}
            </label>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '443px',
                borderRadius: '10px',
                height: '51px',
                gap: '2px',
                border: '1px solid #e1e1e1',
                padding: '10px'
              }}
            >
              <Typography
                sx={{
                  color: '#878787',
                  fontWeight: '600',
                  fontFamily: 'Black Sans'
                }}
              >
                {props.planCode?.pricing?.currency?.toUpperCase()}
              </Typography>
              <Typography
                sx={{
                  color: '#878787',
                  fontWeight: '600',
                  fontFamily: 'Black Sans'
                }}
              >
                {props.discountedAmount ?? 0}
              </Typography>
            </Box>
          </Box>
        )}*/}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '443px'
            }}
          >
            <label htmlFor='total'>Total (Vat Included)</label>

            <Box
              sx={{
                display: 'flex',
                marginTop: '8px',
                alignItems: 'center',
                width: '443px',
                borderRadius: '10px',
                height: '51px',
                gap: '2px',
                border: '1px solid #e1e1e1',
                padding: '10px',
                bgcolor: '#F2F2F2'
              }}
            >
              <Typography
                sx={{
                  color: '#878787',
                  fontWeight: '600',
                  fontFamily: 'Black Sans'
                }}
              >
                {props.planCode?.pricing?.currency?.toUpperCase()}
              </Typography>
              <Typography
                sx={{
                  color: '#878787',
                  fontWeight: '600',
                  fontFamily: 'Black Sans'
                }}
              >
                {props.totalCost + props.taxAmount ?? 0}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: '443px'
            }}
          >
            <Grid container>
              <Grid item>
                <AntSwitch
                  name='recurring'
                  checked={props.isRecurring}
                  onChange={(e) => props.setIsRecurring(!props.isRecurring)}
                />
              </Grid>
              <Grid item>
                <Typography>Make Payment Recurring Monthly</Typography>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              width: '443px'
            }}
          >
            <SubmitButton
              disabled={!props.numberOfEmployees || props.numberOfEmployeeError}
              style={{ width: '100%', height: '50px' }}
              onClick={props.handleProceed}
            >
              Proceed
            </SubmitButton>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          width: '40%',
          bgcolor: '#D6F2F5',

          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '7em',
          alignItems: 'center',
          padding: '5em 0em'
        }}
      >
        <Box width={'80%'}>
          <img src={BizedgeImage} alt='bizedge' width='100%' />
        </Box>
        <Typography variant='subtitle2'>
          All In One for Ease and Effectiveness
        </Typography>
      </Box>
    </Box>
  );
};

const Interlude = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        background:
          'linear-gradient(180deg, rgba(255, 255, 255, 0) 56.25%, #D6F2F5 100%)'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '21px'
        }}
      >
        <Box
          sx={{
            border: '1.61645px solid #D6F2F5',
            width: '176.42px',
            height: '176.42px',
            borderRadius: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '35px'
          }}
        >
          <Box
            sx={{
              border: '1.61645px solid #D6F2F5',
              width: '105.86px',
              height: '105.86px',
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CheckIcon />
          </Box>
        </Box>

        <Box width='368px'>
          <Typography textAlign={'center'} fontWeight={600} fontSize={'24px'}>
            Your Payment was successful{' '}
          </Typography>
          <Typography textAlign={'center'} fontSize={'16px'} color={'#878787'}>
            Thank you for your payment! To get started, please click on the
            button below
          </Typography>
        </Box>
        <CustomButton
          onClick={props.handleProceed}
          style={{
            width: '301px',
            borderRadius: '10px',
            fontSize: '16px',
            fontWeight: 600
          }}
        >
          Continue to Business Setup
        </CustomButton>
      </Box>
    </div>
  );
};
const SignupForm = (props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100vh'
      }}
    >
      <Box
        sx={{
          width: '60%',
          display: 'flex',
          bgcolor: '#fff',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <img
          src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638786088/Bizedge_Frontend_Assets/Bizedge_logo_axcwlg.svg'
          alt='logo'
          style={{ width: '135px', height: '100px', marginLeft: '12%' }}
          className='logo'
        />
        {!props.isComplete ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '30px',
              height: '80vh'
            }}
          >
            <Box width={'433px'}>
              {' '}
              <Typography
                sx={{
                  fontSize: '26px',
                  lineHeight: '29px',
                  fontFamily: 'Black Sans',
                  fontWeight: '600',
                  color: '#171717'
                }}
              >
                Create your account{' '}
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '146%',
                  fontFamily: 'Black Sans',
                  fontWeight: '400',
                  color: '#545454'
                }}
              >
                Let’s get right into business without delay.{' '}
              </Typography>
            </Box>
            {props.serverErrors.password || props.serverErrors.email ? (
              <Box width='443px'>
                <ServerError>
                  <Typography component='span'>
                    {props.serverErrors.email || props.serverErrors.password}
                  </Typography>
                </ServerError>
              </Box>
            ) : null}
            <form onSubmit={props.formik.handleSubmit}>
              {/* =================== EMAIL ================== */}
              <Box width={'443px'}>
                <TextField
                  color='secondary'
                  error={props.hasErrors('email')}
                  fullWidth={true}
                  label='Email'
                  // size={isWidthDown('lg', width) ? 'small' : 'small'}
                  variant='outlined'
                  placeholder='Email'
                  type='email'
                  name='email'
                  value={props.formik.values.email}
                  onBlur={props.formik.handleBlur}
                  onChange={props.formik.handleChange}
                  haserror={props.hasErrors('email')}
                  FormHelperTextProps={{ error: props.hasErrors('email') }}
                  helperText={
                    props.hasErrors('email') ? props.formik.errors.email : null
                  }
                />
              </Box>

              <Box>
                <Label>
                  <PasswordTooTip
                    open={props.showHint}
                    placement='bottom-start'
                    title={
                      <PasswordHint
                        email={props.formik.values.email}
                        password={props.formik.values.password}
                      />
                    }
                  >
                    {/* =================== PASSWORD ================== */}
                    <TextField
                      color='secondary'
                      error={props.hasErrors('password')}
                      fullWidth={true}
                      label='Password'
                      // size={isWidthDown('lg', width) ? 'small' : 'small'}
                      variant='outlined'
                      placeholder='Password'
                      type={props.showPassword ? 'text' : 'password'}
                      name='password'
                      value={props.formik.values.password}
                      onFocus={() => props.setShowHint(true)}
                      onBlur={(e) => {
                        props.setShowHint(false);
                        return props.formik.handleBlur(e);
                      }}
                      onChange={props.formik.handleChange}
                      haserror={props.hasErrors('password')}
                      FormHelperTextProps={{
                        error: props.hasErrors('password')
                      }}
                      helperText={
                        props.hasErrors('password')
                          ? props.formik.errors.password
                          : null
                      }
                    />
                  </PasswordTooTip>

                  <ShowPassword
                    onClick={() => props.setShowpassword(!props.showPassword)}
                  >
                    {props.showPassword ? 'Hide' : 'Show'}
                  </ShowPassword>
                </Label>
              </Box>

              <SubmitButton
                disabled={
                  !isNaN(props.formik.values.password) ||
                  props.formik.values.password.length < 8 ||
                  props.formik.values.email.includes(
                    props.formik.values.password
                  )
                }
                type='submit'
                onClick={props.formik.handleSubmit}
                startIcon={
                  props.formik.isSubmitting ? (
                    <CircularProgress size={25} style={{ color: 'white' }} />
                  ) : null
                }
              >
                {props.formik.isSubmitting ? null : 'Sign Up'}
              </SubmitButton>
            </form>
            <Box width='443px'>
              By signing up, you are indicating that you have read and agreed to
              the
              {/* <br /> */} <Link to='/terms'>Terms of use</Link> and{' '}
              <Link to='/privacy-policy'>Privacy Policy</Link>
            </Box>
            <Box>
              <Divider />
            </Box>
            <SignUpPrompt>
              Already have an account? <Link to='/'>Sign in</Link>
            </SignUpPrompt>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '30px',
              height: '80vh'
            }}
          >
            <Box width={'443px'}>
              {' '}
              <Typography
                sx={{
                  fontSize: '26px',
                  lineHeight: '29px',
                  fontFamily: 'Black Sans',
                  fontWeight: '600',
                  color: '#171717'
                }}
              >
                Create your business
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '146%',
                  fontFamily: 'Black Sans',
                  fontWeight: '400',
                  color: '#545454'
                }}
              >
                Almost there! Let’s get you set for business
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '443px'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '443px',
                  gap: '20px'
                }}
              >
                <TextField
                  color='secondary'
                  variant='outlined'
                  placeholder='First Name'
                  name='first_name'
                  onChange={props.handleChange}
                  value={props.business.first_name}
                />
                <TextField
                  color='secondary'
                  variant='outlined'
                  placeholder='Last Name'
                  name='last_name'
                  onChange={props.handleChange}
                  value={props.business.last_name}
                />
              </Box>

              <TextField
                color='secondary'
                variant='outlined'
                placeholder='Business Name'
                name='name'
                onChange={props.handleChange}
                value={props.business.name}
              />
              <TextField
                color='secondary'
                variant='outlined'
                placeholder='Phone Number '
                name='phone_number'
                onChange={props.handleChange}
                value={props.business.phone_number}
              />
              <TextField
                color='secondary'
                variant='outlined'
                placeholder='Office Address'
                name='address1'
                onChange={props.handleChange}
                value={props.business.address1}
              />
              <TextField
                color='secondary'
                variant='outlined'
                placeholder='Address 2 (Optional)'
                name='address2'
                onChange={props.handleChange}
                value={props.business.address2}
              />

              <Countries
                label='Country'
                onChange={props.handleChange}
                value={props.business.country}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '443px',
                  gap: '20px'
                }}
              >
                <States
                  size='large'
                  onChange={props.handleChange}
                  value={props.business.state}
                  states={State.getStatesOfCountry(
                    Country.getAllCountries().find(
                      (c) =>
                        c.name.toLowerCase() ===
                        props.business?.country?.toLowerCase()
                    )?.isoCode
                  )}
                />
                <TextField
                  color='secondary'
                  variant='outlined'
                  placeholder='City'
                  name='city'
                  onChange={props.handleChange}
                  value={props.business.city}
                />
              </Box>
              {/* <Box
                sx={{
                  width: '443px'
                }}
              >
                <Grid container>
                  <Grid item>
                    <AntSwitch
                      name='recurring'
                      checked={props.addDemoData}
                      onChange={(e) => props.setAddDemoData(!props.addDemoData)}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>Add demo data</Typography>
                  </Grid>
                </Grid>
              </Box> */}
              <SubmitButton onClick={props.handleBusinessCreation}>
                {props.isLoading ? (
                  <CircularProgress size={25} style={{ color: 'white' }} />
                ) : (
                  'Proceed'
                )}
              </SubmitButton>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          width: '40%',
          bgcolor: '#D6F2F5',

          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '2em',
          alignItems: 'center',
          padding: '5em 0em'
        }}
      >
        <Typography
          sx={{
            fontSize: '22px',
            fontFamily: 'Black Sans',
            fontWeight: '600',
            color: '#171717'
          }}
        >
          Purchase Summary
        </Typography>
        <Box
          sx={{
            width: '60%',
            height: '428px',
            bgcolor: '#fff',
            borderRadius: '16px',
            boxShadow: '0px 7px 20px rgba(40, 41, 61, 0.08)',
            padding: '17px 20px'
          }}
        >
          <Box
            sx={{
              borderBottom: '1px solid #e1e1e1',
              paddingY: '0.7em'
            }}
          >
            <Typography
              sx={{
                color: '#878787',
                fontSize: '14px',
                fontFamily: 'Black Sans'
              }}
            >
              Plan:
            </Typography>
            <Typography
              sx={{
                color: '#171717',
                fontSize: '18px',
                fontFamily: 'Black Sans'
              }}
            >
              {props.planCode?.name}
            </Typography>
          </Box>
          <Box
            sx={{
              borderBottom: '1px solid #e1e1e1',
              paddingY: '0.7em',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box>
              {' '}
              <Typography
                sx={{
                  color: '#878787',
                  fontSize: '14px',
                  fontFamily: 'Black Sans'
                }}
              >
                Billing cycle:
              </Typography>
              <Typography
                sx={{
                  color: '#171717',
                  fontSize: '18px',
                  fontFamily: 'Black Sans'
                }}
              >
                {props.billingCycle}
              </Typography>
            </Box>
            <Box>
              {' '}
              <Typography
                sx={{
                  color: '#878787',
                  fontSize: '14px',
                  fontFamily: 'Black Sans'
                }}
              >
                Amount per employee(s):
              </Typography>
              <Typography
                sx={{
                  color: '#171717',
                  fontSize: '18px',
                  fontFamily: 'Black Sans'
                }}
              >
                {`₦  ${
                  props.billingCycle === 'Yearly'
                    ? props.planCode?.pricing?.yearly_amount
                    : props.planCode?.pricing?.amount
                }`}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              borderBottom: '1px solid #e1e1e1',
              paddingY: '0.7em'
            }}
          >
            <Typography
              sx={{
                color: '#878787',
                fontSize: '14px',
                fontFamily: 'Black Sans'
              }}
            >
              No of Employee(s):
            </Typography>
            <Typography
              sx={{
                color: '#171717',
                fontSize: '18px',
                fontFamily: 'Black Sans'
              }}
            >
              {props.numberOfEmployees} Employees
            </Typography>
          </Box>
          {props.billingCycle === 'Yearly' && (
            <Box
              sx={{
                borderBottom: '1px solid #e1e1e1',
                paddingY: '0.7em'
              }}
            >
              <Typography
                sx={{
                  color: '#878787',
                  fontSize: '14px',
                  fontFamily: 'Black Sans'
                }}
              >
                You Save:
              </Typography>
              <Typography
                sx={{
                  color: '#171717',
                  fontSize: '18px',
                  fontFamily: 'Black Sans'
                }}
              >
                ₦
                {new Intl.NumberFormat().format(
                  Number(props.actualCost - props.totalCost)
                )}
              </Typography>
            </Box>
          )}
          {/* <Box
            sx={{
              borderBottom: '1px solid #e1e1e1',
              paddingY: '0.7em'
            }}
          >
            <Typography
              sx={{
                color: '#878787',
                fontSize: '14px',
                fontFamily: 'Black Sans'
              }}
            >
              Subtotal:
            </Typography>
            <Typography
              sx={{
                color: '#171717',
                fontSize: '18px',
                fontFamily: 'Black Sans'
              }}
            >
              {`${props.planCode?.pricing?.currency?.toUpperCase()} ${
                props.totalCost ?? '0'
              }`}
            </Typography>
          </Box>
          <Box
            sx={{
              borderBottom: '1px solid #e1e1e1',
              paddingY: '0.7em'
            }}
          >
            <Typography
              sx={{
                color: '#878787',
                fontSize: '14px',
                fontFamily: 'Black Sans'
              }}
            >
              Discount ({props.planCode?.discount}%):
            </Typography>
            <Typography
              sx={{
                color: '#171717',
                fontSize: '18px',
                fontFamily: 'Black Sans'
              }}
            >
              {`${props.planCode?.pricing?.currency?.toUpperCase()} ${new Intl.NumberFormat().format(
                props.discountedAmount
              )}`}
            </Typography>
          </Box> */}
          <Box
            sx={{
              borderBottom: '1px solid #e1e1e1',
              paddingY: '0.7em'
            }}
          >
            <Typography
              sx={{
                color: '#878787',
                fontSize: '14px',
                fontFamily: 'Black Sans'
              }}
            >
              Payment type
            </Typography>
            <Typography
              sx={{
                color: '#171717',
                fontSize: '18px',
                fontFamily: 'Black Sans'
              }}
            >
              {props.isRecurring ? 'Recurring payment' : 'Non-recurring'}
            </Typography>
            <Typography
              sx={{
                color: '#878787',
                fontSize: '14px',
                fontFamily: 'Black Sans'
              }}
            >
              {props.isRecurring
                ? 'Billed annually, renews automatically.'
                : 'One-time payment. Renew before the end of your subscription period to keep enjoying services.'}
            </Typography>
          </Box>
        </Box>

        <Box width={'60%'}>
          <Typography
            sx={{
              alignSelf: 'flex-start'
            }}
          >
            Total Amount to be paid (VAT included)
          </Typography>
          <Box
            sx={{
              width: '100%',
              height: '50px',
              bgcolor: '#fff',
              borderRadius: '16px',
              boxShadow: '0px 7px 20px rgba(40, 41, 61, 0.08)',
              padding: '17px 20px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Typography
              sx={{
                color: '#171717',
                fontSize: '18px',
                fontFamily: 'Black Sans',
                fontWeight: 600
              }}
            >
              {`    ₦ ${new Intl.NumberFormat().format(
                props.totalCost + props.taxAmount
              )}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const Continue = (props) => {
  const [stage, setStage] = useState(5);
  const [products, setProducts] = useState([]);
  const initialValues = {
    name: props.businessData?.name ?? '',
    rating: '',
    logo: '',
    avatar: ''
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    values?.logo && formData.append('logo', values?.logo);
    values?.avatar && formData.append('avatar', values?.avatar);
    if (values?.rating) {
      formData.append('rating', values?.rating);
    }

    if (products.length > 0) {
      formData.append('interested_in', products.toString());
    }

    props.setFinalizing(true);

    try {
      const response = await updatePremiumBusiness(formData);
      if (response) {
        props.setFinalizing(false);
        window.location.href = '/dashboard';
      }
    } catch (error) {
      props.setFinalizing(false);
    }
  };
  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit
  });
  const handleContinue = () => {
    if (stage === 7) {
      formik.handleSubmit();
      return;
    }
    setStage((prev) => prev + 1);
  };

  const handleBack = () => {
    if (stage === 5) {
      return;
    }
    setStage((prev) => prev - 1);
  };
  const [imageData, setImageData] = useState({
    fileupload: EmptyState,
    file: ''
  });

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100vh'
      }}
    >
      <Box
        sx={{
          width: '60%',
          display: 'flex',
          bgcolor: '#fff',
          flexDirection: 'column',
          justifyContent: 'space-between',
          marginLeft: '12%'
        }}
      >
        <img
          src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638786088/Bizedge_Frontend_Assets/Bizedge_logo_axcwlg.svg'
          alt='logo'
          style={{ width: '135px', height: '100px' }}
          className='logo'
        />

        <FormDivStarter>
          {stage === 5 && (
            <Box marginBottom={'48px'}>
              <Typography
                sx={{
                  fontFamily: 'Black  Sans',
                  fontWeight: '600',
                  fontSize: '18px',
                  color: '#545454',
                  marginBottom: '25px'
                }}
              >
                Do you have a business logo? <span>(optional)</span>{' '}
              </Typography>
              <BusinessLogo
                formik={formik}
                EmptyState={EmptyState}
                imageData={imageData}
                setImageData={setImageData}
              />
            </Box>
          )}

          {stage === 6 && (
            <Box marginBottom={'48px'} width={'543px'}>
              <Typography
                sx={{
                  fontFamily: 'Black  Sans',
                  fontWeight: '600',
                  fontSize: '18px',
                  color: '#545454'
                }}
              >
                What are you interested in? <span>(optional)</span>
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Black  Sans',
                  fontSize: '14px',
                  color: '#878787',
                  marginBottom: '25px'
                }}
              >
                You can select more than one option.
              </Typography>
              <Packages {...{ products, setProducts }} />
            </Box>
          )}

          {stage === 7 && (
            <Box marginBottom={'48px'} width={'543px'}>
              <Typography
                sx={{
                  fontFamily: 'Black  Sans',
                  fontWeight: '600',
                  fontSize: '18px',
                  color: '#545454',
                  marginBottom: '25px'
                }}
              >
                How did you know about us? <span>(optional)</span>
              </Typography>
              <People isSocials formik={formik} />
            </Box>
          )}

          {stage === 8 && (
            <Box marginBottom={'48px'} width={'548px'}>
              <Title>
                <span>Welcome to the Future of work ,</span>
              </Title>
              <BigTitle>{formik.values.name}</BigTitle>
              <RateContainer>
                <div className='rate'>
                  How would you rate your overall onboarding experience?
                </div>
                <Box display='flex' style={{ gap: 11 }} flexWrap='wrap'>
                  {ratings.map((item) => (
                    <WhiteChipText
                      active={formik.values.rating === item.value}
                      onClick={(e) => {
                        formik.setFieldValue('rating', item.value);
                      }}
                    >
                      {item.title}
                    </WhiteChipText>
                  ))}
                </Box>
              </RateContainer>
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              gap: '185px',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '15px'
              }}
            >
              <BackBtn onClick={() => handleBack()}>Back</BackBtn>
              <ContinueBtn variant='outlined' onClick={() => handleContinue()}>
                {stage === 7 ? 'Let’s go!!!' : 'Continue'}
              </ContinueBtn>{' '}
            </Box>
            <Typography
              sx={{
                fontFamily: 'Black  Sans',
                fontWeight: '600',
                fontSize: '16px',
                color: '#545454',
                marginBottom: '25px'
              }}
            >
              <span style={{ color: '#2898A4' }}>{stage}</span> of 9
            </Typography>
          </Box>
        </FormDivStarter>
      </Box>

      <Box
        sx={{
          width: '40%',
          bgcolor: '#D6F2F5',

          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '7em',
          alignItems: 'center',
          padding: '5em 0em'
        }}
      >
        <Box width={'80%'}>
          <img src={BizedgeImage} alt='bizedge' width='100%' />
        </Box>
        <Typography variant='subtitle2'>
          All In One for Ease and Effectiveness
        </Typography>
      </Box>
    </Box>
  );
};

export default SignUp;
const ratings = [
  {
    title: 'Not Good',
    value: 'not_good'
  },
  {
    title: 'Fairly Good',
    value: 'fairly_good'
  },
  {
    title: 'Neutral',
    value: 'neutral'
  },
  {
    title: 'Great',
    value: 'great'
  },
  {
    title: 'Very Great',
    value: 'very_great'
  }
];
