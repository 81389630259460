import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Prompt } from './styled.modals';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMutation, useQueryClient } from 'react-query';
import { bulkDeleteVehicles } from '../../api/fleet';
import useFleetControl from '../../hooks/useFleetControl';

function ConfirmMultipleVehiclesDelete() {
  const mutation = useMutation(bulkDeleteVehicles);
  const [fleetState, setFleetState] = useFleetControl();
  const queryClient = useQueryClient();

  const handleDelete = () => {
    mutation.mutateAsync(fleetState.selectedVehicles).then(() => {
      setFleetState({ ...fleetState, selectedVehicles: [], modal: '' });
      queryClient.refetchQueries(['vehicles']);
      queryClient.refetchQueries(['analytics:vehicles-summary']);
      queryClient.refetchQueries(['analytics:vehicle-status']);
    });
  };

  const handleClose = () => setFleetState({ ...fleetState, modal: '' });

  const open = fleetState.modal === 'ConfirmMultipleVehiclesDelete';
  const vehiclesCount = fleetState.selectedVehicles.length;

  return (
    <Modal
      open={open}
      onBackdropClick={handleClose}
      style={{ textAlign: 'center' }}
    >
      <Prompt>
        <Typography variant='h2'>Delete vehicle</Typography>
        <Typography variant='subtitle1'>
          Are you sure you want to delete{' '}
          {vehiclesCount > 1 ? 'these vehicles?' : 'this vehicle?'}
        </Typography>

        <Box>
          <Button
            variant='outlined'
            onClick={handleClose}
            disabled={mutation.isLoading}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            type='submit'
            onClick={handleDelete}
            disabled={mutation.isLoading}
            startIcon={
              mutation.isLoading ? (
                <CircularProgress size={20} style={{ color: 'white' }} />
              ) : null
            }
          >
            {mutation.isLoading ? null : 'Delete'}
          </Button>
        </Box>
      </Prompt>
    </Modal>
  );
}

export default ConfirmMultipleVehiclesDelete;
