import { Box, Typography, Checkbox, Button, Tooltip } from '@material-ui/core';
import styled from 'styled-components';

export const FormDiv = styled(Box)`
  ${({ theme, scroll }) => `
    box-sizing: border-box; 
    // height: 400px;
    // max-height: 77vh;
    background: ${theme.palette.backgrounds.main};
    // padding: ${scroll ? '4% 2.5% 4% 5%' : '8% 8% 4%'};
    padding: 1em 2em;
    // margin-right: 4rem;
    // margin-top: 14%;
    // max-width: 55%;
    width: 40%;
    max-width: 500px;
    height: 60%;
    max-height: 600px;
    margin: 5em auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    @media (max-width: 640px) {
      width: 100%;
      box-shadow: none;
      border-radius; none;
      background: linear-gradient(
      to top,
      rgba(230, 243, 244, 0) 85%,
      #cbe8eb 93.75%
    );
      // margin: 4em auto;
      // padding: 1em 1.2em;
    }
    ${theme.breakpoints.down('lg')} {
      // width: 55%;
    }
  `}
  input {
    /* height: 25px; */
  }
  label {
    /* margin: .2rem 0; */
  }
  .reset-password {
    @media (max-width: 640px) {
      position: fixed !important;
      bottom: 70px !important;
      width: 80% !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }
  }
  .back {
    cursor: pointer;
  }
`;

export const FormDivAuth = styled(Box)`
  ${({ theme, scroll }) => `
    // background-color: white;
    // width: 45%;
    // max-width: 500px;
    // margin: 5em auto;
    // padding: 0em 2em;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    // border-radius: 20px;
    // height: 70%;
    // max-height: 650px;
    background: white;
    width: 40%;
    max-width: 500px;
    // margin: 2em auto 5em; 
    height: auto;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
    border-radius: 20px;
    // position: absolute; 
    // border: 2px solid red; 
    // display: flex; 
    // flex-direction: column; 
    // justify-content: center; 
    margin: auto;
    padding: 1em 3em;
    @media (max-width: 640px) {
      width: 100%;
      // margin: 4em auto;
      padding: 1em 1.2em;
    }
    ${theme.breakpoints.down('lg')} {
      // width: 55%;
    }
  `}
  input {
    /* height: 25px; */
  }
  label {
    /* margin: .2rem 0; */
  }
`;

export const FormDivAuthSignup = styled(Box)`
  ${({ theme, scroll }) => `
    // background-color: white;
    // width: 35%;
    // max-width: 500px;
    // margin: 5em auto;
    // padding: 0em 2em;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    // border-radius: 20px;
    // height: 75%;
    // max-height: 700px;
    background: white;
    width: 80%;
    max-width: 800px;
    // margin: 2em auto 5em; 
    height: 500px;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
    border-radius: 20px;
    // position: absolute; 
    // border: 2px solid red; 
    // display: flex; 
    // flex-direction: column; 
    // justify-content: center; 
    margin: auto;
    // padding: 1em 3em;
    
    @media (max-width: 640px) {
      width: 100%;
      height: 700px;
      margin: 2em auto;
      // margin: 4em auto;
      // padding: 1em 1.2em;
      overflow: unset;
    }
    ${theme.breakpoints.down('lg')} {
      // width: 55%;
    }
  `}
  input {
    /* height: 25px; */
  }
  label {
    /* margin: .2rem 0; */
  }
`;

export const FormDivContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .form {
    width: 45%;
    margin: 5em auto 0px;
  }
  .detail {
    width: 50%;
    padding: 4em 1.5em;
    height: 600px;
    background: rgba(196, 196, 196, 0.12);
  }
  li {
    color: #545454;
    padding: 0.6em 0px;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
  }
  .amount {
    color: red;
    font-size: 20px;
    color: #545454;
    font-weight: 700;
    font-style: normal;
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
  @media (max-width: 640px) {
    flex-direction: column-reverse;
    .detail {
      height: auto;
      width: 100%;
      padding: 2em;
    }
    .form {
      width: 100%;
      padding: 0px 1em;
      margin-top: 1.5em;
    }
  }
`;

export const AuthFormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: row-reverse;
  height: 100vh;
  @media (max-width: 640px) {
    display: block;
    /* flex-direction: column-reverse;
    justify-content: space-around; */
    padding: 0px;
  }
  .download {
    display: none;
    /* @media (max-width: 640px) {
      display: block;
      width: 80%;
      margin: 2em auto 0px;
      text-align: center;
      color: #545454;
      .downloadCopy {
        font-weight: 600;
      }
      img {
        width: 120px;
        height: 120px;
      }
    } */
  }
  .c-sideLeft {
    background: #eaf8fa;
    // height: 100vh;
    width: 40%;
    border-right: 10px solid #d6f2f5;
    @media (max-width: 640px) {
      display: none !important;
      width: 100%;
      height: 60vh;
      border-top: 10px solid #d6f2f5;
      border-right: unset;
      margin-top: 5em;
    }
    .info {
      width: 80%;
      text-align: center;
      @media (max-width: 640px) {
        width: 95%;
      }
      img {
        width: 100%;
      }
    }
    .container {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 5em 0px;
      .logo {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        @media (max-width: 640px) {
          display: none;
        }
      }
      .intro {
        text-align: center;
        color: #545454;
        font-size: 14px;
        padding-top: 1.5em;
        @media (max-width: 640px) {
          display: none;
        }
      }
      .subtitle {
        display: none;
        @media (max-width: 640px) {
          display: block;
          text-align: center;
          width: 60%;
          margin: 2em auto;
          color: #545454;
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }
  .c-sideRight {
    width: 60%;
    @media (max-width: 640px) {
      width: 100%;
    }
    .c-sideRight-form {
      width: 60%;
      margin: auto;
      .back {
        cursor: pointer;
        margin-bottom: 1em;
      }
      .welcome {
        color: #545454;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 0.5em;
        @media (max-width: 640px) {
          text-align: left;
          padding-bottom: 0.4em;
        }
      }
      .future {
        display: none;
        color: #545454;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 1.5em;
        font-family: 'Black Sans';
        @media (max-width: 640px) {
          display: block;
          text-align: left;
          padding-bottom: 0.4em;
          font-weight: 400;
          font-size: 14px;
        }
      }
      .billing {
        color: #545454;
        font-size: 14px;
        /* font-weight: 400; */
        padding-top: 1.2em;
        padding-bottom: 0.3em;
      }
      .other {
        color: #545454;
        font-size: 16px;
        font-weight: 400;
        padding-bottom: 1.2em;
        @media (max-width: 640px) {
          text-align: center;
        }
      }
      @media (max-width: 640px) {
        width: 90%;
        /* margin-top: 25em; */
        margin-top: 4em;
      }
      .paymentPreview {
        margin: 2em 0px;
        .MuiTypography-subtitle2 {
          color: #878787;
          font-weight: 400;
          font-size: 14px;
        }
        .payment {
          border: 1px solid #2898a4;
          background: #eaf8fa;
          border-radius: 5px;
          padding: 10.5px 10px 10.5px 20px;
          margin-top: 0.6em;
          p {
            color: #545454;
            font-weight: 600;
            font-style: normal;
            font-size: 24px;
          }
        }
      }
    }
    .c-sideRight-form-login {
      width: 70%;
      margin: auto;
      .back {
        cursor: pointer;
        margin-bottom: 1em;
      }
      .welcome {
        color: #545454;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 0.5em;
        @media (max-width: 640px) {
          text-align: left;
          padding-bottom: 0.4em;
        }
      }

      .billing {
        color: #545454;
        font-size: 14px;
        /* font-weight: 400; */
        padding-top: 1.2em;
        padding-bottom: 0.3em;
      }
      .other {
        color: #545454;
        font-size: 16px;
        font-weight: 400;
        padding-bottom: 1.2em;
        @media (max-width: 640px) {
          text-align: center;
        }
      }
      @media (max-width: 640px) {
        width: 90%;
        /* margin-top: 35em; */
        margin-top: 4em;
      }
      .paymentPreview {
        margin: 2em 0px;
        .MuiTypography-subtitle2 {
          color: #878787;
          font-weight: 400;
          font-size: 14px;
        }
        .payment {
          border: 1px solid #2898a4;
          background: #eaf8fa;
          border-radius: 5px;
          padding: 10.5px 10px 10.5px 20px;
          margin-top: 0.6em;
          p {
            color: #545454;
            font-weight: 600;
            font-style: normal;
            font-size: 24px;
          }
        }
      }
    }
  }
`;

export const FormDivStarter = styled(Box)`
  ${({ stage }) =>
    stage === 8 &&
    `
    @media (max-width: 640px) {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(230, 243, 244, 0) 37.5%, #CBE8EB 100%);
   
    }
  `}
  ${({ theme, scroll }) => `
    background: white;
    width: 100%;
    height: auto;
    // box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
    border-radius: 20px;
    margin: auto;
    padding: 1em 0px;
    @media (max-width: 640px) {
      width: 100%;
      // margin: 4em auto;
      padding: 1em 1.2em;
    }
    ${theme.breakpoints.down('lg')} {
      // width: 55%;
    }
  `}
  input {
    /* height: 25px; */
  }
  label {
    /* margin: .2rem 0; */
  }
  .create-business-menu {
    display: none;
    @media (max-width: 640px) {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 47px;
      .create {
        display: block !important;
        font-weight: 600;
        font-family: 'Black Sans';
        font-size: 24px;
        line-height: 138.55%;
        color: #a8a8a8;
        margin-bottom: 47px;
        @media (max-width: 640px) {
          font-size: 20px;
          margin-bottom: 0;
          color: #171717;
        }
      }
      .back-arrow {
        cursor: pointer;
      }
      .close {
        color: #171717 !important;
        font-family: 'Black Sans';
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
  .img-container {
    display: none;
    @media (max-width: 640px) {
      display: flex;
      justify-content: center;
      margin-bottom: 3rem;
    }
  }

  /* .create {
    display: block !important;
    font-weight: 600;
    font-size: 24px;
    line-height: 138.55%;
    color: #a8a8a8;
    margin-bottom: 47px;
    @media (max-width: 640px) {
      font-size: 20px;
    }
  } */

  .welcome-page {
    @media (max-width: 640px) {
      background: linear-gradient(
        180deg,
        rgba(230, 243, 244, 0) 37.5%,
        #cbe8eb 100%
      );
    }
  }
`;

export const FormHeader = styled(Typography)`
  ${({ theme }) => `
    font-family: 'Black Sans';
    font-size: ${theme.palette.dark.black};
    font-size: 45px;
    font-weight: 700;
    line-height: 53px;
    margin-bottom: 1rem;
    margin-top: 1em;
    text-align: center;
    ${theme.mixins.makeResponsive({ theme, fontSize: 40, lineHeight: 47 })};
    @media (max-width: 640px){
      text-align: left;
      font-weight: 600;
      font-size: 26px;
    }
  `}
`;

export const FormHeaderReset = styled(Typography)`
  ${({ theme }) => `
    font-family: 'Black Sans';
    font-size: ${theme.palette.dark.black};
    font-size: 45px;
    font-weight: 700;
    line-height: 53px;
    margin-bottom: .6em;
    margin-top: 2em;
    text-align: center;
    ${theme.mixins.makeResponsive({ theme, fontSize: 40, lineHeight: 47 })};
  `}
`;

export const FormGroup = styled(Box)`
  ${({ theme }) => `
      // height: 100%;
      height: auto;
      // overflow-x: hidden;
      // overflow-y: scroll;
      // padding-right: 40px;
      position: relative;
      ::-webkit-scrollbar {
        width: 10px;
      }
      ::-webkit-scrollbar-track {
        background: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background: #F0F0F0;
        border-radius: 40px;
      }
      & > div {
        margin : .5rem 0 !important;
      }
    `}
`;

export const FormDescription = styled(Typography)`
  ${({ theme }) => `
    font-family: 'Black Sans';
    color: ${theme.palette.dark.main};
    font-weight: 400;
    width: 90%;
    margin-bottom: 2em;
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 14px;
    @media (max-width: 640px){
      text-align: left;
      width: 100%;
    }
  `}
`;

export const SplitInputDiv = styled(Box)`
  ${({ theme }) => `
    display: flex;
    justify-content: space-between;
    margin: 0;
    & > * {
      width: 48%;
      max-width: 48%;
      margin: .3rem 0;
    }
    & > :first-child {
      margin-right: 4%;
    }
    .MuiTextField-root{
      margin : 0 !important;
    }
    @media (max-width:640px){
      flex-direction: column;
      width: 100% !important;
      & > * {
      width: 100% !important;
      max-width: 100% !important;   
      }
      & > :first-child {
      margin-bottom: 20px;
    }
    }
  `}
`;

export const Label = styled.label`
  position: relative;
  padding: 0;
`;

export const Input = styled.input`
  ${({ haserror }) => `
    width: 100%;
    border: 1px solid ${haserror ? '#FF7372' : '#dedede'};
    border-radius: 5px;
    margin: 1.7% auto;
    padding: 3.2%;
    outline: none;
    font-size: 14px;
    &::placeholder {
      color: #a8a8a8;
      font-size: 16px;
     padding : 1rem 0;
    }
    &[type='password'] {
      padding-right: 10%;
    }
    @media only screen and (min-width: 1280px) {
      font-size: 12px;
      &::placeholder {
        color: #a8a8a8;
        font-size: 12px;
      }
    }
  `}
`;

export const ShowPassword = styled(Button)`
  ${({ theme }) => `
    font-family: 'Black Sans';
    text-transform: none;
    position: absolute;
    top: 60%;
    right: 1%;
    transform: translateY(0%);
    color: #A8A8A8;
    font-size: 12px;
    @media only screen and (min-width: 1920px) {
      position: absolute;
      top: 50%;
      right: 1%;
      // top: 130%;
    }
  `}
`;

export const ErrorMessage = styled(Typography)`
  ${({ theme }) => `
    color: ${theme.palette.primary.main};
    ${theme.mixins.makeResponsive({ theme, fontSize: 14 })};
    font-family: 'Black Sans';
    margin-top: -10px;
    ${theme.breakpoints.down('lg')} {
      margin-top: 5px;
    }
  `}
`;

export const RememberSection = styled(Box)`
  ${({ theme }) => `
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
    color: #a8a8a8;
    ${theme.mixins.makeResponsive({ theme, fontSize: 16 })};
    a {
      color: ${theme.palette.secondary.main};
      ${theme.mixins.makeResponsive({ theme, fontSize: 16 })};
      text-transform: capitalize;
    }
  `}
`;

const RememberMeCheckbox = styled(Checkbox)`
  ${({ theme }) => `
    margin-right: 13px;
    padding: 0;
    color: #E5E5E5;
  `}
`;

export const RememberMe = () => (
  <Box>
    <RememberMeCheckbox />
    Remember me
  </Box>
);

export const SubmitButton = styled(Button)`
  ${({ theme }) => `
    width: 100%;
    background: ${theme.palette.secondary.main};
    text-transform: none;
    font-weight: 700;
    color: white !important;
    border-radius: 5px;
    margin: 15px 0;
    padding: 2%;
    margin-top: 1.5em;
    ${theme.mixins.makeResponsive({ theme, fontSize: 20 })};
    &:hover {
      background: ${theme.palette.secondary.main};
    }
    
  `}
`;

export const SignUpPrompt = styled(Typography)`
  ${({ theme }) => `
      color: ${theme.palette.dark.main};
      font-family: 'Black Sans';
      font-weight: 400;
      font-style: normal;
      text-align: center;
      font-size: 16px !important;
      // margin-top: 2%;
      // margin-bottom : 8rem;
      margin-bottom: 3%;
      // ${theme.mixins.makeResponsive({ theme, fontSize: 20 })};
      & a {
        text-transform: capitalize;
        text-decoration: none;
        color: ${theme.palette.secondary.main};
        font-weight:bold;
      }
      @media (max-width: 640px) {
    margin-top: 15rem !important;
    
  }
    `}
`;

export const PolicyDiv = styled(Box)`
  ${({ theme }) => `
      color: ${theme.palette.dark.main};
      font-family: 'Black Sans';
      font-weight: 400;
      font-style: normal;
      font-size: 14px !important;
      line-height: 21.89px;
      // ${theme.mixins.makeResponsive({ theme, fontSize: 18 })};
      text-align: center;
      // margin: auto;
      margin-bottom: 3%;
      margin-top: 3%;
      .link{
        text-decoration: none;
      }
      
      & a {
        text-transform: capitalize;
        text-decoration: underline;
        color: ${theme.palette.secondary.main};
      }
    `}
`;

export const ForgotPassword = styled(Box)`
  ${({ theme }) => `
      text-align: center;
      font-family: 'Black Sans';
      font-weight: 600;
      // ${theme.mixins.makeResponsive({ theme, fontSize: 16 })};
      // margin-top: 3%;
      // margin-bottom : 8rem;
      a {
        color: ${theme.palette.secondary.main};
        font-size: 12px;
        
      }
  `}
`;

export const ResendConfirmation = styled(Box)`
  ${({ theme }) => `
      text-align: center;
      font-family: 'Black Sans';
      ${theme.mixins.makeResponsive({ theme, fontSize: 16 })};
      // margin-top: 3%;
      // margin-bottom : 8rem;
      a {
        color: #545454;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
          color: ${theme.palette.secondary.main};
        }
      }
  `}
`;

export const ServerError = styled(Box)`
  ${({ theme }) => `
    background: #FFEBEA;
    width: 80%;
    border-radius: 3px;
    padding: 1% 2%;
    margin: 0px auto 10px;
    text-align: center;
    &  > span {
      ${theme.mixins.makeResponsive({ theme, fontSize: 15 })};
      color: ${theme.palette.primary.main};
      opacity: 1;
    }
    & .MuiTypography-subtitle2 {
      ${theme.mixins.makeResponsive({ theme, fontSize: 14 })};
      color: ${theme.palette.primary.main};
      opacity: 1;
    }
  `}
`;

export const SessionExpired = styled(Box)`
  .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 12px;
    color: #545454;
    margin-bottom: 7px;
  }
  .sub-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #545454;
    margin-bottom: 10px;
  }
`;

export const PasswordTooTip = styled((props) => (
  <Tooltip
    classes={{ popper: props.className, tooltip: 'tooltip' }}
    {...props}
  />
))`
  ${({ theme }) => `
    & .tooltip {
      margin-top: 1%;
      background: #e0eef0;
      color: ${theme.palette.secondary.main};
      & > ul {
        ${theme.mixins.makeResponsive({ theme, fontSize: 14 })};
        padding: 0 20px;
        li {
          margin: 3% 0;
          // color: red;
          list-style: none;
        }
      }
    }
  `}
`;

export const Title = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #545454;
  margin-bottom: 16px;
  span {
    font-weight: 400;
    font-size: 14px;
  }
`;

export const SubText = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  line-height: 128.86%;
  color: #878787;
  margin-top: 9px;
`;

export const ContinueBtn = styled(Button)`
  font-size: 16px;
  line-height: 148.19%;
  height: 44px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '161px')};
  background: #2898a4;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 148.19%;
  color: #ffffff !important;
  &:hover {
    background-color: rgb(28, 106, 114);
  }
  @media (max-width: 640px) {
    position: fixed;
    bottom: 70px;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const BackBtn = styled(Button)`
  font-weight: 600;
  font-size: ${({ fs }) => (fs ? fs : '16px')};
  line-height: 148.19%;
  color: #2898a4;
  background: #eaf8fa;
  border-radius: 10px;
  height: 44px;
  margin-right: 13px;
  width: ${({ fullWidth, width }) =>
    width ? width : fullWidth ? '100%' : '161px'};
  &:disabled {
    cursor: not-allowed !important;
  }
  &.MuiButtonBase-root.Mui-disabled {
    pointer-events: all;
  }
  &:hover {
    background: #eaf8fa;
  }
  &.MuiButton-root:hover.Mui-disabled {
    background-color: #eaf8fa;
  }
  @media (max-width: 640px) {
    display: none;
  }
`;

export const ButtonWrapper = styled(Box)`
  display: flex;
  gap: 13px;
  align-items: center;
  justify-content: space-between;
  .stage {
    font-weight: 600;
    font-size: 16px;
    line-height: 148.19%;

    color: #878787;
    span {
      color: #2898a4;
    }
    @media (max-width: 640px) {
      display: none;
    }
  }
`;

export const ChipText = styled(Typography)`
  padding: 10px 19px;
  font-weight: 600;
  font-size: 18px;
  line-height: 128.86%;
  color: ${({ active }) => (active ? '#2898A4' : '#878787')};
  background: ${({ active }) => (active ? '#EAF8FA' : '#f2f2f2')};
  border-radius: 10px;
  cursor: pointer;
`;
export const BigTitle = styled(Typography)`
  font-weight: 700;
  font-size: 45px;
  line-height: 51px;
  color: #171717;
  @media (max-width: 640px) {
    margin: 12px 0;
  }
`;

export const RateContainer = styled.div`
  background: #f2f2f2;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  padding: 18px;
  margin-top: 23px;
  .rate {
    font-weight: 400;
    font-size: 14px;
    line-height: 138.86%;
    color: #171717;
    margin-bottom: 15px;
  }
  @media (max-width: 640px) {
    display: none;
  }
`;

export const WhiteChipText = styled(Typography)`
  padding: 10px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 128.86%;
  color: ${({ active }) => (active ? '#2898A4' : '#545454')};
  background: ${({ active }) => (active ? '#EAF8FA' : 'white')};
  border-radius: 10px;
  cursor: pointer;
`;
export const DividerHide = styled(Box)`
  @media (max-width: 640px) {
    display: none !important;
  }
`;
export const RateSubText = styled(Typography)`
  color: #151515;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Black Sans';
`;
export const MobileLogo = styled.img`
  /* justify-self: center; */
  align-self: center !important;
`;
export const EmailConfirmButton = styled(Button)`
  position: fixed !important;
  bottom: 70px !important;
  width: 80% !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
`;
export const CustomBox = styled(Box)`
  border-radius: 23px;
  text-align: center;
  padding: 1px;
  @media (min-width: 640px) {
    padding: 2em 3em;
    border: 1px solid #e1e1e1;
  }
`;
export const SignUpContainer = styled.div`
  @media (max-width) {
    background: linear-gradient(
      to top,
      rgba(230, 243, 244, 0) 85%,
      #cbe8eb 93.75%
    );
  }
`;

export const RequestDownloadContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  border-radius: 27px;
  align-items: center;
  background-color: #ffffff;
  z-index: 5;
  width: 80%;
  padding: 33px 10px;
  gap: 1.5rem;
  .sub-text {
    color: #2898a4;
    text-align: center;
    font-family: 'Black Sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 141.68%;
  }
  .stores {
    width: 150px;
    height: 40px;
    cursor: pointer;
  }
  .redirect-button {
    font-family: 'Black Sans';
    border: 1px solid #2898a4;
  }
`;
