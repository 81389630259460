import { createContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getBusinessDetail } from '../api/settings';

export const BusinessDetailContext = createContext();

export const BusinessIdContext = createContext();

// const BusinessIdProvider = () => {
//   return <BusinessIdContext.Provider></BusinessIdContext.Provider>;
// };

const BusinessDetailProvider = ({ children }) => {
  let token = localStorage.getItem('access_token');
  const { data, isSuccess } = useQuery(['business-detail'], getBusinessDetail, {
    enabled: !!token
  });
  const queryClient = useQueryClient();

  return (
    <BusinessDetailContext.Provider
      value={{ data: data, isSuccess: isSuccess, queryClient: queryClient }}
    >
      {children}
    </BusinessDetailContext.Provider>
  );
};

export default BusinessDetailProvider;
