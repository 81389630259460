export const assetCsvHeader = [
  'S/N',
  'Asset or Vehicle',
  'Type',
  'Asset Name',
  'Make or Brand',
  'Model',
  'License/Serial No',
  'Asset ID',
  'Status',
  'Condition',
  'Milage',
  'Location',
  'Purchase Date',
  'Cost (NGN)'
];

export const assetTableHeader = [
  'S/N',
  'Asset or Vehicle',
  'Type',
  'Asset Name',
  'Make or Brand',
  'Model',
  'License/Serial No',
  'Status',
  'Condition',
  'Milage',
  'Purchase Date',
  'Cost (NGN)'
];

export function generateCSV() {
  const data = [];
  var csvContent = assetTableHeader.join(',') + '\n';

  data.forEach(function (row) {
    csvContent += row.join(',') + '\n';
  });

  var encodedUri = encodeURI('data:text/csv;charset=utf-8,' + csvContent);
  var link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `Asset Import data ${new Date()}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const headerBaseOnIndex = [
  'row_num',
  'asset_or_vehicle',
  'asset_or_vehicle_type',
  'make',
  'model',
  'name',
  'serial_no',
  'plate_no',
  'status',
  'asset_condition',
  'mileage',
  'purchase_date',
  'cost'
];

const addIfHeader = (header, obj, corrHeader) => {
  return obj[header] ? { [corrHeader]: obj[header] } : { [corrHeader]: '' };
};

export function convertCSVtoJSON(file, { setData, setHeader }) {
  var reader = new FileReader();
  reader.onload = async function (e) {
    var csvData = e.target.result;

    var lines = csvData.split('\n');
    if (setHeader) {
      setHeader(assetTableHeader);
    }
    var jsonData = [];

    for (var i = 1; i < lines.length; i++) {
      var currentLine = lines[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);

      if (!lines[i].length) {
        continue;
      }
      var obj = {};

      for (var j = 0; j < assetTableHeader?.length; j++) {
        const currentCell = currentLine[j];
        const currCellHeader = assetTableHeader[j]?.trim();

        if (currCellHeader === 'Cost (NGN)') {
          const formatedCost = currentCell?.replace(/"/g, '');
          obj[currCellHeader] = currentCell
            ? Number(formatedCost?.replace(/,/g, ''))
            : '';
        } else {
          obj[currCellHeader] = currentCell;
        }
      }

      jsonData.push(obj);
    }

    if (setData) {
      const newJsonData = jsonData.map((data) => {
        return {
          ...addIfHeader('S/N', data, 'row_num'),
          ...addIfHeader('Asset or Vehicle', data, 'asset_or_vehicle'),
          ...addIfHeader('Type', data, 'asset_or_vehicle_type'),
          ...addIfHeader('Asset Name', data, 'name'),
          ...addIfHeader('Make or Brand', data, 'make'),
          ...addIfHeader('Model', data, 'model'),
          ...addIfHeader('License/Serial No', data, 'serial_no'),
          ...addIfHeader('Status', data, 'status'),
          ...addIfHeader('Condition', data, 'asset_condition'),
          ...addIfHeader('Milage', data, 'milage'),
          ...addIfHeader('Purchase Date', data, 'purchase_date'),
          ...addIfHeader('Cost (NGN)', data, 'cost')
        };
      });

      setData(newJsonData);
    }
    return jsonData;
  };

  reader.readAsText(file);
}
