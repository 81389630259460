import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    '& #mui-component-select-reminder': {
      fontSize: '1rem !important'
    }
  }
});

function SetReminder({ width, name, value, onChange, OnBlur, error, label }) {
  const classes = useStyles();
  const types = [
    {
      value: 30,
      display_name: '4 weeks'
    },
    {
      value: 21,
      display_name: '3 weeks'
    },
    {
      value: 14,
      display_name: '2 weeks'
    },
    {
      value: 7,
      display_name: '1 week'
    }
  ];
  return (
    <FormControl
      variant='outlined'
      size={isWidthDown('lg', width) ? 'small' : 'medium'}
      color='secondary'
      fullWidth
      error={error}
      label='Set Reminder'
    >
      <InputLabel id='document-types'>{label}</InputLabel>
      <Select
        labelId='document-types'
        label='Set Reminder'
        // size='small'
        name={name}
        value={value}
        onChange={onChange}
        onBlur={OnBlur}
        className={classes.root}
      >
        {types.map((type) => (
          <MenuItem value={type.value}>{type.display_name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default withWidth()(SetReminder);
