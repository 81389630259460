import { useFetchAssetTypes } from 'Assets/queries';
import Loader from 'components/Loader/Loader';
import useDebouceSearch from 'hooks/useDebouceSearch';
import useDisclosure from 'hooks/useDisclosure';
import { useState } from 'react';
import AddAssetTypeDialog from '../Modals/AddAssetTypeModal';
import NoData from 'Account/Resuseable/NoData';
import Header from './Header';
import AssetTypeList from './AssetTypeList';
import { AssetTypeSC } from './AssetTypeSC';

const AssetType = () => {
  const [search, setSearch] = useDebouceSearch(1000);
  const [toEdit, setToEdit] = useState(null);
  const [open, { toggle }] = useDisclosure();
  const [type, setType] = useState(null);

  const { data, isLoading, refetch } = useFetchAssetTypes({
    search,
    vehicle_type_param: type
  });

  const stats =
    data?.map((el) => ({
      ...el,
      title: el.name,
      value: el.asset_count
    })) || [];

  const headerProps = {
    setType,
    type,
    setSearch,
    toggle
  };

  return (
    <AssetTypeSC>
      <Header {...headerProps} />

      {isLoading ? (
        <Loader />
      ) : stats?.length < 1 ? (
        <NoData
          htext={search ? `${'No result'}` : 'You Have no asset type'}
          ptext={
            search
              ? `Asset type with name "${search}" does not Exist `
              : 'Click below to Create Asset Types'
          }
          btext={'Click to add asset type'}
          btnAction={toggle}
        />
      ) : (
        <AssetTypeList types={stats} setToEdit={setToEdit} toggle={toggle} />
      )}

      {open ? (
        <AddAssetTypeDialog
          open={open}
          toggle={() => {
            toggle();
            setToEdit(null);
          }}
          editData={toEdit}
          refetch={refetch}
        />
      ) : null}
    </AssetTypeSC>
  );
};

export default AssetType;
