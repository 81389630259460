import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import AssignDialog from 'components/AssignModal';
import useAssignModalFilters from 'components/AssignModal/useAssignModalFilters';
import Text from 'components/Typography/Text';
import { useFormikContext } from 'formik';
import useAllEmployees from 'hooks/useAllEmployees';
import useDisclosure from 'hooks/useDisclosure';
import styled from 'styled-components';
const SelectEmployees = ({ assignee }) => {
  const [opened, { toggle, open }] = useDisclosure();
  const { setFieldValue, values } = useFormikContext();

  const { searchq, departmentId, setSearchq, setDepartmentId } =
    useAssignModalFilters();
  const query = {
    search: searchq,
    department: departmentId?.label || null
  };

  const employeesQuery = useAllEmployees(query, { enabled: true });

  const openModal = () => {
    open();
    // setSelected([]);
  };

  const emp =
    employeesQuery?.data?.find((item) => item.id === values?.assignee) ??
    assignee ??
    null;

  return (
    <>
      <SelectEmployees.Wrapper onClick={openModal}>
        <Text size='xs'>Select Employee :</Text>
        {emp && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '.5rem'
            }}
          >
            <Text>{emp?.first_name}</Text>
            <Text>{emp?.last_name}</Text>
            <IconButton
              size='small'
              onClick={(e) => {
                e.stopPropagation();
                setFieldValue('assignee', '');
              }}
            >
              <CloseIcon style={{ fontSize: '18px' }} />
            </IconButton>{' '}
          </div>
        )}
      </SelectEmployees.Wrapper>

      <div>
        {opened ? (
          <AssignDialog
            noMultipleSelect
            open={opened}
            toggleOpenModal={toggle}
            moduleName='asset'
            setDepartmentFilter={setDepartmentId}
            setSearchFilter={setSearchq}
            initialSelected={values?.assignee ? [values.assignee] : []}
            title='Select Employee'
            useModule={() => ({
              ...employeesQuery,
              data: employeesQuery.data?.map((employee) => ({
                ...employee,
                job_title: employee?.job?.title,
                department_name: employee?.department?.name
              }))
            })}
            handleSubmit={(selected) => {
              setFieldValue('assignee', selected[0]);
              console.log('submit', { toggle });
              toggle();
            }}
            useModuleAssign={() => {}}
          />
        ) : null}
      </div>
    </>
  );
};

export default SelectEmployees;

SelectEmployees.Wrapper = styled.div`
  border-radius: 8px;
  border: 1px solid #a8a8a8;
  padding: 10.5px 10px 10.5px 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
`;
