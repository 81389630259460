import { useFetchMakeModel, useFetchMakes } from 'Assets/queries';
import { CustomSelect, CustomTextField, DatePicker } from 'components/Inputs';
import Group from 'components/StyledComponents/ButtonGroup';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { assetStatus, conditions, vehicleType } from './data';

const VehicleForm = ({
  locations = [],
  setVehicleName,
  vehicleName,
  editMode
}) => {
  const { values, handleChange, handleBlur, setFieldValue, errors, touched } =
    useFormikContext();
  const { data: makes, isLoading: makeLoading } = useFetchMakes(
    { type: values.vehicle_type },
    { enabled: Boolean(values?.vehicle_type) }
  );
  const { data: models, isLoading: makeVariantLoading } = useFetchMakeModel(
    { id: values.vehicle_make, type: values.vehicle_type },
    { enabled: Boolean(values?.vehicle_make) }
  );

  useEffect(() => {
    const makeName = makes?.find((el) => el.id === values?.vehicle_make)?.name;
    const modelName = models?.find(
      (el) => el.id === values?.vehicle_model
    )?.name;
    setVehicleName(`${makeName} ${modelName}`);
  }, [
    values.vehicle_make,
    values.vehicle_model,
    vehicleName,
    setVehicleName,
    makes,
    models
  ]);

  const getInputProps = (name) => ({
    name,
    value: values[name],
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] && errors[name]
  });

  return (
    <>
      <CustomSelect
        size='small'
        label='Select Vehicle Type'
        options={vehicleType}
        {...getInputProps('vehicle_type')}
      />
      <CustomSelect
        isLoading={makeLoading}
        disabled={makeLoading}
        size='small'
        label='Select Car Make'
        options={
          makes?.map((make) => ({ label: make.name, value: make.id })) || []
        }
        {...getInputProps('vehicle_make')}
      />
      <CustomSelect
        isLoading={makeVariantLoading}
        disabled={makeLoading || makeVariantLoading}
        size='small'
        label='Select Car Model'
        options={
          models?.map((model) => ({ label: model.name, value: model.id })) || []
        }
        {...getInputProps('vehicle_model')}
      />
      <Group>
        <DatePicker
          inputProps={{ fullWidth: true, size: 'small' }}
          fullWidth
          openTo='year'
          views={['year']}
          // minDate={Date.now()}
          label='Year'
          name='year'
          value={values.year}
          onChange={(value) => {
            setFieldValue('year', value);
          }}
        />
        <CustomTextField
          label='color'
          size='small'
          {...getInputProps('color')}
        />
      </Group>
      <CustomTextField
        size='small'
        label='Plate Number'
        {...getInputProps('plate_no')}
      />
      <CustomTextField
        size='small'
        label='Mileage'
        {...getInputProps('mileage')}
      />
      <CustomSelect
        size='small'
        options={locations}
        label='Asset Location'
        {...getInputProps('location')}
      />
      <Group>
        <CustomSelect
          size='small'
          options={assetStatus}
          label='Status'
          {...getInputProps('status')}
        />
        <CustomSelect
          size='small'
          options={conditions}
          {...getInputProps('asset_condition')}
          label='Condition'
        />
      </Group>
    </>
  );
};

export default VehicleForm;
