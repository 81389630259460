import { Divider, Drawer, Stack } from '@mui/material';
import { CustomButton } from 'components/Button';
import Typography from 'components/Typography';
import React from 'react';
import styled from 'styled-components';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import assetDoc from 'Assets/doc/Asset_Vehicle Import Template - Sheet1.csv';
import { Box } from '@material-ui/core';
import { generateCSV } from './helpers';

const Dot = () => {
  return (
    <FiberManualRecordIcon
      fontSize='small'
      sx={{ fontSize: '6px', color: 'var(--545454)', mt: '7px' }}
    />
  );
};
const WithDot = ({ children, icon }) => {
  return (
    <Stack direction='row' gap='10px' alignItems={'start'}>
      {!icon ? <Dot /> : <div className='dot-icon'>{icon}</div>}
      <div>{children}</div>
    </Stack>
  );
};

const AssetImportInfo = ({ open, toggle, handleProceed }) => {
  return (
    <Drawer open={open} onClose={toggle} toggle={toggle} anchor='right'>
      <Wrapper maxWidth={500} px={'1rem'}>
        <div className='import_wrappper'>
          <Stack my='16px' spacing='14px'>
            <Typography bold>Import Asset List</Typography>
            <Divider />
          </Stack>

          <Stack spacing='10px'>
            <Stack spacing={'5px'}>
              <Typography bold className=''>
                Quick Guide: Importing Asset Lists
              </Typography>
              <Typography size={'xs'} light>
                This guide will walk you through importing your asset list into
                our software in just a few easy steps.
              </Typography>
            </Stack>
            <Stack>
              <Typography size='sm' bold>
                Before You Begin:
              </Typography>
              <WithDot>
                <Typography size='xs' light mt='3px'>
                  <a href={assetDoc} download>
                    Download the sample csv file:
                  </a>{' '}
                  It's crucial to use the provided sample CSV file to ensure
                  your asset list is formatted correctly for import. This will
                  help avoid errors during upload.
                </Typography>
              </WithDot>
              <Typography size='xs' mt='5px'>
                <strong>Important Note:</strong> After import, all assets will
                need to be assigned to employees manually if required.
              </Typography>
            </Stack>
            <Stack mt={'8px'} spacing='10px'>
              <Typography bold>Step By Step Guide :</Typography>
              <WithDot icon='1.'>
                <Typography bold size='sm'>
                  Asset/Vehicle Selection
                </Typography>
                <WithDot>
                  <Typography size='xs' light mt='4px'>
                    In the upload file, you'll be need to choose whether you're
                    adding an Asset or a Vehicle.
                  </Typography>
                </WithDot>
              </WithDot>
              <WithDot icon='2.'>
                <Typography bold size='sm'>
                  Asset/Vehicle Type/Category:
                </Typography>
                <Typography size='xs' light mt='4px'>
                  Next, input the appropriate category for your asset or
                  vehicle.
                </Typography>
                <WithDot>
                  <Typography size='xs' light>
                    <strong>For Assets:</strong> Examples include Laptops,
                    Generators, Mobile Phones, etc
                  </Typography>
                </WithDot>
                <WithDot>
                  <Typography size='xs' light>
                    <strong>For Vehicles:</strong> Only use one of
                  </Typography>
                  <WithDot icon='-'>
                    <Typography size='xs' light>
                      Cars
                    </Typography>
                  </WithDot>
                  <WithDot icon='-'>
                    <Typography size='xs' light>
                      Trucks
                    </Typography>
                  </WithDot>
                  <WithDot icon='-'>
                    <Typography size='xs' light>
                      Buses{' '}
                    </Typography>
                  </WithDot>
                  <WithDot icon='-'>
                    <Typography size='xs' light>
                      Bikes{' '}
                    </Typography>
                  </WithDot>
                </WithDot>
              </WithDot>
              <WithDot icon='3.'>
                <Typography bold size='sm'>
                  Vehicle Make & Model (For Vehicles Only):
                </Typography>
                <WithDot>
                  <Typography size='xs' light mt='4px'>
                    When importing a vehicle, the <strong>Vehicle Make</strong>{' '}
                    (e.g., Hyundai, Kia, Toyota) and <strong>Model</strong>{' '}
                    (e.g., Toyota Highlander, Kia Rio) are required fields. In
                    the upload file, you'll be need to choose whether you're
                    adding an Asset or a Vehicle.
                  </Typography>
                </WithDot>
              </WithDot>
              <WithDot icon='4.'>
                <Typography bold size='sm'>
                  Asset Name:
                </Typography>
                <WithDot>
                  <Typography size='xs' light mt='4px'>
                    The <strong> "ASSET NAME"</strong> field is mandatory when
                    importing any asset or vehicle.
                  </Typography>
                </WithDot>
              </WithDot>
              <WithDot icon='5.'>
                <Typography bold size='sm'>
                  Asset Vehicle Status :
                </Typography>
                <WithDot>
                  <Typography size='xs' light>
                    Indicate the current status of your asset or vehicle:
                  </Typography>

                  <WithDot icon='-'>
                    <Typography size='xs' light>
                      Assigned
                    </Typography>
                  </WithDot>
                  <WithDot icon='-'>
                    <Typography size='xs' light>
                      Unassigned
                    </Typography>
                  </WithDot>
                  <WithDot icon='-'>
                    <Typography size='xs' light>
                      In-Repair
                    </Typography>
                  </WithDot>
                  <WithDot icon='-'>
                    <Typography size='xs' light>
                      Damaged
                    </Typography>
                  </WithDot>
                  <WithDot icon='-'>
                    <Typography size='xs' light>
                      Discarded
                    </Typography>
                  </WithDot>
                  <WithDot icon='-'>
                    <Typography size='xs' light>
                      Missing
                    </Typography>
                  </WithDot>
                  <WithDot icon='-'>
                    <Typography size='xs' light>
                      Sold
                    </Typography>
                  </WithDot>
                </WithDot>
              </WithDot>
              <WithDot icon='6.'>
                <Typography bold size='sm'>
                  Asset/Vehicle Condition:
                </Typography>
                <WithDot>
                  <Typography size='xs' light>
                    Finally, select the condition of your asset or vehicle:
                  </Typography>

                  <WithDot icon='-'>
                    <Typography size='xs' light>
                      New
                    </Typography>
                  </WithDot>
                  <WithDot icon='-'>
                    <Typography size='xs' light>
                      Used
                    </Typography>
                  </WithDot>
                </WithDot>
              </WithDot>
              <Typography size='xs' light>
                <strong>That,s it!</strong> Once you've completed these steps
                for each asset in your list, you're ready to upload your CSV
                file and import your assets into the software.
              </Typography>
            </Stack>
          </Stack>
        </div>
        <div className='actions'>
          <CustomButton
            fullWidth
            size='xs'
            py='10px'
            variant={'contained'}
            onClick={handleProceed}
          >
            Proceed To Import
          </CustomButton>
          <CustomButton
            fullWidth
            variant={'offwhite'}
            onClick={generateCSV}
            size='xs'
            py='10px'
          >
            Download Sample Csv
          </CustomButton>
        </div>
      </Wrapper>
    </Drawer>
  );
};

export default AssetImportInfo;

const Wrapper = styled(Box)`
  position: relative;
  height: 100%;
  overflow-y: auto;
  width: 450px;

  .actions {
    position: sticky;
    bottom: 0px;
    width: 98%;
    display: flex;
    gap: 1rem;
    background-color: white;
    padding-block: 0.5rem 1rem;
  }
`;
