import React from 'react';
import { Redirect } from 'react-router-dom';
import { useQuery } from 'react-query';
import { makeStyles } from '@material-ui/core/styles';
import { getBusinessOwner } from '../api/settings';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  loader: {
    width: '20%',
    margin: '10em auto',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '2em auto'
    }
  }
}));

const Index = ({ children, path }) => {
  const classes = useStyles();
  const { data, isSuccess } = useQuery(['current_user'], getBusinessOwner);

  if (!data && !isSuccess) {
    return (
      <div className={classes.loader}>
        <img
          src='https://res.cloudinary.com/dgny8sjrg/image/upload/v1638526892/Bizedge_Frontend_Assets/BizLoader_dzmb4i.gif'
          width={250}
          height={250}
          alt='loading spinner'
        />
      </div>
    );
  } else if (isSuccess && data.business.billing.eligible_to_buy_now) {
    return <Redirect to='/billing/buy-now' />;
  }
  //  else if (
  //   isSuccess &&
  //   data.business.billing.plan === 'free' &&
  //   accessLevels.includes(location.pathname)
  // ) {
  //   return <Redirect to='/404' />;
  // }
  return <Box mx='auto'>{children}</Box>;
};

export default Index;
