import { createContext, useContext, useState } from 'react';

export const VehicleServiceContext = createContext({});

export function VehicleServiceProvider({ children }) {
  const [state, setState] = useState({
    modal: '',
    selectedServices: [],
    editServiceDetails: {},
    toast: false,
    toastMessage: '',
    severity: ''
  });

  return (
    <VehicleServiceContext.Provider value={[state, setState]}>
      {children}
    </VehicleServiceContext.Provider>
  );
}

function useVehicleServiceControl() {
  return useContext(VehicleServiceContext);
}

export default useVehicleServiceControl;
