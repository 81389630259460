import React from 'react';
import styled, { css } from 'styled-components';
const WhiteWrapper = ({ children, bg, minheight, ...props }) => {
  return (
    <Wrapper minheight={minheight} bg={bg} {...props}>
      {children}
    </Wrapper>
  );
};

export default WhiteWrapper;

const Wrapper = styled.div`
  background: ${(props) => props.bg ?? '#fafafa'};
  padding: ${(props) => props.p ?? '29px 26px'};
  border-radius: ${(props) => props?.rounded ?? '20px'};
  /* border: ${(props) =>
    props?.fullborder ? '1px solid #e1e1e1' : props?.border ?? 'none'}; */
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  border-color: ${(props) => props?.borderColor ?? '#e1e1e1'};
  border: ${(props) =>
    props?.fullborder
      ? `1px solid ${props.borderColor ?? '#e1e1e1'}`
      : props?.border ?? 'none'};
  height: ${(props) => props?.height ?? 'auto'};
  min-height: ${(props) => props?.minheight ?? 'auto'};
  ${(props) =>
    props.fullwidth &&
    css`
      width: ${(props) => (props.fullwidth ? '100%' : 'auto')};
      display: grid;
      place-content: center;
    `}
  ${(props) => (props?.css ? props?.css : '')}
`;
